import arrowU from "assets/tool/arrow_u.png";
import arrowUn from "assets/tool/arrow_u_n.png";
import styled from "styled-components";
import React, { useEffect, useState } from "react";
import { useDarkModeManager } from "theme/components/ThemeToggle";
import { MenuItem } from "../Header/components_"
const OptionText = styled.div<{ selected: boolean }>`

  font-size: 14px;
  height: 36px;
  line-height: 36px;
  width: 100%;
  padding: 0 6px;
  box-sizing: border-box;
  margin: 0 auto;
  border-radius: 6px;
  cursor: pointer;
  
  white-space: nowrap;
  background: ${({ selected, theme }) => (selected ? theme.bg2 : theme.bg0)};
  color: ${({ selected, theme }) => (selected ? theme.text1 : theme.text3)};
  :hover{
    background: ${({ theme }) => theme.bg1};
  }
`;


const DropDown = styled.div<{ isopen: boolean }>`
  padding: 7px 7px;
  width: 100%;
  min-width: 80px;

  text-align:left;
  opacity:0;
  height:0;
  display:none;
  border-radius: 6px;
  font-size: 14px;
  background: ${({ theme }) => theme.bg0};
  border: 1px solid ${({ theme }) => theme.bg2};
  position: absolute;
  left: 0;
  top: 39px;
`;
const DropSelect = styled.div`
  padding: 0 7px;
  height: 39px;
  width: 100%;
  border-radius: 6px;
  font-size: 14px;
  background: transparent;
  color: ${({ theme }) => theme.text1};
  position: relative;
  z-index: 1000;
  :hover{
    ${DropDown}{
      opacity:1;  height:auto;
      display:block;
    }
  }
`;
const SelectLine = styled.div`
  display: flex;
  width: 100%;
  height: 39px;
  padding: 0 6px;
  margin: 0 auto;
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  opacity:0.7;
  > div {
    white-space: nowrap;
  }
  :hover{
    opacity:1;
  }
`;
const SelectIcon = styled.img`
  height: 14px;
  width: 14px;
  margin-bottom: 5px;
`;
interface TypeSelectProps {
  size: string;
  defaultText?: string;
  value: string;
  optionArr?: string[];
  typeValues?: string[];
  onChange: (value: string) => void;
}


export default function TypeSelect({
  size, defaultText,
  value, optionArr, typeValues,
  onChange,
}: TypeSelectProps) {
  const [darkMode] = useDarkModeManager();
  const [openDown, setOpenDown] = useState(false);
  const [currentLabel, setCurrentLabel] = useState<string>("");
  // const optionArr = [
  //   "All Type",
  //   "Single offers",
  //   "Collection offers",
  //   "Trait collection offers",
  // ];
  // const typeValues = [ "",
  // "Single offers",
  // "Collection offers",
  // "Trait collection offers",];

  useEffect(() => {
    typeValues?.forEach((e, index) => {
      if (e === value) {
        setCurrentLabel(optionArr?.[index] || '');
      }
    });
  }, [value]);
  return (
    <>
      <DropSelect style={size === 'filed' ? { width: '100%', background: 'transparent' } : size === "big" ? { height: "50px" } : {}}>
        {defaultText ? <SelectLine
          onClick={() => setOpenDown(true)}
          style={size === 'filed' ? { width: '100%', opacity: '1' } : size === "big" ? { height: "50px", lineHeight: "50px", opacity: '1' } : { opacity: '1' }}
        >
          <MenuItem>{defaultText}</MenuItem>

          <SelectIcon src={darkMode ? arrowUn : arrowU} />
        </SelectLine> : <SelectLine
          onClick={() => setOpenDown(true)}
          style={size === 'filed' ? { width: '100%' } : size === "big" ? { height: "50px", lineHeight: "50px" } : {}}
        >
          <div>{currentLabel}</div>

          <SelectIcon src={darkMode ? arrowUn : arrowU} />
        </SelectLine>}

        {openDown ? <DropDown
          isopen={openDown}
          onClick={() => {
            setOpenDown(false);
          }}
          style={size === 'filed' ? { width: '100%' } : size === "big" ? { top: "50px" } : {}}
        >
          {typeValues?.map((e, index) => (
            <OptionText
              selected={value === e}
              onClick={() => {
                onChange(e);
              }}
              style={size === 'filed' ? { width: 'calc(100% - 10px)' } : {}}
            >
              {optionArr?.[index]}
            </OptionText>
          ))}
        </DropDown> : null}
      </DropSelect>
    </>
  );
}
