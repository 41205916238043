import React from "react";
import { useParams } from "react-router-dom";

import styled from "styled-components/macro";

import { useEffect, useState } from "react";

import Modal from "styled-react-modal";

import {
  useModalIsOpen,
  useOpenModal,
  useToggleModal,
} from "../../state/application/hooks";
import { ApplicationModal } from "../../state/application/reducer";

import {
  ButtonPurple,
  ButtonTransparent,
} from "components/Button";
import { useWeb3React } from "@web3-react/core";


import topLogo from "assets/top_logo.png";
import { useGetChainQuery } from "apis/chain";
const StyledModal = Modal.styled`
height:500px;
width:600px;
display: flex;
  align-items: center;
  justify-content: space-around;
  
  flex-direction: column;
  border-radius: 50px;
  padding:30px;
  box-sizing: border-box;
  background-color: ${(props: { theme: any }) => props.theme.bg0};
`;
const LogoImg = styled.img`
  height: 150px;
  width: 150px;
  border-radius: 50%;
  background-color: ${(props: { theme: any }) => props.theme.bg0};
`;
const Title = styled.div`
  font-size: 30px;
  text-align: center;
  font-weight: 600;
  margin: 30px 0;
`;

const TipBlock = styled.div`
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
`;

const Btns = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  > div {
    width: calc(50% - 10px);
  }
`;

export default function AllowSeadropModal({onSumbit}:{onSumbit:  ()=>void}) {


  const openModal = useOpenModal(ApplicationModal.ALLOWED_SEADROP);
  const isOpen = useModalIsOpen(ApplicationModal.ALLOWED_SEADROP);
  const toggleModal = useToggleModal(ApplicationModal.ALLOWED_SEADROP);
  // used for UI loading states
  const [attempting, setAttempting] = useState<boolean>(false);


  const handelAllowedSeadrop = async () => {
    await onSumbit()
    toggleModal();
  };

  useEffect(() => {
    if (attempting) {
      setAttempting(false);
      if (!isOpen) {
        openModal();
      }
    }
  }, [attempting, isOpen, openModal]);
  // console.log("model:", isOpen);
  return (
    <StyledModal
      isOpen={isOpen}
      onBackgroundClick={toggleModal}
      onEscapeKeydown={toggleModal}
    >
      <TipBlock>
        <LogoImg src={topLogo} />
        <Title>Allowed Seadrop</Title>
        Allowed Zooker Seadrop for you contract
      </TipBlock>
      <Btns>
        <div>
          <ButtonPurple
            padding={"10px"}
            $borderRadius={"8px"}
            width={"100%"}
            onClick={handelAllowedSeadrop}
          >
         Submit
          </ButtonPurple>
        </div>
        <div>
          <ButtonTransparent
            padding="10px"
            $borderRadius="8px"
            margin="0 10px 0 0"
            width="100%"
            onClick={toggleModal}
          >
            close
          </ButtonTransparent>
        </div>
      </Btns>
    </StyledModal>
  );
}
