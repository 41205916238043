import styled from "styled-components/macro";

import CollectionBanner from "pages/Crypto/components/HeadBanner";
import { Box, Button, Flex, Text } from "rebass";

import Row from "components/Row";
import Column from "components/Column";

import TokenAvatar from "components/TokenAvatar";
import { Repeat } from "react-feather";
import { Page } from "components/Layout";

import { Token } from "core";
import { useTokenOrderListQuery } from "apis/token_order";

const HeadRow = styled(Row)`
  justify-content: space-between;
  flex-wrap: nowrap;
  > div {
    text-align: left;
    width: 100%;
  }
  > div:last-child {
    text-align: right;
    width: 100%;
  }
`;
const DataRow = styled(Row)`
  justify-content: space-between;
  flex-wrap: nowrap;

  display: flex;
  width: 100%;
  align-items: center;
  border-top: 1px solid ${({ theme }) => theme.border1};
  padding: 12px 0;
  margin: 8px 0;
  box-sizing: border-box;

  > div {
    text-align: left;
    width: 100%;
  }
  > div:last-child {
    text-align: right;
    width: 100%;
  }
`;

const ButtonSell = styled(Button)`
  cursor: pointer;
  background-color: ${({ theme }) => theme.primary1};
  border-radius: 20px;
`;
const ButtonBuy = styled(Button)`
  cursor: pointer;
  background-color: ${({ theme }) => theme.secondary1};
  border-radius: 20px;
`;

export default function Home() {
  const { data } = useTokenOrderListQuery({
    page: 1,
    pageSize: 20,
    key:"",
    chain:""
  });

  return (
    <>
      <CollectionBanner />
      <Page>
        <Box textAlign={"left"} width={1}>
          <Text pb={1} fontSize={36}>
            Latest orders
          </Text>
        </Box>

        <HeadRow fontSize={12}>
          <Column>
            <Text>Crypto</Text>
          </Column>
          <Column>
            <Text>Quote</Text>
          </Column>
          <Column>
            <Text>Unit Price</Text>
          </Column>
          <Column>
            <Text>Operation</Text>
          </Column>
        </HeadRow>
        {data?.data?.map((v) => {
          return (
            <DataRow>
              <Column>
              {v.sell.chain !==v.quote_currency.chain || v.sell.address!==v.quote_currency.address?
                <Flex wrap="nowrap">
                  <TokenAvatar size={32} token={v.sell as Token}></TokenAvatar>
                  <Box ml={3}>
                    <Text>{v.sell_amount}</Text>
                    <Text>{v.sell.symbol}</Text>
                  </Box>
                </Flex>: <Flex wrap="nowrap">
                
                <TokenAvatar size={32} token={v.buy as Token}></TokenAvatar>
                <Box ml={3}>
                  <Text>{v.buy_amount}</Text>
                  <Text>{v.buy.symbol}</Text>
                </Box>
              </Flex>}
              </Column>
              <Column>
                <Flex wrap="nowrap">
                  <TokenAvatar size={32} token={v.buy as Token}></TokenAvatar>
                  <Box ml={3}>
                    <Text>{v.buy_amount}</Text>
                    <Text>{v.buy.symbol}</Text>
                  </Box>
                </Flex>
              </Column>
              <Column>
                <Flex wrap="nowrap">
                  <Box>
                    <Text>{v.price}</Text>
                    <Text>{v.quote_currency.symbol}</Text>
                  </Box>
                
                </Flex>
              </Column>
              <Box>
                <Text fontSize={"12px"} pb={1}>
                  Expires in 6D 23h
                </Text>


                {v.sell.chain !==v.quote_currency.chain || v.sell.address!==v.quote_currency.address?
                    <ButtonSell width={"80px"} height={"40px"}>
                    Buy
                  </ButtonSell>: <ButtonSell width={"80px"} height={"40px"}>
                    Sell
                  </ButtonSell>}

              </Box>
            </DataRow>
          );
        })}

      </Page>
    </>
  );
}
