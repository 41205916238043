// index.jsx
import * as React from 'react';
import * as Popover from '@radix-ui/react-popover';
import styled from "styled-components";

import './styles.css';
const Content = styled(Popover.Content)<{bg?:string}>`
background: ${({ theme,bg }) =>bg?bg: theme.bg0};
border:1px solid ${({ theme }) => theme.border1};
color: ${({ theme,bg }) =>bg?'': theme.text1};
z-index: 999;
padding:2px 5px;
border-radius:4px;
font-size:14px;
`;
const Arrow = styled(Popover.Arrow)`
fill: ${({ theme }) => theme.border1};
`;
const Trigger = styled(Popover.Trigger)`
display: inline-flex;
align-items: center;
justify-content: center;
`;
interface RadixPopoverProps {
  label?: string;
  labelNode?: React.ReactNode;
  val: string;
  bg?: string;
}
const RadixPopover = ({label,val,labelNode}:RadixPopoverProps) => (
  <Popover.Root>
    <Trigger>{labelNode||label}</Trigger>  
    <Content sideOffset={5}>
     {val}
      <Arrow />
    </Content>
</Popover.Root>

);

export default RadixPopover;