import { Input, } from "@rebass/forms";
import {
  CardBox,CardMain,CardItem,CardTitle,CardRow,ItemLabel,CardRowL1,ItemTip,CardRowM,inputStyle
} from "../../components_"

export interface DropEarnings {
  dropEarning: string;
}

interface EarningsFormProps {
  visible: boolean;
  value?: DropEarnings;
  onChange: (value: DropEarnings) => void;
}

export default function EarningsForm({
  visible,
  value,
  onChange,
}: EarningsFormProps) {
  return <>
  <CardBox isAct={visible}>
  <CardMain>
    <CardTitle>Drop Earnings</CardTitle>
    

    <CardItem>
      <ItemLabel>Drop Earnings Addresses</ItemLabel>
      <ItemTip>
        Add a wallet to receive earnings from your primary sale. You
        can add multiplewallets by using a splitter contract. Zooker
        takes a 10% fee on primarysales.
      </ItemTip>
      <CardRow>
        <CardRowL1>
          <Input
            type="text"
            placeholder="Single line entry"
            value={value?.dropEarning}
            sx={inputStyle}
            onChange={(e) => onChange({dropEarning:e.target.value})}
          />
        </CardRowL1>
        <CardRowM>
          <Input
            type="text"
            placeholder="Single line entry"
            value={'97.5'}
            sx={inputStyle}
            disabled
          />
          <div>%</div>
        </CardRowM>
      </CardRow>
      <CardRow>
        <CardRowL1>
          <Input
            type="text"
            placeholder="zooker"
            value={'zooker'}
            sx={inputStyle}
            disabled
          />
        </CardRowL1>
        <CardRowM>
          <Input
            type="text"
            placeholder="Single line entry"
            value={'2.5'}
            sx={inputStyle}
            disabled
          />
          <div>%</div>
        </CardRowM>
      </CardRow>
    </CardItem>
  </CardMain>
</CardBox>
</>;
}
