import arrowU from "assets/tool/arrow_u.png";
import arrowUn from "assets/tool/arrow_u_n.png";
import styled from "styled-components";
import React, { useEffect, useState } from "react";
import { useDarkModeManager } from "theme/components/ThemeToggle";
import { useGetChainListQuery } from "apis/chain"
const OptionText = styled.div<{ selected: boolean }>`

  font-size: 14px;
  height: 36px;
  line-height: 36px;
  width: 100%;
  padding: 0 6px;
  box-sizing: border-box;
  margin: 0 auto;
  border-radius: 6px;
  cursor: pointer;
  white-space:nowrap;
  background: ${({ selected, theme }) => (selected ? theme.bg2 : theme.bg0)};
  color: ${({ selected, theme }) => (selected ? theme.text1 : theme.text3)};
  :hover{
    background: ${({ selected, theme }) => (selected ? theme.bg2 : theme.bg1)};
  }
`;


const DropDown = styled.div<{ isopen: boolean }>`
  padding: 7px 7px;
  min-width: 100%;
  width:auto;
  text-align:left;
  height: 0;
  overflow:hidden;
  display:none;
  // height: ${({ isopen }) => (isopen ? "auto" : "0")};
  // overflow:${({ isopen }) => (isopen ? "visible" : "hidden")};
  // display:${({ isopen }) => (isopen ? " " : "none")};
  // opacity: ${({ isopen }) => (isopen ? "1" : "0")};
  opacity: 1;
  border-radius: 6px;
  font-size: 14px;
  background: ${({ theme }) => theme.bg0};
  border: 1px solid ${({ theme }) => theme.bg2};
  position: absolute;
  left: 0;
  top: 39px;
  :hover{
    opacity: ${({ isopen }) => (isopen ? "1" : "0")};
    opacity:1;
    height: auto;
    overflow:visible;
    display:auto;
  }
`;
const DropSelect = styled.div<{ transparent: boolean }>`
  padding: 0 7px;
  height: 39px;
  min-width: 100%;
  width:auto;
  border-radius: 6px;
  font-size: 14px;
  background: ${({ theme, transparent }) => transparent ? 'transparent' : theme.bg2};
  color: ${({ theme }) => theme.text1};
  position: relative;
  z-index: 9999;
  opacity:0.7;
  :hover{
    opacity:1;
    ${DropDown}{
      opacity:1;
      height: auto;
      overflow:visible;
      display:block;
    }
  }
`;
const SelectLine = styled.div`
  display: flex;
  width: 100%;
  height: 39px;
  padding: 0 6px;
  margin: 0 auto;
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  > div {
    white-space: nowrap;
  }
`;
const SelectIcon = styled.img`
  height: 14px;
  width: 14px;
  margin-bottom: 5px;
`;
interface ChainSelectProps {
  size: string;
  value: string;
  isTransparent?: boolean;
  onChange: (value: string) => void;
}


export default function ChainSelect({
  size,
  value,
  isTransparent,
  onChange,
}: ChainSelectProps) {
  const [darkMode] = useDarkModeManager();
  const [openDown, setOpenDown] = useState(false);
  const [currentLabel, setCurrentLabel] = useState<string>("");
  const [optionArr, setOptionArr] = useState<string[]>([]);
  const [chainValues, setChainValues] = useState<string[]>([]);
  // const optionArr = [
  //   "All Chains",
  //   "Arbitrum",
  //   "BNB Chains",
  //   "Base",
  //   "Polygon",
  //   "Ethereum",
  //   "Goerli",
  //   "Polygon",
  // ];
  // const chainValues = ["", "arbitrum", "bsc", "base", "matic", "ethereum","goerli","polygon"];
  const { data } = useGetChainListQuery({})
  useEffect(() => {
    if (data) {
      console.log('data', data.data)
      if (data.data) {

        let labelList = ["All Chains"]
        let valueList = [""]
        data.data.forEach(el => {
          labelList.push(el.name)
          valueList.push(el?.id || '')
        })
        setOptionArr(labelList)
        setChainValues(valueList)
      }
    }
  }, [data]);
  useEffect(() => {
    chainValues.forEach((e, index) => {
      if (String(e) === String(value)) {
        setCurrentLabel(optionArr[index]);
      }
    });
  }, [value,chainValues]);
  return (
    <>
      <DropSelect transparent={isTransparent || false} style={size === 'filed' ? { width: '100%', background: 'transparent' } : size === "big" ? { height: "50px" } : {}}>
        <SelectLine
          onClick={() => {setOpenDown(true)}}
          style={size === 'filed' ? { width: '100%' } : size === "big" ? { height: "50px", lineHeight: "50px" } : {}}
        >
          <div>{currentLabel}</div>
          <SelectIcon src={darkMode ? arrowUn : arrowU} />
        </SelectLine>
        {openDown?   <DropDown
          isopen={openDown}
          onClick={() => {
            setOpenDown(false);
          }}
          style={size === 'filed' ? { width: '100%' } : size === "big" ? { top: "50px" } : {}}
        >
          {chainValues.map((e, index) => (
            <OptionText
              selected={value === e}
              onClick={() => {
                onChange(e);
              }}
              key={index}
              style={size === 'filed' ? { width: 'calc(100% - 10px)' } : {}}
            >
              {optionArr[index]}
            </OptionText>
          ))}
        </DropDown>:null}
     
      </DropSelect>
    </>
  );
}
