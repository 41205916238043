import React, { useEffect, useState } from "react";
import styled from "styled-components";
import closeIcon from "assets/tool/close_icon.png";
import closeIconN from "assets/tool/close_icon_n.png";
import { Input } from "@rebass/forms";
import { ButtonPurple, ButtonTransparent } from "components/Button";
import UploadCsv from "components/Form/uploadCsv";
import { useDarkModeManager } from "theme/components/ThemeToggle";
import { v1 as uuidv1 } from "uuid";
import {useToastOpen } from 'state/stats/hooks'
import { useUpdateAllowListMutation } from "apis/file";
import {MAX_WIDTH_MEDIA_BREAKPOINT,MIN_WIDTH_MEDIA_BREAKPOINT,LARGE_MEDIA_BREAKPOINT} from "constants/width"


const PopupMain = styled.div<{ isShow: boolean }>`
  background: ${({ theme }) => theme.bg1};
  border-radius: 8px;
  padding: 0 40px 40px;
  box-sizing: border-box;
  font-size: 16px;
  font-family: Inter-Medium, Inter;
  font-weight: 500;
  width: ${({ isShow }) => (isShow ? "800px" : "0")};
  height: ${({ isShow }) => (isShow ? "auto" : "0")};
  overflow: hidden;
  opacity: ${({ isShow }) => (isShow ? "1" : "0")};
  margin: 100px auto;
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}){
    width: 90vw;
    margin: 10vh auto;
    max-height: 80vh;
    overflow: scroll;
  }
`;
const CloseImg = styled.img`
  width: 25px;
  height: 25px;
  cursor: pointer;
`;
const ItemValueIcon = styled.div`
  width: 100%;
  height: 38px;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.border1};
  overflow: hidden;
  display: flex;
  align-items: center;
`;
const PriceSymbol = styled.div`
  margin-right: 10px;
  color: ${({ theme }) => theme.text3};
`;
const InputBlock = styled.div`
 flex:1;
 overflow:hidden
`;
const inputStyle = {
  outline: "none",
  width: "100%",
  height: "40px",
  border: "none",
  textAlign: "left",
  background: "transparent",
};
const inputStyleNum = {
  outline: "none",
  width: "calc(100% + 23px)",
  height: "40px",
  border: "none",
  textAlign: "left",
  background: "transparent",
};
const ItemValueThree = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  > div {
    width: 30%;
    height: 38px;
    border-radius: 4px;
    border: 1px solid ${({ theme }) => theme.border1};
    overflow: hidden;
  }
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}){
    flex-wrap: wrap;
    > div {
      width: 100%;
      height: 38px;
      border-radius: 4px;
      border: 1px solid ${({ theme }) => theme.border1};
      overflow: hidden;
      margin-bottom: 10px;
    }
  }
`;
const ItemValueThree3 = styled.div`
  width: 30%;
  height: 38px;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.border1};
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  > div {
    width: 54px;
    margin-right: 16px;
    font-size: 14px;
    font-family: Inter-Regular, Inter;
    font-weight: 400;
    line-height: 38px;
    color: #828282;
    text-align: right;
  }
`;
const inputStyle2 = {
  outline: "none",
  width: "calc(100% - 86px)",
  height: "40px",
  border: "none",
  textAlign: "left",
  background: "transparent",
};
const inputStyle2Num = {
  outline: "none",
  width: "calc(100% + 23px)",
  height: "40px",
  border: "none",
  textAlign: "left",
  background: "transparent",
};
const ItemUpImg = styled.div`
  width: 100%;
  height: 167px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.border1};
  overflow: hidden;
`;
const UploadBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 20px auto 0;
`;

const CardItem = styled.div`
  margin-bottom: 20px;
  position: relative;
`;
const CardTitle = styled.div`
  padding: 30px 0 10px;
  font-size: 28px;
  font-family: Inter-Semi Bold, Inter;
  font-weight: 600;
  color: ${({ theme }) => theme.text1};
`;
const ItemValue = styled.div`
  width: 100%;
  height: 38px;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.border1};
  overflow: hidden;
`;

const ItemLabel = styled.div`
  margin: 10px 0;
  font-weight: 600;
  > span {
    color: #6a5bda;
    cursor: pointer;
  }
`;
const ItemLabelFlex = styled.div`
  margin: 10px 0;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const ItemTip = styled.div`
  font-size: 14px;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  color: ${({ theme }) => theme.text3};
  line-height: 16px;
  margin-bottom: 10px;
`;

const downOne = () => {
  window.open(
    "https://zooker.oss-ap-northeast-1.aliyuncs.com/allowlistFileTemplate.csv"
  );
};
const defaultMintStage: MintStage = {
  allowlist_file: '',
  is_public:false,
  duration: 0,
  start_time: 0,
  end_time: 0,
  name: "",
  per_wallet_mint_limit: 0,
  sale_price: {
    currency: {
      chain: "",
      contract_address: "",
      decimals: 0,
      image_url: "",
      name: "",
      symbol: "",
    },
    value: "",
  },
};
interface MintStageFormProps {
  chain: string;
  contract_address: string;
  value: MintStage | null;
  isAdd: boolean;
  onEdit: (val:MintStage) => void;
  onAdd: (val:MintStage) => void;
  onClose: () => void;
}

const MintStageForm: React.FC<MintStageFormProps> = ({
  chain,
  contract_address,
  value,
  isAdd,
  onEdit,
  onAdd,
  onClose
}: MintStageFormProps) => {  
  const [,,, setToastOpen] = useToastOpen();
  const [darkMode] = useDarkModeManager();
  const [mintStage, setMintStage] = useState<MintStage>(defaultMintStage);
  const [updateAllowList] = useUpdateAllowListMutation();
  const [presaleKey, setPresaleKey] = useState<string>("");
  const [duration, setDuration] = useState<Duration>({
    days: 0,
    hours: 0,
    minutes: 0,
  });

  useEffect(() => {
    if (value) {
      if(value.sale_price.currency.decimals!==0){
        let num=Number(value.sale_price.value)/(1* 10 ** value.sale_price.currency.decimals)
        setMintStage({...value,...{sale_price:{currency:value.sale_price.currency,value:String(num)}}});
  
      }else{
        setMintStage(value);
      }
      setPresaleKey(value.allowlist_file||'')
      var days = Math.floor(value.duration / (24 * 60));
      var hours = Math.floor((value.duration - days * 24 * 60) / 60);
      var mins = value.duration % 60;
      setDuration({
        days: days,
        hours: hours,
        minutes: mins,
      });
    }

    console.log("MintStageForm.value", value);
  }, [value]);
  useEffect(() => {
    if (duration) {
      let day=duration.days||0
      let hour=duration.hours||0
      let min=duration.minutes||0
      let durationNum=day*24*60+hour*60+min
      let newMintstage={...mintStage,duration:durationNum}
      console.log('newMintstage',newMintstage)
      setMintStage(newMintstage)
    }
    if(presaleKey&&mintStage.allowlist_file!==presaleKey){
      let newMintstage={...mintStage,...{allowlist_file:presaleKey}}
      setMintStage(newMintstage)
    }
    
  }, [duration,presaleKey]);
  
  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    // TODO: Handle form submission
  };

  const addOnefile = async (file: FormData) => {
    // setPresaleUrl(url)

    let uuid = uuidv1();
    let key = `drop/${chain}/${contract_address}/${uuid}`;
    // let key = uuid;
    if (presaleKey) {
      key = presaleKey;
    }
    console.log('key',key)
    file.append("key", key);
    let param = {
      chain: chain as string,
      contract_address: contract_address as string,
      data: file,
    };
    console.log("param", param);
    const { data, success } = await updateAllowList(param).unwrap();
    if (success) {
      setPresaleKey(key);
      console.log("data", data);
      setToastOpen({open:true,type:'success',label: "success"})

    } else {
      setToastOpen({open:true,type:'error',label: "error"})
    }
  };
  return (
    <form onSubmit={handleSubmit}>
      <PopupMain isShow={true}>
        <ItemLabelFlex>
          <CardTitle>Edit {value?.is_public ? "Public" : "Presale"}</CardTitle>
          <CloseImg src={darkMode ? closeIconN : closeIcon} onClick={onClose} />
        </ItemLabelFlex>
        <CardItem>
          <ItemLabel>Name</ItemLabel>
          <ItemValue>
            <Input
              type="text"
              placeholder="Single line entry"
              value={mintStage?.name}
              sx={inputStyle}
              onChange={(e) => setMintStage({...mintStage,...{name:e.target.value}})}
            />
          </ItemValue>
        </CardItem>
        <CardItem>
          <ItemLabel>Sale Price</ItemLabel>
          <ItemValueIcon> <InputBlock>
            <Input
              type="number"
              placeholder="Example:0.01"
              value={mintStage?.sale_price.value.toString()}
              sx={inputStyleNum}
              onChange={(e) => setMintStage({...mintStage,...{sale_price:{value:e.target.value,currency:mintStage.sale_price.currency}}})}
            /> </InputBlock>
            <PriceSymbol>{value?.sale_price.currency.symbol}</PriceSymbol>
          </ItemValueIcon>
        </CardItem>
        <CardItem>
          <ItemLabel>Duration</ItemLabel>
          <ItemValueThree>
            <ItemValueThree3>
            <InputBlock>
              <Input
                type="number"
                placeholder="0"
                value={duration.days}
                sx={inputStyle2Num}
                onChange={(e) => {
                  setDuration({ ...duration, days: parseInt(e.target.value) });
                }}
              /></InputBlock>
              <div>Day</div>
            </ItemValueThree3>
            <ItemValueThree3>
            <InputBlock>
              <Input
                type="number"
                placeholder="0"
                value={duration.hours}
                sx={inputStyle2Num}
                onChange={(e) => {
                    setDuration({ ...duration, hours: parseInt(e.target.value) });
                }}
              /> </InputBlock>
              <div>Hour</div>
            </ItemValueThree3>
            <ItemValueThree3>
            <InputBlock>
              <Input
                type="number"
                placeholder="0"
                value={duration.minutes}
                sx={inputStyle2Num}
                onChange={(e) => { setDuration({ ...duration, minutes: parseInt(e.target.value) });}}
              /> </InputBlock>
              <div>Min</div>
            </ItemValueThree3>
          </ItemValueThree>
        </CardItem>
        <CardItem>
          <ItemLabelFlex>
            <ItemLabel>Per-wallet Mint Limit (optional)</ItemLabel>
          </ItemLabelFlex>
          <ItemValue>
            <Input
              type="number"
              placeholder="0"
              value={mintStage?.per_wallet_mint_limit}
              sx={inputStyleNum}
              onChange={(e) => setMintStage({...mintStage,...{per_wallet_mint_limit:parseInt(e.target.value)}})}
            />
          </ItemValue>
        </CardItem>
        {value?.is_public ? null : (
          <CardItem>
            <ItemLabel>Allowlist</ItemLabel>
            <ItemTip>
              You can set specific mint limits and prices per wallet, which
              willoverride the global sale price and mint limit above for
              thosespecified.
            </ItemTip>
            <ItemUpImg>
              <UploadBox>
                <UploadCsv
                  url={presaleKey}
                  urlChange={(data) => addOnefile(data)}
                ></UploadCsv>
              </UploadBox>
            </ItemUpImg>
            <ButtonTransparent
              padding={"10px"}
              margin={"20px 0 0"}
              $borderRadius={"4px"}
              width={"100%"}
              onClick={() => downOne()}
            >
              Download CSV Template
            </ButtonTransparent>
          </CardItem>
        )}
        <ButtonPurple
          padding={"10px"}
          $borderRadius={"4px"}
          width={"100%"}
          onClick={() => {
            if (isAdd && mintStage) {
              onAdd(mintStage);
            } else if (!isAdd && mintStage) {
              onEdit(mintStage);
            }
          }}
        >
          Apply
        </ButtonPurple>
      </PopupMain>
    </form>
  );
};

export default MintStageForm;
