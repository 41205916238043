import RadixPopconfirm from "components/Popconfirm";
import { useClientWidth } from "state/application/hooks";
import { useDarkModeManager } from "theme/components/ThemeToggle";
import { formatWithArray } from "utils";

import delB from "assets/tool/del_b.png";
import delW from "assets/tool/del_w.png";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import nftDefault from "assets/nft/nft_defult.png";
import starB from "assets/tool/star_b.png";
import starF from "assets/tool/star_f.png";
import starW from "assets/tool/star_w.png";
import { LARGE_MEDIA_BREAKPOINT, MIN_WIDTH_MEDIA_BREAKPOINT } from "constants/width";


export const CardTableMain = styled.div`
  width: 100%;
`;
export const CardTableMaincol = styled.div`
  width: 15%;
  white-space: nowrap;
  font-size: 14px;
  overflow: hidden;
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    display:none;
  }
`
export const CardTableMaincol1 = styled(CardTableMaincol)`
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    display:block;
    width: 10%;
  }
`;
export const CardTableMaincolLast = styled.div`
  width: 5%;
  white-space: nowrap;
  font-size: 14px;
  overflow: hidden;
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    width: 10%;
  }
`
export const CardTableMainRowWrap = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  border-top: 1px solid ${({ theme }) => theme.border1};
  padding: 0 14px;
  box-sizing: border-box;
  height: 96px;
`;
export const CollectInfoWeb = styled.div`
  width: 20%;
  display: flex;
  align-items: center;
  overflow: hidden;
  flex-shrink: 0;
  cursor: pointer;
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    width: 80%;
  }
`;
export const RowImg = styled.img`
  width: 20px;
  height: 20px;
  cursor: pointer;
`;

export const CollectInfoImg = styled.img`
  width: 48px;
  height: 48px;
  border-radius: 6px;
  object-fit: cover;
`;
export const CollectInfoIcon = styled.img`
  width: 14px;
  height: 14px;
`;
export const CollectInfoText = styled.div`
  font-size: 14px;
  font-family: Inter-Medium, Inter;
  font-weight: 500;
  color: ${({ theme }) => theme.text1};
  margin: 0 6px;
  max-width: calc(100% - 80px);
  overflow: hidden;
  text-overflow: ellipsis; //溢出用省略号显示
  white-space: nowrap; //溢出不换行
`;
export const Text3 = styled.span`
  color: ${({ theme }) => theme.text3};
`;

interface ChainSelectProps {
  peruid: string;
  i: Collection;
  type: string;
  iswatch: boolean | null;
  onDelete: () => void;
  onAdd: () => void;
}

export default function CardTableMainRow({ peruid, i, type, iswatch, onDelete, onAdd }: ChainSelectProps) {
  const [darkMode] = useDarkModeManager();
  const clientWidth = useClientWidth();
  const navigate = useNavigate();
  return (
    <>
      <CardTableMainRowWrap>
        <CollectInfoWeb
          onClick={() => {
            navigate(`/nfts/collection/${i.chain}/${i.contract_address}`);
          }}
        >
          <CollectInfoImg src={i.featured_image_url || nftDefault} />
          <CollectInfoText>
            <div> {i.name}</div>
            {Number(clientWidth) < 801 ? <div style={{ marginTop: '4px' }}><Text3>Floor price：</Text3>{i.stats.floor_price}</div>
              : null}
          </CollectInfoText>

          {/* <CollectInfoIcon src={attestationIcon} /> */}
        </CollectInfoWeb>
        <CardTableMaincol1>
          <div> {peruid === "one_day"
            ? formatWithArray(i.stats.one_day_volume)
            : peruid === "six_hour"
              ? formatWithArray(i.stats.six_hour_volume)
              : peruid === "one_hour"
                ? formatWithArray(i.stats?.one_hour_volume)
                : formatWithArray(i.stats.seven_day_volume)}</div>
          {Number(clientWidth) < 801 ? <div style={{ marginTop: '4px' }}>{peruid === "one_day"
            ? (<div style={i.stats.one_day_change<0?{color:'#f00'}:{color:'#0f0'}}>{formatWithArray((i.stats.one_day_change * 100).toFixed(4))}%</div>)
            : peruid === "six_hour"
            ? (<div style={i.stats.six_hour_change<0?{color:'#f00'}:{color:'#0f0'}}>{formatWithArray((i.stats.six_hour_change * 100).toFixed(4))}%</div>)
              : peruid === "one_hour"
              ? (<div style={i.stats.one_hour_change?i.stats.one_hour_change<0?{color:'#f00'}:{color:'#0f0'}:{}}>{formatWithArray((i.stats?.one_hour_change * 100).toFixed(4))}%</div>)
                : (<div style={i.stats.seven_day_change<0?{color:'#f00'}:{color:'#0f0'}}>{formatWithArray((i.stats.seven_day_change * 100).toFixed(4))}%</div>)}
            </div>
            : null}

        </CardTableMaincol1>
        <CardTableMaincol>
          {peruid === "one_day"
            ? (<div style={i.stats.one_day_change<0?{color:'#f00'}:{color:'#0f0'}}>{formatWithArray((i.stats.one_day_change * 100).toFixed(4))}%</div>)
            : peruid === "six_hour"
            ? (<div style={i.stats.six_hour_change<0?{color:'#f00'}:{color:'#0f0'}}>{formatWithArray((i.stats.six_hour_change * 100).toFixed(4))}%</div>)

              : peruid === "one_hour"
              ? (<div style={i.stats.one_hour_change?i.stats.one_hour_change<0?{color:'#f00'}:{color:'#0f0'}:{}}>{i.stats?.one_hour_change?formatWithArray((i.stats.one_hour_change * 100).toFixed(4))+' %':'--'}</div>)

                :  (<div style={i.stats.seven_day_change<0?{color:'#f00'}:{color:'#0f0'}}>{formatWithArray((i.stats.seven_day_change * 100).toFixed(4))}%</div>)}
    
        </CardTableMaincol>

        <CardTableMaincol>{i.stats.floor_price}</CardTableMaincol>
        <CardTableMaincol>
          {peruid === "one_day"
            ? formatWithArray(i.stats.one_day_sales)
            : peruid === "six_hour"
              ? formatWithArray(i.stats.six_hour_sales)
              : peruid === "one_hour"
                ? formatWithArray(i.stats.one_hour_sales)
                : formatWithArray(i.stats.seven_day_sales)}
        </CardTableMaincol>
        <CardTableMaincol>{i.stats.num_owners}</CardTableMaincol>
        {type == 'del' ? (<CardTableMaincolLast>

          <RadixPopconfirm
            val={'Are you sure to delete this?'}
            labelNode={ <RowImg src={darkMode ? delW : delB} />}
            onConfirm={() => {
              onDelete();
            }}
          ></RadixPopconfirm>

      
        </CardTableMaincolLast>) : (

          iswatch ? (
          
            <RadixPopconfirm
            val={'Are you sure to delete this?'}
            labelNode={ <RowImg src={iswatch ? starF : darkMode ? starW : starB} />}
            onConfirm={() => {
              onDelete();
            }}
          ></RadixPopconfirm>

        ) : (<CardTableMaincolLast onClick={() => { onAdd() }}>
            <RowImg src={iswatch ? starF : darkMode ? starW : starB} />
          </CardTableMaincolLast>)


        )}

      </CardTableMainRowWrap>
    </>
  );
}
