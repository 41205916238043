
import { useWeb3React } from "@web3-react/core";
import { useDelWatchOneMutation, useGetCryptoWatchListQuery } from "apis/ranking";
import sortFilled from "assets/tool/sort_filled.png";
import sortIcon from "assets/tool/sort_icon.png";
import NoData from "components/NoData";
import { useEffect, useState } from "react";
import { ApplicationModal, setOpenModal } from 'state/application/reducer';
import { useAppDispatch } from 'state/hooks';
import { useToastOpen, useTokenWatchChain, useTokenWatchPeriod } from 'state/stats/hooks';
import { useToken } from 'state/user/hooks';

import ChainSelect from "components/ChainSelect";
import PeriodSelect from "components/PeriodSelect/indexmore";
import {
  MainBB, MainBottom,
  WebMain,
} from "pages/Nft/NftItems/components_";
import CardTableLoadRow from "../components/CardTableLoadRow/index";
import CardTableMainRow from "../components/CardTableMainRow/topIndex";
import {
  CardTableMain,
  CardTableTitleColFirst, CardTableTitleColLast,
  CardTableTitleColTop,
  CardTableTitleColTop1,
  CardTableTitleRow,
  ChainSelectBlock,
  FilterLine,
  OrderImg,
  PageLine, WebAll
} from "../components_";


import Tab from "../components/Tab";

export function TokensWatchlist() {
  const dispatch = useAppDispatch()
  const token = useToken();
  const { account } = useWeb3React();
  const [loadList] = useState([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]);
  const [tokenWatchPeriod,tokenWatchPeriodLabel,setTokenWatchPeriod] = useTokenWatchPeriod()
  const [tokenWatchChain,setTokenWatchChain] = useTokenWatchChain()
  const [,,, setToastOpen] = useToastOpen();

  const [delWatchOne] = useDelWatchOneMutation()
  const [sortby, setSortby] = useState("change");
  const [direct, setDirect] = useState("desc");
  const [next, setNext] = useState<number>(1);//再次获取watchids

  const [chain, setChain] = useState<string>("");
  const handleChainChange = (value: string) => {
    console.log("value", value);
    if (!value) {
      setTokenWatchChain('');
    } else {
      setTokenWatchChain(value);
    }
  };
  const handleTimeChange = (value: string,label: string) => {
    setTokenWatchPeriod({period:value,label:label});

  };

  const { data: watchlist, status } = useGetCryptoWatchListQuery({
    account: account as string,
    page: 1,
    pageSize: 10,
    chain: tokenWatchChain||'',
    period: tokenWatchPeriod||'',
    direct: direct,
    sortby: sortby,
    next: next
  });
  const getSign = () => {
    dispatch(setOpenModal(ApplicationModal.ACCEPT_SIGN));
  }
  const changeSort = (str: string) => {
    if (sortby === str) {
      if (direct === "asc") {
        setDirect("desc");
      } else if (direct === "desc") {
        setSortby("");
        setDirect("");
      } else if (direct === "") {
        setDirect("asc");
      }
    } else {
      setSortby(str);
      setDirect("asc");
    }
  };
  const delOne = async (i: TopToken, index: number) => {
    if (token) {
      const res = await delWatchOne({
        chain: i.chain,
        contract_address: i.contract_address
      }).unwrap();
      if (res.success) {
        setToastOpen({open:true,type:'success',label:'success'})
        setNext(next + 1)
      } else {
        setToastOpen({open:true,type:'error',label:res.msg})

      }
    } else {//没sign
      getSign()
    }
  }
  //监听拉取数据变化，处理出渲染数据。
  useEffect(() => {
    setNext(next + 1)
  }, []);
  return (
    <>
      <WebAll>
        <WebMain>
          <PageLine>Tokens Stats</PageLine>

          <MainBottom>
            <Tab current="watchlist" />

            <MainBB
              style={{ display: "flex", flexDirection: "column" }}
            >
              <FilterLine>
                <ChainSelectBlock>
                  <ChainSelect
                    value={tokenWatchChain||''}
                    onChange={handleChainChange}
                    size="big"
                  />
                </ChainSelectBlock>
                {/* <PeriodSelect
                  value={peruid}
                  onChange={handleTimeChange}
                  size="big5"
                /> */}
                 <PeriodSelect
                  value={tokenWatchPeriod||''}
                  onChange={handleTimeChange}
                  size="big5"
                />
              </FilterLine>
              <CardTableTitleRow>  <CardTableTitleColFirst>Token</CardTableTitleColFirst>
                <CardTableTitleColTop1 style={{cursor:'auto'}}
                >
                  <div>Price</div>
                </CardTableTitleColTop1>
                <CardTableTitleColTop
                onClick={() => {
                  changeSort("change");
                }}
                >
                  <div>Change({tokenWatchPeriodLabel})</div>
                  <OrderImg
                    src={sortby === "change" ? sortFilled : sortIcon}
                    style={
                      direct === "desc" ? {} : { transform: "rotate(180deg)" }
                    }
                  />
                </CardTableTitleColTop>
                <CardTableTitleColTop
                onClick={() => {
                  changeSort("volume");
                }}
                >
                  <div>Volume({tokenWatchPeriodLabel})</div>
                  <OrderImg
                    src={sortby === "volume" ? sortFilled : sortIcon}
                    style={
                      direct === "desc" ? {} : { transform: "rotate(180deg)" }
                    }
                  />
                </CardTableTitleColTop>
             
                <CardTableTitleColTop
                onClick={() => {
                  changeSort("liquidity");
                }}
                >
                  <div>Liquidity</div>
                  <OrderImg
                    src={sortby === "liquidity" ? sortFilled : sortIcon}
                    style={
                      direct === "desc" ? {} : { transform: "rotate(180deg)" }
                    }
                  />
                </CardTableTitleColTop>
                <CardTableTitleColTop
                  onClick={() => {
                    changeSort("fully_diluted_market_cap");
                  }}
                >
                  <div>Fully Diluted Market Cap</div>
                  <OrderImg
                    src={sortby === "fully_diluted_market_cap" ? sortFilled : sortIcon}
                    style={
                      direct === "desc" ? {} : { transform: "rotate(180deg)" }
                    }
                  />
                </CardTableTitleColTop>
                <CardTableTitleColTop
                  onClick={() => {
                    changeSort("holders");
                  }}
                >
                  <div>Holders</div>
                  <OrderImg
                    src={sortby === "holders" ? sortFilled : sortIcon}
                    style={
                      direct === "desc" ? {} : { transform: "rotate(180deg)" }
                    }
                  />
                </CardTableTitleColTop>
                <CardTableTitleColLast></CardTableTitleColLast>
              </CardTableTitleRow>
              {status === "fulfilled" ? (!watchlist || !watchlist.data || watchlist?.data.length < 1 ? (<NoData onChange={() => setTokenWatchChain('')}></NoData>) : <CardTableMain>
                {watchlist?.data?.map((i: TopToken, index: number) => (
                  <CardTableMainRow
                    i={i}
                    iswatch={i.iswatch || false}
                    type={"del"}
                    onDelete={() => { delOne(i, index) }}
                    onAdd={() => { }}
                  />
                ))}
              </CardTableMain>) : (<CardTableMain>
                {loadList?.map(() => (
                  <CardTableLoadRow />
                ))}
              </CardTableMain>)}

            </MainBB>
          </MainBottom>
        </WebMain>
      </WebAll>
    </>
  );
}
