import React from "react";
import { useNavigate } from "react-router-dom";

import styled from "styled-components/macro";


import { Flex,Text,Box} from "rebass";

import Modal from "styled-react-modal";

import {
  useModalIsOpen,
  useOpenModal,
  useToggleModal,
} from "../../state/application/hooks";
import { ApplicationModal } from "../../state/application/reducer";

import { Trans } from "@lingui/macro";
import {

  ButtonTransparent,
} from "components/Button";

const StyledModal = Modal.styled`
  display: flex;
  align-items: center;
  min-width:500px;
  min-height:300px;
  flex-direction: column;
  border-radius: 14px;
  padding:30px;
  box-sizing: border-box;
  background-color: ${(props: { theme: any }) => props.theme.bg0};
`;
const Title = styled.div`
width:100%;
  font-size: 30px;
  text-align: left;
  font-weight: 600;
`;


export default function PloaceOrderModal() {
  let navigate = useNavigate();
  const openModal = useOpenModal(ApplicationModal.PLACE_ORDER);
  const isOpen = useModalIsOpen(ApplicationModal.PLACE_ORDER);
  const toggleModal = useToggleModal(ApplicationModal.PLACE_ORDER);

  
  return (
    <StyledModal
      isOpen={isOpen}
      onBackgroundClick={toggleModal}
      onEscapeKeydown={toggleModal}
    >
      <Box width={1}>
      <Title>Place order</Title>
      <Text textAlign={"left"}>Buy or sell crypto at the prices you want</Text>
      </Box>
     <Flex marginTop={"20px"}>
          <ButtonTransparent
            padding={"10px"}
            $borderRadius={"8px"}
            width="150px"
            height={"100px"}
            onClick={()=>{
              toggleModal();
              navigate("/cryptos/listing/buy")
            }}
            fontSize={18}
           
          >
            Buy crypto
          </ButtonTransparent>
  
          <ButtonTransparent
            padding="10px"
            $borderRadius="8px"
            margin="0 10px 0 20px"
            width="150px"
            height={"100px"}
            fontSize={18}
            onClick={()=>{
              toggleModal();
              navigate("/cryptos/listing/sell")
            }}
          >
            Sell crypto
          </ButtonTransparent>
      
          </Flex>
    </StyledModal>
  );
}
