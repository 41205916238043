
import { useNavigate } from "react-router-dom";

import styled from "styled-components/macro";

import { useEffect, useState } from "react";
import Modal from "styled-react-modal";
import { v1 as uuidv1 } from "uuid";

import {
  useModalIsOpen,
  useOpenModal,
  useToggleModal,
} from "../../state/application/hooks";
import { ApplicationModal } from "../../state/application/reducer";

import { useWeb3React } from "@web3-react/core";
import {
  ButtonPurple,
  ButtonTransparent,
} from "components/Button";
import { useAccount } from "state/user/hooks";
import { useLoginMutation } from "../../apis/account";

import topLogo from "assets/top_logo.png";
const StyledModal = Modal.styled`
height:500px;
width:600px;
display: flex;
  align-items: center;
  justify-content: space-around;
  
  flex-direction: column;
  border-radius: 50px;
  padding:30px;
  box-sizing: border-box;
  background-color: ${(props: { theme: any }) => props.theme.bg0};
`;
const LogoImg = styled.img`
  height: 150px;
  width: 150px;
  border-radius: 50%;
  background-color: ${(props: { theme: any }) => props.theme.bg0};
`;
const Title = styled.div`
  font-size: 30px;
  text-align: center;
  font-weight: 600;
  margin: 30px 0;
`;
const TipText = styled.div`
  text-align: left;
  font-size: 20px;
`;
const TipBlock = styled.div`
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
`;
const LinkText = styled.span`
  cursor: pointer;
  color: ${(props: { theme: any }) => props.theme.purple1};
`;
const Btns = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  > div {
    width: calc(50% - 10px);
  }
`;

export default function AcceptSignModal() {
  const { account, provider } = useWeb3React();

  let navigate = useNavigate();
  const openModal = useOpenModal(ApplicationModal.ACCEPT_SIGN);
  const isOpen = useModalIsOpen(ApplicationModal.ACCEPT_SIGN);
  const toggleModal = useToggleModal(ApplicationModal.ACCEPT_SIGN);
  // used for UI loading states
  const [attempting, setAttempting] = useState<boolean>(false);
  const [login] = useLoginMutation();
  const [acc, setAccount] = useAccount();
  const handelLogin = async () => {
    var nonce = uuidv1();
    var msg = `Welcome to Zooker!\n\nClick to sign in and accept the Zooker Terms of Service: https://zooker.io/tos\n\nThis request will not trigger a blockchain transaction or cost any gas fees.\n\nYour authentication status will reset after 24 hours.\n\nWallet address:\n${account}\n\nNonce:\n${nonce}`;
    const result = await provider?.getSigner(account).signMessage(msg);
    let signaturestr = result ? result : "";
    const { data } = await login({
      msg: msg,
      signature: signaturestr,
      ref: "0x" + account,
    }).unwrap();
    setAccount(data);
    toggleModal();
  };

  useEffect(() => {
    if (attempting) {
      setAttempting(false);
      if (!isOpen) {
        openModal();
      }
    }
  }, [attempting, isOpen, openModal]);
  // console.log("model:", isOpen);
  return (
    <StyledModal
      isOpen={isOpen}
      onBackgroundClick={toggleModal}
      onEscapeKeydown={toggleModal}
    >
      <TipBlock>
        <LogoImg src={topLogo} />
        <Title>Welcome to Zooker</Title>
        <TipText>
          By connecting your wallet and using Zooker,you agree to our{" "}
          <LinkText
            onClick={() => {
              toggleModal();
              navigate("/tos");
            }}
          >
            Term of Service
          </LinkText>{" "}
          and{" "}
          <LinkText
            onClick={() => {
              toggleModal();
              navigate("/privacy");
            }}
          >
            Privacy Policy
          </LinkText>
        </TipText>
      </TipBlock>
      <Btns>
        <div>
          <ButtonPurple
            padding={"10px"}
            $borderRadius={"8px"}
            width={"100%"}
            onClick={handelLogin}
          >
            accept to sign
          </ButtonPurple>
        </div>
        <div>
          <ButtonTransparent
            padding="10px"
            $borderRadius="8px"
            margin="0 10px 0 0"
            width="100%"
            onClick={toggleModal}
          >
            close
          </ButtonTransparent>
        </div>
      </Btns>
    </StyledModal>
  );
}
