import { useDropzone } from 'react-dropzone';
import styled from "styled-components/macro";
import React, { useCallback, useState } from 'react'
import { useUploadImgMutation } from 'apis/file';
import {useToastOpen } from 'state/stats/hooks'

const UploadBox = styled.div<{width?:string,height?:string,borderRadius?:string}>`
width:${({ width }) => (width ? width : "100px")};
height:${({ height }) => (height ? height : "100px")};
position:relative;
border: 1px dashed ${({ theme }) => theme.border1};
border-radius:${({ borderRadius }) => (borderRadius ? borderRadius : "8px")};
cursor: pointer;
overflow:hidden;
margin-bottom:10px;
:hover{
  border: 1px dashed ${({ theme }) => theme.purple1};
}
`;
const UploadImg = styled.img<{width?:string,height?:string}>`
width:${({ width }) => (width ? width : "100px")};
height:${({ height }) => (height ? height : "100px")};
position:absolute;
left:0;
top:0;
object-fit: cover;
`;
const UploadTip = styled.div<{width?:string,height?:string}>`
width:${({ width }) => (width ? width : "100px")};
height:${({ height }) => (height ? height : "100px")};
display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size:16px;
color:${({ theme }) => theme.text3};
>div:first-child{
  font-size:25px;
}
`;

interface UploadProps {
  id?: string;
  url?: string;
  width?: string;
  height?: string;
  borderRadius?: string;
  urlChange: (url: string) => void;
}
export default function Upload({
  url,
  width,
  height,borderRadius,
  urlChange
}: UploadProps) {
  const [,,, setToastOpen] = useToastOpen();
  const [uploadImg] = useUploadImgMutation()
  const [loading, setLoading] = useState(false);
  const postUploadImg = async (file: File) => {
    const formData = new FormData();
    formData.append('file', file as File);
    try {
      const { data, success } = await uploadImg({ data: formData }).unwrap();
      if (data && data.url) {
        urlChange(data.url)
        setLoading(false)
      }else{
        setLoading(false)
      }
    } catch (err) {
      setLoading(false)
      setToastOpen({open:true,type:'error',label:(err as Error).message})
      console.log('err')
    }
   
  }
  const onDrop = useCallback((acceptedFiles: File[]) => {
    setLoading(true)
    postUploadImg(acceptedFiles[0])
  }, [])

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <section className="container" >
      <UploadBox width={width} height={height} borderRadius={borderRadius} {...getRootProps({ className: 'dropzone' })}>
        <input {...getInputProps()} />
        { loading ? (<UploadTip width={width} height={height}>loading</UploadTip>) :url ? (<UploadImg width={width} height={height} src={url} alt="" />) : (<UploadTip width={width} height={height}>
          <div>+</div>
          <div>Upload</div>
        </UploadTip>)}
      </UploadBox>
      {/* <aside>
        <h4>Files</h4>

      </aside> */}
    </section>
  );
}