import { Input } from "@rebass/forms";
import { useDarkModeManager } from "theme/components/ThemeToggle";

import link1Icon from "assets/nft/link1_icon.png";
import link2Icon from "assets/nft/link2_icon.png";
import link3Icon from "assets/nft/link3_icon.png";
import { CardBox,
  CardMain,
  CardItem,ItemValueIcon,LinkImg,inputStyle1,
  CardTitle,
  ItemLabel,} from "../../components_"

export interface CollectionLinks {
  linkex?: string;
  linkme?: string;
  linkte?:string
}

interface DetailFormProps {
  visible: boolean;
  value?: CollectionLinks;
  onChange: (value: CollectionLinks) => void;
}

export default function DetailForm({
  visible,
  value,
  onChange,
}: DetailFormProps) {
  const [darkMode] = useDarkModeManager();
  const selectStyle = {
    height: "40px",
    width: "100%",
    outline: "none",
    borderRadius: "6px",
    background: darkMode ? "#212429" : "#f7f8fa",
    border: "none",
  };
  const handleChainChange=()=>{}
  return <>
     <CardBox isAct={visible}>
     <CardMain>
              <CardTitle>Links</CardTitle>
              <CardItem>
                <ItemLabel>Links</ItemLabel>
                <ItemValueIcon>
                  <LinkImg src={link1Icon} />
                  <Input
                    type="text"
                    placeholder="yoursite.io"
                    value={value?.linkex}
                    sx={inputStyle1}
                    onChange={(e) => {
                      onChange({linkex:e.target.value})
                    }}
                  />
                </ItemValueIcon>
              </CardItem>
              <CardItem>
                <ItemValueIcon>
                  <LinkImg src={link2Icon} />
                  <Input
                    type="text"
                    placeholder="https://www.medium.com/yourmediumhandle"
                    value={value?.linkme}
                    sx={inputStyle1}
                    onChange={(e) => {
                      onChange({linkme:e.target.value})
                    }}
                  />
                </ItemValueIcon>
              </CardItem>
              <CardItem>
                <ItemValueIcon>
                  <LinkImg src={link3Icon} />
                  <Input
                    type="text"
                    placeholder="https://t.me/abcdef"
                    value={value?.linkte}
                    sx={inputStyle1}
                    onChange={(e) => {
                      onChange({linkte:e.target.value})
                    }}
                  />
                </ItemValueIcon>
              </CardItem>
            </CardMain>
          
          </CardBox>
    </>;
}
