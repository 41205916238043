

import { BaseButton } from "components/Button";
import { Divide, DivideCircle, X } from "react-feather";
import { Button ,Flex,Text} from "rebass";
import styled from "styled-components";
import Modal from "styled-react-modal";
import { Divider, Separator, Z_INDEX } from "theme";
import { useDarkModeManager } from "theme/components/ThemeToggle";
import { Button as RebassButton, ButtonProps as ButtonPropsOriginal } from 'rebass/styled-components'
export const ModalWrapper = Modal.styled`
background: ${(props: { theme: any }) => props.theme.bg1};
border-radius: 8px;

box-sizing: border-box;
font-family: Inter-Medium, Inter;
min-height:auto;
height:auto;
overflow: inherit;
opacity: 1;
margin: 100px auto;
@media screen and (min-width: 0px) and (max-width: 800px) {
  z-index: ${Z_INDEX.modal};
  width: 100%;

  margin: 0;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  box-shadow: unset;
  transition: top ${(props: { theme: any }) =>
    props.theme.transition.duration.medium};
  position: absolute;
  height: 90vh;
  bottom: 0px;
}
`;

const Header=styled(Flex)`
align-items:center;
padding:12px 24px;
justify-content:space-between;
`
const ButtonClose=styled(BaseButton)`
color: ${({ theme }) => theme.text1};
padding:0;
width:auto;
`
interface ModalProps {
  isOpen: boolean;
  modalTitle: string;
  onDismiss?: () => void;
  onSwipe?: () => void;
  height?: number; // takes precedence over minHeight and maxHeight
  minHeight?: number | false;
  maxHeight?: number;
  maxWidth?: number;
  initialFocusRef?: React.RefObject<any>;
  children?: React.ReactNode;
  $scrollOverlay?: boolean;
  hideBorder?: boolean;
}
export function StyledModal({
  isOpen,
  modalTitle,
  onDismiss,
  minHeight = false,
  maxHeight = 90,
  maxWidth = 420,
  height,
  initialFocusRef,
  children,
  onSwipe = onDismiss,
  $scrollOverlay,
  hideBorder = false,
}: ModalProps) {
  const [darkMode] = useDarkModeManager();
  return (
    <>
      <ModalWrapper
        isOpen={isOpen}
        onBackgroundClick={() => {
          onDismiss && onDismiss();
        }}
        onEscapeKeydown={() => {
          onDismiss && onDismiss();
        }}
      >
        <Header>
          <Text fontSize={16} fontWeight={700}>{modalTitle}</Text>
          <ButtonClose  onClick={() => onDismiss && onDismiss()}> <X /></ButtonClose>
       
        </Header>
      
        {children}
      </ModalWrapper>
    </>
  );
}
