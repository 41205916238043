
import { useState, useEffect } from "react";
import UploadDropzone from "components/Form/upload"
import { Input, Textarea } from "@rebass/forms";
import { useDarkModeManager } from "theme/components/ThemeToggle";
import { useToastOpen } from "state/stats/hooks";
import RadixPopconfirm from "components/Popconfirm";

import {ButtonTransparent,ButtonPurple} from "components/Button"
import {
  CardTitle, TeamChange, DropInfoWeb, ItemValueArea1, areaStyle1, TeamItem, TeamImg, TeamInfo, TeamName,
  StyledModal,
} from "../../components_";
import {
  ItemValue, inputStyle, ItemUpImg, ItemLabelFlex, CloseImg, CardItem, AddImgText1, ItemLabel,UploadBox
} from "../../../../components_";
import closeIcon from "assets/tool/close_icon.png";
import closeIconN from "assets/tool/close_icon_n.png";

export interface DropMapForms {
  mapList?: { description: string; image_url: string; name: string; date: string; }[];
}

interface DropMapFormProps {
  visible: boolean;
  value?: DropMapForms;
  onChange: (value: DropMapForms) => void;
}

export default function DropTopForm({
  visible,
  value,
  onChange,
}: DropMapFormProps) {
  const [darkMode] = useDarkModeManager();
  const [, , , setToastOpen] = useToastOpen();

  const [popuptype, setPopuptype] = useState<string>('imglist');
  const [showPopup, setShowPopup] = useState(false);

  const [loading, setLoading] = useState(false);
  const [mapDeac, setMapDeac] = useState<string>('');
  const [mapName, setMapName] = useState<string>('');
  const [mapDate, setMapDate] = useState<string>('');
  const [mapUrl, setMapUrl] = useState<string>('');
  const [mapIndex, setMapIndex] = useState<number>(9999);

  const [mapList, setMapList] = useState<{ description: string; image_url: string; name: string; date: string; }[]>([]);
  useEffect(() => {
    if (value && value.mapList && value.mapList !== mapList) {
      setMapList(value.mapList)
    }
  }, [value]);
  const saveMap = () => {
    if (mapDeac && mapDeac !== '' && mapUrl && mapUrl !== '' && mapName && mapName !== '' && mapDate && mapDate !== '') {
      let list = [...mapList]
      if (mapIndex == 9999) {
        list.push({ description: mapDeac, image_url: mapUrl, name: mapName, date: mapDate })
        setMapList(list)
        setShowPopup(false)
        onChange({ mapList: list })
      } else {
        list[mapIndex] = { description: mapDeac, image_url: mapUrl, name: mapName, date: mapDate }
        setMapList(list)
        setShowPopup(false)
        onChange({ mapList: list })

      }
    } else {
      setToastOpen({ open: true, type: 'error', label: "请完善roadmap信息" })

      return
    }

  }
  const editMap = (index: number) => {
    setMapIndex(index)
    setMapDate(mapList[index].date)
    setMapUrl(mapList[index].image_url)
    setMapName(mapList[index].name)
    setMapDeac(mapList[index].description)
    setPopuptype('map')
    setShowPopup(true)
  }
  const addMap = () => {
    setMapIndex(9999)
    setMapDate('')
    setMapUrl('')
    setMapName('')
    setMapDeac('')
    setPopuptype('map')
    setShowPopup(true)
  }
  const delMap = () => {
    let list = [...mapList]
    list.splice(mapIndex, 1)
    setMapList(list)
    onChange({ mapList: list })

    setShowPopup(false)
  }

  return <>
    <DropInfoWeb isShow={visible} style={{ flexWrap: 'wrap' }}>

      {mapList?.map((i: { description: string; image_url: string; name: string; date: string; }, index: number) => (

        <TeamItem key={index}>
          <TeamImg src={i.image_url} style={{ borderRadius: '8px' }} />
          <TeamInfo>
            <TeamName>{i.name}</TeamName>
            <div>{i.date}</div>
            <div>{i.description}</div>
          </TeamInfo>
          <TeamChange onClick={() => { editMap(index) }}>Edit</TeamChange>
        </TeamItem>

      ))}
      <TeamItem>
        <TeamImg style={{ borderRadius: '8px' }} />
        <TeamInfo>
          <TeamName>Name</TeamName>
          <div>date and time</div>
          <div>Description</div>
        </TeamInfo>
        <TeamChange onClick={() => { addMap() }}>+ Add</TeamChange>
      </TeamItem>
    </DropInfoWeb>
    
    <StyledModal
        isOpen={showPopup && popuptype == "map"}
        onBackgroundClick={() => setShowPopup(!showPopup)}
        onEscapeKeydown={() => setShowPopup(!showPopup)}
      >
        <ItemLabelFlex>
          <CardTitle>{mapIndex == 9999 ? 'Add ' : 'Edit '} roadmap</CardTitle>
          <CloseImg
            src={darkMode ? closeIconN : closeIcon}
            onClick={() => setShowPopup(!showPopup)}
          />
        </ItemLabelFlex>

        <CardItem>
          <ItemLabel>Image *</ItemLabel>
          <ItemUpImg style={{ height: '181px' }}>
          <UploadBox>
                        <UploadDropzone url={mapUrl} urlChange={(url) => setMapUrl(url)}></UploadDropzone>
                    </UploadBox>
        
            <AddImgText1>Drag and drop or click to upload 1:1 aspect ratio. Minimum size of 240px x 240px recommended.</AddImgText1>
          </ItemUpImg>
        </CardItem>
        <CardItem>
          <ItemLabel>Name *</ItemLabel>
          <ItemValue>
            <Input
              type="text"
              placeholder="Enter a name"
              value={mapName}
              sx={inputStyle}
              onChange={(e) => {
                setMapName(e.target.value);
              }}
            />
          </ItemValue>
        </CardItem>
        <CardItem>
          <ItemLabel>Date *</ItemLabel>
          <ItemValue>
            <Input
              type="text"
              placeholder="Enter a date"
              value={mapDate}
              sx={inputStyle}
              onChange={(e) => {
                setMapDate(e.target.value);
              }}
            />
          </ItemValue>
        </CardItem>
        <CardItem>
          <ItemLabel>Description</ItemLabel>
          <ItemValueArea1>
            <Textarea
              id="comment"
              name="comment"
              placeholder="Enter a description"
              value={mapDeac}
              sx={areaStyle1}
              onChange={(e) => {
                setMapDeac(e.target.value);
              }}
            />
          </ItemValueArea1>
          {mapIndex == 9999 ? null : (
            <RadixPopconfirm
            val={'Are you sure to delete this?'}
            labelNode={ <ButtonTransparent padding='10px' $borderRadius='4px' margin="0 0 10px 0" width='100%'>Del</ButtonTransparent>}
            onConfirm={() => {
              delMap();
            }}
          ></RadixPopconfirm>
          
          // <Popconfirm
          //   title="Delete"
          //   description="Are you sure to delete this?"
          //   onConfirm={() => {
          //     delMap()
          //   }}
          //   okText="Yes"
          //   cancelText="No"
          // >
          //   <ButtonTransparent padding='10px' $borderRadius='4px' margin="0 0 10px 0" width='100%'>Del</ButtonTransparent>
           
          // </Popconfirm>
        )}
          <ButtonPurple padding='10px' $borderRadius='4px' width='100%' onClick={()=>saveMap()}>save</ButtonPurple>

        </CardItem>
      </StyledModal>
  </>;
}
