import { useEffect, useState } from "react";
import { ButtonPurple } from "components/Button";
import NoData from "components/NoData";
import { useNavigate } from "react-router-dom";
import {useToastOpen } from 'state/stats/hooks'
import RadixPopconfirm from "components/Popconfirm";

import { useContractDeployedMutation, useAccountCollectionQuery, useAccountCollectionsMutation } from "../../../apis/account";
import { useMyCollectionQuery, useDelCollectionMutation } from "../../../apis/collection";
import {
  ItemBottomName,
  ItemBottomNameText,
} from "pages/Nft/components_";

import { useWeb3React } from "@web3-react/core";
import { useToken } from 'state/user/hooks';
import { useAppDispatch } from 'state/hooks'
import { ApplicationModal, setOpenModal } from 'state/application/reducer';
import { useDarkModeManager } from "theme/components/ThemeToggle";
import menuClose from "../../../assets/tool/menu_close.png";
import menuDel from "../../../assets/tool/menu_del.png";
import menuDelw from "../../../assets/tool/menu_delw.png";
import menuEdit from "../../../assets/tool/menu_edit.png";
import menuEditw from "../../../assets/tool/menu_editw.png";
import menuView from "../../../assets/tool/menu_view.png";
import menuVieww from "../../../assets/tool/menu_vieww.png";
import nftDefault from "assets/nft/nft_defult.png";

import refreshN from "assets/tool/refresh_n.png";
import refresh from "assets/tool/refresh.png";
import { ListSelectBlock, TabItemWeb, TabWeb } from "../Item/components_";
import {
  CollectItem, CollectItemTop,
  CollectTopImg,

  CollectItemBottom,
  CollectItemNone,
  CollectTopImg1,
  DropDown,
  MainTop, LoadIcon,
  OptionIcon,
  OptionText,
  TopLeft,
  TopRightGroup,

} from "./components_";
import {
  WebMain,
  MainBBR,
} from "../../Nft/NftItems/components_";

import CreateErc721AForm from "./components/CreateErc721AForm";
import styled from "styled-components";


export const DeployContract = styled.div<{ isopen: boolean }>`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: ${({ theme }) => theme.modalBG2};
  z-index: 99;
  display: ${({ isopen }) => (isopen ? "flex" : "none")};
  justify-content: center;
  align-items: center;
`;

export default function Home() {
  let navigate = useNavigate();
  const dispatch = useAppDispatch()
  const [,,, setToastOpen] = useToastOpen();
  const [contractDeployed] = useContractDeployedMutation();
  const [delCollection] = useDelCollectionMutation();
  const token = useToken();
  const [accountCollections] = useAccountCollectionsMutation()
  const { account } = useWeb3React();
  // const { data, status } = useMyCollectionQuery({token:token});
  const [darkMode] = useDarkModeManager();
  const [isBeforeToken, setIsBeforeToken] = useState(false);
  const [type, setType] = useState('creator');
  const [next, setNext] = useState<number>(1);
  const [openCreate, setOpenCreate] = useState(false);
  const [isFilter] = useState(true);
  const [openDown, setOpenDown] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selIndex, setSelIndex] = useState(0);
  const [total, setTotal] = useState(1);
  const [accountCount, setAccountCount] = useState(0);
  const [collectionList, setCollectionList] = useState<Collection[] | null>(
    null
  );


  // useEffect(() => {
  //   console.log('datadatadatadata')
  //   if (data && data.success && collectionList == null) {
  //     setTotal(data.total)
  //     setCollectionList(data.data);
  //   }else if(!account){
  //     console.log('notdata')
  //   }
  // }, [data]);

  useEffect(() => {
    const getList = async (account: string, type: string, next: number) => {
      const { data, success, total } = await accountCollections({ account: account as string, type: type, next: next }).unwrap();
      if (success) {
        setTotal(total)
        setCollectionList(data);
      } else {
        setTotal(0)
        setCollectionList([]);

      }
    };
    if (account && type) {
      getList(account as string, type, next)
    }
  }, [account, type, next]);
  useEffect(() => {
    if (token && !isBeforeToken) {
      console.log('get data again')
      setIsBeforeToken(true)
    } else {
      setIsBeforeToken(false)
    }
  }, [token]);
  const getSign = () => {
    dispatch(setOpenModal(ApplicationModal.ACCEPT_SIGN));
  }
  const toPage = (url: string) => {
    if (token) {
      navigate(url)
    } else {
      getSign()

    }
  }
  const handleDelete = async (id: string, index: number) => {
    if (loading) {
      setToastOpen({open:true,type:'error',label:'Loading...'})

      return
    }
    if (collectionList) {
      setLoading(true)
      try {
        const { message, status } = await delCollection({
          chain: collectionList[index].chain,
          contract_address: collectionList[index].contract_address,
        }).unwrap();
        if (status === 'success') {
          let templist: Collection[] = [...collectionList]
          templist.splice(index, 1)
          setCollectionList(templist)
          setLoading(false)
          setToastOpen({open:true,type:'success',label:"success"})

          setOpenDown(false);
        } else {
          setLoading(false)
          setToastOpen({open:true,type:'error',label:message})

        }
      } catch (err) {
        setLoading(false)
        setToastOpen({open:true,type:'error',label:(err as Error).message})

      }

    }

  };

  return (
    <>
      <WebMain>

        <MainTop>
          <TopLeft>My Collections</TopLeft>
          <TopRightGroup>
            <ButtonPurple padding='10px' $borderRadius='4px' width='auto' margin='0 20px 0 0' onClick={() => setOpenCreate(true)}>create a collection</ButtonPurple>
            {/* <ButtonPurple padding='10px' $borderRadius='4px' width='auto' onClick={() => navigate("/import-nft")}>submit a collection</ButtonPurple> */}
          </TopRightGroup>
        </MainTop>
        <TabWeb style={{ marginTop: '30px', marginBottom: '0' }}>
          <div>
            <TabItemWeb isAct={type === 'creator'} onClick={() => { setType('creator'); setNext(next + 1); }}>Created</TabItemWeb>
            {/* <TabItemWeb isAct={current === 'offers'} onClick={() => navigate('/account/offers')}>Offers made</TabItemWeb> */}
            <TabItemWeb isAct={type === 'owner'} onClick={() => { setType('owner'); setNext(next + 1); }}>Owner</TabItemWeb>



          </div>



        </TabWeb>
        <MainBBR isFilter={isFilter}>

          {collectionList?.map((i: Collection, index: number) => (
            <CollectItem key={i.id}>
              <CollectItemTop>
                <CollectTopImg
                  src={i.featured_image_url || nftDefault}
                  alt=""
                  onClick={() => toPage(`/nfts/collection/${i.chain}/${i.contract_address}`)}
                />
              </CollectItemTop>
              <CollectItemBottom>
                <CollectTopImg1 src={i.image_url || nftDefault} alt="" />

                <ItemBottomName>
                  <ItemBottomNameText>{i.name}</ItemBottomNameText>
                </ItemBottomName>
              </CollectItemBottom>
              <img
                onClick={() => {
                  setOpenDown(!openDown);
                  setSelIndex(index);
                }}
                src={menuClose}
                style={{
                  position: "absolute",
                  right: "9px",
                  top: "9px",
                  cursor: "pointer",
                  width: "20px",
                  height: "20px",
                }}
              />
              <DropDown
                style={{ zIndex: "999" }}
                isopen={openDown && selIndex === index}
              >
                <OptionText
                  selected={false}
                  onClick={() => toPage(`/nfts/${i.chain}/${i.contract_address}/edit`)}
                >
                  <OptionIcon src={darkMode ? menuEditw : menuEdit} />
                  <div>Edit Collection</div>
                </OptionText>
                {loading ? (
                  <OptionText
                    selected={false}
                    onClick={() => { }}
                  >
                    <LoadIcon src={darkMode ? refreshN : refresh} style={{
                      width: '14px',
                    }} />
                    <div style={{ marginLeft: '4px' }}>Delete Collection</div>
                  </OptionText>
                ) : (
                  <RadixPopconfirm
                  val={'Are you sure to delete this?'}
                  labelNode={ <OptionText selected={false}>
                 
                  <OptionIcon src={darkMode ? menuDelw : menuDel} />

                  <div>Delete Collection</div>
                </OptionText>}
                  onConfirm={() => {
                    handleDelete(i.id, index);
                  }}
                ></RadixPopconfirm>
                
                // <Popconfirm
                //   title="Delete"
                //   description="Are you sure to delete this?"
                //   onConfirm={() => {
                //     handleDelete(i.id, index);
                //   }}
                //   okText="Yes"
                //   cancelText="No"
                // >
                 
                //   <OptionText selected={false}>
                 
                //     <OptionIcon src={darkMode ? menuDelw : menuDel} />

                //     <div>Delete Collection</div>
                //   </OptionText>
                // </Popconfirm>
              )}

              </DropDown>
            </CollectItem>
          ))}
          <CollectItemNone
            style={{ opacity: "0", height: "0", overflow: "hidden" }}
          ></CollectItemNone>
          <CollectItemNone
            style={{ opacity: "0", height: "0", overflow: "hidden" }}
          ></CollectItemNone>
          <CollectItemNone
            style={{ opacity: "0", height: "0", overflow: "hidden" }}
          ></CollectItemNone>
          {/* {status === 'fulfilled' && total < 1 ? (<NoData onChange={() => { }}></NoData>) : null} */}
          {total < 1 ? (<NoData onChange={() => { }}></NoData>) : null}

        </MainBBR>
      </WebMain>

      <DeployContract isopen={openCreate}>
        <CreateErc721AForm
          size={""}
          value={""}
          onClose={function (): void {
            setOpenCreate(false);
          }}
          onChange={function (value: string): void {
            setNext(next + 1);
            throw new Error("Function not implemented.");

          }}
        />
      </DeployContract>
    </>
  );
}
