
import styled, { keyframes } from 'styled-components/macro'
import {MAX_WIDTH_MEDIA_BREAKPOINT,MIN_WIDTH_MEDIA_BREAKPOINT,LARGE_MEDIA_BREAKPOINT} from "constants/width"
export const MediaNone = styled.div`
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
 
    display:none !important;
  }
`;
export const TrendLineText1 = styled.div`
font-size: 12px;
font-family: Inter-Regular, Inter;
font-weight: 400;
color: ${({ theme }) => theme.text3};
line-height: 14px;
> span {
  color: ${({ theme }) => theme.text1};
}
`;
export const TrendRight = styled.div`
width: 30%;
text-align: right;
font-size: 14px;
font-family: Arial-Regular, Arial;
font-weight: 400;
color: ${({ theme }) => theme.text1};
line-height: 16px;
font-weight: bold;
overflow:hidden
`;
export const TrendRight1 = styled.div`
width: calc(30% - 20px);
text-align: right;
font-size: 14px;
font-family: Arial-Regular, Arial;
font-weight: 400;
color: ${({ theme }) => theme.text1};
line-height: 16px;
font-weight: bold;
overflow:hidden
`;
export const TrendRightTop = styled.div`
display: flex;
align-items: center;
height: 40px;
flex-direction: row-reverse;
`;
export const TrendRightImg = styled.img`
width: 15px;
height: 15px;
`;
export const TrendRightBottom = styled.div`
font-size: 12px;
font-family: Arial-Regular, Arial;
font-weight: 400;
color: #28c968;
line-height: 14px;
`;

export const TrendItemTel = styled.div`

width: 100%;
height:77px;
padding:11px 0 13px;
border-bottom: 1px solid ${({ theme }) => theme.border1};
box-sizing:border-box;
display: flex;
align-items: center;
justify-content: space-between;
font-size: 14px;
font-family: Arial-Regular, Arial;
font-weight: 400;
color: ${({ theme }) => theme.text2};
line-height: 16px;
`;
export const TrendIndex = styled.div`
width: 6%;
flex-shrink: 0;
`;
export const TrendImg = styled.img`
flex-shrink: 0;
width: 10%;
min-width: 70px;
min-height: 70px;
margin-left: 5px;
margin-right: 5px;
border-radius:8%;
`;

export const TrendImgNone = styled.div`
flex-shrink: 0;
width: 10%;
min-width: 70px;
min-height: 70px;
margin-left: 5px;
margin-right: 5px;
border-radius:8%;
background: ${({ theme }) => theme.bg2};
`;
export const DivNone = styled.div`
white-space:pre;

color: ${({ theme }) => theme.bg2};
background: ${({ theme }) => theme.bg2};
`;

export const TrendImgTel = styled.img`
flex-shrink: 0;
width: 53px;
height: 53px;
border-radius:4px;
`;


export const TrendTextLoad = styled.div`

background: ${({ theme }) => theme.bg2};
color: ${({ theme }) => theme.bg2};
white-space:pre;
`;
export const TrendImgTelLoad = styled.div`
flex-shrink: 0;
width: 53px;
height: 53px;
background: ${({ theme }) => theme.bg2};
border-radius:4px;
margin-left:4px
`;

export const TrendMain = styled.div`
padding: 0 10px;
box-sizing: border-box;
flex: 1;
`;
export const TrendMainTel = styled.div`
max-width: 50%;
padding: 0 10px;
box-sizing: border-box;
flex: 1;
`;
export const TrendLine = styled.div`
display: flex;
align-items: center;
max-width: 100%;
overflow: hidden;
text-overflow: ellipsis;
white-space: nowrap;
`;
export const TrendLineImg = styled.img`
width: 12px;
height: 12px;
`;
export const TrendLineText = styled.div`
font-size: 18px;
font-family: Inter-Regular, Inter;
font-weight: 400;
color: ${({ theme }) => theme.text2};
line-height: 40px;
max-width: calc(100% - 20px);
overflow: hidden;
white-space: nowrap;
  text-overflow: ellipsis;
  -o-text-overflow:ellipsis;
`;


export const PageLine = styled.div`
padding-top:40px;
padding-bottom:40px;
font-size: 38px;
line-height:44px;
color:${({ theme }) => theme.text1};
`
export const SetBtn = styled.div`
width: 128px;
height: 41px;
border-radius: 4px;
border: 1px solid #6A5BDA;
display: flex;
    justify-content: center;
    align-items: center;
font-size: 16px;
font-family: Inter-Regular, Inter;
font-weight: 400;
color: #6A5BDA;

`
export const SetBtnImg = styled.img`
width: 21px;
height: 21px;
margin-right:9px;
`

export const MainBottomTitleItem = styled.div<{ isAct: boolean }>`
margin-right:50px;
margin-bottom:30px;
line-height:56px;
padding:0 25px;
height: 56px;
cursor: pointer;
color:${({ isAct, theme }) => (isAct ? theme.text1 : theme.text3)};
background:${({ isAct, theme }) => (isAct ? theme.bg2 : "transparent")};

border-radius: 6px;
@media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
  margin-right:20px;
  margin-bottom:10px;
  line-height:40px;
  padding:0 10px;
  height: 40px; 
}
`

export const ChainSelectBlock = styled.div`
max-width:200px;
min-width:150px;
width:30%;
`
export const MainBottomTitleItemTel = styled.div<{ isAct: boolean }>`
margin-right:20px;
margin-bottom:10px;
line-height:40px;
padding:0 10px;
height: 40px;
cursor: pointer;
color:${({ isAct, theme }) => (isAct ? theme.text1 : theme.text3)};
background:${({ isAct, theme }) => (isAct ? theme.bg2 : "transparent")};
border-radius: 6px;
`
export const FilterLine=styled.div`
display: flex;
align-items: center;
justify-content: space-between;
padding:30px 0;
width:100%;
@media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
  width: 125%;
  transform: scale(0.8);
  transform-origin: 0 0;
}
`
export const TitleBtns = styled.div`
display: flex;
// width: 229px;
height:50px;
border-radius: 6px;
border: 1px solid ${({ theme }) => theme.border1};
background: ${({ theme }) => theme.bg0};
align-items: center;
overflow: hidden;
margin-right: 18px;
`;
export const TitleBtnsTel = styled.div`
display: flex;
height:35px;
border-radius: 6px;
border: 1px solid ${({ theme }) => theme.border1};
background: ${({ theme }) => theme.bg0};
align-items: center;
overflow: hidden;
margin-right: 6px;
`;
export const BtnText = styled.div`

padding:0 21px;
text-align: center;
font-size:20px;
font-family: Inter-Regular, Inter;
font-weight: 400;
color: ${({ theme }) => theme.text2};
line-height: 50px;
background: ${({ theme }) => theme.btnbg3};
`;
export const BtnTextTel = styled.div`

padding:0 10px;
text-align: center;
font-size:15px;
font-family: Inter-Regular, Inter;
font-weight: 400;
color: ${({ theme }) => theme.text2};
line-height: 35px;
background: ${({ theme }) => theme.btnbg3};
`;
export const ImgList = styled.div`
display: flex;
flex: 1;
align-items: center;
justify-content: space-around;
background: ${({ theme }) => theme.bg0};
height: 41px;
`;
export const ImgOne = styled.img`
width: 27px;
height: 27px;
margin:0 10px;
`;
export const ImgListTel = styled.div`
display: flex;
flex: 1;
align-items: center;
justify-content: space-around;
background: ${({ theme }) => theme.bg0};
height: 35px;
`;
export const ImgOneTel = styled.img`
width: 20px;
height: 20px;
margin:0 5px;
`;
export const BtnOne = styled.div`
width: 100px;
height: 41px;
border-radius: 6px;
border: 1px solid ${({ theme }) => theme.border1};
background: transparent;
text-align: center;
font-size: 15px;
font-family: Inter-Regular, Inter;
font-weight: 400;
color: ${({ theme }) => theme.text2};
line-height: 41px;
`;
export const FilterR=styled.div`
display: flex;
align-items: center;
justify-content: space-around;
border:1px solid ${({ theme }) => theme.border1};
border-radius: 6px;
`
export const FilterRItem=styled.div<{isAct:boolean}>`
width:50px;
height:50px;
line-height:50px;
text-align:center;
font-size: 20px;
border-radius: 6px;
background: ${({ theme,isAct }) => isAct?theme.bg2:theme.bg0};
color: ${({ theme,isAct }) =>isAct?theme.text1:theme.text3};
cursor: pointer;
`
export const FilterRItemTel=styled.div<{isAct:boolean}>`
width:35px;
height:35px;
line-height:35px;
text-align:center;
font-size: 14px;
border-radius: 6px;
background: ${({ theme,isAct }) => isAct?theme.bg2:theme.bg0};
color: ${({ theme,isAct }) =>isAct?theme.text1:theme.text3};
cursor: pointer;
`
export const OrderImg = styled.img`
  width: 20px;
  height: 20px;
  cursor: pointer;
`;
export const CardTableTitleRow = styled.div`
width:100%;
display:flex;
align-items:center;
padding:0 14px;
box-sizing:border-box;
height:37px;
color: ${({ theme }) => theme.text3};
>div{
  
  white-space:nowrap;
  font-size:12px;
  overflow:hidden;
  display:flex;
  align-items:center
}

`;

export const CardTableTitleColFirst = styled.div`

  width:20%;
  white-space:nowrap;
  font-size:12px;
  overflow:hidden;
  display:flex;
  align-items:center;
  cursor: auto;
  
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
 
    width:80%;
  }
`;

export const CardTableTitleCol = styled(MediaNone)`

  width:15%;
  white-space:nowrap;
  font-size:12px;
  overflow:hidden;
  display:flex;
  align-items:center;
  cursor: pointer;
`;
export const CardTableTitleCol1 = styled(CardTableTitleCol)`
@media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
  display:flex !important;
  width: 10%;
}
`;
export const CardTableTitleColTop = styled(MediaNone)`

  width:12.5%;
  white-space:nowrap;
  font-size:12px;
  overflow:hidden;
  display:flex;
  align-items:center;
  cursor: pointer;
`;
export const CardTableTitleColTop1 = styled(CardTableTitleColTop)`
@media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
  display:flex !important;
  width: 12.5%;
}
`;
export const CardTableMain = styled.div`
width:100%;
`;
export const CollectInfoWeb = styled.div`
width:20%;
display:flex;
align-items:center;
overflow:hidden;
flex-shrink: 0;
cursor: pointer;
@media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
  width:90%;
}
`;
export const RowImg = styled.img`
width:20px;
height:20px;
cursor: pointer;
`;

export const CollectInfoImg = styled.img`
width:48px;
height:48px;
border-radius:6px;
object-fit:cover
`;
export const CollectInfoIcon = styled.img`
width:14px;
height:14px;
`;
export const CollectInfoText = styled.div`
font-size: 14px;
font-family: Inter-Medium, Inter;
font-weight: 500;
color:${({ theme }) => theme.text1};
margin:0 6px;
max-width: calc(100% - 80px);
overflow:hidden;
text-overflow: ellipsis; //溢出用省略号显示
white-space: nowrap; //溢出不换行
`;


export const WebTop = styled.div<{ isTel: boolean }>`
  width: ${({ isTel }) => (isTel ? "0" : "100vw")};
  height: ${({ isTel }) => (isTel ? "0" : "19vw")};
  position: relative;
  opacity: ${({ isTel }) => (isTel ? "0" : "1")};
  overflow: hidden;
`;
export const TopImg = styled.img<{ isTel: boolean }>`
  width: ${({ isTel }) => (isTel ? "0" : "100vw")};
  height: ${({ isTel }) => (isTel ? "0" : "19vw")};
  opacity: ${({ isTel }) => (isTel ? "0" : "1")};
  overflow: hidden;
`;


export const WebAll = styled.div`
width:100%;
min-height:calc(100vh - 370px);
background:${({ theme }) => theme.bg0};
`;
export const MainInfo = styled.div``;

export const InfoImg = styled.img`
  height: 11.5vw;
  width: 11.5vw;
  max-width: 220px;
  max-height: 220px;
`;
export const AttesImg = styled.img`
  width: 18px;
  height: 18px;
  margin-left: 4px;
`;
export const AttesImg1 = styled.img`
  width: 14px;
  height: 14px;
  margin-right: 4px;
`;
export const InfoNameRow = styled.div`
  margin-top: 17px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const InfoNameCol = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
`;
export const AddressText = styled.div`
  margin: 0 3px;
  font-size: 14px;
  font-family: Inter-Bold, Inter;
  font-weight: normal;
  color: ${({ theme }) => theme.text3};
  line-height: 20px;
`;
export const NameText = styled.div`
  font-size: 24px;
  font-family: Inter-Bold, Inter;
  font-weight: bold;
  color: ${({ theme }) => theme.text1};
  line-height: 28px;
`;
export const NameTextTel = styled.div`
  font-size: 18px;
  font-family: Inter-Bold, Inter;
  font-weight: bold;
  color: ${({ theme }) => theme.text1};
  line-height: 21px;
`;
export const InfoNameImg = styled.img`
  margin-left: 24px;
  width: 24px;
  height: 24px;
`;
export const InfoNameImgTel = styled.img`
  margin-right: 13px;
  width: 18px;
  height: 18px;
`;

export const EthImg = styled.img`
  margin-right: 2px;
  width: 12px;
  height: 12px;
`;
export const ArrowImg = styled.img`
  margin-left: 2px;
  width: 12px;
  height: 12px;
`;


export const MainBottomTitle = styled.div`
  margin: 10px 0 0;
  font-size: 20px;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  color: ${({ theme }) => theme.text3};
  display: flex;
  align-items: center;
  border-bottom:1px solid ${({ theme }) => theme.border1};
`;
export const MainBottomTitleTel = styled.div`
  margin: 10px 0 0;
  font-size: 15px;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  color: ${({ theme }) => theme.text3};
  display: flex;
  align-items: center;
  border-bottom:1px solid ${({ theme }) => theme.border1};
`;

export const CardTableTitleColLast = styled.div`

  width:5%;
  white-space:nowrap;
  font-size:12px;
  overflow:hidden;
  display:flex;
  align-items:center;
  cursor: pointer;
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
 
    width:10%;
  }
`;