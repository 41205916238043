import {
  TrendImgNone, DivNone, TrendItem, TrendIndex, TrendMain, TrendLine, TrendLineText,
  TrendLineText1, TrendRight, TrendRightTop, TrendRightBottom, TrendItemTel, TrendTextLoad, TrendImgTelLoad,
  TrendMainTel, SpotItem, SpotItemImgNone, SpotItemText, SpotItemTel, SpotItemImgTelLoad, PopCollectionItem, PopCollectionLine, PopCollectionImgBlock,
  PopCollectionName, SearchCollectionItem, SearchCollectionLine, SearchCollectionImgBlock, SearchImgNone
} from "pages/Nft/components_";
import {
  PublicCardBottomL,
  PublicCardBottomR,
  PublicCardBottomWeb,
  PublicCardPrice,
  PublicCardWeb,
} from "pages/Drop/components_";
import {
  DropTitleWeb,
} from "pages/Nft/EditCollection/components/DropPageForm/components_";
export function TrendLoadItem() {
  return (
    <>
      <TrendItem>
        <TrendIndex>
          <DivNone>{"   "}</DivNone>
        </TrendIndex>
        <TrendImgNone></TrendImgNone>
        <TrendMain>
          <TrendLine>
            <TrendLineText>
              <DivNone>{"    "}</DivNone>
            </TrendLineText>
          </TrendLine>
          <TrendLine>
            <TrendLineText1>
              <DivNone>{"          "}</DivNone>
            </TrendLineText1>
            <TrendLineText1>
              <DivNone>{"    "}</DivNone>
            </TrendLineText1>
          </TrendLine>
        </TrendMain>
        <TrendRight>
          <TrendRightTop>
            <DivNone>{"    "}</DivNone>
          </TrendRightTop>
          <TrendRightBottom>
            <DivNone> </DivNone>
          </TrendRightBottom>
        </TrendRight>
      </TrendItem>
    </>
  );
}
export function LoadMintStage() {
  return (
    <>
      <PublicCardWeb>
        <DropTitleWeb><DivNone>{'     '}
        </DivNone></DropTitleWeb>
        <PublicCardPrice>
          <DivNone>{'            '}
          </DivNone>
        </PublicCardPrice>
        <PublicCardBottomWeb>
          <PublicCardBottomL>
            <div >-</div>
            <div>1</div>
            <div>+</div>
          </PublicCardBottomL>


          <PublicCardBottomR
            style={{ lineHeight: "56px" }}
          >{ }
          </PublicCardBottomR>

        </PublicCardBottomWeb>
      </PublicCardWeb>
    </>
  );
}
export function PopCollectionLoadItem() {
  return (
    <>
      <PopCollectionItem><PopCollectionLine>
        <div>  <TrendIndex>
          <DivNone>{"   "}</DivNone>
        </TrendIndex>
          <PopCollectionImgBlock>
            <TrendImgNone></TrendImgNone>
          </PopCollectionImgBlock>
          <DivNone style={{ marginLeft: '5px' }}>{"         "}</DivNone></div>
        <div>
          <DivNone>{"       "}</DivNone></div>
        <div> <DivNone>{"       "}</DivNone></div>
        <div> <DivNone>{"         "}</DivNone></div></PopCollectionLine>

      </PopCollectionItem>
    </>
  );
}
export function SearchCollectionLoadItem() {
  return (
    <>
      <SearchCollectionItem><SearchCollectionLine>
        <div>
          <SearchCollectionImgBlock>
            <SearchImgNone></SearchImgNone>
          </SearchCollectionImgBlock>
          <DivNone style={{ marginLeft: '5px' }}>{"         "}</DivNone></div>

        <div> <DivNone>{"         "}</DivNone></div></SearchCollectionLine>

      </SearchCollectionItem>
    </>
  );
}
export function SpotLoadItem() {
  return (
    <>
      <SpotItem>
        <SpotItemImgNone></SpotItemImgNone>
        <SpotItemText>
          <DivNone>{" "}</DivNone>
        </SpotItemText>
      </SpotItem>
    </>
  );
}
export function SpotLoadItemTel() {
  return (
    <>
      <SpotItemTel>
        <SpotItemImgTelLoad />
        <TrendTextLoad style={{ marginTop: '5px' }}> {" "}</TrendTextLoad>
      </SpotItemTel>

    </>
  );
}
export function TrendLoadItemTel() {
  return (
    <>
      <TrendItemTel>
        <TrendTextLoad>{"      "}</TrendTextLoad>
        <TrendImgTelLoad />
        <TrendMainTel>
          <TrendRightTop style={{ flexDirection: "row" }}>
            <TrendTextLoad>{"      "}</TrendTextLoad>
          </TrendRightTop>
          <TrendRightBottom style={{ display: "flex", flexDirection: "row" }}>
            <TrendTextLoad>{"              "}</TrendTextLoad>
          </TrendRightBottom>
        </TrendMainTel>
        <TrendRight>
          <TrendRightTop>
            <TrendTextLoad>{"      "}</TrendTextLoad>
          </TrendRightTop>
          <TrendRightBottom>
            <TrendTextLoad>{"      "}</TrendTextLoad>
          </TrendRightBottom>
        </TrendRight>
      </TrendItemTel>
    </>
  );
}
