import styled, { keyframes } from "styled-components/macro";
import Modal from "styled-react-modal";
import {MAX_WIDTH_MEDIA_BREAKPOINT,MIN_WIDTH_MEDIA_BREAKPOINT,LARGE_MEDIA_BREAKPOINT} from "constants/width"

export const StyledModal = Modal.styled`
background: ${(props: { theme: any }) => props.theme.bg1};
border-radius: 8px;
padding: 0 40px 20px;
box-sizing: border-box;
font-size: 16px;
font-family: Inter-Medium, Inter;
font-weight: 500;
width: 470px;
min-height:auto;
height:auto;
overflow: hidden;
opacity: 1;
margin: 100px auto;
@media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
  width: 95vw;
  min-width: 0px;
  max-height:calc(100vh - 200px);
  margin:100px auto;
  overflow-y: scroll;
}
`;

export const RadioIcon = styled.div<{ checked: boolean }>`
  width: 15px;
  height:15px;
  border-radius:50%;
  border:6px solid ${({ theme,checked }) => (checked ? theme.purple1 : theme.border1)};
  display:inline-block;
  margin-right:2px;
`;
export const PopupDiv = styled.div<{ isShow: boolean }>`
  width: ${({ isShow }) => (isShow ? "100vw" : "0")};
  height: ${({ isShow }) => (isShow ? "100vh" : "0")};
  overflow: hidden;
  opacity: ${({ isShow }) => (isShow ? "1" : "0")};
  background: ${({ theme }) => theme.modalBG2};
  position: fixed;
  top: 0;
  left: 0;
  z-index: 20;
`;

export const PopupMain = styled.div<{ isShow: boolean }>`
  background: ${({ theme }) => theme.bg1};
  border-radius: 8px;
  padding: ${({ isShow }) => (isShow ? "0 40px 40px" : "0")};
  box-sizing: border-box;
  font-size: 16px;
  font-family: Inter-Medium, Inter;
  font-weight: 500;
  width: ${({ isShow }) => (isShow ? "470px" : "0")};
  min-height: ${({ isShow }) => (isShow ? "50vh" : "0")};
  height: ${({ isShow }) => (isShow ? "550px" : "0")};
  overflow: hidden;
  opacity: ${({ isShow }) => (isShow ? "1" : "0")};
  margin: ${({ isShow }) => (isShow ? "200px auto" : "0")};
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    width: 95vw;
    min-width: 0px;
    max-height: calc(100vh - 200px);
    margin: ${({ isShow }) => (isShow ? "100px auto" : "0")};
    overflow-y: scroll;
  }
`;
export const CardTitle = styled.div`
  padding: 30px 0 10px;
  font-size: 28px;
  font-family: Inter-Semi Bold, Inter;
  font-weight: 600;
  color: ${({ theme }) => theme.text1};
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    font-size: 24px;
  }
`;
export const TeamChange = styled.div`
  position: absolute;
  right: 32px;
  top: 32px;
  color: ${({ theme }) => theme.text1};
  font-size: 20px;
  font-family: Inter-Medium, Inter;
  font-weight: 500;
  line-height: 23px;
  cursor: pointer;
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    top: 16px;
    right: 22px;
  }
`;
export const FaqItem = styled.div`
  width: 70%;
  position: relative;
  color: ${({ theme }) => theme.text1};
  background: ${({ theme }) => theme.bg0};
  padding: 30px;
  border-radius: 26px;
  margin-bottom: 30px;

  transform-origin: right;
  transform: scale(1);
  transition: all 450ms ease;
  box-shadow: -2px -2px 5px ${({ theme }) => theme.text5};
  :hover {
    transform-origin: right;
    transform: scale(1.03);
    box-shadow: -5px -5px 8px ${({ theme }) => theme.text5};
  }
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    padding: 15px 20px;
    width: 100%;
  }
`;
export const FaqItemLeft = styled.div`
  width: 70%;
  position: relative;
  color: ${({ theme }) => theme.text1};
  background: ${({ theme }) => theme.bg0};
  padding: 30px;
  border-radius: 26px;
  margin-bottom: 30px;

  transform-origin: left;
  transform: scale(1);
  transition: all 450ms ease;
  box-shadow: 2px -2px 5px ${({ theme }) => theme.text5};
  :hover {
    transform-origin: left;
    transform: scale(1.03);
    box-shadow: 5px -5px 8px ${({ theme }) => theme.text5};
  }
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    padding: 15px 20px;
    width: 100%;
  }
`;

export const ItemQuestion = styled.div`
  width: calc(80% - 60px);
  font-size: 36px;
  font-family: Inter-Medium, Inter;
  font-weight: 500;
  line-height: 42px;
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    font-size: 28px;
    line-height: 35px;
  }
`;
export const ItemAnswer = styled.div`
  width: calc(80% - 60px);
  font-size: 20px;
  font-family: Inter-Medium, Inter;
  color: ${({ theme }) => theme.text3};
  line-height: 42px;
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    font-size: 14px;
    line-height: 20px;
  }
`;

export const DropInfoWeb = styled.div<{ isShow: boolean }>`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding-top: ${({ isShow }) => (isShow ? "20px" : "0px")};
  height: ${({ isShow }) => (isShow ? "" : "0px")};
  opacity: ${({ isShow }) => (isShow ? "1" : "0")};
  overflow: ${({ isShow }) => (isShow ? "" : "hidden")};
`;

export const SecList = styled.div<{ isShow: boolean }>`
  display: ${({ isShow }) => (isShow ? "" : "none")};
`;
export const DropInfoWebMint = styled.div<{ isShow: boolean }>`
  max-width: 1440px;
  min-width: 800px;
  height: auto;
  margin: 0 auto;
  text-align: left;
  overflow: hidden;
  opacity: ${({ isShow }) => (isShow ? "1" : "0")};

  display: ${({ isShow }) => (isShow ? "" : "none")};
`;

export const SecItem = styled.div`
  width: 100%;
`;
export const SecTitle = styled.div`
  width: 60%;
  font-size: 30px;
  text-align: left;
  margin: 0 auto;
  
  
`;
export const EditIcon = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 5px;
`;

export const SecEdit = styled.div`
  width: 60%;
  text-align: left;
  margin: 5px auto;
  display: flex;
  align-items: center;
  cursor: pointer;
`;
export const SecDesc = styled.div`
  width: 60%;
  text-align: center;
  margin: 0 auto;
`;
export const SecImg = styled.img`
  width: 100%;
  max-height: 500px;
`;
export const SecVideo = styled.video`
  width: 100%;
  max-height: 500px;
`;

export const DropInfoWebL = styled.div`
  width: 50%;
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    width: 95vw;
  }
`;
export const DropInfoWebBottom = styled.div`
  width: 100%;
  border: 1px solid ${({ theme }) => theme.border1};
  text-align: center;
  cursor: pointer;
  border-radius: 20px;
`;

export const DropInfoWebR = styled.div`
  width: 50%;
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    width: 95vw;
  }
`;
export const ItemValueArea = styled.div`
  width: 100%;
  height: 300px;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.border1};
  overflow: hidden;
  margin: 20px auto;
`;
export const ItemValueArea1 = styled.div`
  width: 100%;
  height: 118px;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.border1};
  overflow: hidden;
  margin: 20px auto;
`;
export const areaStyle = {
  outline: "none",
  width: "100%",
  height: "300px",
  border: "none",
  textAlign: "left",
  background: "transparent",
  resize: "none",
};
export const areaStyle1 = {
  outline: "none",
  width: "100%",
  height: "118px",
  border: "none",
  textAlign: "left",
  background: "transparent",
  resize: "none",
};

export const TeamItem = styled.div`
  width: calc(50% - 10px);
  height: 220px;
  border-radius: 26px;
  border: 1px solid ${({ theme }) => theme.border2};
  background: ${({ theme }) => theme.bg1};
  color: ${({ theme }) => theme.text1};
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 32px;

  transition: all 450ms ease;
  :hover {
    background: ${({ theme }) => theme.bg0};
  }
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    width: 100%;
  }
`;
export const TeamImg = styled.img`
  width: 136px;
  height: 136px;
  border-radius: 50%;
  margin: 0 32px;
  object-fit: cover;
  background: ${({ theme }) => theme.bg3};
`;
export const TeamInfo = styled.div`
  max-width: (100% - 232px);
  height: 136px;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  > div {
    font-size: 16px;
    font-family: Inter-Regular, Inter;
    font-weight: 400;
    line-height: 19px;
    margin-bottom: 10px;
  }
`;
export const TeamName = styled.div`
  margin-top: 10px;
  font-size: 26px !important;
  font-family: Inter-Regular, Inter;
  font-weight: 600;
  line-height: 30px !important;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const DropTitle = styled.div`
  margin: 20px 0;
  height: 23px;
  font-size: 19px;
  font-family: Inter-Semi Bold, Inter;
  font-weight: normal;
  color: ${({ theme }) => theme.text1};
  line-height: 23px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const DropTitleWeb = styled.div`
  margin-bottom: 28px;
  font-size: 36px;
  font-family: Inter-Semi Bold, Inter;
  font-weight: normal;
  color: ${({ theme }) => theme.text1};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const DropTitleRight = styled.div`
  font-size: 15px;
  font-family: Inter-Semi Bold, Inter;
  font-weight: normal;
  color: ${({ theme }) => theme.text3};
  line-height: 23px;
`;
export const DropTitleLeft = styled.div`
  font-size: 17px;
  font-family: Inter-Semi Bold, Inter;
  font-weight: normal;
  color: ${({ theme }) => theme.text1};
  line-height: 23px;
`;
export const TagList = styled.div`
  display: flex;
  flex-warp: warp;
  align-items: center;
`;
export const Available = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.bg2};
  height: 9px;
  border-radius: 4px;
`;

export const TagItem = styled.div`
  margin-right: 20px;
  margin-bottom: 20px;
  background: ${({ theme }) => theme.bg2};
  color: ${({ theme }) => theme.text2};
  padding: 0 14px;
  height: 32px;
  line-height: 32px;
  border-radius: 16px;

  font-size: 15px;
`;
export const TagItemWeb = styled.div`
  margin-right: 25px;
  margin-bottom: 25px;
  background: ${({ theme }) => theme.bg2};
  color: ${({ theme }) => theme.text2};
  padding: 0 18px;
  height: 44px;
  line-height: 44px;
  border-radius: 22px;

  font-size: 20px;
`;

export const DropNameTextTel = styled.div`
  font-size: 15px;
  font-family: Inter-Semi Bold, Inter;
  font-weight: normal;
  color: ${({ theme }) => theme.text1};
  line-height: 18px;
  margin: 0 7px;
`;

export const DropNameTextWeb = styled.div`
  font-size: 24px;
  font-family: Inter-Semi Bold, Inter;
  font-weight: normal;
  color: ${({ theme }) => theme.text1};
  margin: 0 7px;
`;
export const DropNameTel = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;
export const DropNameWeb = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 25px;
`;
export const DropImgTel = styled.img`
  width: 34px;
  height: 34px;
  border-radius: 4px;
`;
export const DropImgWeb = styled.img`
  width: 46px;
  height: 46px;
  border-radius: 8px;
  background:  ${({ theme }) => theme.bg2};
`;
export const DropIconTel = styled.img`
  width: 15px;
  height: 15px;
`;

export const DropInfoWebLBottom = styled.div`
  width: calc(100% - 60px);
  display: flex;
  flex-warp: warp;
  align-items: center;
`;
export const DropInfoWebLImg4 = styled.img`
  width: calc(25% - 10px);
  margin-bottom: 10px;
  margin-right: 10px;
  border-radius: 12px;
  cursor: pointer;
  background:  ${({ theme }) => theme.bg2};
`;
export const DropInfoWebLImg4Block = styled.div`
  width: calc(25% - 10px);
  margin-bottom: 10px;
  margin-right: 10px;
  border-radius: 12px;
  cursor: pointer;
  background:  ${({ theme }) => theme.bg2};
  overflow:hidden;
`;

export const DescText = styled.div<{ isShow: boolean }>`
  max-height: ${({ isShow }) => (isShow ? "" : "72px")};
  overflow: hidden;
  font-size: 15px;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  color: ${({ theme }) => theme.text2};
  line-height: 18px;
  margin-bottom: 20px;
`;

export const DescBtn = styled.div`
  margin-top: 15px;
  display: flex;
  align-items: center;
`;
export const ImgUpdateItemDel = styled.div`
  height: 25px;
  width: 100%;
  position: absolute;
  bottom: 10px;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  cursor: pointer;
  background: ${({ theme }) => theme.modalBG};
`;
export const ImgUpdateItemFilled = styled.div`
  position: relative;
  :hover {
    ${ImgUpdateItemDel} {
      opacity: 1;
    }
  }
`;
export const ImgGroup = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.border1};
  width: 390px;
  justify-content: space-around;
  flex-wrap: wrap;
  padding: 20px 20px 0;
  box-sizing: border-box;
  margin-bottom: 10px;
  border-radius: 6px;
  > div {
    margin-bottom: 20px;
  }
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    width: 100%;
    margin: 0 auto;
    justify-content: space-around;
  }
`;

export const MintItem = styled.div`
  margin-left: 8px;
  padding-left: 20px;
  padding-bottom: 10px;
  box-sizing: border-box;
  border-left: 2px solid ${({ theme }) => theme.bg2};
  position: relative;
  &:last-child {
    border-left: 2px solid transparent;
  }
`;
export const MintItemIcon = styled.div`
  width: 15px;
  height: 15px;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: -8px;

  background: ${({ theme }) => theme.bg2};
`;
export const MintItemText = styled.div`
  color: ${({ theme }) => theme.text1};

  font-size: 17px;
  font-family: Inter-Medium, Inter;
  font-weight: 500;
  margin-bottom: 10px;
`;
export const MintItemTextSub = styled.div`
  color: ${({ theme }) => theme.bg4};
  padding-bottom: 10px;
  font-size: 15px;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
`;
export const ImgUpdateItem = styled.div`
  height: 102px;
  width: 102px;
`;
export const DropInfoWebLImg1 = styled.div`
  width: calc(100% - 60px);
  margin-bottom: 40px;
  border-radius: 26px;
  position: relative;
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    width: 100%;
  }
`;
export const DropInfoWebLImgnone = styled.img`
  width: 100%;
  height: 100%;
  opacity: 0;
`;
export const DropInfoWebLImg = styled.img`
  width: 100%;
  height: 100%;
  opacity: 1;
`;
export const ImgUpdateItemImg = styled.img`
  height: 102px;
  width: 102px;
  object-fit: cover;
`;
export const DropInfoWebLImgL = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.bg2};
`;
export const DropInfoWebLImgLImg = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
export const DropInfoWebLImgLImgBlock = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
export const TopBanner = styled.div`
  width: 100vw;
  height: 500px;
  position: relative;
  overflow: hidden;
`;
export const BannerInfo = styled.div`
  width: 100vw;
  height: 500px;
  position: absolute;
  padding-bottom: 50px;
  box-sizing: border-box;
  z-index: 12;
  color: #fff;
  overflow: hidden;
  top: 0px;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: flex-end;
`;

export const UpView = styled.div`
  width: 100vw;
  height: 500px;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 14;
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: ${({ theme }) => theme.modalBG1};
  > div {
    width: 110px;
  }
  &:hover {
    opacity: 1;
  }
`;
export const InfoView = styled.div`
  position: relative;
  z-index: 15;
  ${UpView}:hover {
    opacity: 1;
  }
`;
export const AvatarUp = styled.div`
  height: 102px;
  width: 102px;
  position: absolute;
  left: 0;
  top: 0px;
  opacity: 0;
  z-index: 15;
  &:hover {
    opacity: 1;
  }
`;

export const AvatarImg = styled.img`
  width: 102px;
  height: 102px;
  object-fit: cover;
  background: #b7b7b7;
`;

export const DropName = styled.div`
  font-size: 32px;
  font-weight: 600;
  margin-top: 10px;
`;
export const DropBottom = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
  justify-content: space-between;
  > div {
    font-size: 20px;
  }
  > div:last-child {
    cursor: pointer;
  }
`;

export const PageHeader = styled.div`
  width: 100%;
  height: 70px;
  position: fixed;
  z-index: 999;
  top: 70px;
  left: 0;
  background: ${({ theme }) => theme.bg0};
  display: flex;
  align-items: center;
  padding: 0 40px;
`;
export const PageFooter = styled.div`
  width: 100%;
  height: 70px;
  position: fixed;
  z-index: 999;
  bottom: 0px;
  left: 0;
  background: ${({ theme }) => theme.bg0};
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  padding: 0 40px;
  > div {
    cursor: pointer;
  }
`;
export const PageAll = styled.div`
  max-width: 1600px;
  min-width: 800px;
  min-height: calc(100vh - 152px);
  height: auto;
  margin: 0 auto;
  text-align: left;
  overflow: hidden;
  padding-bottom: 50px;
`;
export const TabWeb = styled.div`
  width: 100%;
  height: 168px;
  display: flex;
  align-items: center;
  border-bottom: 2px solid ${({ theme }) => theme.bg2};
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    height: 90px;
  }
`;

export const TabItemWeb = styled.div<{ isAct: boolean }>`
  // width: 157px;
  height: 76px;
  text-align: center;
  font-size: 36px;
  line-height: 76px;
  padding: 0 20px;
  background: ${({ theme, isAct }) => (isAct ? theme.bg2 : theme.bg1)};
  border-radius: 6px;
  color: ${({ theme, isAct }) => (isAct ? theme.text1 : theme.text2)};
  margin-right: 100px;
  cursor: pointer;
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    font-size: 20px;
    padding: 0 8px;
    height: 50px;
    margin-right: 10px;
    line-height: 50px;
  }
`;
export const BackImg = styled.img`
  width: 20px;
  height: 20px;
  transform: rotate(90deg);
`;
