import { Field } from "components/swap/constants";
import { useCallback, useContext, useMemo } from "react";
import {
    CurrencyState,
    SwapAndLimitContext,
    SwapContext,
    SwapInfo,
    SwapState,
} from "./types";



import tryParseCurrencyAmount from "lib/utils/tryParseCurrencyAmount";
import { ChainId, Currency } from "core";
import useParsedQueryString from "hooks/useParsedQueryString";
import { useCurrentChain, useSelectChain } from "state/chain/hooks";
import { DEFAULT_TOKEN_IN, DEFAULT_TOKEN_OUT } from "constants/tokens";
import { useWeb3React } from "@web3-react/core";

export function useSwapAndLimitContext() {
  return useContext(SwapAndLimitContext);
}

export function useSwapContext() {
  return useContext(SwapContext);
}

export function useDerivedSwapInfo(state: SwapState): SwapInfo {
  const {
    currencyState: { inputCurrency, outputCurrency },
  } = useSwapAndLimitContext();
  const { independentField, typedValue } = state;
  const {account}= useWeb3React()
  const currencies: { [field in Field]?: Currency } = useMemo(
    () => ({
      [Field.INPUT]: inputCurrency,
      [Field.OUTPUT]: outputCurrency,
    }),
    [inputCurrency, outputCurrency]
  );
  const isExactIn: boolean = independentField === Field.INPUT;
  const parsedAmount = useMemo(
    () =>
      tryParseCurrencyAmount(
        typedValue,
        (isExactIn ? inputCurrency : outputCurrency) ?? undefined
      ),
    [inputCurrency, isExactIn, outputCurrency, typedValue]
  );

  return useMemo(
    () => ({
      currencies,
      parsedAmount,
 
    }),
    [currencies, parsedAmount]
  );
}

export function useSwapActionHandlers(): {
  onCurrencySelection: (field: Field, currency: Currency) => void;
  onSwitchTokens: (options: {
    newOutputHasTax: boolean;
    previouslyEstimatedOutput: string;
  }) => void;
  onUserInput: (field: Field, typedValue: string) => void;
} {
  const { swapState, setSwapState } = useSwapContext();
  const { currencyState, setCurrencyState } = useSwapAndLimitContext();

  const onCurrencySelection = useCallback(
    (field: Field, currency: Currency) => {
      const [currentCurrencyKey, otherCurrencyKey]: (keyof CurrencyState)[] =
        field === Field.INPUT
          ? ["inputCurrency", "outputCurrency"]
          : ["outputCurrency", "inputCurrency"];
      const otherCurrency = currencyState[otherCurrencyKey];
      // the case where we have to swap the order
      if (otherCurrency && currency.equals(otherCurrency)) {
        setCurrencyState({
          [currentCurrencyKey]: currency,
          [otherCurrencyKey]: currencyState[currentCurrencyKey],
        });
        setSwapState((swapState) => ({
          ...swapState,
          independentField:
            swapState.independentField === Field.INPUT
              ? Field.OUTPUT
              : Field.INPUT,
        }));
        // multichain ux case where we set input or output to different chain
      } else if (otherCurrency?.chain !== currency.chain) {
        setCurrencyState((state) => ({
          ...state,
          [currentCurrencyKey]: currency,
          [otherCurrencyKey]: undefined,
        }));
      } else {
        setCurrencyState((state) => ({
          ...state,
          [currentCurrencyKey]: currency,
        }));
      }
    },
    [currencyState, setCurrencyState, setSwapState]
  );

  const onSwitchTokens = useCallback(
    ({
      newOutputHasTax,
      previouslyEstimatedOutput,
    }: {
      newOutputHasTax: boolean;
      previouslyEstimatedOutput: string;
    }) => {
      // To prevent swaps with FOT tokens as exact-outputs, we leave it as an exact-in swap and use the previously estimated output amount as the new exact-in amount.
      if (newOutputHasTax && swapState.independentField === Field.INPUT) {
        setSwapState((swapState) => ({
          ...swapState,
          typedValue: previouslyEstimatedOutput,
        }));
      } else {
        setSwapState((prev) => ({
          ...prev,
          independentField:
            prev.independentField === Field.INPUT ? Field.OUTPUT : Field.INPUT,
        }));
      }

      setCurrencyState((prev) => ({
        inputCurrency: prev.outputCurrency,
        outputCurrency: prev.inputCurrency,
      }));
    },
    [setCurrencyState, setSwapState, swapState.independentField]
  );

  const onUserInput = useCallback(
    (field: Field, typedValue: string) => {
      setSwapState((state) => {
        return {
          ...state,
          independentField: field,
          typedValue,
        };
      });
    },
    [setSwapState]
  );

  return {
    onSwitchTokens,
    onCurrencySelection,
    onUserInput,
  };
}


export function useInitialCurrencyState(): {
  initialInputCurrency: Currency
  initialOutputCurrency: Currency
  chainInfo:ChainInfo|null
} {
  const multichainUXEnabled = false
  const parsedQs = useParsedQueryString()

  const initialInputCurrency = DEFAULT_TOKEN_IN
  const initialOutputCurrency = DEFAULT_TOKEN_OUT
  const chainInfo=useSelectChain(DEFAULT_TOKEN_IN.chain)

  return { initialInputCurrency, initialOutputCurrency,chainInfo }
}