
import { emptySplitApi } from "./emptySplitApi";

export interface ContractCreatorVerifyRequest {
  contract_address: string;
  chain:string;
}

const contractApi = emptySplitApi.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    verifyContractCreator: builder.mutation<
      {
        data: DropInfo;
        success: boolean | null;
        message: string | null;
      },
      Record<string, any>
    >({
      query: (params) => ({
        url: `/contract/verify-creator`,
        method: "POST",
        body: { ...params },
      }),
    }),
  }),
});

export const { useVerifyContractCreatorMutation } = contractApi;
