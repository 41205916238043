import styled from "styled-components/macro";
import {MAX_WIDTH_MEDIA_BREAKPOINT,MIN_WIDTH_MEDIA_BREAKPOINT,LARGE_MEDIA_BREAKPOINT} from "constants/width"
export const mediaPadding15 = styled.div`
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    padding: 15px;
  }
`;
export const mediaWidth = styled.div`
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    width: 100%;
    min-width:0px;
  }
`;
export const mediaWidth2 = styled.div`
@media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
  width: calc(50% - 10px);
  min-width: 0px;
}
`;
export const PageAll = styled.div`
  max-width: 1440px;
  min-width: 800px;
  min-height: calc(100vh - 165px);
  height: auto;
  margin: 0 auto;
  text-align: left;
  overflow: hidden;
  position: relative;
  z-index: 10;
  padding-bottom: 100px;
  @media screen and (min-width: ${LARGE_MEDIA_BREAKPOINT}) {
    width: 95vw;
    max-width: 1440px !important;
    min-width: 760px !important;
  }
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    width: 95vw !important;
    max-width: 800px !important;
    min-width: 0px !important;
  }
`;
export const PageCenter = styled.div`
  max-width: 1440px;
  min-width: 800px;
  height: auto;
  margin: 0 auto;
  text-align: left;
  overflow: hidden;
  padding-top:40px;
  position: relative;
  z-index: 10;
  @media screen and (min-width: ${LARGE_MEDIA_BREAKPOINT}){

    width: 95vw !important;
    max-width: 1440px !important;
    min-width: 760px !important;
  }
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    width: 95vw !important;
    padding-top:20px;
    max-width: 800px !important;
    min-width: 0px !important;
  }
`;
export const InfoArea = styled(mediaPadding15)`
  width: 100%;
  position: absolute;
  bottom: 0;
  z-index: 12;
  color: ${({ theme }) => theme.bg0};
  left: 0;
  padding: 35px;
  box-sizing: bordedr-box;
`;
export const DetailInfoArea = styled(mediaPadding15)`
  width: 100%;
  position: absolute;
  bottom: 0;
  z-index: 12;
  color: ${({ theme }) => theme.bg0};
  left: 0;
  padding: 35px;
  box-sizing: bordedr-box;
  @media screen and (min-width: ${MAX_WIDTH_MEDIA_BREAKPOINT}) {
    padding: 35px 0;
  }
`;
export const InfoBtns = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
  margin-bottom: 35px;
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    flex-wrap: wrap;
  }
`;
export const InfoBtn = styled.div`
  background: rgba(255, 255, 255, 0.3);
  border-radius: 8px;
  padding: 0 20px;
  height: 47px;
  line-height: 47px;
  font-size: 15px;

  text-align: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(14px);
`;
export const InfoBtnIcon = styled.div`
  background: #7beaa1;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 8px;
`;
export const CollectRolesd = styled.div`
  background: ${({ theme }) => theme.modalBG1};
  padding: 8px;
  border-radius: 8px;
  box-sizing: border-box;
  height: 76px;
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    height: 50px;
  }
  > div {
    font-size: 14px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 30px;
    @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
      height: 17px;
    }
    color: ${({ theme }) => theme.text2};
    :hover {
      color: ${({ theme }) => theme.text1};
    }
  }
`;

export const InfoBtnIconNone = styled.div`
  background: ${({ theme }) => theme.bg0};
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 8px;
`;
export const AdimnImg = styled.img`
  width: 15px;
  height: 15px;
  margin-left: 8px;
`;
export const AvatarImg = styled.img`
  width: 103px;
  height: 103px;
  border-radius: 8px;
  border: 2px solid ${({ theme }) => theme.bg0};
`;
export const NameIcon = styled.img`
  width: 15px;
  height: 15px;
  margin-left: 6px;
`;
export const FlexText = styled.div`
  display: flex;
  align-items: flex-end;
  margin-top: 6px;
  font-size: 28px;
  font-weight: 600;
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    font-size: 20px;
  }
`;

export const TabWeb = styled.div`
  width: 100%;
  height: 168px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  > div {
    display: flex;
    align-items: center;
    height: 168px;
  }
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    height: 90px;
    flex-wrap: wrap;
    > div {
      display: flex;
      align-items: center;
      height: 90px;
    }
  }
`;

export const TabItemWeb = styled.div<{ isAct: boolean }>`
  height: 76px;
  text-align: center;
  font-size: 36px;
  line-height: 76px;
  padding: 0 20px;
  background: ${({ theme, isAct }) => (isAct ? theme.bg2 : theme.bg1)};
  border-radius: 6px;
  color: ${({ theme, isAct }) => (isAct ? theme.text1 : theme.text2)};
  margin-right: 100px;
  cursor: pointer;
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    font-size: 20px;
    padding: 0 8px;
    height: 50px;
    margin-right: 10px;
    line-height: 50px;
  }
`;

export const DropTitleWeb = styled.div`
  margin-bottom: 28px;
  font-size: 36px;
  font-family: Inter-Semi Bold, Inter;
  font-weight: normal;
  color: ${({ theme }) => theme.text1};
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    margin: 20px 0;
    height: 23px;
    font-size: 19px;
    font-family: Inter-Semi Bold, Inter;
    font-weight: normal;
    color: ${({ theme }) => theme.text1};
    line-height: 23px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;
export const DropNameTextWeb = styled.div`
  font-size: 24px;
  font-family: Inter-Semi Bold, Inter;
  font-weight: normal;
  color: ${({ theme }) => theme.text1};
  margin: 0 7px;
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    font-size: 15px;
    font-family: Inter-Semi Bold, Inter;
    font-weight: normal;
    line-height: 18px;
  }
`;
export const DropInfoWebLBottom = styled.div`
  width: calc(100% - 60px);
  display: flex;
  flex-warp: warp;
  align-items: center;
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    width: 100%;
  }
`;
export const DropInfoWebLImg4 = styled.img`
  width: calc(25% - 10px);
  margin-bottom: 10px;
  margin-right: 10px;
  border-radius: 12px;
  cursor: pointer;
`;
export const MintItem = styled.div`
  margin-left: 8px;
  padding-left: 20px;
  box-sizing: border-box;
  border-left: 2px solid ${({ theme }) => theme.bg2};
  position: relative;
`;
export const MintItemIcon = styled.div`
  width: 15px;
  height: 15px;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: -8px;

  background: ${({ theme }) => theme.bg2};
`;
export const MintItemIconNow = styled.div`
  width: 15px;
  height: 15px;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: -8px;

  background: ${({ theme }) => theme.purple1};
`;
export const MintItemText = styled.div`
  color: ${({ theme }) => theme.text1};

  font-size: 17px;
  font-family: Inter-Medium, Inter;
  font-weight: 500;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const MintItemBuy = styled.div`
  color: ${({ theme }) => theme.purple1};
  display: flex;
  align-items: center;
  cursor: pointer;
`;
export const MintBuyNot = styled.div`
  color: ${({ theme }) => theme.text3};
  display: flex;
  align-items: center;
`;

export const MintItemTextSub = styled.div`
  color: ${({ theme }) => theme.bg4};
  padding-bottom: 10px;
  font-size: 15px;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
`;
export const DropInfoWebL = styled(mediaWidth)`
  width: 50%;
`;
export const DropInfoWebR = styled(mediaWidth)`
  width: 50%;
`;
export const PublicCardWeb = styled.div`
  width: 100%;
  padding: 15px;
  box-sizing: border-box;
  border-radius: 13px;
  opacity: 1;
  margin-top: 25px;
  margin-bottom: 25px;
  background: ${({ theme }) => theme.bg0};
  border: 1px solid ${({ theme }) => theme.bg2};
`;
export const DescBtnText = styled.div`
  font-size: 15px;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  color: ${({ theme }) => theme.text2};
  line-height: 18px;
  margin-right: 5px;
`;

export const PublicCardBottomL = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  font-size: 17px;
  font-family: Inter-Medium, Inter;
  font-weight: 500;
  color: ${({ theme }) => theme.text2};
  background: ${({ theme }) => theme.bg2};
  margin-right: 20px;
  > div:first-child {
    font-size: 20px;
    color: ${({ theme }) => theme.text3};
    cursor: pointer;
  }
  > div:last-child {
    font-size: 20px;
    cursor: pointer;
    color: ${({ theme }) => theme.text3};
  }
`;
export const PublicCardBottomWeb = styled.div`
  display: flex;
  align-items: center;

  justify-content: space-between;

  font-size: 20px;
  > div:first-child {
    width: calc(30% - 10px);

    height: 56px;
    border-radius: 6px;
    padding: 0 19px;
    box-sizing: border-box;
  }
  > div:last-child {
    width: calc(70% - 10px);

    height: 56px;
    border-radius: 6px;
    padding: 0 19px;
    box-sizing: border-box;
  }
`;
export const PublicCardBottomR = styled.div`
  background: #6a5bda;
  color: ${({ theme }) => theme.bg0};
  text-align: center;
  line-height: 48px;
  flex: 1;
  cursor: pointer;
  @media screen and (min-width: ${LARGE_MEDIA_BREAKPOINT}) {
    font-size: 18px;
  }
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    font-size: 14px;
  }
`;
export const PublicCardPrice = styled.div`
  margin: 13px 0 27px;
  font-size: 15px;
  font-family: Inter-Medium, Inter;
  font-weight: 500;
  color: ${({ theme }) => theme.text1};
  line-height: 18px;
`;
export const DropTitleRight = styled.div`
  font-size: 15px;
  font-family: Inter-Semi Bold, Inter;
  font-weight: normal;
  color: ${({ theme }) => theme.text3};
  line-height: 23px;
`;
export const DropTitleLeft = styled.div`
  font-size: 17px;
  font-family: Inter-Semi Bold, Inter;
  font-weight: normal;
  color: ${({ theme }) => theme.text1};
  line-height: 23px;
`;
export const DropInfoWeb = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding-top: 20px;
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    flex-direction: column;
  }
`;
export const DropTitle = styled.div`
  margin: 20px 0;
  height: 23px;
  font-size: 19px;
  font-family: Inter-Semi Bold, Inter;
  font-weight: normal;
  color: ${({ theme }) => theme.text1};
  line-height: 23px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const TagList = styled.div`
  display: flex;
  flex-warp: warp;
  align-items: center;
`;
export const Available = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.bg2};
  height: 9px;
  border-radius: 4px;
  position: relative;
`;
export const AvailableColor = styled.div`
  width: 50%;
  background: ${({ theme }) => theme.purple1};
  height: 9px;
  border-radius: 4px;

  position: absolute;
`;
export const DropNameWeb = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 25px;
`;
export const DropIconTel = styled.img`
  width: 15px;
  height: 15px;
`;
export const DropImgWeb = styled.img`
  width: 46px;
  height: 46px;
  border-radius: 8px;
`;

export const TagItemWeb = styled.div`
  margin-right: 25px;
  margin-bottom: 25px;
  background: ${({ theme }) => theme.bg2};
  color: ${({ theme }) => theme.text2};
  padding: 0 18px;
  height: 44px;
  line-height: 44px;
  border-radius: 22px;

  font-size: 20px;
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    font-size: 15px;
    margin-right: 20px;
    margin-bottom: 20px;
    padding: 0 14px;
    height: 32px;
    line-height: 32px;
    border-radius: 16px;
  }
`;
export const DropInfoWebLImg1 = styled.img`
  width: calc(100% - 60px);
  margin-bottom: 40px;
  border-radius: 26px;
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    width: 100%;
  }
`;
export const DescText = styled.div<{ isShow: boolean }>`
  overflow: hidden;
  font-size: 15px;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  color: ${({ theme }) => theme.text2};
  line-height: 18px;
  word-wrap: break-word;
  white-space: normal;
  word-break: break-all;
  max-height: ${({ isShow }) => (isShow ? "" : "36px")};
`;

export const DescBtn = styled.div`
  margin-top: 15px;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const TopWhite = styled.div`
  background-image: linear-gradient(
    180deg,
    transparent,
    ${({ theme }) => theme.bg1}
  );
  z-index: 9;
  overflow: hidden;
  height: 610px;
  position: absolute;
  top: 0px;
  left: 0;
  // backdropfilter: blur(20px);

  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
`;
export const TopWhiteNone = styled.div`
  z-index: 9;
  overflow: hidden;
  height: 610px;
  position: absolute;
  top: 0px;
  left: 0;
`;
export const TopBannerBg = styled.img`
  z-index: 8;
  overflow: hidden;
  height: 610px;
  object-fit: cover;
  position: absolute;
  top: 0px;
  left: 0;
`;

export const TopBannerBgNone = styled.div`
  z-index: 8;
  overflow: hidden;
  height: 610px;
  object-fit: cover;
  background: ${({ theme }) => theme.bg2};
`;
export const TopBanner = styled.img`
  width: 100%;
  object-fit: cover;
  border-radius: 20px;
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    height: 500px;
    object-fit: cover;
  }
`;
export const TopBannerBlock = styled.div`
  width: 100%;
  object-fit: cover;
  border-radius: 20px;
  overflow:hidden;
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    height: 500px;
    object-fit: cover;
  }
`;

export const CollectList = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
`;
export const CollectItem = styled(mediaWidth2)`
  margin-bottom: 50px;
  cursor: pointer;
  width: calc(23vw - 10px);
  min-width: 189px;
  max-width: 350px;
  background: ${({ theme }) => theme.bg0};
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.border1};
  overflow: hidden;
`;
export const CollectItemNone = styled(mediaWidth2)`
  margin-bottom: 50px;
  width: calc(23vw - 10px);
  height: calc(16vw - 10px);
  min-width: 189px;
  max-width: 350px;
  max-height: 250px;
  background: ${({ theme }) => theme.bg2};
`;

export const CollectItemTop = styled(mediaWidth)`
  width: calc(23vw - 10px);
  height: 12vw;
  min-width: 189px;
  min-height: 103px;
  max-width: 350px;
  max-height: 206px;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
`;
export const CollectTopImg = styled.img`
  min-width: 189px;
  max-width: 350px;
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    width: 100%;
    min-width: 0px;
  }
`;
export const CollectItemBottom = styled.div`
  padding: 10px 10px;
  position: relative;
`;
export const CollectItemBottomInfo = styled.div`
  display: flex;
  align-items: center;
`;
export const BottomInfoLine = styled.div`
  width: 115px;
`;

export const BottomInfoTitle = styled.div`
  font-size: 12px;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  color: ${({ theme }) => theme.text3};
  line-height: 14px;
  max-width: 115px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
export const BottomInfoDesc = styled.div`
  display: flex;
  font-size: 12px;
  font-family: Arial-Regular, Arial;
  font-weight: 400;
  color: ${({ theme }) => theme.text1};
  margin-top: 2px;
  line-height: 14px;
  max-width: 115px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  span {
    color: ${({ theme }) => theme.text3};
  }
`;
export const ItemBottomName = styled.div`
  margin-bottom: 8px;
  display: flex;
  align-items: center;
`;
export const ItemBottomNameText = styled.div`
  font-size: 14px;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  color: ${({ theme }) => theme.text1};
  line-height: 16px;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
