import NoData from "components/NoData";
import { LARGE_MEDIA_BREAKPOINT, MIN_WIDTH_MEDIA_BREAKPOINT } from "constants/width";
import { useEffect, useState } from "react";
import {
    useNavigate
} from "react-router-dom";
import styled from "styled-components/macro";

import { Input } from "@rebass/forms";
import { SearchCollectionLoadItem } from "pages/Nft/components/loadCompents";
import { useDarkModeManager } from "theme/components/ThemeToggle";




import closeIcon from "assets/tool/close_icon.png";
import closeIconN from "assets/tool/close_icon_n.png";
import searchIcon from "assets/tool/search_icon.png";
import {
    SearchCollectionItem,
    SearchCollectionLine,
    SearchCollectionName,
    TrendLineText1
} from "pages/Nft/components_";



import { useSearchQuery } from "apis/search";
import TokenAvatar from "components/TokenAvatar";

import { Box,Text } from "rebass";
import { shortenAddress } from "utils";
import { Token } from "core";


const DownBlock = styled.div`
width: 100%;
height:auto;
padding:20px 10px;
background: ${({ theme }) => theme.bg0};
position: absolute;
top: 50px;
left: 0;
z-index: 9;
border-radius:10px;
box-shadow: 2px 2px 10px ${({ theme }) => theme.bg3};
`;

const SearchImg = styled.img`
width:25px;
height:25px;

transform: revert;

`;
const CloseImg = styled.img`
width:15px;
height: 15px;
cursor: pointer;

`;

const ItemValue = styled.div`
  width:100%;
  height: 38px;
  border-radius: 8px;
  background: ${({ theme }) => theme.modalBG1};
  border: 1px solid ${({ theme }) => theme.bg2};
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;

  position: relative;
  :hover {
    border: 1px solid ${({ theme }) => theme.border1};
  }
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}){
    max-width: 800px;
    min-width: 0px;
    width: calc(100% - 25px);
  }
`;
const inputStyle = {
  outline: "none",
  width: "calc(100% - 40px)",
  height: "40px",
  border: "none",
  textAlign: "left",
  background: "transparent",
};

const Tag=styled.div`
font-size:12px;
line-height:14px;
float:left;
border-radius: 8px;
background: ${({ theme }) => theme.modalBG1};
border: 1px solid ${({ theme }) => theme.bg2};
display: flex;
padding: 4px ;
position: relative;
`;

export default function Search() {
    const [results, setResults] = useState<SearchResult[] | null>(null);
    const [key, setKey]=useState("")
    const [darkMode] = useDarkModeManager();
    let navigate = useNavigate();

    const { data, status } = useSearchQuery({
        page: 1,
        pageSize: 4,
        key: key,
      });

    useEffect(()=>{
        if(data && data.success){
            setResults(data.data)
        }
    },[data])

    const toPage=(v:SearchResult)=>{
        setKey("");
        if(v.is_erc20==="YES"){
          navigate(`/cryptos/${v.chain}/${v.address}/details`)
        }else if(v.is_erc721==="YES" || v.is_erc1155==="YES"){
          navigate(`/nfts/collection/${v.chain}/${v.address}`)
        }
    
      }

    return (
      <>
         <ItemValue>
                <SearchImg src={searchIcon} />
                <Input
                  type="text"
                  placeholder="Search"
                  value={key}
                  sx={inputStyle}
                  onChange={(e) => {
                    setKey(e.target.value);
                  }}
                />
                <CloseImg
                  onClick={() => setKey("")}
                  src={darkMode ? closeIconN : closeIcon}
                  style={key ? { opacity: "0.5" } : { opacity: "0" }}
                />
                {key && key !== "" ? (
                  <DownBlock>
                    {status === "fulfilled" ? (
                      //nodata or collections.map
                      results && results.length > 0 ? (
                        results?.map((v: SearchResult, index: number) => (
                          <SearchCollectionItem
                            key={v.id}
                            onClick={() => {
                             toPage(v)
                            }}
                          >
                            <SearchCollectionLine>
                              <div>
                                <TokenAvatar size={36} token={new Token(v.chain,v.address,0,v.symbol,v.name,v.image_url)} />
                                <SearchCollectionName>
                                  <Text lineHeight={"16px"}>{v.name} </Text>
                                  <Text lineHeight={"16px"}>{shortenAddress(v.address,10)} </Text>
                                </SearchCollectionName>

                              </div>
           
                              <div>
  
                                <TrendLineText1>
                                {v.is_erc20==="YES"? <Tag>ERC20</Tag>:null}
                                {v.is_erc1155==="YES"? <Tag>ERC1155</Tag>:null}
                                {v.is_erc721==="YES"? <Tag>ERC721</Tag>:null}
                                </TrendLineText1>
                              </div>
                            </SearchCollectionLine>
                          </SearchCollectionItem>
                        ))
                      ) : (
                        <NoData
                          onChange={() => setKey("")}
                          size={"small"}
                        ></NoData>
                      )
                    ) : (
                      <>
                        {" "}
                        <SearchCollectionLoadItem />
                        <SearchCollectionLoadItem />
                        <SearchCollectionLoadItem />
                        <SearchCollectionLoadItem />
                      </>
                    )}
                  </DownBlock>
                ) : null}
              </ItemValue>
      </>
    );
  }