import { Token } from 'core';
import { emptySplitApi } from './emptySplitApi';


export interface TokenOrder {
  sell:Token
  buy:Token
  price:number
  sell_amount:number
  buy_amount:number
  quote_currency:Token

}

export interface TokenOrderListResponse {
  data?: TokenOrder[]
  total: number;
  message?: null | string;
  success: boolean;
}


const tokenOrderApi = emptySplitApi.injectEndpoints({
  overrideExisting: true,

  endpoints: (builder) => ({
    tokenOrderList: builder.query<TokenOrderListResponse, Record<string, any>>({
      query: (params) => ({
        url: `/cryptos/orders?page=${params.page}&pageSize=${params.pageSize}&sort=${params.sort}&order=${params.order}&key=${params.key}&chain=${params.chain}&watchlist=${params.watchlist}&account=${params.account?params.account:""}`,
        method: "GET",
      }),
      // transformResponse: (returnValue: CurrencyList, meta) => {
      //   // `meta` here contains our added `requestId` & `timestamp`, as well as
      //   // `request` & `response` from fetchBaseQuery's meta object.
      //   // These properties can be used to transform the response as desired.
      //   if (!meta) return []
      //   return returnValue.data.filter(
      //     (post) => new Token(post.chain,post.address,post.decimals,post.symbol,post.name,post.image_url)
      //   )
      // }
      // providesTags: [],
    }),
  }),
});

export const {
  useTokenOrderListQuery,

} = tokenOrderApi;
