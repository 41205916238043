
import { StyledModal } from "components/Modal/styled_modal";
import { Input } from "components/NumericalInput";
import TokenAvatar from "components/TokenAvatar";
import TokenSelectPanel from "components/TokenSelectPanel";
import { Currency, Percent } from 'core';
import { useCallback, useState } from "react";
import { ChevronDown } from "react-feather";
import { Flex } from "rebass";
import styled from "styled-components";
import { Box, Text } from "rebass";
import { useWeb3React } from "@web3-react/core";
import { BaseButton } from "components/Button";
import useCurrencyBalance from "hooks/useCurrencyBalance";

const BlanceWrapper = styled(Flex)`
  flex-wrap: nowrap;
  margin-top: 10px;
  justify-content: space-between;
`;
const ButtonUse = styled(BaseButton)`
  background: ${({ theme }) => theme.modalBG1};
  border: 1px solid ${({ theme }) => theme.bg2};
  color: ${({ theme }) => theme.text2};
  padding: 6px 16px;
  line-height: 12px;
  font-size: 14px;
  font-weight: 500;
`;

const Container = styled(Flex)`
  border-radius: 8px;
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
  background: ${({ theme }) => theme.modalBG1};
  border: 1px solid ${({ theme }) => theme.bg2};

  width: 100%;
  justify-content: flex-start;
  align-items: center;
  padding: 18px;
  > div:first-child {
      >div {
        margin-right:5px;
      }
  }
  > input {
    border: 0;
    text-align: left;
    font-weight: 700;
    margin:0;
    padding:0;
  }
`;

const TokenLabel=styled.div`
display: -webkit-flex; /* Safari */
align-items: center;
cursor: pointer;
`

interface SwapCurrencyInputPanelProps {
  value: string
  onUserInput: (value: string) => void
  onMax?: () => void
  showMaxButton: boolean
  onCurrencySelect?: (currency: Currency) => void
  currency: Currency | null
  hideBalance?: boolean
  hideInput?: boolean
  otherCurrency?: Currency | null
  fiatValue?: { data?: number; isLoading: boolean }
  priceImpact?: Percent
  locked?: boolean
  loading?: boolean
  disabled?: boolean
  numericalInputSettings?: {
    disabled?: boolean
    onDisabledClick?: () => void
  }
}

export default function TokenAmountInput({value,onUserInput,onCurrencySelect,showMaxButton,onMax,currency}:SwapCurrencyInputPanelProps) {
  const {account} =useWeb3React()
  const [modalVisable,setModalVisable]=useState(false)

   const handleTokenChange=(token:Currency)=>{
    onCurrencySelect&& onCurrencySelect(token)
   }

   const selectedCurrencyBalance = useCurrencyBalance(account, currency ?? undefined)


  return (
    <>
      <Container>
      <Input width={"100%"}  
      
      value={value}
      onUserInput={onUserInput}

      maxDecimals={currency?.decimals}
      
      />
        <TokenLabel onClick={()=>{
          setModalVisable(true)
        }}>
           {currency && <TokenAvatar
              size={32}
              token={currency }
            />}
         
          <div style={{marginLeft:"5px"}}>{currency?.symbol}</div>
          <div><ChevronDown size={12}/></div>
        </TokenLabel>

      </Container>
      <BlanceWrapper>
              <Text>Balance</Text>
              <Flex>
                <Text marginRight={"10px"}>{selectedCurrencyBalance?.toExact()}</Text>{" "}
                {showMaxButton && selectedCurrencyBalance ?  <Box>
                  <ButtonUse onClick={() => {onMax && onMax()}}>Max</ButtonUse>
                </Box>:null}
              </Flex>
            </BlanceWrapper>

      <StyledModal isOpen={modalVisable} modalTitle={"Select Crypto"} onDismiss={()=>{setModalVisable(false)}}>
        <div><TokenSelectPanel value={""} onChange={function (e: Currency): void {
          handleTokenChange(e);
          setModalVisable(false);
        } } /></div>
      </StyledModal>
    </>
  );
}
