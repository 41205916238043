
export enum InterfacePageName {
    ABOUT_PAGE = 'about-page',
    LANDING_PAGE = 'landing-page',
    NFT_COLLECTION_PAGE = 'nft-collection-page',
    NFT_DETAILS_PAGE = 'nft-details-page',
    NFT_EXPLORE_PAGE = 'nft-explore-page',
    NFT_PROFILE_PAGE = 'nft-profile-page',
    NOT_FOUND = 'not-found',
    POOL_PAGE = 'pool-page',
    SWAP_PAGE = 'swap-page',
    TOKENS_PAGE = 'tokens-page',
    TOKEN_DETAILS_PAGE = 'token-details',
    VOTE_PAGE = 'vote-page',
  }
export function getCurrentPageFromLocation(locationPathname: string): InterfacePageName | undefined {
  switch (true) {
    case locationPathname.startsWith('/swap'):
      return InterfacePageName.SWAP_PAGE
    case locationPathname.startsWith('/vote'):
      return InterfacePageName.VOTE_PAGE
    case locationPathname.startsWith('/pools'):
    case locationPathname.startsWith('/pool'):
      return InterfacePageName.POOL_PAGE
    case locationPathname.startsWith('/tokens'):
      return InterfacePageName.TOKENS_PAGE
    case locationPathname.startsWith('/nfts/profile'):
      return InterfacePageName.NFT_PROFILE_PAGE
    case locationPathname.startsWith('/nfts/asset'):
      return InterfacePageName.NFT_DETAILS_PAGE
    case locationPathname.startsWith('/nfts/collection'):
      return InterfacePageName.NFT_COLLECTION_PAGE
    case locationPathname.startsWith('/nfts'):
      return InterfacePageName.NFT_EXPLORE_PAGE
    default:
      return undefined
  }
}