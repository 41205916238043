


import { walletConnectV2Connection } from '../../connection'
import { ActivationStatus, useActivationState } from '../../connection/activate'
import { Connection, ConnectionType } from '../../connection/types'

import { MouseEvent, useEffect, useRef, useState } from 'react'
import { MoreHorizontal } from 'react-feather'
import styled from 'styled-components/macro'
import { ThemedText } from '../../theme'

import { flexColumnNoWrap, flexRowNoWrap } from '../../theme/styles'
import { Z_INDEX } from '../../theme/zIndex'

import NewBadge from './NewBadge'

import { useAccountDrawer } from '../AccountDrawer'
import { useIsDarkMode } from 'theme/components/ThemeToggle'
import Loader from 'components/Icons/LoadingSpinner'

const OptionCardLeft = styled.div`
  ${flexColumnNoWrap};
  flex-direction: row;
  align-items: center;
`

const OptionCardClickable = styled.button<{ selected: boolean }>`
  align-items: center;
  background-color: unset;
  border: none;
  cursor: pointer;
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
  justify-content: space-between;
  opacity: ${({ disabled, selected }) => (disabled && !selected ? '0.5' : '1')};
  padding: 18px;

`

const HeaderText = styled.div`
  ${flexRowNoWrap};
  align-items: center;
  justify-content: center;
  color: ${(props) => (props.color === 'blue' ? ({ theme }) => theme.primaryText1 : ({ theme }) => theme.primaryText1)};
  font-size: 16px;
  font-weight: 600;
  padding: 0 8px;
`
const IconWrapper = styled.div`
  ${flexColumnNoWrap};
  align-items: center;
  justify-content: center;
  & > img,
  span {
    height: 40px;
    width: 40px;
  }

`

const TOGGLE_SIZE = 24
const WCv2PopoverToggle = styled.button`
  align-items: center;
  background-color: transparent;
  border: none;

  cursor: pointer;
  display: flex;
  height: ${TOGGLE_SIZE}px;
  justify-content: center;
  margin: 0;
  max-width: 48px;
  padding: 0;
  position: absolute;
  right: 16px;
  top: calc(50% - ${TOGGLE_SIZE / 2}px);
  width: ${TOGGLE_SIZE}px;

  &:hover {
    opacity: 0.6;
  }
`
const Wrapper = styled.div<{ disabled: boolean }>`
  align-items: stretch;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  width: 100%;



  &:hover {
    cursor: ${({ disabled }) => !disabled && 'pointer'};

  }
  &:focus {

  }
`

const WCv2Icon = styled.img`
  height: 20px !important;
  width: 20px !important;
`


interface PopupButtonContentProps {
  connection: Connection
  isDarkMode: boolean
  show: boolean
  onClick: (e: MouseEvent<HTMLButtonElement>) => void
  onClose: () => void
}


interface OptionProps {
  connection: Connection
}
export default function Option({ connection }: OptionProps) {
  const { activationState, tryActivation } = useActivationState()
  const [WC2PromptOpen, setWC2PromptOpen] = useState(false)
  const [accountDrawerOpen, toggleAccountDrawerOpen] = useAccountDrawer()
  const activate = () => tryActivation(connection, toggleAccountDrawerOpen)



  const isSomeOptionPending = activationState.status === ActivationStatus.PENDING
  const isCurrentOptionPending = isSomeOptionPending && activationState.connection.type === connection.type
  const isDarkMode = useIsDarkMode()


  return (
    <Wrapper disabled={isSomeOptionPending}>

        <OptionCardClickable
          disabled={isSomeOptionPending}
          selected={isCurrentOptionPending} 
          onClick={activate}
          data-testid={`wallet-option-${connection.type}`}
        >
          <OptionCardLeft>
            <IconWrapper>
              <img src={connection.getIcon?.(isDarkMode)} alt={connection.getName()} />
            </IconWrapper>
            <HeaderText>{connection.getName()}</HeaderText>

          </OptionCardLeft>
          {isCurrentOptionPending && <Loader />}
        </OptionCardClickable>
    </Wrapper>
  )
}