import {
  CryptoTradeActivity,
  useCryptoTradeHistoryListQuery,
} from "apis/activity";
import { ButtonEmpty } from "components/Button";
import { useEffect, useState } from "react";

import { Text } from "rebass";

import etherscanpng from "assets/images/ether-scan.png";
import { useSelectChain } from "state/chain/hooks";
import { formatNumber, NumberType } from "utils/formatNumbers";
import { ShortToken } from "types/token";

function TradeRow({
  trade,
  token_address,
}: {
  trade: CryptoTradeActivity;
  token_address?: string;
}) {
  const date = new Date(trade.timestamp * 1000);
  const [tradeType, setTradeType] = useState("");
  const [quote, setQuote] = useState<ShortToken | null>(null);
  const [base, setBase] = useState<ShortToken | null>(null);
  const [baseAmount, setBaseAmount] = useState(0);
  const [quoteAmount, setQuoteAmount] = useState(0);
  const [price, setPrice] = useState(0);
  const chainInfo = useSelectChain(trade.chain);

  useEffect(() => {
    if (token_address === trade.token0.address) {
      setQuote(trade.token1);
      setBase(trade.token0);
      setBaseAmount(Math.abs(trade.amount0));
      setQuoteAmount(Math.abs(trade.amount1));
      setPrice(Math.abs(trade.amount1 / trade.amount0));

      if (trade.amount0 > 0) {
        setTradeType("Buy");
      } else {
        setTradeType("Sell");
      }
    } else if (token_address === trade.token1.address) {
      setQuote(trade.token0);
      setBase(trade.token1);
      setBaseAmount(Math.abs(trade.amount1));
      setQuoteAmount(Math.abs(trade.amount0));
      setPrice(Math.abs(trade.amount0 / trade.amount1));
      if (trade.amount1 > 0) {
        setTradeType("Buy");
      } else {
        setTradeType("Sell");
      }
    } else {
      console.log(token_address, trade.token0.address, trade.token1.address);
    }
  }, [trade, token_address]);
  return (
    <>
      <tr style={{ padding: "15px", lineHeight: "2em" }}>
        <td>{date.toLocaleString()}</td>
        <td>
          <Text padding={"5px"}>{tradeType}</Text>
        </td>
        <td>
          <Text padding={"5px"}>
            {baseAmount} {base?.symbol}
          </Text>
        </td>
        <td>
          <Text padding={"5px"}>
            {formatNumber({input:price,type:NumberType.SwapPrice})} {quote?.symbol}
          </Text>
        </td>
        <td>
          <Text padding={"5px"}>
            {formatNumber({input:quoteAmount,type:NumberType.SwapTradeAmount})} {quote?.symbol}
          </Text>
        </td>

        <td>
          {chainInfo ? (
            <ButtonEmpty
              onClick={() => {
                
                const href = `${chainInfo.block_explorer_url}/tx/${trade.tx_hash}`;
   
                window.open(href, '_blank');
              }}
            >
              <img src={etherscanpng} height={"18px"} width={"18px"} alt="" />
            </ButtonEmpty>
          ) : null}
        </td>

        <td>{/* {shortenAddress(trade.from_account)}  */}</td>
        <td></td>
      </tr>
    </>
  );
}

export default function TradeList({
  chain,
  address,
}: {
  chain?: string;
  address?: string;
}) {
  const { data, isSuccess } = useCryptoTradeHistoryListQuery({
    chain: chain,
    address: address,
    current: 1,
    pageSize: 30,
  });
  return (
    <>
      <table>
        <tr>
          <td>Date</td>
          <td>
            <Text padding={"5px"}>Act</Text>
          </td>
          <td>
            <Text padding={"5px"}>Amount</Text>
          </td>
          <td>
            <Text padding={"5px"}>Price</Text>
          </td>
          <td>
            <Text padding={"5px"}>Total</Text>
          </td>

          <td>Other</td>
        </tr>
        {data?.data?.map((v) => {
          return <TradeRow trade={v} token_address={address} />;
        })}
      </table>
    </>
  );
}
