import Row from "components/Row";
import { useEffect, useState } from "react";
import { Select, Input, Textarea, Switch } from "@rebass/forms";
import { useDarkModeManager } from "theme/components/ThemeToggle";
import styled from "styled-components";
import {
  CardBox,
  CardMain,
  CardItem,
  CardTitle,CardRow,CardRowL,inputStyle,CardRowM,CardRowR,ItemLabelFlex,
  ItemTip,inputStyleNum,InputBlock
} from "../../components_";
import delIcon from "assets/tool/del_icon.png";
import delIconN from "assets/tool/del_icon_n.png";
const ContentBox = styled.div<{ visible: boolean }>`
  display: ${({ visible }) => (visible ? "flex" : "none")};
`;
interface EarningItem {
  address:string,rate:string
}

export interface CollectionEarnings {
  earningList: EarningItem[];
}

interface EarningsFormProps {
  visible: boolean;
  value?: CollectionEarnings;
  onChange: (value: CollectionEarnings) => void;
}

export default function EarningsForm({
  visible,
  value,
  onChange,
}: EarningsFormProps) {
  const [darkMode] = useDarkModeManager();
  const [earningList, setEarningList] = useState<EarningItem[]>();
  const [total, setTotal] = useState<number>(0);
  function chengeEarningList(e: string, index: number, key: string) {
    if(earningList){
      let list: any = [...earningList];
      list[index][key] = e;
      setEarningList(list);
    }

   
  }
  function addItem() {
    console.log('earningList',earningList)
    if(earningList){
      let list: EarningItem[] = [...earningList];
      let item = { address: "", rate: "" };
      list.push(item);
      setEarningList(list);
    }else{
      setEarningList([{ address: "", rate: "" }]);
    }

  }
  function delItem(index: number) {
    if(earningList){
      let list:EarningItem[] = [...earningList];
      list.splice(index, 1);
      setEarningList(list);
    }
   
  }
  useEffect(() => {
    if (value&&value.earningList!==earningList) {
      setEarningList(value.earningList)
    }
  }, [value]);
  useEffect(() => {
    if (earningList) {
      let num=0
      earningList.forEach(el=>{
        if(el.rate){
          num=num+Number(el.rate)
        }
      })
      setTotal(num)
    }
    earningList && onChange({earningList:earningList})
  }, [earningList]);
  return <> <CardBox isAct={visible}>
  <CardMain>
    <CardTitle>Creator Earnings</CardTitle>
    <CardItem>
      <ItemTip>
        Collection owners can collect creator earnings when a user
        re-sells an item they created. Contact the collection owner to
        change the collection earnings percentage or the payout
        address.
      </ItemTip>
    </CardItem>
    <CardItem>
      {earningList?.map((i: any, index1: number) => (
        <CardRow key={index1}>
          <CardRowL>
            <Input
              type="text"
              placeholder="Please enter an address, e.g. 0x1ed3... or destination.eth, destination.lens"
              value={i.address}
              sx={inputStyle}
              onChange={(e) => {
                chengeEarningList(e.target.value, index1, "address");
              }}
            />
          </CardRowL>
          <CardRowM>
            <InputBlock>
            <Input
              type="number"
              placeholder="0"
              value={i.rate}
              sx={inputStyleNum}
              onChange={(e) => {
                chengeEarningList(e.target.value, index1, "rate");
              }}
            /></InputBlock>
            <div>%</div>
          </CardRowM>
          <CardRowR
            src={darkMode ? delIconN : delIcon}
            alt=""
            onClick={() => {
              delItem(index1);
            }}
          />
        </CardRow>
      ))}
    </CardItem>
    <ItemLabelFlex>
      <div
        style={{ color: "#6a5bda", cursor: "pointer" }}
        onClick={() => {
          addItem();
        }}
      >
        + Add address
      </div>
      <div>
        Total<span style={{ opacity: " 0" }}>111</span>
       {total}%
      </div>
    </ItemLabelFlex>
  </CardMain>
</CardBox></>;
}
