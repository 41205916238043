// index.jsx
import * as React from 'react';
import {useEffect} from 'react';

import * as Toast from '@radix-ui/react-toast';
import styled from "styled-components";
import { useToastOpen } from "state/stats/hooks"
import './styles.css';
const Provider = styled(Toast.Provider)`
background: ${({ theme }) => theme.bg2};
z-index: 999999;
padding: 5px;
border-radius:4px;
font-size:14px;
position: fixed;
top: 100px;
`;
const Root = styled(Toast.Root)`
background-color: ${({ theme }) => theme.bg0};
border:1px solid ${({ theme }) =>theme.bg1};
border-radius: 6px;
box-shadow: ${({ theme }) => theme.border1} 0px 10px 38px -10px, ${({ theme }) => theme.border1} 0px 10px 20px -15px;
padding: 15px;
display: grid;
grid-template-areas: 'title action' 'description action';
grid-template-columns: auto max-content;
column-gap: 15px;
align-items: center;

`;
const Description = styled(Toast.Description)<{typestr:string}>`
maxWidth:100%;
color:${({ theme,typestr }) => typestr==='success'?theme.success:typestr==='error'?theme.error:theme.text3};

word-wrap:break-word;
word-break:break-all;       按字符截断换行 /* 支持IE和chrome，FF不支持*/
// word-wrap:break-word;    按英文单词整体截断换行  /* 以上三个浏览器均支持 */

`;
interface RadixPopoverProps {
  label?: string;
  labelNode?: React.ReactNode;
  val?: string;
  type?: string;
}
export default function RadixPopover({ label, val, labelNode }: RadixPopoverProps) {
  const [toastOpen,toastType,toastLabel, setToastOpen] = useToastOpen();
  useEffect(() => {
   if(toastOpen&&toastLabel){
    setTimeout(() => {
      setToastOpen({open:false,type:'',label:''})
    }, 5000);
   }
  }, [toastOpen,toastLabel])
  return (
  <Provider>

    <Root open={toastOpen||false} onClick={()=>setToastOpen(false)}>
      <Description typestr={toastType||''}>
      {toastType+':'+toastLabel}       
      </Description>
    </Root>
    <Toast.Viewport className="ToastViewport" />
  </Provider>
  )

}
