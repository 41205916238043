
import { TableFoot, PageGroups, PageBtn, PageBtn1, } from "components/Paging/components";
import { useClientWidth } from "state/application/hooks";
import arrowN from "assets/tool/btn_l.png";
interface PagingProps {
  value:{
    total:number;
    current:number;
    pagesize:number;
    
    isLoad:boolean;
  }
  onChangeCurrent: (value: number) => void;
  
}


export default function Paging({
  value,onChangeCurrent
}: PagingProps) {

  const clientWidth = useClientWidth(); 

  const changeCurrent = (isadd: string) => {
    if (!value?.isLoad) {
      if (isadd === "add") {
        if (value?.current < Math.ceil(value?.total / value?.pagesize)) {
          onChangeCurrent(value?.current + 1);
        }
      } else {
        if (value?.current > 1) {
          onChangeCurrent(value?.current - 1);
        }
      }
    }
  };

  const changeCurrentFirst = () => {
    if (!value?.isLoad && value?.total > 0) {
      onChangeCurrent(1);
    }
  };
  const changeCurrentLast = () => {
    if (!value?.isLoad && value?.total > 0) {
      onChangeCurrent(Math.ceil(value?.total / value?.pagesize));
    }
  };
  return (
    <>
     <TableFoot>
                <div>A total of {value?.total} items found</div>
                <PageGroups>
                  <PageBtn
                    onClick={() => {
                      changeCurrentFirst();
                    }}
                  >
                    First
                  </PageBtn>
                  <PageBtn
                    onClick={() => {
                      changeCurrent("");
                    }}
                  >
                    <img src={arrowN} style={{ width: "15px", height: "15px" }} />
                  </PageBtn>
                  <PageBtn1>
                    Page {value?.current} {Number(clientWidth) < 801 ? null : (<>of Page {Math.ceil(value?.total / value?.pagesize)}</>)}
                  </PageBtn1>
                  <PageBtn
                    onClick={() => {
                      changeCurrent("add");
                    }}
                  >
                    <img
                      src={arrowN}
                      style={{
                        transform: "rotate(180deg)",
                        width: "15px",
                        height: "15px",
                      }}
                    />
                  </PageBtn>
                  <PageBtn
                    onClick={() => {
                      changeCurrentLast();
                    }}
                  >
                    Last
                  </PageBtn>
                </PageGroups>
              </TableFoot>
    </>
  );
}
