import { useState, useEffect } from "react";
import { Input, Textarea } from "@rebass/forms";
import { useDarkModeManager } from "theme/components/ThemeToggle";
import RadixPopconfirm from "components/Popconfirm";

import {useToastOpen } from 'state/stats/hooks'

import { ButtonTransparent, ButtonPurple } from "components/Button";
import Modal from "styled-react-modal";
import {
  CardTitle,
  TeamChange,
  FaqItemLeft,
  ItemQuestion,
  ItemAnswer,
  DropInfoWeb,
  ItemValueArea1,
  areaStyle1,
} from "../../components_";
import {
  ItemValue,
  inputStyle,
  ItemLabelFlex,
  CloseImg,
  CardItem,
  ItemLabel,
} from "../../../../components_";
import {MAX_WIDTH_MEDIA_BREAKPOINT,MIN_WIDTH_MEDIA_BREAKPOINT,LARGE_MEDIA_BREAKPOINT} from "constants/width"

import closeIcon from "assets/tool/close_icon.png";
import closeIconN from "assets/tool/close_icon_n.png";

import editIcon from "assets/tool/menu_edit.png";
import editIconN from "assets/tool/menu_editw.png";
import arrowIcon from "assets/tool/arrow_iconb.png";
import arrowIconN from "assets/tool/arrow_iconN.png";
const StyledModal = Modal.styled`
  background: ${(props: { theme: any }) => props.theme.bg1};
  border-radius: 8px;
  padding: 0 40px 20px;
  box-sizing: border-box;
  font-size: 16px;
  font-family: Inter-Medium, Inter;
  font-weight: 500;
  width: 800px;
  min-height:auto;
  height:auto;
  overflow: hidden;
  opacity: 1;
  margin: 100px auto;
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    width: 90vw;
    margin: 10vh auto;
    max-height: 80vh;
    overflow: scroll;
  }
`;
export interface DropFaqForms {
  questList?: { answer: string; question: string }[];
}

interface DropFaqFormProps {
  visible: boolean;
  value?: DropFaqForms;
  onChange: (value: DropFaqForms) => void;
}

export default function DropTopForm({
  visible,
  value,
  onChange,
}: DropFaqFormProps) {
  const [darkMode] = useDarkModeManager();
  const [,,, setToastOpen] = useToastOpen();

  const [popuptype, setPopuptype] = useState<string>("imglist");
  const [showPopup, setShowPopup] = useState(false);
  const [questList, setQuestList] = useState<
    { answer: string; question: string; showAnswer?: boolean }[]
  >([]);
  const [questAnswer, setQuestAnswer] = useState<string>("");
  const [questQ, setQuestQ] = useState<string>("");
  const [questIndex, setQuestIndex] = useState<number>(9999);
  useEffect(() => {
    if (value && value.questList) {
      let list = questList.map((el) => {
        return { answer: el.answer, question: el.question };
      });
      if (value.questList !== list) {
        let templist = value.questList.map((el) => {
          return {
            answer: el.answer,
            question: el.question,
            showAnswer: false,
          };
        });
        setQuestList(templist);
      }
    }
  }, [value]);
  const saveQuest = () => {
    if (questAnswer && questAnswer !== "" && questQ && questQ !== "") {
      let list = [...questList];
      if (questIndex == 9999) {
        list.push({ answer: questAnswer, question: questQ });
        setQuestList(list);
        let templist = list.map((el) => {
          return { answer: el.answer, question: el.question };
        });
        onChange({ questList: templist });
        setShowPopup(false);
      } else {
        list[questIndex] = { answer: questAnswer, question: questQ };
        setQuestList(list);
        let templist = list.map((el) => {
          return { answer: el.answer, question: el.question };
        });
        onChange({ questList: templist });
        // onChange({ questList: list })
        setShowPopup(false);
      }
    } else {
      setToastOpen({open:true,type:'error',label:"请完善roadmap信息"})
      return;
    }
  };
  const delQuest = () => {
    let list = [...questList];
    list.splice(questIndex, 1);

    setQuestList(list);
    // onChange({ questList: list })
    let templist = list.map((el) => {
      return { answer: el.answer, question: el.question };
    });
    onChange({ questList: templist });
    setShowPopup(false);
  };

  const editShow = (index: number) => {
    let list = [...questList];
    if (list[index].showAnswer) {
      list[index].showAnswer = false;
    } else {
      list[index].showAnswer = true;
    }
    // list[index] ={answer:list[index].answer,question:list[index].question,showAnswer:true}
    // list[index].showAnswer =!list[index].showAnswer
    setQuestList(list);
  };
  const editQuest = (index: number) => {
    setQuestIndex(index);
    setQuestAnswer(questList[index].answer);
    setQuestQ(questList[index].question);
    setPopuptype("faq");
    setShowPopup(true);
  };
  const addQuest = () => {
    setQuestIndex(9999);
    setQuestAnswer("");
    setQuestQ("");
    setPopuptype("faq");
    setShowPopup(true);
  };

  return (
    <>
      <DropInfoWeb isShow={visible} style={{ flexDirection: "column" }}>
        {questList?.map(
          (
            i: { answer: string; question: string; showAnswer?: boolean },
            index: number
          ) => (
            <FaqItemLeft key={index}>
              <ItemQuestion>{i.question}</ItemQuestion>
              {i?.showAnswer ? <ItemAnswer>{i.answer}</ItemAnswer> : null}

              <TeamChange>
                <CloseImg
                  onClick={() => {
                    editQuest(index);
                  }}
                  style={{ marginRight: "30px" }}
                  src={darkMode ? editIconN : editIcon}
                />
                {i?.showAnswer ? (
                  <CloseImg
                    onClick={() => {
                      editShow(index);
                    }}
                    style={{ transform: "rotate(180deg)" }}
                    src={darkMode ? arrowIconN : arrowIcon}
                  />
                ) : (
                  <CloseImg
                    onClick={() => {
                      editShow(index);
                    }}
                    src={darkMode ? arrowIconN : arrowIcon}
                  />
                )}

                {/* Edit */}
              </TeamChange>
            </FaqItemLeft>
          )
        )}
        <FaqItemLeft>
          <ItemQuestion>Question</ItemQuestion>
          <TeamChange
            onClick={() => {
              addQuest();
            }}
            style={{ top: "40px" }}
          >
            + Add
          </TeamChange>
        </FaqItemLeft>
      </DropInfoWeb>
      <StyledModal
        isOpen={showPopup && popuptype == "faq"}
        onBackgroundClick={() => setShowPopup(!showPopup)}
        onEscapeKeydown={() => setShowPopup(!showPopup)}
      >
        <ItemLabelFlex>
          <CardTitle>{questIndex == 9999 ? "Add " : "Edit "}FAQ</CardTitle>
          <CloseImg
            src={darkMode ? closeIconN : closeIcon}
            onClick={() => setShowPopup(!showPopup)}
          />
        </ItemLabelFlex>
        <CardItem>
          <ItemLabel>Question *</ItemLabel>
          <ItemValue>
            <Input
              type="text"
              placeholder="ex:What is the mint price?"
              value={questQ}
              sx={inputStyle}
              onChange={(e) => {
                setQuestQ(e.target.value);
              }}
            />
          </ItemValue>
        </CardItem>

        <CardItem>
          <ItemLabel>Answer *</ItemLabel>
          <ItemValueArea1>
            <Textarea
              id="comment"
              name="comment"
              placeholder="The mint price is 0.07 ETH"
              value={questAnswer}
              sx={areaStyle1}
              onChange={(e) => {
                setQuestAnswer(e.target.value);
              }}
            />
          </ItemValueArea1>
          {questIndex == 9999 ? null : (
               <RadixPopconfirm
               val={'Are you sure to delete this?'}
               labelNode={<ButtonTransparent
                padding="10px"
                $borderRadius="4px"
                margin="0 0 10px 0"
                width="100%"
              >
                Del
              </ButtonTransparent>}
               onConfirm={() => {
                delQuest();
               }}
             ></RadixPopconfirm>

        
          )}

          <ButtonPurple
            padding="10px"
            $borderRadius="4px"
            width="100%"
            onClick={() => saveQuest()}
          >
            save
          </ButtonPurple>
        </CardItem>
      </StyledModal>
    </>
  );
}
