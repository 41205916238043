
import { emptySplitApi } from './emptySplitApi'
export interface ChainAccountRequest {
  page: number;
  pageSize: number | null;
  chain: string;
  period: string;
  sortby: string;
  direct: string;
}
export interface ChainAccountResponse {
  data: ChainAccount
  total: number;
  message: null | string;
  success: boolean;
}
const chainAccountApi = emptySplitApi.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    chainAccount: builder.query<ChainAccountResponse, Record<string, any>>({
      query: (params) => ({
        url: `/chain-account/${params.chain}/${params.address}`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useChainAccountQuery,

} = chainAccountApi;
