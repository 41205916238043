import { createSlice } from '@reduxjs/toolkit'




export enum ApplicationModal {
  WALLET,
  SETTINGS,
  ACCEPT_SIGN,
  ALLOWED_SEADROP,
  PLACE_ORDER,
}

type PopupList = Array<{ key: string; show: boolean; content: string; removeAfterMs: number | null }>

export interface ApplicationState {
  readonly blockNumber: { readonly [chainId: number]: number }
  readonly chainId: number | null
  readonly clientWidth: string | null
  readonly openModal: ApplicationModal | null
  readonly openMenu:false
  readonly showSidebar:false
  readonly popupList: PopupList
}

const initialState: ApplicationState = {
  blockNumber: {},
  chainId: null,
  openModal: null,
  openMenu: false,
  showSidebar:false,
  clientWidth:'clientWidthclientWidth',
  popupList: [],
}

const applicationSlice = createSlice({
  name: 'application',
  initialState,
  reducers: {
    updateChainId(state, action) {
      const { chainId } = action.payload
      state.chainId = chainId
    },
    updateBlockNumber(state, action) {
      const { chainId, blockNumber } = action.payload
      if (typeof state.blockNumber[chainId] !== 'number') {
        state.blockNumber[chainId] = blockNumber
      } else {
        state.blockNumber[chainId] = Math.max(blockNumber, state.blockNumber[chainId])
      }
    },
    setOpenModal(state, action) {
      state.openModal = action.payload
    },
    setOpenMenu(state, action) {
      state.openMenu = action.payload
    },
    setShowSidebar(state, action) {
      // console.log(action.payload)
      state.showSidebar = action.payload
    },
    setClientWidth(state, action) {
      // console.log(action.payload)
      state.clientWidth = action.payload
    }
  },
})

export const { updateChainId, updateBlockNumber, setOpenModal,setOpenMenu,setShowSidebar,setClientWidth } =
  applicationSlice.actions
export default applicationSlice.reducer
