import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import {useToastOpen } from 'state/stats/hooks'
import NoData from "components/NoData"
import AccountTop from "../components/AccountTop";

import { useClientWidth, useOpenMenu } from "state/application/hooks";
import {
  MainItemNone,
  WebMainB,
  FilterSearch,
  SearchL,
  MainBBl, TypeItem,
  LeftSearchTitle,
  PropItemIcon,
} from "../components_";
import { MainBB,
  MainBottom,
  MainBBR,} from "pages/Nft/NftItems/components_";
import closeIcon from "assets/tool/close_icon.png";
import closeIconN from "assets/tool/close_icon_n.png";
import fliterIcon from "assets/fliter_icon.png";
import fliterIconN from "assets/fliter_icon_n.png";
import { useDarkModeManager } from "theme/components/ThemeToggle";

// import "./index.css";

export function Deals() {
  const clientWidth = useClientWidth();
  let navigate = useNavigate();
  const [,,, setToastOpen] = useToastOpen();
  const [darkMode] = useDarkModeManager();
  const [isFilter, setIsFilter] = useState(true);
  const [total, setTotal] = useState(0);
  const [current, setCurrent] = useState(1);
  const [isLoad, setIsLoad] = useState(false);
  const [itemsList, setItemsList] = useState<Item[] | null>();
  const [type, setType] = useState<string>("All");
  const typeList = ['All', 'Active', 'Accepted', 'Canceled', 'Inactive', 'Expired'];







  const backToAll = () => {
    setCurrent(1)
  }
  return (
    <>
      <AccountTop current="deals"></AccountTop>

      <WebMainB>

        <MainBottom>
          {Number(clientWidth) < 801 ? (
            <FilterSearch>
              <SearchL
                src={darkMode ? fliterIconN : fliterIcon}
                onClick={() => {
                  setIsFilter(!isFilter);
                }}
              />
            </FilterSearch>
          ) : null}


          <MainBB>
            <MainBBl isFilter={isFilter}>
              {Number(clientWidth) < 801 ? (
                <LeftSearchTitle style={{ margin: '20px 0' }}><div>Search Filtwer</div> <PropItemIcon src={darkMode ? closeIconN : closeIcon} onClick={() => setIsFilter(!isFilter)} /></LeftSearchTitle>
              ) : null}
              {typeList.map((i: string, index: number) => (<div key={index}>
                <TypeItem isAct={i === type} onClick={() => setType(i)}>{i}</TypeItem>
              </div>

              ))}
            </MainBBl>
            <MainBBR isFilter={isFilter}>
              {!isLoad && total < 1 ? (
                <NoData onChange={() => backToAll()}></NoData>


              ) : ('')}
              <MainItemNone></MainItemNone>
              <MainItemNone></MainItemNone>
              <MainItemNone></MainItemNone>
            </MainBBR>
          </MainBB>

        </MainBottom>
      </WebMainB>

    </>
  );
}
