import styled, { keyframes } from "styled-components/macro";

import Modal from "styled-react-modal";
import {
  MAX_WIDTH_MEDIA_BREAKPOINT,
  MIN_WIDTH_MEDIA_BREAKPOINT,
  LARGE_MEDIA_BREAKPOINT,
} from "constants/width";
import { WebMain, MainBB, MainBottom } from "pages/Nft/NftItems/components_";
import { WebAll, PageLine } from "../rankings/components_";
export const PageBg = styled(WebAll)`
  background: ${({ theme }) => theme.bg0};
`;
export const PageTitle = styled(PageLine)`
  display: flex;
  align-items: flex-end;

  cursor: pointer;
  color: ${({ theme }) => theme.text1};
  > div {
    opacity: 0.8;
    margin-right: 4px;
    :hover {
      opacity: 1;
    }
  }
  > div:last-child {
    font-size: 25px;

    line-height: 36px;
    cursor: auto;
    color: ${({ theme }) => theme.text2};
    :hover {
      opacity: 0.8;
    }
  }
`;

export const AccountTable = styled.div<{ isAct: boolean }>`
  padding: 10px 20px;
  display: ${({ isAct }) => (isAct ? "" : "none")};
`;
export const InfoBlock = styled.div<{ isAct: boolean }>`
  min-height:400px;
  padding: 40px;
  overflow:auto;
  display: ${({ isAct }) => (isAct ? "" : "none")};
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
  ::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }
  
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    padding: 10px;
  }
`;
export const TableTitleItem = styled.div`
  width: 25%;
  height: 50px;
  line-height: 50px;
  color: ${({ theme }) => theme.text3};
  font-size: 14px;
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    font-size: 12px;
    width: 30%;
  }
`;
export const DataRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
`;
export const DataDesc = styled.div`
padding:20px 0;
margin:20px 0;
border-bottom: 1px solid ${({ theme }) => theme.border1};
border-top: 1px solid ${({ theme }) => theme.border1};

`;

export const DataCol = styled.div`
  color: ${({ theme }) => theme.text3};
  > div {
    font-size: 14px;
    max-width: 100%;
    overflow: hidden;
    // text-overflow: ellipsis; //溢出用省略号显示
    white-space: nowrap; //溢出不换行
    
    margin: 1px 0;
  }
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    width: 50%;
  }
`;
export const DataCol2 = styled(DataCol)`
  width: auto;
  margin-right:50px;
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    width: 100%;
    margin-right:0px;

  }
`;
export const ColVal = styled.div`
  line-height:25px;
  color: ${({ theme }) => theme.text1};
`;
export const ColLink = styled.div`
display: flex;

>div{
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  line-height:25px;
  color: ${({ theme }) => theme.text1};
  padding:0px 5px;
  background:${({ theme }) => theme.bg2};
  margin-right:5px;
  margin-top:5px;
  border-radius:4px;
  font-size: 14px;

  font-family: Amiko-SemiBold, Amiko;
  font-weight: bold;
  color: ${({ theme }) => theme.text2};
}
 
`;
export const BtnImg = styled.img`
  width: 15px;
  height: 15px;
  margin-right: 2px; 

`;
export const TableTitleItemLast = styled(TableTitleItem)`
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    width: 10%;
  }
`;

export const CopyBlock = styled.div`
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    display: none;
  }
`;

export const TableMain = styled.div`
  width: 100%;
  height: cal(100% - 50px);
  font-size: 14px;
`;

export const TableTitle = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 20px;
  border-bottom: 1px solid ${({ theme }) => theme.border1};
  box-sizing: border-box;
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    padding: 0 10px;
  }
`;
export const TableRow = styled(TableTitle)``;
export const TableCol = styled(TableTitleItem)`
  color: ${({ theme }) => theme.text1};
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    width: 30%;
  }
`;
export const TableColLast = styled(TableCol)`
  display: flex;
  align-items: center;
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    width: 10%;
  }
`;

export const BlockBg = styled.div`
  margin-bottom: 20px;
  background: ${({ theme }) => theme.bg1};
  border-radius: 10px;
  padding: 10px 20px;
  color: ${({ theme }) => theme.text1};
`;
export const ModalMain = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px 0;
  margin: 10px 0 30px;
  box-sizing: border-box;
  border-top: 1px solid ${({ theme }) => theme.border1};
  border-bottom: 1px solid ${({ theme }) => theme.border1};
`;

export const Quest = styled.div`
  width: 15px;
  height: 15px;
  line-height: 15px;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  font-size: 12px;
  color: ${({ theme }) => theme.bg3};
  background: ${({ theme }) => theme.text3};
`;
export const TokenItem = styled.div`
  width: 100%;
  padding: 20px 0;
  font-size: 12px;
  color: ${({ theme }) => theme.text3};
  > div:last-child {
    margin-top: 10px;
  }
`;
export const Text1 = styled.div`
  color: ${({ theme }) => theme.text1};
`;
export const Text3 = styled.div`
  color: ${({ theme }) => theme.text3};
`;

export const BlockFlex = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    flex-wrap: wrap;
  }
`;
export const TokenBtns = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    width: 100%;
    transform: scale(0.8);
    transform-origin: right;
    flex-direction: row-reverse;
  }
`;
export const TokenData = styled(BlockBg)`
  width: 65%;
  padding: 0;
  height: 500px;
  overflow: hidden;
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    width: 100%;
  }
`;

export const TokenTransaction = styled(BlockBg)`
  width: calc(35% - 20px);
  padding-top: 0;
  height: 500px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    width: 100%;
  }
`;

export const MoreIcon = styled.img`
  width: 30px;
  height: 30px;
  object-fit: cover;
  padding: 5px;

  cursor: pointer;
`;
export const ChangeIcon = styled(MoreIcon)`
  background: ${({ theme }) => theme.bg2};

  position: absolute;
  top: -15px;
  left: calc(50% - 15px);
  transform: rotate(90deg);
  border-radius: 50%;
`;

export const OptionText = styled.div<{ selected: boolean }>`
  font-size: 14px;
  height: 36px;
  line-height: 36px;
  width: 100%;
  padding: 0 6px;
  box-sizing: border-box;
  margin: 0 auto;
  border-radius: 6px;
  cursor: pointer;

  white-space: nowrap;
  background: ${({ selected, theme }) => (selected ? theme.bg2 : theme.bg0)};
  color: ${({ selected, theme }) => (selected ? theme.text1 : theme.text3)};
  :hover {
    background: ${({ theme }) => theme.bg1};
  }
`;
export const OptionToken = styled(OptionText)<{ selected: boolean }>`
  border-radius: 5px;
  display: flex;
  align-items: center;
  line-height: 14px;
  padding: 6px;
  height: auto;

  justify-content: space-between;
`;
export const OptionInfo = styled.div`
  flex: 1;
`;

export const TitleLine = styled.div`
  background: ${({ theme }) => theme.modalBG1};
  height: 50px;
  width: 100%;

  display: flex;
  align-items: center;
`;
export const TitleItem = styled.div<{ isAct: boolean }>`
  background: ${({ isAct, theme }) => (isAct ? theme.bg1 : "")};
  height: 50px;
  line-height: 50px;
  padding: 0 20px;
  color: ${({ isAct, theme }) => (isAct ? theme.text1 : theme.text3)};
  border-top-left-radius: ${({ isAct }) => (isAct ? "10px" : "")};
  border-top-right-radius: ${({ isAct }) => (isAct ? "10px" : "")};
  cursor: pointer;
`;

export const DropDown = styled.div<{ isopen: boolean }>`
  padding: 7px 7px;
  width: 100%;
  min-width: 80px;
  z-index: 9999;
  text-align: left;
  opacity: 0;
  height: 0;
  display: none;
  border-radius: 6px;
  font-size: 14px;
  background: ${({ theme }) => theme.bg0};
  border: 1px solid ${({ theme }) => theme.bg2};
  position: absolute;
  right: 0;
  top: 30px;
`;
export const DropTokenDown = styled(DropDown)<{ isopen: boolean }>`
  min-width: 350px;

  left: 0 !important;
  top: 50px;
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    min-width: 300px;
    max-width: 95vw;
  }
`;
export const MoreBlock = styled.div`
  width: 30px;
  height: 30px;
  position: relative;

  :hover {
    ${DropDown},${DropTokenDown} {
      opacity: 1;
      height: auto;
      display: block;
    }
  }
`;
export const TokenImage = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
  background: ${({ theme }) => theme.bg0};
  margin-right: 10px;
  object-fit: cover;
`;

export const TokenIcon = styled(TokenImage)`
  width: 30px;
  height: 30px;
  margin-right: 5px;
`;
export const ArrowImg = styled.img`
  width: 15px;
  height: 15px;
  // transform: rotate(-90deg);
  margin-left: 5px;
  transition: transform 450ms ease;
`;
export const DivisionLine = styled.div`
  position: relative;
  width: 100%;
  height: 0;
  border-top: 1px solid ${({ theme }) => theme.border1};
`;
export const TokenBlock = styled.div`
  padding: 10px;
  background: ${({ theme }) => theme.bg2};
  border-radius: 5px;
  display: flex;
  align-items: center;
`;

export const TokenSelectBlock = styled(TokenBlock)`
  position: relative;
  cursor: pointer;

  :hover {
    ${DropTokenDown} {
      opacity: 1;
      height: auto;
      display: block;
    }
  }
`;
export const TopLeft = styled(BlockFlex)`
  width: 15%;
  border-right: 1px solid ${({ theme }) => theme.border1};
  padding-right: 20px;
  box-sizing: border-box;
  justify-content: flex-start;
  > div:last-child {
    width: calc(100% - 60px);
  }
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    width: 100%;
    border: none;
  }
`;
export const TextName = styled.div`
  font-size: 16px;
  font-weight: 600;
  margin: 2px 0;
`;
export const TextDesc = styled.div`
  font-size: 12px;
  margin: 2px 0;
  color: ${({ theme }) => theme.text3};
`;
export const TopRight = styled(BlockFlex)`
  width: calc(85% - 20px);
  border: none;
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    width: 100%;
  }
`;
export const TokenInfo = styled(BlockFlex)`
  width: auto;
  > div {
    margin-right: 15px;
  }
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    margin: 5px 0;
  }
`;
export const ChangeTag = styled(TextDesc)`
  display: inline-block;
  color: #1d7b6b;
  background: rgb(29 123 107 / 40%);
  margin: 2px 2px;
  padding: 2px 4px;
  border-radius: 4px;

  transform: scale(0.8);
`;

export const CardTableTitleColLast = styled.div`
  width: 5%;
  white-space: nowrap;
  font-size: 14px;
  overflow: hidden;
  display: flex;
  align-items: center;
  cursor: pointer;
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    width: 10%;
  }
`;
