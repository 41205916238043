import { useWeb3React } from "@web3-react/core";
import { useState, useEffect } from "react";
import {useToastOpen } from 'state/stats/hooks'
import { Input } from "@rebass/forms";
import { useNavigate, useParams } from "react-router-dom";
import { ButtonPurple } from "components/Button";
import TransferImg from "../Transfer/components/TransferImg";
import { PageAll } from "../Transfer/components_";
import {
  CardMain,
  CardTitle,
  CardItem,
  ItemLabel,
  ItemValue,
  inputStyle,
} from "../EditCollection/components_";
import { useAssetsInfoQuery } from "apis/assets";
import erc721SeaDropABI from "abis/erc721SeaDrop.json";
import { useErc721SeaDropContract } from "hooks/useContract";

export default function Transfer() {
  const { chain, contract_address, token_id } = useParams();

  let navigate = useNavigate();
  const { account } = useWeb3React();
  const [,,, setToastOpen] = useToastOpen();
  const [transferLoading, setTransferLoading] = useState(false);
  const [address, setAddress] = useState("");
  const [list, setList] = useState<Assets[]>([]);
  const { data } = useAssetsInfoQuery({
    chain: chain as string,
    contract_address: contract_address as string,
    token_id: token_id as string,
  });
  const erc721Seadrop = useErc721SeaDropContract(
    contract_address,
    erc721SeaDropABI,
    true
  );

  const transferItems = async () => {
    if (
      erc721Seadrop?.["safeTransferFrom(address,address,uint256)"] &&
      account &&
      address
    ) {
      setTransferLoading(true);
      try {
        let res = await erc721Seadrop[
          "safeTransferFrom(address,address,uint256)"
        ](account, address, token_id as string);
        setToastOpen({open:true,type:'success',label:"success"})

        setTransferLoading(false);
        navigate(-1);
      } catch (err) {
        console.log("error", err);
        setToastOpen({open:true,type:'error',label:(err as Error).message})

        setTransferLoading(false);
      }
    }
  };

  useEffect(() => {
    if (data?.success) {
      console.log(data, "data");
      setList([data.data]);
    }
  }, [data]);
  return (
    <>
      <PageAll>
        <CardMain>
          <CardTitle>Transfer items</CardTitle>
          <CardItem>
            <TransferImg list={list} />
          </CardItem>
          <CardItem>
            <ItemLabel>Address</ItemLabel>
            <ItemValue>
              <Input
                type="text"
                placeholder="e.g. 0x1ed3... or destination.eth, destination.lens"
                value={address}
                sx={inputStyle}
                onChange={(e) => {
                  setAddress(e.target.value);
                }}
              />
            </ItemValue>
          </CardItem>
          <ButtonPurple
            padding={"10px"}
            $borderRadius={"4px"}
            width={"100%"}
            onClick={() => transferItems()}
          >
            {transferLoading
              ? "loading..."
              : "Transfer " + list.length + " items"}
          </ButtonPurple>
        </CardMain>
      </PageAll>
    </>
  );
}
