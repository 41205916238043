import { SupportedChainId } from './chains'


type AddressMap = { [chainId: number]: string }

export const AddressZero= "0x0000000000000000000000000000000000000000"

export const PANCAKE_ROUTER_ADDRESS: AddressMap = {

    [SupportedChainId.ARBITRUM_GOERLI]: '0x7B119924Dd48c72dB9b37Bf6ECBAAeeF016C334b',
    [SupportedChainId.BNB]: "0xc6911C2467Ae18E922ec31a63f0B4876e2FE9824"
}

export const SEADROP_ADDRESS: AddressMap = {
    [SupportedChainId.GOERLI]: "0x3462d107EbF42199259F388a9F920536FFFc5fd9",
}
export const SEADPORT_ADDRESS: AddressMap = {
    [SupportedChainId.GOERLI]: "0x00000000000000ADc04C56Bf30aC9d3c0aAF14dC",
}
export const SEADROP_FEE_ADDRESS: AddressMap = {
    [SupportedChainId.GOERLI]: '0x92B81493a6984f5907A73F7f7Db3aD09FA85bF1B',
}

export const TRANSFER_HELPER_ADDRESS: AddressMap = {
    [SupportedChainId.GOERLI]: '0x0000000000c2d145a2526bD8C716263bFeBe1A72',
}