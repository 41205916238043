
import { useCallback } from 'react'
import { SupportedLocale } from '../../constants/locales'


import { useAppDispatch, useAppSelector } from '../hooks'
import {
  updateUserLocale,
} from './actions'
import { setCredentials, updateAccount } from './reducer'


export function useAccount(): [Account|null, (data:any) => void] {
  const dispatch = useAppDispatch()
  const account = useAppSelector((state) => state.user.account)
  const setAccount = useCallback((data:any) => {
    dispatch(setCredentials(data))
  }, [dispatch])
  return [account, setAccount]
}
export function useUpdateAccount(): [Account|null, (data:any) => void] {
  const dispatch = useAppDispatch()
  const accountInfo = useAppSelector((state) => state.user.account)
  const setAccountInfo = useCallback((data:any) => {
    dispatch(updateAccount(data))
  }, [dispatch])
  return [ accountInfo,setAccountInfo]

}
export function useUserLocale(): SupportedLocale | null {
  return useAppSelector((state) => state.user.userLocale)
}

export function useToken(): string | null {
  return useAppSelector((state) => state.user.token)
}
export function useRefAddress(): string | null {
  return useAppSelector((state) => state.user.ref)
}
export function useUserLocaleManager(): [SupportedLocale | null, (newLocale: SupportedLocale) => void] {
  const dispatch = useAppDispatch()
  const locale = useUserLocale()

  const setLocale = useCallback(
    (newLocale: SupportedLocale) => {
      dispatch(updateUserLocale({ userLocale: newLocale }))
    },
    [dispatch]
  )

  return [locale, setLocale]
}



