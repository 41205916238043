
import { useClientWidth } from "state/application/hooks";
import styled from "styled-components";
import {CardTableMaincol,CardTableMaincol1,CardTableMaincolLast,CardTableMainRowWrap,CollectInfoWeb,RowImg,CollectInfoImg,CollectInfoIcon,CollectInfoText
} from "../CardTableMainRow";

export const CollectInfoBlock = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 20px;
  object-fit: cover;
  background:${({ theme }) => theme.bg2};
`;
const DivNone = styled.div`
  white-space: pre;

  display: inline;
  color: ${({ theme }) => theme.bg2};
  background: ${({ theme }) => theme.bg2};
`;


export default function CardTableLoadRow() {
  const clientWidth = useClientWidth();
  return (
    <>
      <CardTableMainRowWrap>
        <CollectInfoWeb>
          <CollectInfoBlock />
          <CollectInfoText>
            <div><DivNone>{'     '}
        </DivNone></div>
            {Number(clientWidth) < 801 ? <div style={{ marginTop: '4px' }}><DivNone>{'     '}
        </DivNone></div>
              : null}
          </CollectInfoText>

        </CollectInfoWeb>
        <CardTableMaincol1>
        <div> <DivNone>{'     '}
        </DivNone></div>
          
          {Number(clientWidth) < 801 ? <div style={{marginTop: '4px'}}><DivNone>{'     '}
        </DivNone></div>
              : null}
        </CardTableMaincol1>
       
        <CardTableMaincol>
        
        <DivNone>{'     '}
        </DivNone>
        </CardTableMaincol>
        <CardTableMaincol>
        <DivNone>{'     '}
        </DivNone>
        
        </CardTableMaincol>

        <CardTableMaincol><DivNone>{'     '}
        </DivNone></CardTableMaincol>
        <CardTableMaincol>
        <DivNone>{'     '}
        </DivNone>
        </CardTableMaincol>
        <CardTableMaincol><DivNone>{'     '}
        </DivNone></CardTableMaincol>
        <CardTableMaincolLast><DivNone>{'     '}
        </DivNone></CardTableMaincolLast>
    

      </CardTableMainRowWrap>
    </>
  );
}
