import { useEffect, useState } from 'react';
import { useWeb3React } from "@web3-react/core";
import useTelegramAuth from "@use-telegram-auth/hook";
import UploadDropzone from "components/Form/upload"
import { useAccount, useUpdateAccount } from "state/user/hooks";
import { useToastOpen } from "state/stats/hooks";

import { Input, Textarea } from "@rebass/forms";
import { ButtonPurple } from "components/Button";

import { useUpdateUserMutation } from 'apis/account';
import {
  RightTitle, ItemTitle, AddressIcon, Address
} from "../../components_";
import {
  CardBox, CardMain, ItemValue, inputStyle,
  ItemValueArea, areaStyle, ItemValueIcon, LinkImg, inputStyle1,
} from "pages/Nft/EditCollection/components_";
import { v1 as uuidv1 } from "uuid";
import { useLoginMutation } from "apis/account";

import linkIcon from "assets/nft/link1_icon.png"
import copyIcon from "assets/nft_copy.png"


export interface ProfileDetails {
  userName?: string;
  bannerUrl?: string;
  imageUrl?: string;
  bio?: string;
  links?: string;
  address?: string;
  email?: string;
}

interface ProfileDetailsFromProps {
  visible: boolean;
  value?: ProfileDetails;
  onChange: (value: ProfileDetails) => void;
}





export default  function ProfileDetailsFrom({
  visible,
  value,
  onChange,
}: ProfileDetailsFromProps) {
  const [accJWT,setAccountJWT ] = useAccount()
  const [acc, setAccount] = useUpdateAccount()
  const [login] = useLoginMutation();
  const [,,, setToastOpen] = useToastOpen();

  const [updateUser] = useUpdateUserMutation()
  const { account,provider } = useWeb3React();
  const [next, setNext] = useState(false);
  const [loading, setLoading] = useState(false);
  const [telegram,setTelegram]=useState("");

  const BOT_ID = "6807358451";


  const { onAuth, isLoading } =   useTelegramAuth(BOT_ID,
    {
      requestAccess:"write",
      windowFeatures: { popup: true,width:350,height:600 },
    },
    {
      onSuccess: (result) => {
        console.log(result)
        setTelegram(result.username)
        // Send the result to the server
      },
    });



  useEffect(() => {
    if (value?.imageUrl && value?.userName) {
      setNext(true)
    } else {
      setNext(false)
    }
  }, [value]);

  const save = async () => {
    console.log('accJWT',accJWT)
    
    if(!account){return}
    if(loading){return}
    if(!value?.imageUrl){
      setToastOpen({ open: true, type: 'error', label: "Profile Image" })
      return}
    if(!value?.userName){
      setToastOpen({ open: true, type: 'error', label: "User name" })

      return}
    if(!accJWT){
      setToastOpen({ open: true, type: 'error', label: "Please sign wallet" })
      var nonce = uuidv1();
      var msg = `Welcome to Zooker!\n\nClick to sign in and accept the Zooker Terms of Service: https://zooker.io/tos\n\nThis request will not trigger a blockchain transaction or cost any gas fees.\n\nYour authentication status will reset after 24 hours.\n\nWallet address:\n${account}\n\nNonce:\n${nonce}`;
      const result = await provider?.getSigner(account).signMessage(msg);
      let signaturestr = result ? result : "";
      const { data } = await login({
        msg: msg,
        signature: signaturestr,
        ref: "0x" + account,
      }).unwrap();
      setAccountJWT(data);
      return
    }
      setLoading(true)
      try {
        let str=JSON.stringify({ avatar: value.imageUrl, bio: value.bio, email: value.email, links: value.links, nickname: value.userName})
        const signaturestr = await provider?.getSigner(account).signMessage(str);
        if(signaturestr){
          const { success,data } = await updateUser({ data:str,account:account,signature:signaturestr }).unwrap();
          if (success) {
            setToastOpen({ open: true, type: 'success', label: "success" })
             setAccount({...accJWT,...data})

          } else {
            setToastOpen({ open: true, type: 'error', label: "error" })
          }
          setLoading(false)
        }else{
          setLoading(false)
        }
      } catch (err) {
        setToastOpen({ open: true, type: 'error', label: (err as Error).message})

        
        setLoading(false)
      }
      
    

  }
  return <>
    <CardBox isAct={visible}>
      <CardMain>
        <RightTitle>Profile Details</RightTitle>
        <ItemTitle>Profile Image</ItemTitle>
        <UploadDropzone url={value?.imageUrl} borderRadius='50%' urlChange={(url) => onChange({ imageUrl: url })}></UploadDropzone>
        {/* <ItemTitle>Profile Banner</ItemTitle>
        <UploadDropzone url={value?.bannerUrl} urlChange={(url) => onChange({ bannerUrl: url })}></UploadDropzone> */}
        <ItemTitle>User name</ItemTitle>
        <ItemValue>
          <Input
            type="text"
            placeholder="entry user name"
            value={value?.userName}
            sx={inputStyle}
            onChange={(e) => {
              onChange({ userName: e.target.value })
            }}
          />
        </ItemValue>
        <ItemTitle>Bio</ItemTitle>
        <ItemValueArea>
          <Textarea
            id="comment"
            name="comment"
            placeholder="Tell the world your story!"
            value={value?.bio}
            sx={areaStyle}
            onChange={(e) => {
              onChange({ bio: e.target.value })
            }}
          />
        </ItemValueArea>

      <ItemTitle>Telegram</ItemTitle>
      <div>  {telegram}    <button onClick={() => onAuth()}>
            {isLoading ? "Authenticating..." : "Bing"}
      </button></div>

        <ItemTitle>Email Address</ItemTitle>
        <ItemValue>
          <Input
            type="text"
            placeholder="entry email"
            value={value?.email}
            sx={inputStyle}
            onChange={(e) => {
              onChange({ email: e.target.value })
            }}
          />
        </ItemValue>
        <ItemTitle>Links</ItemTitle>
        <ItemValueIcon>
          <LinkImg src={linkIcon} />
          <Input
            type="text"
            placeholder="yoursite.io"
            value={value?.links}
            sx={inputStyle1}
            onChange={(e) => {
              onChange({ links: e.target.value })
            }}
          />
        </ItemValueIcon>
        <ItemTitle>Wallet Address</ItemTitle>
        <Address onClick={() => {
          navigator.clipboard.writeText(account||'');
          setToastOpen({open:true,type:'success',label:"success"})
        }}>
          <div>{account ? account.substring(0, 7) + '...' + account.substr(account.length - 5,) : 'none'}</div>
          <AddressIcon src={copyIcon} />
        </Address>
        <ButtonPurple padding='10px' $borderRadius='4px' margin='20px 0 0 0' width='auto' style={next ? { opacity: '1' } : { opacity: '0.5' }} onClick={() => save()}>
          {loading?'loading...':'save'}
          </ButtonPurple>

      </CardMain>
    </CardBox>
  </>;
}
