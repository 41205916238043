import { Box, Button, Flex, Text } from "rebass";

import { useEffect, useState } from "react";
import styled from "styled-components";

import { Input } from "@rebass/forms";
import { useTokenListQuery } from "apis/token";
import { ButtonClear } from "components/Button";
import TokenAvatar from "components/TokenAvatar";
import { Search } from "react-feather";
import { useSupportChains } from "state/chain/hooks";

import { Token } from "core";
import { shortenAddress } from "utils/address";
import starImage from "assets/images/star.png";
import { useWeb3React } from "@web3-react/core";
import { IconStar } from "components/Icons/Star";
import { useAddWatchListMutation, useDelWatchOneMutation } from "apis/ranking";

const Container = styled.div`
  padding: 24px;
  width: 100wh;
`;
const InputWrap = styled(Flex)`
  border-radius: 8px;
  font-size: 16px;
  width: 100%;
  line-height: 24px;
  background: ${({ theme }) => theme.modalBG1};
  border: 1px solid ${({ theme }) => theme.bg2};
  justify-content: center;
  align-items: center;
  padding: 8px;
  > div:first-child {
    > div {
      margin-right: 5px;
    }
  }
  > div {
    margin-right: 5px;
  }
  > input {
    border: 0;
    text-align: left;
    margin: 0;
    padding: 0;
  }
`;
const List = styled.div`
  width: 100%;

 
  height: 400px;
  display:block;
  overflow: auto;
  ::-webkit-scrollbar {
    width: 4px;
    background-color: ${({ theme }) => theme.bg1};
  }
  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.bg3};
  }

    @media screen and (min-width: 800px){
    min-width:400px;
  }

  @media screen and (min-width: 0px) and (max-width: 800px) {
    width: 100%;
    height: auto;
  }
`;

const SearchIcon = styled(Search)`
  width: 18px;
  color: ${({ theme }) => theme.text4};
`;

const TextButton = styled(Flex)<{selected:boolean}>`
  margin:6px 6px 0 0;
  cursor: pointer;;
  float: left;
  justify-content: center;
  align-items:: center;
  align-content:center;
  background: ${({ theme }) => theme.modalBG1};
  border: 1px solid ${({ theme,selected}) => selected?theme.bg5:theme.bg2};
  border-radius: 8px;
  display: block;
  height: 40px;
  width: 40px;
  :active {
    border: 1px solid ${({ theme }) => theme.bg5};
  }
`;
const IconButton = styled(Flex)<{selected:boolean}>`
margin:6px 6px 0 0;
  justify-content: flex-start;
  align-items: center;
  background: ${({ theme }) => theme.modalBG1};
  border: 1px solid ${({ theme,selected }) => selected?theme.bg5:theme.bg2};
  border-radius: 8px;
  float: left;
  display: block;
  height: 40px;
  width: 40px;
  padding: 5px;
  cursor: pointer;
  > img {
    max-width: 100%;
    max-height: 100%;
    margin: auto;
    display: block;
  }
  :active {
    border: 1px solid ${({ theme }) => theme.bg5};
  }
`;

const ChainSelect=styled.div`
margin-top:24px;
display:block;
`
const Tile=styled(Flex)`
justify-content:space-between;
border-radius: 8px;
padding:12px 7px 12px 0;
cursor:pointer;
:hover {
  background: ${({ theme }) => theme.bg2};
}
`

interface TokensSelectProps {
  showChainSelect?:boolean;
  value: string;
  onChange: (e: Token) => void;
}

export default function TokenSelectPanel({
  showChainSelect,
  value,
  onChange,
}: TokensSelectProps) {
  const {account}=useWeb3React()
  const [key, setKey] = useState("");
  const [tag,setTag]=useState("all")
  const [tokenList, setTokenList] = useState<Token[]>([]);
  const [chain,setChain]=useState<string|undefined>(undefined)
  const [isSelectAvail,setIsSelectAvail]=useState(true)
  const [supportChains] = useSupportChains();
  const [delWatchOne] = useDelWatchOneMutation()
  const [addWatchList] = useAddWatchListMutation()
  const { data } = useTokenListQuery({
    chain: chain?chain:"",
    key: key?key:"",
    current: 1,
    pageSize: 20,
    watchlist:tag==="favorite"?account:"",
    account:account
  });
  useEffect(() => {
    if (data && data.success) {
      const result:Token[]=[]
      data.data?.forEach(e=>result.push(new Token(e.chain,e.address,e.decimals,e.symbol,e.name,e.image_url,e.is_watching)) )
      setTokenList(result);
    }
  }, [data]);

  const handleTagClick=(v:ChainInfo|string)=>{
    if(typeof v==="string"){
      setChain(undefined)
      setTag(v)
    }else{
      setChain(v.id)
      setTag(v.id)
    }
  }


  const handleWatchingClick = async (chain:string,address:string, is_watching: boolean) => {

        if(is_watching){
          const res = await delWatchOne({
            chain: chain,
            address: address
          }).unwrap();
          
        }else{
          const res=await addWatchList({
            chain: chain,
            address: address,
            type:"CRYPTO"
          }).unwrap();
        }

    
  }
  
  return (
    <>
      <Container>
        <InputWrap>
          <div>
            <SearchIcon />
          </div>
          <Input
            onChange={(e) => {
              setKey(e.target.value);
            }}
            value={key}
          />
          <ButtonClear
            onClick={() => {
              setKey("");
              console.log("fff");
            }}
          />
        </InputWrap>
      
        <ChainSelect>
          <Text>Select network:</Text>
          <Box height={"46px"}>

          <IconButton key={"star"} selected={tag==="favorite"?true:false} onClick={()=>{
            handleTagClick("favorite")
          }}>
                  <img src={starImage} alt="favorite" />
          </IconButton>

            <TextButton onClick={()=>handleTagClick("all")} selected={tag==="all"?true:false}>
              <Text textAlign={"center"}>All</Text>
            </TextButton>
            {supportChains.map((v) => {
              return (
                <IconButton key={v.id} selected={chain===v.id?true:false} onClick={()=>handleTagClick(v)}>
                  <img src={v.logo}  alt={v.id}/>
                </IconButton>
              );
            })}
          </Box>
        </ChainSelect>
            
            <Text marginTop={"24px"}>Select crypto</Text>
        <List>
          {tokenList.map((v) => {
            return (
           
                <Tile
                key={`${v.chain}-${v.address}`}
      onClick={()=>{
        
        isSelectAvail && onChange(v as Token)
      }}
                >
                  <Flex><TokenAvatar size={36} token={v} />
                  <Box marginLeft={"12px"}>
                    <Text fontSize={16}>{v.symbol}</Text>
                    <Text fontSize={12}>
                      {shortenAddress(v.address, 8, 8)}
                    </Text>
                  </Box>
                  </Flex>
                  <Box marginRight={0} style={{zIndex:1}} > <Button onFocus={()=>setIsSelectAvail(false)} onBlur={()=>{setIsSelectAvail(true)}} onClick={()=>{
                    handleWatchingClick(v.chain,v.address,v.is_watching||false)
                  }} ><IconStar check={v.is_watching || false} size="16px"/></Button></Box>
                </Tile>
         
            );
          })}
        </List>
      </Container>
    </>
  );
}
