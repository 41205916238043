
import styled from "styled-components/macro";
export const ItemLabel = styled.div`
margin: 10px 0;
font-weight: 600;
>span{
  color:#6a5bda;
  cursor: pointer;
}
`;
export const VerificationBtn = styled.div`
width: 172px;
height: 40px;
text-align:center;
background:${({ theme }) => theme.bg2};

opacity: 1;
border-left: 1px solid ${({ theme }) => theme.border1};
font-size: 16px;
font-family: Inter-Regular, Inter;
font-weight: 400;
color: ${({ theme }) => theme.text3};
white-space: nowrap;
line-height: 40px;
padding: 0 10px;
cursor: pointer;
`;