import { useDarkModeManager } from "theme/components/ThemeToggle";
import { BackImg, MenuItem, MenuTitle } from "../../components_";

import arrowL from "assets/tool/arrow_u.png";
import arrowLN from "assets/tool/arrow_u_n.png";
import { useNavigate } from "react-router";
import styled from "styled-components";

export const InfoImg = styled.img`
width:50%;
height:auto;
object-fit: cover;
margin: 30px 25% 0;`;

const collectionMenus: { name: string, label: string }[] = [
    {
      name: "Details",
      label: "Details",
    },
    {
      name: "Graphics",
      label: "Graphics",
    },
    {
      name: "Earnings",
      label: "Earnings",
    },
    {
      name: "Links",
      label: "Links",
    }
  ];
  const DropFormMenu = [
    { name: "Settings", label: "Settings" },
    { name: "Pre-reveal", label: "Pre-reveal" },
    { name: "DropEarnings", label: "Earnings" },
    { name: "Drop page", label: "Drop page" }
  ]

interface EditMenuProps {
    current: string;
    has_drop:boolean|null
    logo_url:string
    onChange:(group:string,value:string)=>void
  }

export default function EditMenu({current,has_drop,logo_url,onChange}:EditMenuProps) {
    const [darkMode] = useDarkModeManager();
    let navigate = useNavigate();
    return (<>
 
 <InfoImg src={logo_url} />
          <MenuTitle onClick={() => navigate('/my-collection')}>
            <BackImg src={darkMode ? arrowLN : arrowL} />
            <div>My Collections</div>
          </MenuTitle>
          <MenuItem
            isAct={false}
            style={{ opacity: '0.5',cursor:'auto',background:'transparent' }}
          >
            Collecttions
          </MenuItem>

          {collectionMenus.map((v, i) => {
            return (
              <MenuItem
                isAct={current == v.name}
                key={v.name}
                onClick={() => {
                onChange("collection",v.name);                
                }}
              >
                {v.label}
              </MenuItem>
            );
          })}

          {
            has_drop ?
              <MenuItem
                isAct={false}
                style={{ opacity: '0.5',cursor:'auto',background:'transparent' }}
                >
                Drop
              </MenuItem> : null}
          {
            has_drop && 
            DropFormMenu.map((v, i) => {
              return <MenuItem
                isAct={current === v.name}
                key={v.name}
                onClick={() => {
                    onChange("drop",v.name);
    
                }}
              >
                {v.label}
              </MenuItem>
            })
          }
    
    </>)
}