import { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Flex, Text } from "rebass";

import { Seaport } from "@opensea/seaport-js";
import { useWeb3React } from "@web3-react/core";
import { useSaveListingOrderMutation } from "apis/orders";
import { useAccountDrawer } from "components/AccountDrawer";
import { ButtonError, ButtonPrimary } from "components/Button";
import { Field } from "components/swap/constants";
import { useSwitchChain } from "hooks/useSwitchChain";
import DurationInput from "pages/Crypto/components/DurationInput";
import TokenAmountInput from "pages/Crypto/components/TokenAmountInput";
import UnitPriceInput from "pages/Crypto/components/UnitPriceInput";
import { useCurrentChain, useSelectChain } from "state/chain/hooks";
import {
  LimitContextProvider,
  useLimitContext,
} from "state/limit/LimitContext";
import styled from "styled-components";
import { maxAmountSpend } from "utils/maxAmountSpend";

import { formatCurrencyAmount, NumberType } from "utils/formatNumbers";

import { Currency, CurrencyAmount, Token } from "core";
import {
  LimitPriceErrorType,
  useCurrentPriceAdjustment,
} from "pages/Crypto/components/UnitPriceInput/useCurrentPriceAdjustment";
import { LimitState } from "state/limit/limit_ypes";
import { LimitOrderTrade } from "state/routing/types";
import {
  useSwapActionHandlers,
  useSwapAndLimitContext,
} from "state/swap/hooks";
import { CurrencyState } from "state/swap/types";

const FormItem = styled(Box)`
  margin-bottom: 42px;
`;
const FormLabel = styled(Box)`
  margin-bottom: 14px;
  font-size: 18px;
  font-weight: 600;
`;

const ExpiresFlex = styled(Flex)`
  justify-content: space-between;
`;

type LimitFormProps = {
  onCurrencyChange?: (selected: CurrencyState) => void;
};

function LimitForm({ onCurrencyChange }: LimitFormProps) {
  const { onSwitchTokens } = useSwapActionHandlers();
  const { provider, account } = useWeb3React();

  const {
    currencyState: { inputCurrency, outputCurrency },
    setCurrencyState,
  } = useSwapAndLimitContext();
  const { limitState, setLimitState, derivedLimitInfo } = useLimitContext();
  const {
    currencyBalances,
    parsedAmounts,
    parsedLimitPrice,
    limitOrderTrade,
    marketPrice,
  } = derivedLimitInfo;

  const [limitPriceInverted, setLimitPriceInverted] = useState(false);

  const [duration, setDuration] = useState("1day");

  const [loading,setLoading]=useState(false)

  const chainInfo = useCurrentChain();

  const [createlistingOrder] = useSaveListingOrderMutation();

  useEffect(() => {
    if (limitPriceInverted) {
    }
  }, [limitPriceInverted, parsedAmounts]);

  const { currentPriceAdjustment, priceError } = useCurrentPriceAdjustment({
    parsedLimitPrice,
    marketPrice: limitState.limitPriceInverted
      ? marketPrice?.invert()
      : marketPrice,
    baseCurrency: limitState.limitPriceInverted
      ? outputCurrency
      : inputCurrency,
    quoteCurrency: limitState.limitPriceInverted
      ? inputCurrency
      : outputCurrency,
    limitPriceInverted: limitState.limitPriceInverted,
  });

  const hasInsufficientFunds =
    parsedAmounts.INPUT && currencyBalances.INPUT
      ? currencyBalances.INPUT.lessThan(parsedAmounts.INPUT)
      : false;

  const handlePlaceOrder = async () => {
    setLoading(true)
    try {
      const seaport = new Seaport(provider as any);
     
      const offerer = account;
      const startTime = (new Date().getTime() / 1000).toFixed(0);
      const endTime = (new Date().getTime() / 1000).toFixed(0);

      const offerToken=parsedAmounts[Field.INPUT]?.currency.address
      const offerAmount=parsedAmounts[Field.INPUT]?.quotient.toString() as string

      const considerationToken=parsedAmounts[Field.OUTPUT]?.currency.address
      const considerationAmount=parsedAmounts[Field.OUTPUT]?.quotient.toString()  as string
      console.log("seaport address", seaport.contract.address,"offer",offerAmount,"consideration",considerationAmount);
      const { executeAllActions } = await seaport.createOrder(
        {
          startTime: startTime,
          endTime: endTime,
          offer: [
            {
              token: offerToken,
              amount:offerAmount ,
              endAmount: offerAmount,
            },
          ],
          consideration: [
            {
              token: considerationToken,
              amount:considerationAmount,
              endAmount:considerationAmount,
              recipient: offerer,
            },
          ],
        },
        offerer
      );

      const order = await executeAllActions();
      const orderHash = seaport.getOrderHash(order.parameters);
      const result = await createlistingOrder({
        orderHash,
        order,
        chain: chainInfo?.id as string,
        protocol: seaport.contract.address,
      }).unwrap();

      console.log("result:", result);
    } catch (err) {
      console.log("errorerror", err);
    }
    setLoading(false)
  };

  const formattedAmounts = useMemo(() => {
    // if there is no Price field, then just default to user-typed amounts
    if (!limitState.limitPrice) {
      return {
        [Field.INPUT]: limitState.inputAmount,
        [Field.OUTPUT]: limitState.outputAmount,
      };
    }
    const formattedInput = limitState.isInputAmountFixed
      ? limitState.inputAmount
      : formatCurrencyAmount({
          amount: derivedLimitInfo.parsedAmounts[Field.INPUT],
          type: NumberType.SwapTradeAmount,
          placeholder: "",
        });
    const formattedOutput = limitState.isInputAmountFixed
      ? formatCurrencyAmount({
          amount: derivedLimitInfo.parsedAmounts[Field.OUTPUT],
          type: NumberType.SwapTradeAmount,
          placeholder: "",
        })
      : limitState.outputAmount;

    return {
      [Field.INPUT]: formattedInput,
      [Field.OUTPUT]: formattedOutput,
    };
  }, [
    limitState.limitPrice,
    limitState.isInputAmountFixed,
    limitState.inputAmount,
    limitState.outputAmount,
    derivedLimitInfo.parsedAmounts,
  ]);

  const switchTokens = useCallback(() => {
    onSwitchTokens({
      newOutputHasTax: false,
      previouslyEstimatedOutput: limitState.outputAmount,
    });
    setLimitState((prev) => ({ ...prev }));
  }, [limitState.outputAmount, onSwitchTokens, setLimitState]);

  const onSelectCurrency = useCallback(
    (type: keyof CurrencyState, newCurrency: Currency) => {
      if (
        (type === "inputCurrency" ? outputCurrency : inputCurrency)?.equals(
          newCurrency
        )
      ) {
        return switchTokens();
      }
      const [newInput, newOutput] =
        type === "inputCurrency"
          ? [newCurrency, outputCurrency]
          : [inputCurrency, newCurrency];
      const newCurrencyState = {
        inputCurrency: newInput,
        outputCurrency: newOutput,
      };
      const [otherCurrency, currencyToBeReplaced] =
        type === "inputCurrency"
          ? [outputCurrency, inputCurrency]
          : [inputCurrency, outputCurrency];
      // Checking if either of the currencies are native, then checking if there also exists a wrapped version of the native currency.
      // If so, then we remove the currency that wasn't selected and put back in the one that was going to be replaced.
      // Ex: Initial state: inputCurrency: USDC, outputCurrency: WETH. Select ETH for input currency. Final state: inputCurrency: ETH, outputCurrency: USDC
      if (otherCurrency && (newCurrency.isNative || otherCurrency.isNative)) {
        const [nativeCurrency, nonNativeCurrency] = newCurrency.isNative
          ? [newCurrency, otherCurrency]
          : [otherCurrency, newCurrency];
        if (nativeCurrency.wrapped.equals(nonNativeCurrency)) {
          newCurrencyState[
            type === "inputCurrency" ? "outputCurrency" : "inputCurrency"
          ] = currencyToBeReplaced;
        }
      }
      // If the user selects 2 currencies on different chains we should set the other field to undefined
      // if (newCurrency.chain !== otherCurrency?.chain) {
      //   newCurrencyState[
      //     type === "inputCurrency" ? "outputCurrency" : "inputCurrency"
      //   ] = undefined;
      // }
      setLimitState((prev) => ({ ...prev, limitPriceEdited: false }));
      onCurrencyChange?.(newCurrencyState);
      setCurrencyState(newCurrencyState);
    },
    [
      inputCurrency,
      onCurrencyChange,
      outputCurrency,
      setCurrencyState,
      setLimitState,
      switchTokens,
    ]
  );

  const onTypeInput = useCallback(
    (type: keyof LimitState) => (newValue: string) => {
      setLimitState((prev) => ({
        ...prev,
        [type]: newValue,
        limitPriceEdited: type === "limitPrice" ? true : prev.limitPriceEdited,
        isInputAmountFixed: type !== "outputAmount",
      }));
    },
    [setLimitState]
  );

  const maxInputAmount: CurrencyAmount<Currency> | undefined = useMemo(
    () => maxAmountSpend(currencyBalances[Field.INPUT]),
    [currencyBalances]
  );
  const showMaxButton = Boolean(
    maxInputAmount?.greaterThan(0) &&
      !parsedAmounts[Field.INPUT]?.equalTo(maxInputAmount)
  );
  const handleMaxInput = useCallback(() => {
    maxInputAmount && onTypeInput("inputAmount")(maxInputAmount.toExact());
  }, [maxInputAmount, onTypeInput]);
  return (
    <>
      <Box maxWidth={"800px"}>
        <FormItem>
          <Box>
            <FormLabel>Unit price</FormLabel>
            <UnitPriceInput onCurrencySelect={onSelectCurrency} />
          </Box>
        </FormItem>
        <FormItem>
          <FormLabel>Sell</FormLabel>
          <Box>
            <TokenAmountInput
              value={formattedAmounts[Field.INPUT]}
              onUserInput={onTypeInput("inputAmount")}
              showMaxButton={showMaxButton}
              onMax={handleMaxInput}
              currency={inputCurrency ?? null}
              onCurrencySelect={(token) => {
                onSelectCurrency("inputCurrency", token);
              }}
            />
          </Box>
        </FormItem>
        <FormItem>
          <FormLabel>Buy</FormLabel>
          <Box>
            <TokenAmountInput
              value={formattedAmounts[Field.OUTPUT]}
              onUserInput={onTypeInput("outputAmount")}
              showMaxButton={false}
              currency={outputCurrency ?? null}
              onCurrencySelect={(token) => {
                onSelectCurrency("outputCurrency", token);
              }}
            />
          </Box>
        </FormItem>

        <FormItem>
          <ExpiresFlex>
            <Box>Expires in</Box>
            <Box>
              <DurationInput
                value={duration}
                onChange={function (value: number | string): void {
                  setDuration(value as string);
                }}
              />
            </Box>
          </ExpiresFlex>
        </FormItem>

        <SubmitOrderButton
          trade={limitOrderTrade}
          limitPriceError={priceError}
          inputCurrency={inputCurrency}
          outputCurrency={outputCurrency}
          handleContinueToReview={handlePlaceOrder}
          hasInsufficientFunds={hasInsufficientFunds}
          parsedAmounts={parsedAmounts}
          loading={loading}
        />
      </Box>
    </>
  );
}

function SubmitOrderButton({
  trade,
  handleContinueToReview,
  inputCurrency,
  outputCurrency,
  hasInsufficientFunds,
  limitPriceError,
  parsedAmounts,
  loading,
}: {
  trade?: LimitOrderTrade;
  handleContinueToReview: () => void;
  inputCurrency?: Currency;
  outputCurrency?: Currency;
  hasInsufficientFunds: boolean;
  limitPriceError?: LimitPriceErrorType;
  parsedAmounts?: { [field in Field]?: CurrencyAmount<Currency | Token> };
  loading:boolean

}) {
  const { account, chainId, connector } = useWeb3React();

  const chainInfo = useCurrentChain();
  const [walletDrawerOpen, toggleWalletDrawer] = useAccountDrawer();
  const switchChain = useSwitchChain();
  const selectedChainInfo=useSelectChain(inputCurrency?.chain)
  const isValidate=()=>{
    if(!parsedAmounts){
      return false
    }
    const offerAmount=parsedAmounts[Field.INPUT]?.quotient.toString()
    const considerationAmount=parsedAmounts[Field.OUTPUT]?.quotient.toString()
    if (offerAmount && considerationAmount){
      return true
    }
    return false
  }

  if(loading) {
    return (
      <ButtonError
        onClick={()=>{}}
        id="submit-order-button"
        data-testid="submit-order-button"
        disabled={true}
      >
        <Text fontSize={20}>Loading</Text>
      </ButtonError>
    );
  }


  if(!inputCurrency || !outputCurrency) {
    return (
      <ButtonError
        onClick={handleContinueToReview}
        id="submit-order-button"
        data-testid="submit-order-button"
        disabled={false}
      >
        <Text fontSize={20}>Place Limit Order</Text>
      </ButtonError>
    );
  }

 

  if (inputCurrency?.chain !== outputCurrency?.chain) {
    return (
      <ButtonError disabled>
        <Text fontSize={20}>Cross Chain Unsupported</Text>
      </ButtonError>
    );
  }

  if (!account || !chainInfo) {
    return (
      <ButtonPrimary
        onClick={() => {
          !walletDrawerOpen && toggleWalletDrawer();
        }}
      >
        Connect wallet
      </ButtonPrimary>
    );
  }
  if (inputCurrency?.chain === outputCurrency?.chain && inputCurrency?.chain!==chainInfo.id && selectedChainInfo) {
    return (
      <ButtonError onClick={()=>{switchChain(connector,parseInt(selectedChainInfo.chain_id))}}>
        <Text fontSize={20}>switch to {inputCurrency?.chain}</Text>
      </ButtonError>
    );
  }
  if (hasInsufficientFunds) {
    return (
      <ButtonError disabled>
        <Text fontSize={20}>
          {inputCurrency
            ? `Insufficient ${inputCurrency.symbol} Balance`
            : `Insufficient Balance`}
        </Text>
      </ButtonError>
    );
  }

  if (account && chainInfo && parseInt(chainInfo.chain_id) !== chainId) {
    return (
      <ButtonPrimary
        onClick={() => {
          switchChain(connector, parseInt(chainInfo.chain_id), chainInfo);
        }}
      >
        Switch to {`${chainInfo.id}`}
      </ButtonPrimary>
    );
  }

  return (
    <ButtonError
      onClick={handleContinueToReview}
      id="submit-order-button"
      data-testid="submit-order-button"
      disabled={!isValidate() && !loading }
    >
      <Text fontSize={20}>Place Limit Order</Text>
    </ButtonError>
  );
}

export default function LimitFormWrapper(props: LimitFormProps) {
  return (
    <LimitContextProvider>
      <LimitForm {...props} />
    </LimitContextProvider>
  );
}
