import styled from "styled-components/macro";
import { MIN_WIDTH_MEDIA_BREAKPOINT, LARGE_MEDIA_BREAKPOINT } from "constants/width"

export const TeamItem = styled.div`
  width: 70%;
  //  height:220px;
  border-radius: 26px;
  border: 1px solid ${({ theme }) => theme.border2};
  background: ${({ theme }) => theme.bg1};
  color: ${({ theme }) => theme.text1};
  display: flex;
  align-items: center;
  padding: 10px 0;
  position: relative;
  margin-bottom: 32px;
  transition: all 450ms ease;  
  transform-origin: right;
  transform: scale(1);
  :hover {
    transform-origin: right;
    transform: scale(1.03);
    border: 1px solid ${({ theme }) => theme.bg0};

    background: ${({ theme }) => theme.bg0};
  }
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    width: 100%;
  }
`;
export const DropInfoWebLImg = styled.img`
  width: 100%;
  height: 100%;
  objectFit: cover;
  aspect-Ratio:1/1;
`;
export const DropImg = styled.img`
  width: 70px;
  height: 70px;
  transition: all 450ms ease;
  border: 3px solid ${({ theme }) => theme.bg1};
  border-radius: 5px;
  position: relative;
`;
export const DropImg1 = styled.img`
  width: 50px;
  height: 50px;
  transition: all 450ms ease;
  border: 3px solid ${({ theme }) => theme.bg1};
  border-radius: 5px;
  transform-origin: bottom left;
  transform: rotate(-30deg);
  position: absolute;
  left: 0;
  bottom: 0;
`;
export const BottomRight = styled.div`
  width: 500px;
  display: flex;
  align-items: center;

  justify-content: space-between;

  font-size: 20px;
  > div:first-child {
    width: calc(30% - 10px);

    height: 56px;
    border-radius: 6px;
    padding: 0 19px;
    box-sizing: border-box;
  }
  > div:last-child {
    width: calc(70% - 10px);

    height: 56px;
    border-radius: 6px;
    padding: 0 19px;
    box-sizing: border-box;
  }
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    width: 60%;
    > div:first-child {
      width: calc(50% - 10px);

      height: 56px;
      border-radius: 6px;
      padding: 0 19px;
      box-sizing: border-box;
    }
    > div:last-child {
      width: calc(50% - 10px);

      height: 56px;
      border-radius: 6px;
      padding: 0 19px;
      box-sizing: border-box;
    }
  }
`;
export const StageInfo = styled.div`
  padding-left: 20px;
  transition: all 450ms ease;
  > div {
    color: ${({ theme }) => theme.text1};
    font-size: 20px;
  }
  > div:last-child {
    font-size: 14px;
    color: ${({ theme }) => theme.text3};
  }
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    display: none;
    width: 0;
  }
`;
export const BottomLeft = styled.div`
  padding-left: 20px;
  padding-bottom: 25px;
  display: flex;
  align-items: flex-end;
`;
export const LeftImgBlock = styled.div`
  position: relative;

  transition: all 450ms ease;
`;

export const BottomLine = styled.div`
  height: 90px;
  margin: 0 auto 0;
  max-width: 1440px;
  min-width: 800px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  position: relative;

  :hover {
    ${LeftImgBlock} {
      transform-origin: bottom left;
      transform: scale(1.2);
      left: 20px;
      ${DropImg1} {
        transform: rotate(-45deg);
      }
    }
    ${StageInfo} {
      padding-left: 50px;
    }
  }

  @media screen and (min-width: ${LARGE_MEDIA_BREAKPOINT}){
    width: 95vw;
    max-width: 1440px !important;
    min-width: 760px !important;
  }
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    width: 95vw !important;
    max-width: 800px !important;
    min-width: 0px !important;
  }
`;
export const PageBottom = styled.div`
  width: 100%;
  height: 90px;
  background: ${({ theme }) => theme.bg0};
  position: fixed;
  bottom: 0;
  left: 0;
`;
export const ArrowImg = styled.img`
  width: 15px;
  height: 15px;
  transform: rotate(180deg);
  margin-left: 5px;
  transition: transform 450ms ease;
`;
export const SecItem = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    flex-wrap: wrap;
  }
  > div {
    width: calc(50% - 10px);
    @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
      width: 100%;
    }
  }
`;
export const TeamInfo = styled.div`
  max-width: (100% - 232px);
  height: anto;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  > div {
    font-size: 16px;
    font-family: Inter-Regular, Inter;
    font-weight: 400;
    line-height: 19px;
    margin-bottom: 10px;
  }
`;
export const SecImg = styled.img`
  width: calc(50% - 10px);
  border-radius: 20px;
  max-height: 500px;
  object-fit: cover;
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    width: 100%;
    margin-bottom: 20px;
    border-radius: 0px;
  }
`;
export const SecTitle = styled.div`
  width: 80%;
  font-size: 30px;
  text-align: left;
  margin: 0 auto;
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    font-size: 18px;
    width:100%;
  }
`;
export const SecDesc = styled.div`
  width: 80%;
  text-align: left;
  margin: 0 auto;

  word-wrap: break-word;
  white-space: normal;
  word-break: break-all;
`;
