import { isRejectedWithValue } from '@reduxjs/toolkit'
import type {  Middleware } from '@reduxjs/toolkit'
import { ApplicationModal, setOpenModal } from './application/reducer';
import { logout } from './user/reducer';

/**
 * Log a warning and show a toast!
 */
export const unauthenticatedMiddleware: Middleware = ({
    dispatch
   }) => (next) => (action) => {

    if (isRejectedWithValue(action) && action.payload.status === 401) {
     console.log("show accept sign modal")
    dispatch(setOpenModal(ApplicationModal.ACCEPT_SIGN));
    dispatch(logout())
    }
   
    return next(action);
   };
   
