import { useRef, useState } from 'react';
import { useDarkModeManager } from "theme/components/ThemeToggle";
import styled from "styled-components/macro";
import nftDefault from "assets/nft/nft_defult.png";
import nftLoad from "assets/tool/load.png";
const ImageBlock = styled.div`
    width:100%;
    height:100%;
    background: ${({ theme }) => theme.bg2};
    display: flex;
    align-items: center;
    justify-content: center;
`;
export default function Img(props:any) {
  const [darkMode] = useDarkModeManager();

  const {
    style = {},
    src = "",
    alt = "",
    errImg = nftDefault,
    loadingImg = nftLoad
  } = props;
  const imgRef = useRef<HTMLDivElement>(null);
  const [error, setError] = useState(false);
  const [neededSrc, setNeededSrc] = useState(loadingImg || src);
  const [random, setRandom] = useState<number>();

  // 加载失败
  const onError = (obj: any) => {
    setNeededSrc(nftDefault);
  }

  // img加载
  const onLoad = (url: string) => {
    setError(false);
    // 创建一个img标签
    const imgDom = new Image();
    imgDom.src = url;
    imgDom.onload = function () {
      console.log('onload--');
      setNeededSrc(url);
    }
    imgDom.onerror = () => {
      onError({});
    };
  }

  // 加载成功返回渲染
  return (
    <ImageBlock ref={imgRef}>
      <img
        style={style}
        src={neededSrc}
        alt={alt}
        onLoad={() => onLoad(props?.src)}
        onError={() => onError({ url: errImg })}
      />
    </ImageBlock>
  )
}
