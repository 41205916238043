import styled from "@emotion/styled";
import { Route, Routes, useSearchParams } from "react-router-dom";
import AcceptSignModal from "../components/AcceptSignModal";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Wallet from "./Wallet";

import DropList from "./Drop";
import NFTs from "./Nft";
import DropItems from "./Nft/NftItems";
import NftItems from "./Nft/NftItems/nft";

import Transfer from "./Nft/Transfer";
import TransferOne from "./Nft/TransferOne";

// import Tokens from "./Tokens";
import Crypto from "./Crypto";
import Collection from "./My/Collection";
import Settings from "./My/Settings";

import NftImport from "./Nft/NftImport";

import NftDetail from "./Nft/NftDetail";
import TokenDetail from "./Tokens/TokenDetail";

import PloaceOrderModal from "components/PlaceOrderModal";
import RadixToast from "components/Toast";
import { useEffect, useState } from "react";
import { isAddress } from "utils";
import { useClientWidth } from "../state/application/hooks";
import { setClientWidth } from "../state/application/reducer";
import { useAppDispatch } from "../state/hooks";
import { setRef } from "../state/user/reducer";
import LimitOrder from "./Crypto/Listing/LimitOrder";
import { Collected, Deals, Hide, Offers } from "./My/Item";
import EditCollection from "./Nft/EditCollection";
import NftActivity from "./Nft/NftItems/nftActivity";
import { Top, Trending, Watchlist } from "./Nft/rankings";
import { TokensTop, TokensTrending, TokensWatchlist } from "./Tokens/rankings";

import { useGetChainListQuery } from "apis/chain";
import { setSupportChains } from "state/chain/reducer";
import About from "./About";

const AppWrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  width: 100vw;
  overflow-x: hidden;
`;

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
  padding: 0 0 0px 0;
  align-items: center;
  flex: 1;
  z-index: 1;
  // user-select:none;
`;

function App() {
  const stateclientWidth = useClientWidth();
  const dispatch = useAppDispatch();
  const [isScroll, setIsScroll] = useState(false);
  let [searchParams, setSearchParams] = useSearchParams();
  const getWindowSize = () => ({
    clientWidth: Math.max(
      document.documentElement.clientWidth,
      document.body.clientWidth
    ),
  });

  
  const { data } = useGetChainListQuery({})

  useEffect(()=>{
    if(data?.success){
      dispatch(setSupportChains(data.data))
    }
  },[data, dispatch])

  const handleResize = () => {
    let width = getWindowSize().clientWidth;

    dispatch(setClientWidth(width));
  };
  const handleScroll = () => {
    let onPullUpHeight =
      window.pageYOffset ||
      document.body.scrollTop ||
      document.documentElement.scrollTop;
    if (onPullUpHeight > 5 && !isScroll) {
      setIsScroll(true);
    } else if (onPullUpHeight < 5 && isScroll) {
      setIsScroll(false);
    }
  };
  
  useEffect(() => {
    var ref = searchParams.get("ref");
    if (isAddress(ref)) {
      dispatch(setRef({ ref:ref as string }))
    }
  }, [dispatch, searchParams]);



  useEffect(() => {
    dispatch(setClientWidth(getWindowSize().clientWidth));
  }, []);
  useEffect(() => {
    // 监听
    window.addEventListener("resize", handleResize);
    window.addEventListener("scroll", handleScroll);
    // 销毁
    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <>
      <AppWrapper
        style={
          Number(stateclientWidth) < 801
            ? { width: stateclientWidth }
            : { width: stateclientWidth }
        }
      >
        <Header isScroll={isScroll} />

        <BodyWrapper
          style={
            Number(stateclientWidth) < 801
              ? { width: stateclientWidth }
              : { width: stateclientWidth }
          }
        >
          <AcceptSignModal />
          <PloaceOrderModal />
          <RadixToast/>
          <Routes>
            <Route path="/" element={<NFTs />} />
            <Route path="/download" element={<Wallet />} />
            <Route path="/nfts" element={<NFTs />} />
            {/* <Route path="/privacy" element={<Privacy />} />
            <Route path="/tos" element={<Tos />} /> */}
            <Route
              path="/nfts/collection/:chain/:contract_address"
              element={<NftItems />}
            />
            <Route
              path="/nfts/collection/:chain/:contract_address/overview"
              element={<DropItems />}
            />
            <Route
              path="/nfts/collection/:chain/:contract_address/activity"
              element={<NftActivity />}
            />
            <Route path="/drop" element={<DropList />} />
            <Route path="/cryptos" element={<Crypto />} />
            <Route path="/nfts/rankings/trending" element={<Trending />} />
            <Route path="/nfts/rankings/top" element={<Top />} />
            <Route path="/nfts/rankings/Watchlist" element={<Watchlist />} />
            <Route path="/cryptos/:chain/:address/details" element={<TokenDetail />} />
            <Route path="/tokens/rankings/trending" element={<TokensTrending />} />
            <Route path="/tokens/rankings/top" element={<TokensTop />} />
            <Route path="/tokens/rankings/Watchlist" element={<TokensWatchlist />} />

            <Route path="/account/:address/offers" element={<Offers />} />
            <Route path="/account/:address/collected" element={<Collected />} />
            <Route path="/account/:address/hide" element={<Hide />} />
            <Route path="/account/:address/deals" element={<Deals />} />
            <Route path="/account/:address/settings" element={<Settings />} />

            <Route path="/:chain/transfer" element={<Transfer />} />
            <Route
              path="/:chain/:contract_address/:token_id/transfer"
              element={<TransferOne />}
            />

            <Route path="/my-collection" element={<Collection />} />
            {/* <Route path="/my-item" element={<Item />} /> */}
            <Route
              path="/nfts/:chain/:contract_address/edit"
              element={<EditCollection />}
            />

            <Route path="/import-nft" element={<NftImport />} />
            <Route
              path="/nfts/assets/:chain_identifier/:contract_address/:tokenId"
              element={<NftDetail />}
            />

          <Route path="/cryptos/listing/limit-order" element={<LimitOrder />} />
          <Route path="/about" element={<About />} />
          </Routes>
        </BodyWrapper>
        <Footer />
      </AppWrapper>
    </>
  );
}

export default App;
