import styled, { keyframes } from "styled-components/macro";
import {  MIN_WIDTH_MEDIA_BREAKPOINT, LARGE_MEDIA_BREAKPOINT } from "constants/width"

export const refreshRotate = keyframes`
from {
  
  transform: rotate(0deg);
}
to {
  
  transform: rotate(-360deg);
}
`;


export const OptionText = styled.div<{ selected: boolean }>`
  font-size: 14px;
  height: 30px;
  line-height: 30px;
  width: 145px;
  padding: 0 6px;
  box-sizing: border-box;
  margin: 0 auto;
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  align-items: center;
  background: ${({ theme }) => theme.bg0};
  color: ${({ theme }) => theme.text3};
`;
export const OptionIcon = styled.img`
  height: 12px;
  width: 12px;
  margin-right: 4px;
`;
export const LoadIcon = styled.img`
 font-size:14;
 animation: ${refreshRotate} 2s linear 0s infinite normal;
  -webkit-animation: ${refreshRotate} 2s linear 0s infinite normal; /* Safari 与 Chrome */
`;

export const DropDown = styled.div<{ isopen: boolean }>`
  padding: 7px 0;
  width: 153px;
  height: ${({ isopen }) => (isopen ? "auto" : "0")};
  overflow: ${({ isopen }) => (isopen ? "visible" : "hidden")};
  opacity: ${({ isopen }) => (isopen ? "1" : "0")};
  border-radius: 6px;
  font-size: 14px;
  background: ${({ theme }) => theme.bg0};
  border: 1px solid ${({ theme }) => theme.bg2};

  position: absolute;
  right: 10px;
  top: 30px;
  > div:hover {
    background: ${({ theme }) => theme.bg2};
    color: ${({ theme }) => theme.text1};
  }
`;
export const CollectItemBottom = styled.div`
  padding: 20px 10px 20px 70px;
  position: relative;
`;
export const CollectItem = styled.div`
  margin-bottom: 50px;
  cursor: pointer;
  width: calc(23vw - 10px);
  min-width: 189px;
  max-width: 350px;
  position: relative;
  background: ${({ theme }) => theme.bg0};
  border-radius: 6px;
  border: 2px solid ${({ theme }) => theme.border1};
  overflow: hidden;
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    width: calc(50% - 10px);
    min-width: calc(50% - 10px);
  }
`;
export const CollectItemNone = styled.div`
  margin-bottom: 50px;
  width: calc(23vw - 10px);
  height: calc(16vw - 10px);
  min-width: 189px;
  max-width: 350px;
  max-height: 250px;
  background: ${({ theme }) => theme.bg2};
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    width: calc(50% - 10px);
    min-width: calc(50% - 10px);
  }
`;
export const CollectItemTop = styled.div`
  width: calc(23vw - 10px);
  height: 12vw;
  min-width: 189px;
  min-height: 103px;
  max-width: 350px;
  max-height: 206px;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    width: 100%;
    min-width: 100%;
  }
`;
export const CollectTopImg = styled.img`
  width: calc(23vw - 10px);
  height: 12vw;
  min-width: 189px;
  min-height: 103px;
  max-width: 350px;
  max-height: 206px;
  object-fit: cover;
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    width: 100%;
    min-width: 100%;
  }
`;

export const CollectTopImg1 = styled.img`
  position: absolute;
  top: -12px;
  left: 10px;
  width: 44px;
  height: 44px;
  border: 2px solid ${({ theme }) => theme.bg0};
  border-radius: 4px;
  box-sizing: border-box;
`;
export const MainTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 30px;
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    padding-top: 10px;
    flex-wrap: wrap;
  }
`;
export const TopRightGroup = styled.div`
  display: flex;
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    width: 100%;
    justify-content: flex-end;
  }
`;

export const MainItemNone = styled.div`
  width: calc(20% - 10px);
  height: 0;
`;
export const TopLeft = styled.div`
  font-size: 38px;
  font-family: Arial-Bold, Arial;
  font-weight: 700;
  color: ${({ theme }) => theme.text1};
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    font-size: 20px;
    line-height: 50px;
  }
`;
export const TopRight = styled.div`
  width: 145px;
  height: 42px;
  background: #6a5bda;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  font-family: Inter-Medium, Inter;
  font-weight: 500;
  color: #fff;
  line-height: 42px;
  text-align: center;
`;
export const DeployContract = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: ${({ theme }) => theme.modalBG2};
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const DeployContractMain = styled.div`
  width: 500px;
  padding: 25px;
  box-sizing: border-box;

  background: ${({ theme }) => theme.bg1};
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  font-family: Inter-Medium, Inter;
  font-weight: 500;
  color: ${({ theme }) => theme.text1};
  line-height: 35px;
  text-align: center;
`;
export const PopupTitle = styled.div`
  font-size: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;
`;
export const inputStyle = {
  outline: "none",
  width: "100%",
  height: "40px",
  border: "none",
  textAlign: "left",
  background: "transparent",
};
export const PopupClose = styled.img`
  width: 1.5rem;
  height: 1.5rem;
`;
export const FromLable = styled.div`
  font-size: 1.2rem;
  text-align: left;
  margin-top: 10px;
`;
export const FromInput = styled.div`
  width: 100%;
  height: 38px;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.border1};
  overflow: hidden;
`;
export const PopupBtn = styled.div`
  width: 100%;
  height: 38px;
  color: ${({ theme }) => theme.bg0};
  border-radius: 4px;
  background: #6a5bda;
  margin-top: 20px;
`;
