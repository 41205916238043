
import { ZERO_PERCENT } from 'constants/misc';
import { Currency, CurrencyAmount, Percent, Price, Token, TradeType } from "core";

  export enum TradeState {
    LOADING = 'loading',
    INVALID = 'invalid',
    STALE = 'stale',
    NO_ROUTE_FOUND = 'no_route_found',
    VALID = 'valid',
  }

export enum TradeFillType {
    Classic = 'classic', // Uniswap V1, V2, and V3 trades with on-chain routes
    UniswapX = 'uniswap_x', // off-chain trades, no routes
    UniswapXv2 = 'uniswap_x_v2',
    None = 'none', // for preview trades, cant be used for submission
  }

  export enum OffchainOrderType {
    DUTCH_AUCTION = 'Dutch',
    DUTCH_V2_AUCTION = 'Dutch_V2',
    LIMIT_ORDER = 'Limit',
    DUTCH_V1_AND_V2 = 'Dutch_V1_V2', // Only used for GET /orders queries. Returns both Dutch V1 and V2 orders.
  }
  export type ApproveInfo = { needsApprove: true; approveGasEstimateUSD: number } | { needsApprove: false }
  export type WrapInfo = { needsWrap: true; wrapGasEstimateUSD: number } | { needsWrap: false }
  
export type SwapFeeInfo = { recipient: string; percent: Percent; amount: string /* raw amount of output token */ }
export enum RouterPreference {
    X = 'uniswapx',
    API = 'api',
  }

  export class LimitOrderTrade {
    public readonly fillType = TradeFillType.UniswapX
    public readonly offchainOrderType = OffchainOrderType.LIMIT_ORDER
    deadlineBufferSecs: number
    wrapInfo: WrapInfo
    approveInfo: ApproveInfo
    swapFee: SwapFeeInfo | undefined
    amountIn: CurrencyAmount<Token>
    amountOut: CurrencyAmount<Currency>
    tradeType: TradeType
    swapper: string
    deadline: number
  
    // Placeholder values that aren't used in a limit trade
    inputTax = ZERO_PERCENT
    outputTax = ZERO_PERCENT
    slippageTolerance = ZERO_PERCENT
    quoteId = undefined
    requestId = undefined
  
    constructor({
      tradeType,
      amountIn,
      amountOut,
      deadlineBufferSecs,
      swapFee,
      wrapInfo,
      approveInfo,
      swapper,
    }: {
      tradeType: TradeType
      amountIn: CurrencyAmount<Token>
      amountOut: CurrencyAmount<Currency>
      deadlineBufferSecs: number
      swapFee?: SwapFeeInfo
      wrapInfo: WrapInfo
      approveInfo: ApproveInfo
      swapper: string
    }) {
      this.deadlineBufferSecs = deadlineBufferSecs
      this.swapFee = swapFee
      this.wrapInfo = wrapInfo
      this.approveInfo = approveInfo
      this.amountIn = amountIn
      this.amountOut = amountOut
      this.tradeType = tradeType
      this.swapper = swapper
      // deadline is shown in the review modal, but updated on submission
      const nowSecs = Math.floor(Date.now() / 1000)
      this.deadline = (nowSecs + deadlineBufferSecs) * 1000
    }
  
    /**
     * Ensures that LimitOrderTrade conforms to the same interface as DutchOrderTrade
     * By using trade.asDutchOrderTrade(), we can uniformly handle both trade types without needing to verify their specific class type.
     */
  
  
    public get inputAmount(): CurrencyAmount<Token> {
      return this.amountIn
    }
  
    public get outputAmount(): CurrencyAmount<Currency> {
      return this.amountOut
    }
  
    /** For UniswapX, handling token taxes in the output amount is outsourced to quoters */
    public get postTaxOutputAmount() {
      return this.outputAmount
    }
  
    public get totalGasUseEstimateUSD(): number {
      return this.wrapInfo.needsWrap ? this.wrapInfo.wrapGasEstimateUSD : 0
    }
  
    public get classicGasUseEstimateUSD(): number {
      return 0
    }
  
    // no decay for limit orders
    public get startTimeBufferSecs(): number {
      return 0
    }
  
    // no decay auction for limit orders
    public get auctionPeriodSecs(): number {
      return 0
    }
  
    public get executionPrice(): Price<Currency, Currency> {
      return new Price(this.amountIn.currency, this.amountOut.currency, this.amountIn.quotient, this.amountOut.quotient)
    }
  
    public worstExecutionPrice(): Price<Currency, Currency> {
      return this.executionPrice
    }
  
    public maximumAmountIn(): CurrencyAmount<Currency> {
      return this.inputAmount
    }
  
    public minimumAmountOut(): CurrencyAmount<Currency> {
      return this.outputAmount
    }
  }
  export type SubmittableTrade =  LimitOrderTrade
  export type InterfaceTrade = SubmittableTrade 