import { Trans } from '@lingui/macro'
import { useCloseAccountDrawer } from '../AccountDrawer'
import { ButtonEmpty, ButtonPrimary } from '../Button'
import { ActivationStatus, useActivationState } from '../../connection/activate'
import { AlertTriangle } from 'react-feather'
import styled from 'styled-components/macro'
import { ThemedText } from '../../theme'
import { flexColumnNoWrap } from '../../theme/styles'

const Wrapper = styled.div`
  ${flexColumnNoWrap};
  align-items: center;
  justify-content: center;
  width: 100%;
`

const AlertTriangleIcon = styled(AlertTriangle)`
  width: 90px;
  height: 90px;
  stroke-width: 1;
  margin: 36px;

`

// TODO(cartcrom): move this to a top level modal, rather than inline in the drawer
export default function ConnectionErrorView() {
  const { activationState, tryActivation, cancelActivation } = useActivationState()
  const closeDrawer = useCloseAccountDrawer()

  if (activationState.status !== ActivationStatus.ERROR) return null

  const retry = () => tryActivation(activationState.connection, closeDrawer)

  return (
    <Wrapper>
      <AlertTriangleIcon />

        <Trans>Error connecting</Trans>


        <Trans>
          The connection attempt failed. Please click try again and follow the steps to connect in your wallet.
        </Trans>

      <ButtonPrimary $borderRadius="16px" onClick={retry}>
        <Trans>Try Again</Trans>
      </ButtonPrimary>
      <ButtonEmpty width="fit-content" padding="0" marginTop={20}>
        <ThemedText.Link onClick={cancelActivation} marginBottom={12}>
          <Trans>Back to wallet selection</Trans>
        </ThemedText.Link>
      </ButtonEmpty>
    </Wrapper>
  )
}
