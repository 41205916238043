import { Token as ShortToken } from 'types/token';
import { emptySplitApi } from './emptySplitApi'
import { Token } from 'core';

export interface WatchListRequest {
  page: number;
  pageSize: number | null;
  chain: string;
  period: string;
  sortby: string;
  direct: string;
}
export interface WatchListResponse {
  data: []
  // data: Collection[]
  total: number;
  message: null | string;
  success: boolean;
}
export interface AddWatchListResponse {
  msg: null | string;
  success: boolean;
}
export interface IsWatchResponse {
  id: string;
  account: string;
  is_watching: boolean;
}
export interface TrendingListResponse {
  data?: Collection[]
  total: number;
  msg?: null | string;
  success: boolean;
}
export interface WatchListIDListResponse {
  data: string[]
  msg: null | string;
  success: boolean;
}
export interface TrendingListRequest {
  page: number;
  pageSize: number;
  sort: string;
  period: string;
  chain: string;
  direct?: string;
}

export interface CurrencyList {
  data: ShortToken[]
  total: number;
  msg: null | string;
  success: boolean;
}

const tokenApi = emptySplitApi.injectEndpoints({
  overrideExisting: true,

  endpoints: (builder) => ({
    tokenList: builder.query<CurrencyList, Record<string, any>>({
      query: (params) => ({
        url: `/cryptos?current=${params.current}&pageSize=${params.pageSize}&sort=${params.sort}&order=${params.order}&key=${params.key}&chain=${params.chain}&watchlist=${params.watchlist}&account=${params.account?params.account:""}`,
        method: "GET",
      }),
      // transformResponse: (returnValue: CurrencyList, meta) => {
      //   // `meta` here contains our added `requestId` & `timestamp`, as well as
      //   // `request` & `response` from fetchBaseQuery's meta object.
      //   // These properties can be used to transform the response as desired.
      //   if (!meta) return []
      //   return returnValue.data.filter(
      //     (post) => new Token(post.chain,post.address,post.decimals,post.symbol,post.name,post.image_url)
      //   )
      // }
      providesTags: ['Token'],
    }),
    tokenList2: builder.query<{
      data: ShortToken[]
      total: number;
      msg: null | string;
      success: boolean;

    }, Record<string, any>>({
      query: (params) => ({
        url: `/cryptos?current=${params.current}&pageSize=${params.pageSize}&sort=${params.sort}&order=${params.order}&key=${params.key}&chain=${params.chain}`,
        method: "GET",
      }),
    }),
    marketPrice: builder.query<{
      data: MarketPrice
      message: null | string;
      success: boolean;

    }, Record<string, any>>({
      query: (params) => ({
        url: `/cryptos/market-price?base=${params.base}&quote=${params.quote}`,
        method: "GET",
      }),
    }),
    tokenDetail: builder.query<{
      data: TokenInfo
      message: null | string;
      success: boolean;

    }, Record<string, any>>({
      query: (params) => ({
        url: `/cryptos/detail?chain=${params.chain}&address=${params.address}`,
        method: "GET",
      }),
    }),
    tokenBalance: builder.query<{
      data: TokenBalance
      message: null | string;
      success: boolean;

    }, Record<string, any>>({
      query: (params) => ({
        url: `/cryptos/balance?token_id=${params.token_id}&account=${params.account}`,
        method: "GET",
      }),
    }),

    currencyList: builder.query<{
      data: CurrencyInfo[]
      total: number;
      message: null | string;
      success: boolean;
      

    }, Record<string, any>>({
      query: (params) => ({
        url: `/currency/${params.chain}?current=${params.current}&pageSize=${params.pageSize}${params.native?'&native='+params.native:''}`,
        method: "GET",
      }),
    }),

  }),
});

export const {
  useTokenListQuery,
  useTokenList2Query,

  useTokenDetailQuery,
  useCurrencyListQuery,
  useMarketPriceQuery,
  useTokenBalanceQuery,
} = tokenApi;
