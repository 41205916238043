import { useWeb3React } from '@web3-react/core'
import IconButton from '../AccountDrawer/IconButton'
import { AutoColumn } from '../Column'
import { AutoRow } from '../Row'
import { getConnections, networkConnection } from '../../connection'
import { ActivationStatus, useActivationState } from '../../connection/activate'
import { isSupportedChain } from '../../constants/chains'
import { useEffect } from 'react'
import { Settings } from 'react-feather'
import styled from 'styled-components/macro'
import { ThemedText } from '../../theme'
import { flexColumnNoWrap } from '../../theme/styles'


import Option from './Option'
import ConnectionErrorView from './ConnectionErrorView'


const Wrapper = styled.div`
  ${flexColumnNoWrap};
  background-color: ${({ theme }) => theme.backgroundSurface};

  padding: 14px 16px 16px;
  flex: 1;
`

const OptionGrid = styled.div`
  display: grid;
  grid-gap: 2px;
  border-radius: 12px;
  overflow: hidden;

`

const PrivacyPolicyWrapper = styled.div`
  padding: 0 4px;
`

export default function WalletModal({ openSettings }: { openSettings: () => void }) {
  const { connector, chainId } = useWeb3React()

  const connections = getConnections()

  const { activationState } = useActivationState()

  // Keep the network connector in sync with any active user connector to prevent chain-switching on wallet disconnection.
  useEffect(() => {
    if (chainId && isSupportedChain(chainId) && connector !== networkConnection.connector) {
      networkConnection.connector.activate(chainId)
    }
  }, [chainId, connector])

  return (
    <Wrapper data-testid="wallet-modal">
      <AutoRow justify="space-between" width="100%" marginBottom="16px">
        <ThemedText.SubHeader>Connect a wallet</ThemedText.SubHeader>
        <IconButton Icon={Settings} onClick={openSettings} data-testid="wallet-settings" />
      </AutoRow>
      {activationState.status === ActivationStatus.ERROR ? (
        <ConnectionErrorView />
      ) : (
        <AutoColumn gap="16px">
          <OptionGrid data-testid="option-grid">
            {connections
              .filter((connection) => connection.shouldDisplay())
              .map((connection) => (
                <Option key={connection.getName()} connection={connection} />
              ))}
          </OptionGrid>
          <PrivacyPolicyWrapper>

          </PrivacyPolicyWrapper>
        </AutoColumn>
      )}
    </Wrapper>
  )
}
