import invariant from 'tiny-invariant'
import { Currency } from './currency'
import { NativeCurrency } from './nativeCurrency'
import { Token } from './token'
import { WETH9 } from './weth9'

/**
 * Ether is the main usage of a 'native' currency, i.e. for Ethereum mainnet and all testnets
 */
export class Ether extends NativeCurrency {
  protected constructor(chain: string) {
    super(chain, 18, 'ETH', 'Ether')
  }

  public get wrapped(): Token {
    const weth9 = WETH9[this.chain]
    invariant(!!weth9, 'WRAPPED')
    return weth9
  }

  private static _etherCache: { [chain: string]: Ether } = {}

  public static onChain(chain: string): Ether {
    return this._etherCache[chain] ?? (this._etherCache[chain] = new Ether(chain))
  }

  public equals(other: Currency): boolean {
    return other.isNative && other.chain === this.chain
  }
}
