
import { useState, useEffect } from "react";

import UploadDropzone from "components/Form/upload"

import {ButtonPurple,ButtonTransparent} from "components/Button"
import { Input, Textarea } from "@rebass/forms";
import { useDarkModeManager } from "theme/components/ThemeToggle";

import { useToastOpen } from "state/stats/hooks";
import RadixPopconfirm from "components/Popconfirm";
import {
  CardTitle,  ItemValueArea1, areaStyle1, 
    TeamChange, TeamItem, TeamImg, TeamInfo, TeamName,DropInfoWeb,StyledModal,
} from "../../components_";
import {
    ItemValue, inputStyle, ItemUpImg,ItemLabelFlex, CloseImg, CardItem, AddImgText1, ItemLabel,UploadBox
} from "../../../../components_";
import closeIcon from "assets/tool/close_icon.png";
import closeIconN from "assets/tool/close_icon_n.png";

export interface DropTeamForms {
  teamList?: { bio: string; image_url: string; name: string; title: string; }[];
}

interface DropTeamFormProps {
    visible: boolean;
    value?: DropTeamForms;
    onChange: (value: DropTeamForms) => void;
}

export default function DropTopForm({
    visible,
    value,
    onChange,
}: DropTeamFormProps) {
    const [darkMode] = useDarkModeManager();
    const [, , , setToastOpen] = useToastOpen();

    const [popuptype, setPopuptype] = useState<string>('imglist');
    const [showPopup, setShowPopup] = useState(false);

    const [teamBio, setTeamBio] = useState<string>('');
    const [teamName, setTeamName] = useState<string>('');
    const [teamTitle, setTeamTitle] = useState<string>('');
    const [teamUrl, setTeamUrl] = useState<string>('');
    const [teamIndex, setTeamIndex] = useState<number>(9999);


    const [teamList, setTeamList] = useState<{ bio: string; image_url: string; name: string; title: string; }[]>([]);
    useEffect(() => {
        if (value && value.teamList && value.teamList !== teamList) {
          setTeamList(value.teamList)
        }
    }, [value]);
    const saveTeam = () => {
      if (teamBio && teamBio !== '' && teamUrl && teamUrl !== '' && teamName && teamName !== '' && teamTitle && teamTitle !== '') {
        let list = [...teamList]
      if (teamIndex == 9999) {
        list.push({ bio: teamBio, image_url: teamUrl, name: teamName, title: teamTitle })
        setTeamList(list)
        onChange({teamList:list})
        setShowPopup(false)
      } else {
        list[teamIndex] = { bio: teamBio, image_url: teamUrl, name: teamName, title: teamTitle }
        setTeamList(list)
        onChange({teamList:list})
        setShowPopup(false)
      }
      } else {
        setToastOpen({ open: true, type: 'error', label: "请完善roadmap信息" })

        return
      }
  
     
    }
    const editTeam = (index: number) => {
      setTeamIndex(index)
      setTeamBio(teamList[index].bio)
      setTeamUrl(teamList[index].image_url)
      setTeamName(teamList[index].name)
      setTeamTitle(teamList[index].title)
      setPopuptype('team')
      setShowPopup(true)
    }
    const addTeam = () => {
      setTeamIndex(9999)
      setTeamBio('')
      setTeamUrl('')
      setTeamName('')
      setTeamTitle('')
      setPopuptype('team')
      setShowPopup(true)
    }
    const delTeam = () => {
      let list = [...teamList]
      list.splice(teamIndex, 1)
      setTeamList(list)
      onChange({teamList:list})
      setShowPopup(false)
    }

   
    return <>
        <DropInfoWeb isShow={visible} style={{ flexWrap: 'wrap' }}>

        {teamList?.map((i: { bio: string; image_url: string; name: string; title: string; }, index: number) => (

          <TeamItem key={index}>
            <TeamImg src={i.image_url} />
            <TeamInfo>
              <TeamName>{i.name}</TeamName>
              <div>{i.title}</div>
              <div>{i.bio}</div>
            </TeamInfo>
            <TeamChange onClick={() => { editTeam(index) }}>Edit</TeamChange>
          </TeamItem>

          ))}
            <TeamItem>
                <TeamImg />
                <TeamInfo>
                    <TeamName>Name</TeamName>
                    <div>date and time</div>
                    <div>Description</div>
                </TeamInfo>
                <TeamChange onClick={() => { addTeam() }}>+ Add</TeamChange>
            </TeamItem>
        </DropInfoWeb>
        <StyledModal
        isOpen={showPopup && popuptype == "team"}
        onBackgroundClick={() => setShowPopup(!showPopup)}
        onEscapeKeydown={() => setShowPopup(!showPopup)}
      >  <ItemLabelFlex>
      <CardTitle>{teamIndex == 9999 ? 'Add ' : 'Edit '} a team member</CardTitle>
      <CloseImg
        src={darkMode ? closeIconN : closeIcon}
        onClick={() => setShowPopup(!showPopup)}
      />
    </ItemLabelFlex>

    <CardItem>
      <ItemLabel>Image *</ItemLabel>
      <ItemUpImg style={{ height: '181px' }}>
      <UploadBox>
                <UploadDropzone url={teamUrl} urlChange={(url) => setTeamUrl(url)}></UploadDropzone>
            </UploadBox>
        <AddImgText1>Drag and drop or click to upload 1:1 aspect ratio. Minimum size of 240px x 240px recommended.</AddImgText1>
      </ItemUpImg>
    </CardItem>
    <CardItem>
      <ItemLabel>Name *</ItemLabel>
      <ItemValue>
        <Input
          type="text"
          placeholder="Enter a team member name"
          value={teamName}
          sx={inputStyle}
          onChange={(e) => {
            setTeamName(e.target.value);
          }}
        />
      </ItemValue>
    </CardItem>
    <CardItem>
      <ItemLabel>Title</ItemLabel>
      <ItemValue>
        <Input
          type="text"
          placeholder="Enter a title"
          value={teamTitle}
          sx={inputStyle}
          onChange={(e) => {
            setTeamTitle(e.target.value);
          }}
        />
      </ItemValue>
    </CardItem>
    <CardItem>
      <ItemLabel>Bio</ItemLabel>
      <ItemValueArea1>
        <Textarea
          id="comment"
          name="comment"
          placeholder="Enter their bio"
          value={teamBio}
          sx={areaStyle1}
          onChange={(e) => {
            setTeamBio(e.target.value);
          }}
        />
      </ItemValueArea1>
      {teamIndex == 9999 ? null : (
       <RadixPopconfirm
       val={'Are you sure to delete this?'}
       labelNode={ <ButtonTransparent padding='10px' $borderRadius='4px' margin="0 0 10px 0" width='100%'>Del</ButtonTransparent>}
       onConfirm={() => {
        delTeam();
       }}
     ></RadixPopconfirm>
   )}
      <ButtonPurple padding='10px' $borderRadius='4px' width='100%' onClick={()=>saveTeam()}>save</ButtonPurple>

    </CardItem></StyledModal>
       
    </>;
}
