// index.jsx
import * as React from 'react';
import * as Popover from '@radix-ui/react-popover';
import styled from "styled-components";

import { ButtonPurple } from "components/Button";

import '../Popover/styles.css';
const Content = styled(Popover.Content) <{ bg?: string }>`
// background: ${({ theme }) =>  theme.bg4};
background: ${({ theme }) =>  theme.bg0};
border:1px solid ${({ theme }) => theme.border1};
color: ${({ theme }) => theme.text1};
z-index: 999;
padding:5px 5px;
border-radius:4px;
font-size:14px;
`;
const Arrow = styled(Popover.Arrow)`
fill: ${({ theme }) => theme.border1};
`;
const Trigger = styled(Popover.Trigger)`
width:100%;
display: inline-flex;
align-items: center;
justify-content: center;
`;
const Btns = styled.div`
display: flex;
align-items: center;
padding-top:5px;
`;
const WarningIcon = styled.div`
width:15px;
height:15px;
line-height:15px;
border-radius:50%;
background: ${({ theme }) => theme.warning};
color: ${({ theme }) => theme.bg0};
margin-right:5px;
text-align:center;
`;

interface RadixPopconfirmProps {
  labelNode?: React.ReactNode;
  val: string;
  onConfirm: () => void;

}
const RadixPopconfirm = ({  val, labelNode,onConfirm }: RadixPopconfirmProps) => (
  <Popover.Root>
    <Trigger>{labelNode}</Trigger>
    <Content sideOffset={5}>
      <Btns><WarningIcon>!</WarningIcon><div>{val}</div></Btns>
      <Btns><div style={{flex:'1'}}></div> <ButtonPurple
        padding="2px 5px"
        $borderRadius="4px"
        margin="0 0 0 0"
        width="auto"
        onClick={()=>onConfirm()}
      >
        Yes
      </ButtonPurple>
      </Btns>

      <Arrow />
    </Content>
  </Popover.Root>

);

export default RadixPopconfirm;