import arrowU from "assets/tool/arrow_u.png";
import arrowUn from "assets/tool/arrow_u_n.png";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { useDarkModeManager } from "theme/components/ThemeToggle";

import { useTokenListQuery } from "apis/token";
import { Token } from "core";


const OptionText = styled.div<{ selected: boolean }>`

  font-size: 14px;
  height: 36px;
  line-height: 36px;
  width: 100%;
  padding: 0 6px;
  box-sizing: border-box;
  margin: 0 auto;
  border-radius: 6px;
  cursor: pointer;
  background: ${({ selected, theme }) => (selected ? theme.bg2 : theme.bg0)};
  color: ${({ selected, theme }) => (selected ? theme.text1 : theme.text3)};
  display: flex;
  align-items: center;
  :hover{
    background: ${({ theme }) =>  theme.bg2};
    color: ${({ theme }) =>  theme.text1};
  }
`;

const DropSelect = styled.div<{ transparent: boolean }>`
  padding: 0 7px;
  height: 39px;
  width: 100%;
  border-radius: 6px;
  font-size: 14px;
  background: ${({ theme,transparent }) => transparent?'transparent':theme.bg2};
  color: ${({ theme }) => theme.text1};
  position: relative;
  z-index: 1000;
`;
const DropDown = styled.div<{ isopen: boolean }>`
  padding: 7px 7px;
  width: 100%;
  text-align:left;
  height: ${({ isopen }) => (isopen ? "auto" : "0")};
  opacity: ${({ isopen }) => (isopen ? "1" : "0")};
  border-radius: 6px;
  font-size: 14px;
  background: ${({ theme }) => theme.bg0};
  border: 1px solid ${({ theme }) => theme.bg2};
  position: absolute;
  left: 0;
  top: 39px;
  max-height:250px;
  overflow-x:hidden;
  overflow-y:${({ isopen }) => (isopen ? "scroll" : "hidden")};

`;
const SelectLine = styled.div`
  display: flex;
  width: 100%;
  height: 39px;
  padding: 0 6px;
  margin: 0 auto;
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  > div {
    white-space: nowrap;
  }
`;
const SelectIcon = styled.img`
  height: 14px;
  width: 14px;
  margin-bottom: 5px;
`;
interface TokensSelectProps {
  size: string;
  value: string;
  isTransparent?:boolean;
  onChange: (e: Token) => void;
}


export default function TokensSelect({
  size,
  value,
  isTransparent,
  onChange,
}: TokensSelectProps) {
  const [darkMode] = useDarkModeManager();
  const [openDown, setOpenDown] = useState(false);
  const [currentLabel, setCurrentLabel] = useState<string>("");
  
  const [currencyList, setCurrencyList] = useState<Token[]>([]);
  const { data } = useTokenListQuery({
    chain: value as string,
    current: 1,
    pageSize: 10
  })
  useEffect(() => {
    if (data && data.success) {
 
      
      const result:Token[]=[]
      data.data.forEach(e=>result.push(new Token(e.chain,e.address,e.decimals,e.symbol,e.name,e.image_url)) )
      setCurrencyList(result)
      setCurrentLabel(data.data[0]?.symbol as string);
    }
  }, [data]);
  // const optionArr = [
  //   "All Tokenss",
  //   "Arbitrum",
  //   "BNB Tokenss",
  //   "Base",
  //   "Polygon",
  //   "Ethereum",
  //   "Goerli",
  //   "Polygon",
  // ];
  // const TokensValues = ["", "arbitrum", "bsc", "base", "matic", "ethereum","goerli","polygon"];

  // useEffect(() => {
  //   TokensValues.forEach((e, index) => {
  //     if (e === value) {
  //       setCurrentLabel(optionArr[index]);
  //     }
  //   });
  // }, [value]);
  return (
    <>
      <DropSelect transparent={isTransparent||false} style={size ==='filed'?{width:'100%',background:'transparent'}:size === "big" ? { height: "50px" } : {}}>
        <SelectLine
          onClick={() => setOpenDown(!openDown)}
          style={size ==='filed'?{width:'100%'}:size === "big" ? { height: "50px", lineHeight: "50px" } : {}}
        >
          {/* <div>{currentLabel}</div> */}
          <div></div>
          <SelectIcon src={darkMode ? arrowUn : arrowU} />
        </SelectLine>
        <DropDown
          isopen={openDown}
          onClick={() => {
            setOpenDown(!openDown);
          }}
          style={size ==='filed'?{width:'100%'}:size === "big" ? { top: "50px" } : {}}
        >
          {currencyList.map((e, index) => (
            <OptionText
              selected={false}
              onClick={() => {
                onChange(e);
              }}
              style={size ==='filed'?{width:'calc(100% - 10px)'}: {}}
            >
              <img src={e.image_url} style={{marginRight:'10px',height:'25px',width:'25px'}}/>
              <div> {e.symbol}</div>
             
            </OptionText>
          ))}
        </DropDown>
      </DropSelect>
    </>
  );
}
