
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";


import arrowL from "assets/tool/arrow_l.png";
import arrowR from "assets/tool/arrow_r.png";
import arrowLn from "assets/tool/arrow_ln.png";
import arrowRn from "assets/tool/arrow_rn.png";

import nftDefault from "assets/nft/nft_defult.png";

import { useDarkModeManager } from "theme/components/ThemeToggle";

import { PosaLImg,PosaSImg,SpotItem,SpotItemImg,SpotCenter } from "../../components_";
import { SpotList, } from "pages/Nft/components_";



interface TransferFormProps {
  list:Assets[]
}
export default function Home({
  list
}: TransferFormProps) {
  const [darkMode] = useDarkModeManager();
  const [posts, setPosts] = useState<Assets[]|null>(null);
    const [index, setIndex] = useState(0);

  let navigate = useNavigate();

 
  const changeIndex = (e: string) => {
    if (e === "next") {
      if (posts && posts.length - 3 > index) {
          setIndex(index + 1)
      }
    } else {
      if (index > 0) {
        setIndex(index - 1)
      }
    }
  }
  useEffect(() => {
    if(list&&list!==posts){
      // console.log('list',list)
      // let tempPost:Assets[]=[]
      // for (let i in list) {
      //   tempPost.push(list[i])
      // }
      // setPosts(tempPost)
      setPosts(list)
      
    }
    
  }, [list]);
 
  return (
    <>
   
       <SpotList>
            <PosaLImg
              src={darkMode ? arrowLn : arrowL}
              alt=""
              className="posa-l"
              onClick={() => {
                changeIndex("before");
              }}
            />
            <PosaSImg
              src={darkMode ? arrowRn : arrowR}
              alt=""
              className="posa-r"
              onClick={() => {
                changeIndex("next");
              }}
            />
             <SpotCenter style={posts?.length&&posts?.length>3?{}:{justifyContent:'center'}}>


            {posts?.map((i: any) => (
              <SpotItem style={{ left: index * (-30) + '%' }}
                key={i.id}
                onClick={() => navigate(`/nfts/collection/${i.chain}/${i.contract_address}`)}
              >
                <SpotItemImg src={i.image_url||nftDefault} alt="" style={{minHeight:"100px"}}/>
                {/* <SpotItemText> {i.name}</SpotItemText> */}
              </SpotItem>
            ))} </SpotCenter>
          </SpotList>
    </>
  );
};
// export default App;
