
import { emptySplitApi } from './emptySplitApi'
export interface WatchListRequest {
  page: number;
  pageSize: number | null;
  chain: string;
  account: string;
  period: string;
  sortby: string;
  direct: string;
  token?:string | null
  next?:number
}
export interface WatchListResponse {
  data: []
  // data: Collection[]
  total: number;
  message: null | string;
  success: boolean;
}
export interface AddWatchListResponse {
  msg: null | string;
  success: boolean;
}
export interface IsWatchResponse {
  id:string;
    account:string;
    is_watching:boolean;
}
export interface TrendingListResponse {
  data?: Collection[]
  total: number;
  msg?: null | string;
  success: boolean;
}
export interface TopListResponse {
  data?: TopCollection[]
  total: number;
  message?: null | string;
  success: boolean;
}
export interface TopTokenListResponse {
  data?: TopToken[]
  total: number;
  message?: null | string;
  success: boolean;
}
export interface TopMintResponse {
  data?: TopMinting[]
  total: number;
  msg?: null | string;
  success: boolean;
}
export interface WatchListIDListResponse {
  data:string[]
  msg: null | string;
  success: boolean;
}
export interface TrendingListRequest {
  page?: number;
  pageSize: number;
  sort: string;
  period: string;
  chain: string;
  direct?: string;
  next?: number;
}

const rankingApi = emptySplitApi.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    trendingList: builder.query<TopListResponse, TrendingListRequest>({
      query: (params) => ({
        url: `/ranking/trending?page=${params.page}&pageSize=${params.pageSize}&sort=${params.sort}&period=${params.period}&chain=${params.chain}&direct=${params.direct}`,
        method: "GET",
      }),
    }),
    topList: builder.query<TopListResponse, TrendingListRequest>({
      query: (params) => ({
        url: `/ranking/top?page=${params.page}&pageSize=${params.pageSize}&sort=${params.sort}&period=${params.period}&chain=${params.chain}&direct=${params.direct}`,
        method: "GET",
      }),
    }),
   
    tokensTopList: builder.query<TopTokenListResponse, TrendingListRequest>({
      query: (params) => ({
        url: `/cryptos/rankings/top?current=${params.page}&pageSize=${params.pageSize}&sort=${params.sort}&period=${params.period}&chain=${params.chain}&direct=${params.direct}`,
        method: "GET",
      }),
    }),
    tokensTrendList: builder.query<TopTokenListResponse, TrendingListRequest>({
      query: (params) => ({
        url: `/cryptos/rankings/trending?current=${params.page}&pageSize=${params.pageSize}&sort=${params.sort}&period=${params.period}&chain=${params.chain}&direct=${params.direct}`,
        method: "GET",
      }),
    }),
    topMinting: builder.query<TopMintResponse, TrendingListRequest>({
      query: (params) => ({
        url: `/ranking/top-minting?pageSize=${params.pageSize}&period=${params.period}&chain=${params.chain}`,
        method: "GET",
      }),
    }),
    isWatching: builder.query<IsWatchResponse, Record<string, any>>({ 
      query: (params) => ({
        url: `/watchlist/${params.chain}/${params.contract_address}/is-watching?owner=${params.account}`,
        method: "GET",
      }),
    }),

    addWatchList: builder.mutation<AddWatchListResponse, Record<string, any>>({
      query: (params) => ({
        url: `/watchlist`,
        method: "POST",
        body: { ...params }
      }),
      invalidatesTags: ['Token'],
    }),
    watchListIds: builder.query<WatchListIDListResponse, Record<string, any>>({
      query: (params) => ({
        url: `/watchlist-ids?owner=${params.account}&type=${params.type}`,
        method: "GET",
      }),
    }),
    delWatchOne: builder.mutation<AddWatchListResponse, Record<string, any>>({
      query: (params) => ({
        url: `/watchlist/${params.chain}/${params.address}`,
        method: "DELETE"
      }),
      invalidatesTags: ['Token'],
    }),
    // getWatchList: builder.query<WatchListResponse, WatchListRequest>({
    //   query: (params) => ({
    //     url: `/watchlist/${params.account}?chain=${params.chain}&period=${params.period}&sortBy=${params.sortby}&direct=${params.direct}&page=${params.page}&pageSize=${params.pageSize}`,
    //     method: "GET",
    //   }),
    // }),
    getWatchList: builder.query<WatchListResponse, WatchListRequest>({
      query: (params) => ({
        url: `/account/${params.account}/watchlist?chain=${params.chain}&period=${params.period}&sortBy=${params.sortby}&direct=${params.direct}&page=${params.page}&pageSize=${params.pageSize}`,
        method: "GET",
      }),
    }),
    getCryptoWatchList: builder.query<WatchListResponse, WatchListRequest>({
      query: (params) => ({
        url: `/cryptos/watchlist/${params.account}?chain=${params.chain}&period=${params.period}&sort=${params.sortby}&order=${params.direct}&page=${params.page}&pageSize=${params.pageSize}`,
        method: "GET",
      }),
    }),

  }),
});

export const {
  useTrendingListQuery,
  useDelWatchOneMutation,
  useIsWatchingQuery,
  useWatchListIdsQuery,
  useGetWatchListQuery,
  useAddWatchListMutation,
  useTopListQuery,
  useTopMintingQuery,
  useTokensTopListQuery,
  useTokensTrendListQuery,
  useGetCryptoWatchListQuery
} = rankingApi;
