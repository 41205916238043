
import { emptySplitApi } from './emptySplitApi'
export interface SearchRequest {
  page: number;
  pageSize: number | null;
  chain: string;
  period: string;
  sortby: string;
  direct: string;
}
export interface SearchResponse {
  data: SearchResult[]
  total: number;
  message: null | string;
  success: boolean;
}
const searchApi = emptySplitApi.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    search: builder.query<SearchResponse, Record<string, any>>({
      query: (params) => ({
        url: `/search?key=${params.key}`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useSearchQuery,

} = searchApi;
