import { useGetChainListQuery } from "apis/chain";
import {
  ActivityIcon,
  ActivityIconRotate,
  BottomInfoDesc,
  BottomInfoLine,
  BottomInfoLineTel, BottomInfoTitle,
  CastItem,
  CastItemBottom,
  CastItemTelLoad,
  CastItemTop, CastTopImg,
  CategItemTel,
  CategItemTelLoad,
  CategListTel,
  CollectInfoLogo,
  CollectItemBottom,
  CollectItemBottomInfo,
  CollectItemBottomTel,
  CollectItemTopTel,
  CollectList,
  CollectTopImg1,
  CollectTopImgTel,
  ItemBottomName, ItemBottomNameText,
  TitleItem
} from "pages/Nft/components_";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { formatWithArray } from "utils";

import { useDarkModeManager } from "theme/components/ThemeToggle";

import { useClientWidth } from "state/application/hooks";

import nftDefault from "assets/nft/nft_defult.png";

import { useTopMintingQuery } from "apis/ranking";
import refresh from "assets/tool/refresh.png";
import refreshN from "assets/tool/refresh_n.png";

import "../../../index.scss";
const App: React.FC = () => {
  const clientWidth = useClientWidth();
  const [darkMode] = useDarkModeManager();
  const [posts, setPosts] = useState<TopMinting[] | null>(null);
  const [isRefresh, setIsRefresh] = useState<number>(0);


  const [list4None,] = useState([1, 2, 3, 4, 5]);
  const [listTime] = useState(
    [{ value: 'one_hour', label: "1H" },
    { value: 'six_hour', label: "6H" },
    { value: 'twentyfour_hour', label: "24H" },
    { value: 'seven_day', label: "7D" },
    { value: 'thirty_day', label: "24D" },]);
  let navigate = useNavigate();

  const { data, status } = useTopMintingQuery({
    pageSize: 4,
    period: "twentyfour_hour",
    next: isRefresh,
    page: 1,
    sort: '',
    chain: ''
  });

  const [idList, setIdList] = useState<string[]>([]);

  const { data:chaindata } = useGetChainListQuery({});
  useEffect(() => {
    if (chaindata) {
      if (chaindata.data) {
        let idList: string[] = [];
        chaindata.data.forEach((el) => {
          idList.push(el.id || "");
        });
        setIdList(idList);
      }
    }
  }, [chaindata]);
  useEffect(() => {
    if (status === 'fulfilled' && data?.data&& chaindata?.data) {
      let list:TopMinting[]=[]
      chaindata.data.forEach((el) => {
        // idList.push(el.id || "");
        data.data?.forEach((item)=>{
          if(item.chain===el.id){
            list.push({...item,...{logo:el.logo}})
            // item.logo=el.logo
          }
        })
      });
      setPosts(list);

      // setIsRefresh(false)
    }
  }, [data, status,chaindata]);



  return (
    <>
      {Number(clientWidth) < 801 ? (<>  <TitleItem><div>Top minting</div> {
        status !== "fulfilled" ? (
          <ActivityIconRotate src={darkMode ? refreshN : refresh} />

        ) : (<ActivityIcon onClick={() => setIsRefresh(isRefresh + 1)} src={darkMode ? refreshN : refresh} />
        )
      }</TitleItem>
        {status !== "fulfilled" ? (
          <CategListTel className="scrollbar" style={{ width: Number(clientWidth) - 20 }}>
            {list4None.map(() => (
              <CategItemTelLoad></CategItemTelLoad>
            ))}
          </CategListTel>
        ) : (
          <CategListTel className="scrollbar" style={{ width: Number(clientWidth) - 20 }}>
            {posts?.map((i: TopMinting) => (
              <CategItemTel key={i.id} onClick={() => navigate(`/nfts/collection/${i.chain}/${i.contract_address}`)}>
                <CollectItemTopTel>
                  <CollectTopImgTel src={i.image_url || nftDefault} alt="" />
                  {i.logo ?  (
                          <CollectInfoLogo style={{top:'5px',right:'5px'}}
                            src={i.logo}
                          />
                        ):null} 
                </CollectItemTopTel>
                <CollectItemBottomTel>
                  <ItemBottomName>
                    <ItemBottomNameText>{i.name}</ItemBottomNameText>
                    {/* <ItemBottomImg src={attestationIcon} alt="" /> */}
                  </ItemBottomName>
                  <CollectItemBottomInfo>
                  <BottomInfoLineTel style={{ width: '20%' }}>
                      <BottomInfoDesc>

                        <div>
                          {listTime.map((item) => (
                            item.value === i.peroid ? item.label : null
                          ))}
                        </div>
                      </BottomInfoDesc>
                    </BottomInfoLineTel>
                      <BottomInfoLineTel style={{ width: '40%' }}>
                        <BottomInfoDesc>mints{' '}
                          {i.mints}</BottomInfoDesc>

                      </BottomInfoLineTel>
                      <BottomInfoLineTel style={{ width: '40%' }}>
                        <BottomInfoDesc>owners{' '}
                          {i.owners}</BottomInfoDesc>
                      </BottomInfoLineTel>

                    {/* <BottomInfoLineTel>
                      <BottomInfoTitle>Floor price</BottomInfoTitle>
                      <BottomInfoDesc>
                        <div>
                          {formatWithArray(i.avg_native_price)}                         </div>
                      </BottomInfoDesc>
                    </BottomInfoLineTel>
                    <BottomInfoLineTel>
                      <BottomInfoTitle>Owners</BottomInfoTitle>
                      <BottomInfoDesc>{formatWithArray(i.owners)} </BottomInfoDesc>
                    </BottomInfoLineTel> */}
                  </CollectItemBottomInfo>
                </CollectItemBottomTel>
              </CategItemTel>
            ))}
          </CategListTel>
        )}</>) : (<> <TitleItem><div>Top minting</div>
          {
            status !== "fulfilled" ? (
              <ActivityIconRotate src={darkMode ? refreshN : refresh} />

            ) : (<ActivityIcon onClick={() => setIsRefresh(isRefresh + 1)} src={darkMode ? refreshN : refresh} />
            )
          }
        </TitleItem>
          {status !== "fulfilled" ? (
            <CollectList>
              {list4None.map(() => (
                <CastItemTelLoad></CastItemTelLoad>
              ))}
            </CollectList>
          ) : (
            <CollectList>
              {posts?.map((i: TopMinting) => (
                <CastItem
                  key={i.id}
                  onClick={() => navigate(`/nfts/collection/${i.chain}/${i.contract_address}`)}
                >
                  <CastItemTop>
                    <CastTopImg src={i.image_url || nftDefault} alt="" />
                    {i.logo ?  (
                          <CollectInfoLogo style={{top:'5px',right:'5px'}}
                            src={i.logo}
                          />
                        ):null} 
                  </CastItemTop>
                  <CastItemBottom>

                    <ItemBottomName>
                      <ItemBottomNameText>{i.name}</ItemBottomNameText>
                      {/* <ItemBottomImg src={attestationIcon} alt="" /> */}
                    </ItemBottomName>
                    <CollectItemBottomInfo>  <BottomInfoLine style={{ width: '20%' }}>
                      <BottomInfoDesc>

                        <div>
                          {listTime.map((item) => (
                            item.value === i.peroid ? item.label : null
                          ))}
                        </div>
                      </BottomInfoDesc>
                    </BottomInfoLine>
                      <BottomInfoLine style={{ width: '40%' }}>
                        <BottomInfoDesc>mints{' '}
                          {i.mints}</BottomInfoDesc>

                      </BottomInfoLine>
                      <BottomInfoLine style={{ width: '40%' }}>
                        <BottomInfoDesc>owners{' '}
                          {i.owners}</BottomInfoDesc>
                      </BottomInfoLine>
                    </CollectItemBottomInfo>
                  </CastItemBottom>
                  <CollectItemBottom style={{ opacity: '0' }}>
                    <CollectTopImg1 src={i.image_url || nftDefault} alt="" style={{ borderRadius: '50%' }} />

                    <ItemBottomName>
                      <ItemBottomNameText>{i.name}</ItemBottomNameText>
                    </ItemBottomName>
                    <CollectItemBottomInfo>

                      <BottomInfoLine style={{ width: '50%' }}>
                        <BottomInfoTitle>Floor price</BottomInfoTitle>
                        <BottomInfoDesc>
                          <div>{formatWithArray(i.avg_native_price)}
                            {/* {i.stats.floor_price.toFixed(4)} */}
                          </div>
                        </BottomInfoDesc>
                      </BottomInfoLine>
                      <BottomInfoLine style={{ width: '50%' }}>
                        <BottomInfoTitle>Owners</BottomInfoTitle>
                        <BottomInfoDesc>{formatWithArray(i.owners)} </BottomInfoDesc>
                      </BottomInfoLine>
                    </CollectItemBottomInfo>
                  </CollectItemBottom>

                </CastItem>
              ))}
            </CollectList>
          )}
        </>)}

    </>
  );
};
export default App;
