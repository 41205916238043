import { useNavigate } from "react-router-dom";
import { Select, Input } from "@rebass/forms";
import { StyledModal } from "components/Modal/styled_modal";
import { useWeb3React } from "@web3-react/core";
import Paging from "components/Paging";
import RadixPopover from "components/Popover"

import { ApplicationModal, setOpenModal } from 'state/application/reducer';
import { useToken } from 'state/user/hooks';
import { useState, useEffect, SyntheticEvent } from "react";
import ChainSelect from "components/ChainSelect";
import ListSelect from "components/ListSelect";
import {useToastOpen } from 'state/stats/hooks'
import NoData from "components/NoData"
import AccountTop from "../components/AccountTop";
import { ButtonPurple, ButtonTransparent } from "components/Button"
import { useUpdateUserMutation } from 'apis/account';
import { useAccount, useUpdateAccount } from "state/user/hooks";
import { useClientWidth } from "state/application/hooks";
import "react-datepicker/dist/react-datepicker.css";
import { useAppDispatch } from 'state/hooks'
import { updateAccount } from "state/user/reducer"
import "pages/index.scss"
import {
  MainItemNone, WebMainB, MainItem, MainItemImg, MainItemInfo, ItemTop, InfoTop, InfoBottom, InfoBottomBuy,
  FilterSearch, MainItemLoad, CollectRoles, FooterCenter, InfoLine, SearchL,
  SearchM,
  SearchIcon,
  SearchR,
  SearchInput,
  CollectionsSearch,
  MainBBl,
  LeftSearchTitle, SearchInput1, SearchIcon1, SearchClose, ChooseItem, BtnOne2to,

  BtnRow, SearchSelect, WebFooter,
  BtnOne1,
  PropItemIcon,
} from "../components_";

import {
  MainBB,
  MainBottom,
  MainBBR,
} from "pages/Nft/NftItems/components_";
import closeIcon from "assets/tool/close_icon.png";
import closeIconN from "assets/tool/close_icon_n.png";
import searchIcon from "assets/tool/search_icon.png";
import fliterIcon from "assets/fliter_icon.png";
import fliterIconN from "assets/fliter_icon_n.png";
import nftDefault from "assets/nft/nft_defult.png";
import checkFilled from "assets/tool/check_filled.png";
import checkNone from "assets/tool/check_none.png";
import arrowIcon from "assets/tool/arrow_iconw.png"
import arrowIconN from "assets/tool/arrow_iconN.png"
import ethIcon from "assets/nft/ethl_b.png"
import ethIconN from "assets/nft/ethl_w.png"
import { useDarkModeManager } from "theme/components/ThemeToggle";
import { useMyAssetsMutation, useAssetsHideMutation, useAssetsListHideMutation } from "apis/assets"
import {
  useSaveListingOrderMutation, useUpdateListingOrderMutation
} from "apis/orders";
import QuickListEdit, { QuickListForm } from "../components/EditListing";

import QuickList, { ListingOrder, QuickForm } from "../components/QuickList"
import "pages/index.scss";

import { SEADPORT_ADDRESS } from "constants/addresses";



export function Hide() {

  const [,,, setToastOpen] = useToastOpen();
  const clientWidth = useClientWidth();
  const dispatch = useAppDispatch()
  const token = useToken()
  const { provider, chainId, account } = useWeb3React();
  const [getAssets] = useMyAssetsMutation()
  const [changeHide] = useAssetsHideMutation()
  const [listHide] = useAssetsListHideMutation()
  const [updatelistingOrder] = useUpdateListingOrderMutation();
  const [createlistingOrder] = useSaveListingOrderMutation();

  const [updateUser] = useUpdateUserMutation()
  const [acc, setAccount] = useUpdateAccount()

  let navigate = useNavigate();
  const [darkMode] = useDarkModeManager();
  const [filterKey, setFilterKey] = useState("");
  const [isFilter, setIsFilter] = useState(false);
  const [list, setList] = useState<Assets[]>([]);
  const [listLoad] = useState([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10,]);

  const [total, setTotal] = useState(0);
  const [current, setCurrent] = useState(1);
  const [isLoad, setIsLoad] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [listIndex, setListIndex] = useState(0);
  const [currentAsset, setCurrentAsset] = useState<Assets | null>();
  const [minPrice, setMinPrice] = useState('');
  const [maxPrice, setMaxPrice] = useState('');
  const [priceChange, setPriceChange] = useState(false);
  const [showCollections, setShowCollections] = useState(false);
  const [showPrice, setShowPrice] = useState(false);
  const [showCurrency, setShowCurrency] = useState(false);
  const [selectStatus, setSelectStatus] = useState(false);
  const [selectArr, setSelectArr] = useState<Assets[]>([]);
  const [selectArrNum, setSelectArrNum] = useState<number[]>([]);

  const [direct, setDirect] = useState("Low");
  const [sorttext, setSorttext] = useState('price_desc');

  const [priceType, setPriceType] = useState<string>("USD");
  const [chain, setChain] = useState<string>("");
  const [status, setStatus] = useState<string>("");
  const [currencyList, setCurrencyList] = useState([{ text: 'ETH', checked: false }, { text: 'WETH', checked: false }]);
  const priceList = ['USD', 'ETH', 'SOL'];
  const [quickListFormVisible, setQuickListFormVisible] = useState(false);
  const [quickListEditFormVisible, setQuickListEditFormVisible] = useState(false);

  const handleChainChange = (value: string) => {
    setChain(value);
  };
  const handlePriceChange = (value: string) => {
    setPriceType(value);
  };
  const handleStatusChange = (value: string) => {
    setStatus(value);
  };


  const getSign = () => {
    dispatch(setOpenModal(ApplicationModal.ACCEPT_SIGN));
  }





  const getAssetsList = async () => {
    setIsLoad(true);
    try {
      const { data, total, success } = await getAssets({
        page: current,
        pageSize: 20,
        owner: acc?.address,
        hidden: true,
        chain: chain,
      }).unwrap();
      setIsLoad(false);
      if (success) {
        setList(data);
        setTotal(total);
      }
    } catch (error) {
      setIsLoad(false);
    }
  };
  const showQuickListEditForm = (index: number) => {
    setQuickListFormVisible(false);
    setCurrentAsset(list[index]);
    setQuickListEditFormVisible(true)
  };

  const showQuickListForm = (index: number) => {
    setQuickListEditFormVisible(false)
    setCurrentAsset(list[index]);
    setQuickListFormVisible(true);
  };


  useEffect(() => {
    if (acc?.address) {
      getAssetsList()
    }
  }, [chain, acc]);
  const changeCheck = async (index: number) => {
    const newArray = currencyList.map((item, i) => {
      if (index === i) {
        return { ...item, checked: !item.checked };
      } else {
        return item;
      }
    });
    setCurrencyList(newArray);

  }
  const clearSealctArr = () => {
    setSelectStatus(false)
    setSelectArr([])
    setSelectArrNum([])
  }
  const sealctAll = () => {
    let arr: Assets[] = []
    let arrnum: number[] = []
    list.forEach((el, index) => {
      arrnum.push(index)
      arr.push(el)
    })
    setSelectArr(arr)
    setSelectArrNum(arrnum)
  }

  const addOneItem = async (index: number) => {
    if (selectStatus) {
      if (selectArrNum.indexOf(index) == -1) {
        let arr = [...selectArr]
        let arrnum = [...selectArrNum]
        arr.push(list[index])
        arrnum.push(index)
        setSelectArr(arr)
        setSelectArrNum(arrnum)
      } else {
        let arr = [...selectArr]
        let arrnum = [...selectArrNum]
        arr.splice(selectArrNum.indexOf(index), 1)
        arrnum.splice(selectArrNum.indexOf(index), 1)
        setSelectArr(arr)
        setSelectArrNum(arrnum)
      }
    } else {
      setSelectStatus(true)
      setSelectArr([list[index]])
      setSelectArrNum([index])
    }

  }

  const copyLink = (e: string) => {
    let str = window.location.href.split('/account')[0]
    let path = str + e
    navigator.clipboard.writeText(path);
    setToastOpen({open:true,type:'success',label:"success"})
  }
  const setAvatar = async (e: string) => {
    if (isLoad) { return }
    if (!token) {
      getSign()
      return
    }
    if (e) {
      console.log(e); //设置头像
      setIsLoad(true)
      try {
        let data = JSON.stringify({ avatar: e })
        const signaturestr = await provider?.getSigner(account).signMessage(data);
        if (signaturestr) {
          const { success } = await updateUser({ data: data, account: account, signature: signaturestr }).unwrap();
          if (success) {
            setToastOpen({open:true,type:'success',label:'success'})
            if (acc) {
              let temp: Account = { ...acc }
              temp.user.avatar = e
              console.log('temp', temp)
              setAccount(temp)
            }
          } else {
            setToastOpen({open:true,type:'error',label:'error'})
          }
          setIsLoad(false)
        } else {
          setIsLoad(false)
        }
      } catch (err) {

        setToastOpen({open:true,type:'error',label:(err as Error).message})
        setIsLoad(false)
      }

    } else {
      setToastOpen({open:true,type:'error',label:"Unable to set NFT as profile picture, the content is invalid"})

    }

  }
  const stopClick = (e: SyntheticEvent) => {
    e.stopPropagation()
    e.nativeEvent.stopImmediatePropagation()
  }
  const toTransfer = (i?: Assets) => {

    let list: Assets[] = []
    if (i) {
      list = [i]
    } else {
      list = selectArr
    }

    if (list.length < 1) {
      setToastOpen({open:true,type:'error',label:'Plase Select Assets'})
    } else if (list.length === 1) {
      navigate(`/${list[0].id}/transfer`)
    } else {
      let chainNum = 1
      let str = ''
      list.forEach((el: Assets, index: number) => {
        if (index < 1) {
          str = '?assets[' + index + ']=' + el.id
        } else {
          str = str + '&assets[' + index + ']=' + el.id
        }

        if (el.chain_identifier !== list[0].chain_identifier) {
          chainNum = 2
        }
      })
      if (chainNum == 2) {
        setToastOpen({open:true,type:'error',label:'Assets Chain Error'})
      } else {
        navigate(`/${list[0].chain_identifier}/transfer${str}`)
      }


    }

  }

  const changeAssetsHide = async (index: number) => {
    if (!token) {
      getSign()
      return
    }
    if (list[index]) {
      const { status } = await changeHide({ chain: list[index].chain_identifier, contract_address: list[index].contract_address, token_id: list[index].token_id, value: !list[index].hidden }).unwrap()
      if (status === "success") {
        setToastOpen({open:true,type:'success',label:'success'})
        let templist: Assets[] = [...list]
        templist.splice(index, 1)
        setList(templist)
      } else {
        setToastOpen({open:true,type:'error',label:'error'})
      }
    } else {
      setToastOpen({open:true,type:'error',label:'error'})

    }

  };
  const changeListHide = async () => {
    if (!token) {
      getSign()
      return
    }
    let newarr = list.filter((el, index) => {
      if (!selectArrNum.includes(index)) {
        return el
      }
    })
    console.log('newarr', newarr)
    let ids: string[] = selectArr?.map((el: Assets) => {
      return el.id
    })
    console.log('ids', ids)
    if (ids && ids.length > 0) {
      const { status } = await listHide({ ids: ids, op: "hide", value: false }).unwrap()
      if (status === "success") {
        setToastOpen({open:true,type:'success',label:'success'})
        setList(newarr)
        setSelectStatus(false)
        setSelectArr([])
        setSelectArrNum([])
      } else {
        setToastOpen({open:true,type:'error',label:'error'})

      }
    } else {
      setToastOpen({open:true,type:'error',label:'Please Select Asstes'})
    }

  };
  const handleChange = () => {
    if (sorttext == 'price_desc') {
      setSorttext('price_asc')
      setCurrent(1)
    } else {
      setSorttext('price_desc')
      setCurrent(1)
    }
    console.log('sorttextchange')
  };
  const backToAll = () => {
    setMaxPrice('')
    setMinPrice('')
    setFilterKey('')
    setChain('')
    setCurrent(1)
  }


  const saveListOrder = async (value: ListingOrder) => {
    if (value) {

    }
  }

  return (
    <>
      <AccountTop current="hide"></AccountTop>
      <WebMainB>
        <MainBottom>

          <FilterSearch>
            <SearchL
              src={darkMode ? fliterIconN : fliterIcon}
              onClick={() => {
                setIsFilter(!isFilter);
              }}
            />
            <SearchSelect>
              <ChainSelect
                value={chain}
                isTransparent={true}
                onChange={handleChainChange}
                size=""
              /></SearchSelect>
            <SearchSelect>
              <ListSelect
                value={status}
                optionArr={["All Status", "Listed", "On auction", "New", "Has offers",]}
                typeValues={["", "Listed", "On auction", "New", "Has offers",]}
                onChange={handleStatusChange}
                size=""
              /></SearchSelect>

            <SearchM>
              <SearchIcon src={searchIcon} />
              <SearchInput
                id="email"
                name="email"
                type="text"
                placeholder="Name or ID in the collection"
                value={filterKey}
                onChange={(e) => {
                  setFilterKey(e.target.value);
                  setCurrent(1);
                }}
              />
            </SearchM>
            <SearchR>
              <ListSelect
                value={direct}
                optionArr={["Low", "Height",]}
                typeValues={["Low", "Height",]}
                onChange={(val: string) => setDirect(val)}
                size=""
              />
            </SearchR>
          </FilterSearch>
          <MainBB>
            <MainBBl isFilter={isFilter}>
              {Number(clientWidth) < 801 ? (
                <LeftSearchTitle style={{ margin: '20px 0' }}><div>Search Filtwer</div> <PropItemIcon src={darkMode ? closeIconN : closeIcon} onClick={() => setIsFilter(!isFilter)} /></LeftSearchTitle>
              ) : null}
              <LeftSearchTitle onClick={() => setShowCollections(!showCollections)}><div>Collections</div> <PropItemIcon src={darkMode ? arrowIconN : arrowIcon} style={showCollections ? {} : { transform: 'rotate(180deg)' }} /></LeftSearchTitle>
              {showCollections ? (<div>
                <CollectionsSearch>
                  <SearchIcon1 src={searchIcon} />
                  <SearchInput1
                    id="email"
                    name="email"
                    type="text"
                    placeholder="Search"
                    value={filterKey}
                    onChange={(e) => {
                      setFilterKey(e.target.value);
                      setCurrent(1);
                    }}
                  />
                  <SearchClose src={darkMode ? closeIconN : closeIcon} />

                </CollectionsSearch>
              </div>) : null}
              <LeftSearchTitle onClick={() => setShowPrice(!showPrice)}><div>Price</div> <PropItemIcon src={darkMode ? arrowIconN : arrowIcon} style={showPrice ? {} : { transform: 'rotate(180deg)' }} /></LeftSearchTitle>

              {showPrice ? (<>
                <CollectionsSearch>

                  <ListSelect
                    value={priceType}
                    optionArr={priceList}
                    typeValues={priceList}
                    onChange={handlePriceChange}
                    size=""
                  />

                </CollectionsSearch>
                <BtnRow style={{ marginTop: '10px' }}>
                  {/* <BtnOne3>{priceType}</BtnOne3> */}
                  <BtnOne2to>
                    <Input
                      id="email"
                      name="email"
                      type="number"
                      placeholder="min"
                      value={minPrice}
                      sx={{
                        outline: "none",
                        width: "calc(100% + 23px)",
                        height: "40px",
                        border: "none",
                        textAlign: "left",
                      }}
                      onChange={(e) => {
                        setMinPrice(e.target.value);
                      }}
                    />
                  </BtnOne2to>
                  <div>To</div>
                  <BtnOne2to>
                    <Input
                      id="email"
                      name="email"
                      type="number"
                      placeholder="max"
                      value={maxPrice}
                      sx={{
                        outline: "none",
                        width: "calc(100% + 23px)",
                        height: "40px",
                        border: "none",
                        textAlign: "left",
                      }}
                      onChange={(e) => {
                        setMaxPrice(e.target.value);
                      }}
                    />
                  </BtnOne2to>
                </BtnRow>
                <BtnRow>
                  <BtnOne1 isAct={minPrice && maxPrice ? true : false} onClick={() => { setCurrent(1); setPriceChange(!priceChange) }}>Apply</BtnOne1>
                </BtnRow>
              </>) : null}
              <LeftSearchTitle onClick={() => setShowCurrency(!showCurrency)}><div>Currency</div> <PropItemIcon src={darkMode ? arrowIconN : arrowIcon} style={showCurrency ? {} : { transform: 'rotate(180deg)' }} /></LeftSearchTitle>
              {showCurrency ? (<div>
                <CollectionsSearch>
                  <SearchIcon1 src={searchIcon} />
                  <SearchInput1
                    id="email"
                    name="email"
                    type="text"
                    placeholder="Search"
                    value={filterKey}
                    onChange={(e) => {
                      setFilterKey(e.target.value);
                      setCurrent(1);
                    }}
                  />
                  <SearchClose src={darkMode ? closeIconN : closeIcon} />

                </CollectionsSearch>
                {currencyList?.map((i: { text: string, checked: boolean }, index: number) => (
                  <ChooseItem onClick={() => changeCheck(index)}>  <SearchClose src={i.checked ? checkFilled : checkNone} /><div>{i.text}</div></ChooseItem>
                ))}
              </div>) : null}

            </MainBBl>
            <MainBBR isFilter={isFilter}>


              {isLoad ? listLoad.map(() => <MainItemLoad></MainItemLoad>) : total < 1 ? (
                <NoData onChange={() => backToAll()}></NoData>


              ) : list?.map((i: Assets, index: number) => (
                <MainItem className="main-item" isselect={selectArrNum.includes(index)}
                  key={index}
                  onClick={() =>
                    navigate(
                      `/nfts/assets/${i.chain_identifier}/${i.contract_address}/${i.token_id}`
                    )
                  }
                >
                  {index === listIndex && showMenu && !selectStatus ? <CollectRoles onClick={stopClick}>
                    {/* <div onClick={() => addOneItem(index)}>
                      <div>Select</div>
                    </div> */}
                    {i?.listing_time && i.listing_time > 0 ? (
                      // <div onClick={() => showQuickListEditForm(index)}>
                      <div onClick={() => showQuickListForm(index)}>
                        <div>Edit Listing</div>
                      </div>
                    ) : (
                      <div
                        onClick={() => {
                          showQuickListForm(index);
                        }}
                      >
                        <div>List for sale</div>
                      </div>

                    )}
                    {i.hidden ? (<div onClick={() => changeAssetsHide(index)}>
                      <div>Unhide</div>
                    </div>) : (<div onClick={() => changeAssetsHide(index)}>
                      <div>Hide</div>
                    </div>)}

                    <div onClick={() => toTransfer(i)}>
                      <div>Transfer</div>
                    </div>
                    <div onClick={() => setAvatar(i.image_url)}>
                      <div>Make profile picture</div>
                    </div>
                    <div onClick={() => copyLink(`/nfts/assets/${i.chain_identifier}/${i.contract_address}/${i.token_id}`)}>
                      <div>Copy link</div>
                    </div>
                  </CollectRoles> : null}
                  <ItemTop onClick={stopClick}>
                    
                    <div>
                    <RadixPopover val= {'chain:'+i.chain_identifier} labelNode={ <img src={darkMode ? ethIcon : ethIconN} alt="" />}></RadixPopover>

                      {/* <Popover content={(<div>chain:{i.chain_identifier}</div>)}>
                      <img src={darkMode ? ethIcon : ethIconN} alt="" />
                    </Popover> */}
                    </div>
                    {/* <div onClick={() => addOneItem(index)}>
                      {selectArrNum.includes(index) ? '-' : '+'}
                    </div> */}

                  </ItemTop>


                  <div style={{ width: '100%', paddingTop: '100%', position: 'relative' }}>
                    <MainItemImg src={i.image_url || nftDefault} alt="" /></div>
                  <MainItemInfo className={i.current_price ? "item-bottom-none" : ''} style={{ position: "relative", padding: '0' }}></MainItemInfo>
                  <MainItemInfo className={i.current_price ? "item-bottom" : ''} style={i.current_price ? { padding: '0' } : { padding: '0', bottom: '0', justifyContent: 'flex-start' }}>
                    <InfoTop style={{ marginLeft: '5px', marginTop: '5px', marginRight: '5px' }}>
                      <div>{i?.name || i?.collection?.name || '--'}</div>
                    </InfoTop>

                    <InfoLine style={{ marginLeft: '5px', marginTop: '5px', marginBottom: '10px', marginRight: '5px', overflow: 'inherit' }}>#{i.token_id.length > 10 ? i.token_id.substring(0, 10) + '...' : i.token_id}</InfoLine>
                    <InfoBottom
                      style={
                        i.name
                          ? {
                            marginLeft: "5px",
                            marginBottom: "5px",
                            marginRight: "5px",
                          }
                          : {
                            marginLeft: "5px",
                            marginBottom: "5px",
                            marginRight: "5px",
                          }
                      }
                    >
                      <div>

                        <span>
                          {i?.current_price ? Number(i?.current_price?.value) / Number(BigInt(1 * 10 ** i?.current_price?.currency?.decimals)) : '--'}
                        </span>
                        {' '}
                        {i?.current_price?.currency?.symbol || ''}
                      </div>
                    </InfoBottom>
                    {selectStatus ? null : (<InfoBottomBuy className="buy-btn" onClick={stopClick}>

                      {i?.listing_time && i.listing_time > 0 ? (
                        <div onClick={() => showQuickListEditForm(index)}>
                          Edit Listing
                        </div>
                      ) : (
                        <div onClick={() => showQuickListForm(index)}>
                          List for sale
                        </div>
                      )}
                      <div onClick={() => { setListIndex(index); setShowMenu(true) }}>···</div>
                    </InfoBottomBuy>)}

                  </MainItemInfo>
                </MainItem>
              ))}
              <MainItemNone></MainItemNone>
              <MainItemNone></MainItemNone>
              <MainItemNone></MainItemNone>
              <Paging value={{ total: total, current: current, pagesize: 20, isLoad: isLoad }} onChangeCurrent={(val) => setCurrent(val)} />

            </MainBBR>
          </MainBB>

        </MainBottom>
      </WebMainB>
      {selectArrNum.length > 0 || selectStatus ? (<WebFooter>
        <FooterCenter>
          <div>
            <div>{selectArrNum.length} items</div>
            <div onClick={() => sealctAll()}>Select all</div>
            <div onClick={() => clearSealctArr()}>Clear</div>

          </div>
          <div>
            <ButtonTransparent padding='10px' $borderRadius='4px' margin="0 10px 0 0" width='auto' onClick={() => changeListHide()}>Unhide</ButtonTransparent>
            <ButtonTransparent padding='10px' $borderRadius='4px' margin="0 10px 0 0" width='auto' onClick={() => toTransfer()}>Transfer</ButtonTransparent>
            <ButtonPurple padding={'10px'} $borderRadius={'4px'} width={'auto'} onClick={() => { }}>List {selectArrNum.length} items</ButtonPurple>

          </div>
        </FooterCenter>
      </WebFooter>) : null}
      <StyledModal
        modalTitle={'Quick list'}
        isOpen={quickListFormVisible}
        onDismiss={() => {
          setQuickListFormVisible(false);
          setQuickListEditFormVisible(false);
          setCurrentAsset(null);
        }}
      >
        {currentAsset && (
          <QuickList
            value={{
              chain_identifier: currentAsset.chain_identifier,
              contract_address: currentAsset.contract_address,
              token_id: currentAsset.token_id,
            }}
            onChange={(value) => {
              const protocol = SEADPORT_ADDRESS[chainId as number]
              createlistingOrder({ order: value.order, orderHash: value.orderHash, chain: currentAsset.chain_identifier, protocol })
              setQuickListFormVisible(false)
              getAssetsList()

            }
            }
          />
        )}
        {/* <QuickListForm value={quickListForm} onChange={(value:any) => setQuickListForm({ ...quickListForm, ...value })} /> */}
      </StyledModal>
      <StyledModal
        modalTitle={'Edit listing'}
        isOpen={quickListEditFormVisible}
        onDismiss={() => {
          setQuickListEditFormVisible(false);
          setQuickListFormVisible(false);
          setCurrentAsset(null);
        }}
      >
        {currentAsset && (
          <QuickListEdit
            value={{
              chain_identifier: currentAsset.chain_identifier,
              contract_address: currentAsset.contract_address,
              token_id: currentAsset.token_id,
            }}
            onClose={() => {
              setQuickListEditFormVisible(false);
            }}
            onChange={(value) => {
              const protocol = SEADPORT_ADDRESS[chainId as number]
              updatelistingOrder({ order: value.order, orderHash: value.orderHash, chain: currentAsset.chain_identifier, protocol })
              setQuickListEditFormVisible(false)
              getAssetsList()
            }
            }
          />
        )}
      </StyledModal>
    </>
  );
}
