// import { useEffect, useRef } from 'react'
// import { useMemo } from 'react'
import { Contract, ContractFactory } from "@ethersproject/contracts";
import {
  useWeb3React
} from "@web3-react/core";
import { useMemo } from "react";

import Erc20_ABI from "../abis/erc20.json";
import SeaDrop_ABI from "../abis/seadrop.json";
import { Erc20, Erc721SeaDrop, Seadrop } from "../abis/types";

import { getContract, getContractFactory } from "../utils";



export function useContract<T extends Contract = Contract>(
  addressOrAddressMap: string | { [chainId: number]: string } | undefined,
  ABI: any,
  withSignerIfPossible = true
): T | null {
  const { provider, account, chainId } = useWeb3React();

  return useMemo(() => {
    if (!addressOrAddressMap || !ABI || !provider || !chainId) return null;
    let address: string | undefined;
    if (typeof addressOrAddressMap === "string") address = addressOrAddressMap;
    else address = addressOrAddressMap[chainId];
    if (!address) return null;
    try {
      return getContract(
        address,
        ABI,
        provider,
        withSignerIfPossible && account ? account : undefined
      );
    } catch (error) {
      console.error("Failed to get contract", error);
      return null;
    }
  }, [
    addressOrAddressMap,
    ABI,
    provider,
    chainId,
    withSignerIfPossible,
    account,
  ]) as T;
}

export function useContractFactory<T extends ContractFactory = ContractFactory>(
  // addressOrAddressMap: string | { [chainId: number]: string } | undefined,
  ABI: any,
  code: any,
  withSignerIfPossible = true
): T | null {
  const { provider } = useWeb3React();

  return useMemo(() => {
    // const result = await provider?.getSigner(account).signMessage(msg);
    // let signaturestr = result ? result : "";
    // if (!addressOrAddressMap || !ABI || !provider || !chainId) return null
    // let address: string | undefined
    // if (typeof addressOrAddressMap === 'string') address = addressOrAddressMap
    // else address = addressOrAddressMap[chainId]
    // if (!address) return null  withSignerIfPossible
    try {
      return getContractFactory(ABI, code, provider?.getSigner());
    } catch (error) {
      console.error("Failed to get contract", error);
      return null;
    }
  }, [ABI, code, provider]) as T;
}
// getContractFactory



export function useSeaDropContract(seadrop_address:string,withSignerIfPossible?: boolean) {
  return useContract<Seadrop>(
    seadrop_address,
    SeaDrop_ABI,
    withSignerIfPossible
  ) as Seadrop;
}
export function useErc721SeaDropContract(address:string | undefined,ABI: any,withSignerIfPossible?: boolean) {
  return useContract<Erc721SeaDrop>(
    address,
    ABI,
    withSignerIfPossible
  ) as Erc721SeaDrop;
}


export function useERC20(contract_address?:string,withSignerIfPossible?: boolean) :Erc20|null{

    return useContract<Erc20>(
      contract_address,
      Erc20_ABI,
      withSignerIfPossible
    ) ;
}




