
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import {MIN_WIDTH_MEDIA_BREAKPOINT,LARGE_MEDIA_BREAKPOINT} from "constants/width"



export const MainBottomTitle = styled.div`
  margin: 10px 0 0;
  font-size: 20px;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  color: ${({ theme }) => theme.text3};
  display: flex;
  align-items: center;
  border-bottom:1px solid ${({ theme }) => theme.border1};
`;
export const MainBottomTitleItem = styled.div<{ isAct: boolean }>`
  margin-right:50px;
  margin-bottom:30px;
  line-height:56px;
  padding:0 25px;
  height: 56px;
  cursor: pointer;
  color:${({ isAct, theme }) => (isAct ? theme.text1 : theme.text3)};
  background:${({ isAct, theme }) => (isAct ? theme.bg2 : "transparent")};
  border-radius: 6px;
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    margin-right:20px;
    margin-bottom:10px;
    line-height:40px;
    padding:0 10px;
    height: 40px; 
  }
`

interface TabProps {
  current: "trending"|"top"|"watchlist";
}

export default function CardTableMainRow({ current }: TabProps) {
  
 const navigate = useNavigate();
  return (
    <>
      <MainBottomTitle>
              <MainBottomTitleItem
                isAct={current === "trending" ? true : false}
                onClick={() => navigate("/nfts/rankings/trending")}
              >
                Trending
              </MainBottomTitleItem>
              <MainBottomTitleItem
                isAct={current === "top" ? true : false}
                onClick={() => navigate("/nfts/rankings/top")}
              >
                Top
              </MainBottomTitleItem>
              <MainBottomTitleItem
                isAct={current === "watchlist" ? true : false}
                onClick={() => navigate("/nfts/rankings/watchlist")}
              >
                Watchlist
              </MainBottomTitleItem>
            </MainBottomTitle>
    </>
  );
}
