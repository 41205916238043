import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './pages/App';
import reportWebVitals from './reportWebVitals';
import ThemeProvider, { ThemedGlobalStyle } from './theme';
import { Provider } from 'react-redux';
import Web3Provider from './components/Web3Provider';
import store from './state';
import { LanguageProvider } from './i18n';
import { BrowserRouter } from 'react-router-dom';
import { ModalProvider } from 'styled-react-modal';
import { Buffer } from 'buffer';

// @ts-ignore
window.Buffer = Buffer;
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
       <Provider store={store}>
       <BrowserRouter>
       <LanguageProvider>
       <Web3Provider>
        <ThemeProvider >
          <ThemedGlobalStyle/>
          <ModalProvider>
        <App />
        </ModalProvider>
      </ThemeProvider>
      </Web3Provider>
      </LanguageProvider>
      </BrowserRouter>
      </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
