
import { useEffect, useState } from "react";

import styled from "styled-components";


import { timestampToLocaleString } from "utils";


const ItemLabel = styled.div`
  margin: 10px 0;
  font-weight: 600;
  > span {
    color: #6a5bda;
    cursor: pointer;
  }
`;
const ItemLabelFlex = styled.div`
  margin: 10px 0;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const ItemTip = styled.div`
  font-size: 14px;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  color: ${({ theme }) => theme.text3};
  line-height: 16px;
  margin-bottom: 10px;
`;



const CardItem1 = styled.div`
  margin-bottom: 20px;
  width: auto;
  height: auto;
  overflow: hidden;
`;
const ItemRadio1 = styled.div`
  width: 100%;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.border1};
  padding: 0 16px;
  box-sizing: border-box;
`;

interface MintStageInputProps {
  size: string;
  chain: string;
  contract_address: string;
  value: MintStage;
  onChange: (value: MintStage) => void;
  onEdit: () => void;
  onDel:(value: MintStage)=>void;

}




export default function MintStageInputSelect({
  chain,
  contract_address,
  value,
  onChange,
  onEdit,
  onDel
}: MintStageInputProps) {
  const [showPopup, setShowPopup] = useState(false);
  const [mintStage, setMintStage] = useState<MintStage>(value);
  const [duration, setDuration] = useState<Duration>({
    days: 0,
    hours: 0,
    minutes: 0,
  });

  useEffect(() => {
    
    if(value.sale_price.currency.decimals!==0){
      let num=Number(value.sale_price.value)/(1* 10 ** value.sale_price.currency.decimals)
      setMintStage({...value,...{sale_price:{currency:value.sale_price.currency,value:String(num)}}});

    }else{
      setMintStage(value);
    }
    var days = Math.floor(value.duration / (24 * 60))
    var hours = Math.floor((value.duration - (days * 24 * 60)) / 60)
    var mins = value.duration % 60
    setDuration({
      days: days,
      hours: hours,
      minutes: mins,
    });
  }, [value]);



  return (
    <>
      <CardItem1>
        <ItemRadio1>
          <ItemLabelFlex>
            <ItemLabel>{mintStage.name}</ItemLabel>
            <ItemLabel>
              {mintStage.is_public ? (
                <span
                  onClick={() => {
                    onEdit();
                  }}
                >
                  Edit
                </span>
              ) : (
                <>
                  <span
                    onClick={() => {
                      onEdit();
                      // setShowPopup(!showPopup);
                    }}
                  >
                    Edit
                  </span>
                  <span style={{ opacity: "0",display:'inline-block',width:'20px' }}></span>
                  <span onClick={() => { onDel(mintStage)}}>Delete</span>
                </>
              )}
            </ItemLabel>
          </ItemLabelFlex>
          <ItemTip>{timestampToLocaleString(mintStage.start_time * 1000)}</ItemTip>
          <ItemTip>
            {mintStage.sale_price.value}{" "}
            {mintStage?.sale_price?.currency?.symbol} ·
            {duration.days} days {duration.hours} hours {duration.minutes} mins
          </ItemTip>
        </ItemRadio1>
      </CardItem1>

    </>
  );
}
