import styled, { keyframes } from "styled-components/macro";
import {MAX_WIDTH_MEDIA_BREAKPOINT,MIN_WIDTH_MEDIA_BREAKPOINT,LARGE_MEDIA_BREAKPOINT} from "constants/width"

export const TokenTable = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 1440px;
  margin-bottom: 30px;
  background: ${({ theme }) => theme.bg0};
  border-radius: 10px;
`;

export const TableFoot = styled.div`
  padding: 15px 10px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  color: ${({ theme }) => theme.text3};
  font-size: 12px;
  border-top: 1px solid ${({ theme }) => theme.bg2};
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    flex-wrap: wrap;
   >div:first-child{
    margin-bottom: 10px;
   }
  }
`;
export const PageGroups = styled.div`
  display: flex;
  align-items: center;
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    
      width: 100%;
      justify-content: space-between;
      >div:first-child{
        margin-left:0
      }
   }
`;
export const PageBtn = styled.div`
  margin-left: 10px;
  color: #6a5bda;
  border: 1px solid ${({ theme }) => theme.bg2};
  border-radius: 4px;
  padding: 0 15px;
  height: 30px;
  line-height: 30px;
  display: flex;
  align-items: center;
  cursor: pointer;
`;
export const PageBtn1 = styled.div`
  margin-left: 10px;
  color: ${({ theme }) => theme.text3};
  border: 1px solid ${({ theme }) => theme.bg2};
  background: ${({ theme }) => theme.bg2};
  border-radius: 4px;
  padding: 0 15px;
  height: 30px;
  line-height: 30px;
  display: flex;
  align-items: center;
  opacity: 0.7;
  cursor: pointer;
`;