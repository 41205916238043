import styled from "styled-components/macro";
import {MAX_WIDTH_MEDIA_BREAKPOINT,MIN_WIDTH_MEDIA_BREAKPOINT,LARGE_MEDIA_BREAKPOINT} from "constants/width"

export const PageAll = styled.div`
  height: auto;
  min-height: calc(100vh - 240px);
  margin: 0 auto;
  flex-shrink: 0;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media screen and (min-width: ${LARGE_MEDIA_BREAKPOINT}){
    width: 95vw !important;
    max-width: 1440px !important;
    min-width: 760px !important;
  }
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    width: 95vw !important;
    max-width: 800px !important;
    min-width: 0px !important;

    min-height: calc(100vh - 290px);
  }
`;
export const PageTitle = styled.div`
  height: 60px;
  font-size: 40px;
  font-weight: 600;
  line-height: 60px;
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    height: 40px;
    font-size: 25px;
    font-weight: 600;
    line-height: 40px;
  }
`;
export const PosaLImg = styled.img`
  position: absolute;
  left: 0;
  top: calc(50% - 17px);
  width: 34px;
  height: 34px;
  cursor: pointer;
`;
export const PosaSImg = styled.img`
  position: absolute;
  right: 0;
  top: calc(50% - 17px);
  width: 34px;
  height: 34px;
  cursor: pointer;
`;
export const SpotList = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 40px;
  max-width: 1440px;
  position: relative;
  min-height: 100px;
`;
export const SpotCenter = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  overflow: hidden;
`;
export const SpotItem = styled.div`
  width: calc(30% - 26px);
  cursor: pointer;
  padding: 5px;
  border-radius: 8px;
  left: 0;
  margin: 0 13px 0 13px;
  display: flex;
  position: relative;
  transition: all 450ms ease;
  
  justify-content: center;
  :hover {
    background: ${({ theme }) => theme.bg2};
  }
`;
export const SpotItemImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
