import { Field } from "components/swap/constants";
import JSBI from "jsbi";
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { useAccount } from "state/user/hooks";

import { LimitState } from "./limit_ypes";
import { useSwapAndLimitContext } from "state/swap/hooks";
import { Currency, CurrencyAmount, Fraction, Percent, Price, Token, TradeType } from "core";
import tryParseCurrencyAmount from "lib/utils/tryParseCurrencyAmount";
import { useMarketPriceQuery } from "apis/token";
import useCurrencyBalance from "hooks/useCurrencyBalance";
import { useWeb3React } from "@web3-react/core";
import { LimitOrderTrade, SubmittableTrade } from "state/routing/types";
import { expiryToDeadlineSeconds } from "./expiryToDeadlineSeconds";

export type WrapInfo =
  | { needsWrap: true; wrapGasEstimateUSD: number }
  | { needsWrap: false };
export type ApproveInfo =
  | { needsApprove: true; approveGasEstimateUSD: number }
  | { needsApprove: false };
export type SwapFeeInfo = {
  recipient: string;
  percent: Percent;
  amount: string /* raw amount of output token */;
};


export type LimitInfo = {
  currencyBalances: { [field in Field]?: CurrencyAmount<Currency> };
  parsedAmounts: { [field in Field]?: CurrencyAmount<Currency | Token> };
  parsedLimitPrice?: Price<Currency, Currency>;
  limitOrderTrade?: LimitOrderTrade;
  marketPrice?: Price<Currency, Currency>;
  fee?: SwapFeeInfo;
};

export function useDerivedLimitInfo(
  state: LimitState,
  setState: Dispatch<SetStateAction<LimitState>>
): LimitInfo {
  const {account} = useWeb3React();
  const { inputAmount, outputAmount, limitPriceInverted } = state;
  const {
    currencyState: { inputCurrency, outputCurrency },
  } = useSwapAndLimitContext();

  const { marketPrice, fee: swapFee } = useMarketPriceAndFee(
    inputCurrency,
    outputCurrency
  );
  const parsedLimitPrice = useMemo(() => {
    if (!inputCurrency || !outputCurrency || !state.limitPrice) {
      return undefined;
    }

    const [baseCurrency, quoteCurrency] = limitPriceInverted
      ? [outputCurrency, inputCurrency]
      : [inputCurrency, outputCurrency];

    const parsedLimitPriceQuoteAmount = tryParseCurrencyAmount(
      state.limitPrice,
      quoteCurrency
    );
    if (!parsedLimitPriceQuoteAmount) {
      return undefined;
    }

    return new Price(
      baseCurrency,
      quoteCurrency,
      JSBI.BigInt(10 ** baseCurrency.decimals),
      parsedLimitPriceQuoteAmount.quotient
    );
  }, [inputCurrency, limitPriceInverted, outputCurrency, state.limitPrice]);

  const parsedAmounts = useMemo(() => {
    let parsedInputAmount;
    let parsedOutputAmount;
    const limitPrice = limitPriceInverted
      ? parsedLimitPrice?.invert()
      : parsedLimitPrice;
    if (state.isInputAmountFixed) {
      parsedInputAmount = tryParseCurrencyAmount(inputAmount, inputCurrency);
      parsedOutputAmount = !limitPrice
        ? tryParseCurrencyAmount(outputAmount, outputCurrency)
        : parsedInputAmount && limitPrice.quote(parsedInputAmount);
    } else {
      parsedOutputAmount = tryParseCurrencyAmount(outputAmount, outputCurrency);
      parsedInputAmount = !limitPrice
        ? tryParseCurrencyAmount(inputAmount, inputCurrency)
        : parsedOutputAmount && limitPrice.invert().quote(parsedOutputAmount);
    }

    return {
      [Field.INPUT]: parsedInputAmount,
      [Field.OUTPUT]: parsedOutputAmount,
    };
  }, [
    inputAmount,
    inputCurrency,
    limitPriceInverted,
    outputAmount,
    outputCurrency,
    parsedLimitPrice,
    state.isInputAmountFixed,
  ]);

  const inputBalance = useCurrencyBalance(account,inputCurrency)
  const outputBalance = useCurrencyBalance(account,outputCurrency)
  const currencyBalances={
        [Field.INPUT]: inputBalance,
        [Field.OUTPUT]: outputBalance,
      }
  // const currencyBalances= useMemo(() => {

  //   const parsedInputBalance=tryParseCurrencyAmount("0.2", inputCurrency);
  //   const parsedOutputBalance=tryParseCurrencyAmount("222222222", outputCurrency);
  //   return {
  //     [Field.INPUT]: parsedInputBalance,
  //     [Field.OUTPUT]: parsedOutputBalance,
  //   }
  // },[inputCurrency, outputCurrency ])




  const limitOrderTrade = useLimitOrderTrade({
    inputCurrency,
    parsedAmounts,
    outputAmount: parsedAmounts[Field.OUTPUT],
 
    state,
    swapFee,
  })


  return { currencyBalances, parsedAmounts, parsedLimitPrice, marketPrice,limitOrderTrade };
}

function useMarketPriceAndFee(
  inputCurrency: Currency | undefined,
  outputCurrency: Currency | undefined
): { marketPrice?: Price<Currency, Currency>; fee?: SwapFeeInfo } {
  const skip = !(inputCurrency && outputCurrency);
  const feesEnabled = false;

  const base =
    inputCurrency?.chain + "/" + inputCurrency?.address.toLowerCase();
  const quote =
    outputCurrency?.chain + "/" + outputCurrency?.address.toLowerCase();
  const { data } = useMarketPriceQuery({ skip, base, quote });

  const marketPrice: Price<Currency, Currency> | undefined = useMemo(() => {
    if (skip) {
      return undefined;
    }
    if (data && data.data.unit_price>0) {
     
      var unit_price=data.data.unit_price*10**(outputCurrency.decimals-inputCurrency.decimals)

     //2.21251E+2   221.251 221251 10**3
     //2.21241e-2   0.021241 221241 10**7
     //0.1E+3       100      100     10*0
     //0.1E-3      0.0001     1     10**4
      var v=unit_price.toExponential().split(/[eE]/);
      var d=parseInt(v[1]) 
      var decimal_place= v[0].length-2
      var  n=parseInt(v[0].replace(".",""))
      var denominator
      var numerator
      var fraction
      // console.log("data.data.unit_price:",data.data.unit_price)
      // console.log("v:",v)
      // console.log("decimal_place:",decimal_place)
      // console.log("d:",d)
      // console.log("n:",n)
      if(decimal_place<d){
         denominator= 1
         numerator=n*10**Math.abs(d)
          fraction=new Fraction(numerator,denominator)
         return new Price(inputCurrency,outputCurrency,fraction.denominator,fraction.numerator);
      }else{
        denominator= 10**(decimal_place-d)
        numerator=n
         fraction=new Fraction(numerator,denominator)
        return new Price(inputCurrency,outputCurrency,fraction.denominator,fraction.numerator); 
      }
    }
    return undefined;
  }, [skip, inputCurrency, outputCurrency, data]);

  const fee = useMemo(() => {
    if (!marketPrice || !inputCurrency || !outputCurrency || !feesEnabled) {
      return undefined;
    }
    return undefined;
  }, [feesEnabled, inputCurrency, marketPrice, outputCurrency]);
  return useMemo(() => ({ marketPrice, fee }), [marketPrice, fee]);
}


function useLimitOrderTrade({
  state,
  trade,
  inputCurrency,
  parsedAmounts,
  outputAmount,
  swapFee,
}: {
  state: LimitState
  trade?: SubmittableTrade
  inputCurrency?: Currency
  parsedAmounts: { [field in Field]?: CurrencyAmount<Currency> }
  outputAmount?: CurrencyAmount<Currency>
  swapFee?: SwapFeeInfo
}) {
  const {account} = useWeb3React()
  const [wrapInfo, setWrapInfo] = useState<WrapInfo>()


  const limitOrderTrade = useMemo(() => {
    if (!inputCurrency || !parsedAmounts?.[Field.INPUT] || !account || !outputAmount || !wrapInfo) {
      return undefined
    }
    const amountIn = CurrencyAmount.fromRawAmount(inputCurrency.wrapped, parsedAmounts?.[Field.INPUT].quotient)
    return new LimitOrderTrade({
      amountIn,
      amountOut: outputAmount,
      tradeType: TradeType.EXACT_INPUT,
      wrapInfo,
      approveInfo: { needsApprove: false },
      swapper: account,
      deadlineBufferSecs: expiryToDeadlineSeconds(state.expiry),
      swapFee,
    })
  }, [account, outputAmount, inputCurrency, parsedAmounts, state.expiry, wrapInfo, swapFee])

  return limitOrderTrade
}