import { Input } from "@rebass/forms";
import { useWeb3React } from "@web3-react/core";
import { ethers } from "ethers";
import { useEffect, useState } from "react";
import {useToastOpen } from 'state/stats/hooks'
import { Seaport } from "@opensea/seaport-js";
import { useGetChainQuery } from "apis/chain";
import { ButtonPurple } from "components/Button";
import RadixPopover from "components/Popover"

import ListSelect from "components/ListSelect";
import { CardItem, InputBlock, ItemLabel, ItemLabelFlex, ItemValue, ItemValueIcon, PriceSymbol } from "pages/Nft/EditCollection/components_";
import "pages/index.scss";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDarkModeManager } from "theme/components/ThemeToggle";
import { useCollectionInfoQuery } from "apis/collection"
import {
  DurationSelect,ItemValueBottom,TipIcon,inputStyleNum
} from "../../components_";
// import "../../index.scss";
import { ItemType } from "@opensea/seaport-js/lib/constants";
import { OrderWithCounter } from "@opensea/seaport-js/lib/types";
import "pages/index.scss";
import { Currency } from "types/token";

export interface QuickForm {

  visible?: boolean;
  isEarning?: boolean;
  earningNum?: number;
  chain_identifier?: string
  contract_address?: string
  token_id?: string
}

export interface ListingOrder {
  order: OrderWithCounter;
  orderHash: string;
}

interface QuickFormProps {
  value?: QuickForm;
  onChange: (value: ListingOrder) => void;
}

export default function QuickForm({
  value,
  onChange,
}: QuickFormProps) {

  const { account, provider } = useWeb3React();
  const [,,, setToastOpen] = useToastOpen();
  const [darkMode] = useDarkModeManager();

  const [isLoading, setIsLoading] = useState(false);
  const [earningsCustom, setEarningsCustom] = useState(false);
  const [startTime, setStartTime] = useState(new Date().getTime());
  const [endTime, setEndTime] = useState(new Date());
  const [fromPrice, setFromPrice] = useState('');
  const [fromEarnings, setFromEarnings] = useState('');

  const [total, setTotal] = useState(0);
  const [usdPrice, setUsdPrice] = useState(0);
  const [durationTime, setDurationTime] = useState('1month');
  const [chainCurrency, setChainCurrency] = useState<Currency>();

  const handleDurationChange = (value: string) => {
    setDurationTime(value);
  };
  const collectionRes = useCollectionInfoQuery({
    chain: value?.chain_identifier || '',
    contract_address: value?.contract_address as string || '',
  });
  const { data } = useGetChainQuery({ chain: value?.chain_identifier as string });
  useEffect(() => {
    if (data?.success) {
      setChainCurrency(data?.data?.currency)
    }
    if (collectionRes && collectionRes.status === "fulfilled" && collectionRes.data && collectionRes.data.success && collectionRes.data.data) {//seller_fees  Creator earnings
      console.log('collectionRes.data.data', collectionRes.data.data)
    }
  }, [data, collectionRes]);
  useEffect(() => {
    let dateTime = Date.parse(new Date().toString())
    let num = 0
    if (durationTime) {
      num = Number(durationTime)
      if (durationTime === "1month") {
        let myDate = new Date();
        let tMonth = myDate.getMonth();
        let m = tMonth + 1;
        let day31 = [1, 3, 5, 7, 8, 10, 12]
        let time = 0
        if (m === 2) {
          time = 86400 * 28
        } else if (day31.indexOf(m) === -1) {
          time = 86400 * 30
        } else {
          time = 86400 * 31
        }
        num = time
      }
    }
    let duration = dateTime + num * 1000
    setEndTime(new Date(duration))
  }, [durationTime]);
  useEffect(() => {
    if (fromPrice) {
      let num = value?.earningNum || 0
      let total = Number(fromPrice) * (1 - num - 0.025)
      setTotal(total)
    }
  }, [value, fromPrice]);



  const ondataChange = (
    value: any
  ) => {
    console.log('data', value)
    setEndTime(new Date(value.toString()))
  };
  const save = async () => {
    if (isLoading) {
      setToastOpen({open:true,type:'error',label: "Loading..."})
      return
    }
    if (fromPrice && fromPrice !== '') {
      setIsLoading(true)
      try {
        const seaport = new Seaport(provider as any);
        const offerer = account;
        const { executeAllActions } = await seaport.createOrder(
          {
            startTime: (startTime / 1000).toFixed(0),
            endTime: (endTime.getTime() / 1000).toFixed(0),
            offer: [
              {
                itemType: ItemType.ERC721,
                token: value?.contract_address as string,
                identifier: value?.token_id as string,
              },
            ],
            consideration: [
              {
                amount: ethers.utils.parseEther(fromPrice).toString(),
                recipient: offerer,
              },
            ],
          },
          offerer,
        );

        const order = await executeAllActions();
        const orderHash = seaport.getOrderHash(order.parameters);
        onChange && onChange({ orderHash, order })
      } catch (err) {
        console.log('errorerror', err)

        setToastOpen({open:true,type:'error',label:(err as Error).message})
      }
      setIsLoading(false)
    } else {
      setToastOpen({open:true,type:'error',label:"Plase enter amount"})

    }
  };
  return (
    <>


      <CardItem>
        <ItemLabel>Starting price</ItemLabel>
        <ItemValueIcon>
          <InputBlock style={{ paddingLeft: '8px' }}>
            <Input
              type="number"
              placeholder="Amount"
              value={fromPrice}
              sx={inputStyleNum}
              onChange={(e) => {
                setFromPrice(e.target.value);
              }}
            /></InputBlock>
          <PriceSymbol>{chainCurrency?.name}</PriceSymbol>
        </ItemValueIcon>
        {/* <ItemTip style={{ padding: '5px 0 0' }}>${fromPrice ? fromPrice : '0'} Total</ItemTip> */}
      </CardItem>
      <CardItem>
        <ItemLabel>Duration</ItemLabel>
        <ItemLabelFlex>
          <DurationSelect style={{ marginLeft: '0' }}>
            <ListSelect
              value={durationTime}
              optionArr={["Custom", "1 hour", "6 hour", "1 day", "3 days", "7 days", "1 month"]}
              typeValues={["", "3600", "21600", "86400", "259200", "604800", "1month"]}
              onChange={handleDurationChange}
              size=""
            /></DurationSelect>
          <ItemValue className={darkMode ? 'date-picker-divw' : 'date-picker-div'}>
            <DatePicker
              selected={endTime}
              onChange={(date) => ondataChange(date)}
              timeInputLabel="Time:"
              dateFormat="MM/dd/yyyy h:mm aa"
              className={darkMode ? 'date-picker-w' : 'date-picker'}
              showTimeInput
            />
          </ItemValue>

        </ItemLabelFlex>
      </CardItem>
      <CardItem>
        <ItemLabel></ItemLabel>
        <ItemValueBottom>
          <div>Listing price</div>
          <div>{fromPrice || '--'}{chainCurrency?.name}</div>
        </ItemValueBottom>
        <ItemValueBottom>
          <div>Zooker fee</div>
          <div>2.5%</div>
        </ItemValueBottom>
        <ItemValueBottom style={{ border: 'none' }}>
          <div>
            <div>Creator earnings </div>
            <RadixPopover val={value?.isEarning ? "This creator hasn't enabled creator earnings." : "100% of creator earnings are paid to the creator of the collection"} labelNode={ <TipIcon>?</TipIcon>}></RadixPopover>
           
          </div>
          <div>{value?.isEarning ? value.isEarning : 0}%</div>
        </ItemValueBottom>
        {/* {value?.isEarning ? (
          <> <ItemLabelFlex>
            <ItemRadioOne isAct={!earningsCustom}
              onClick={() => setEarningsCustom(false)}
            >
              <ItemLabelFlex>

                <Input
                  type="text"
                  placeholder="Enter amount"
                  value={10}
                  sx={inputStyle1}
                  disabled
                  onChange={(e) => {
                    setFromPrice(e.target.value);
                  }}
                />%</ItemLabelFlex>
              <ItemTip>{fromPrice ? <div>{Number(fromPrice) * 0.1} {chainCurrency?.name}(${usdPrice})</div>: null}</ItemTip> 

            </ItemRadioOne>
            <ItemRadioOne isAct={earningsCustom}
              onClick={() => setEarningsCustom(true)}
            >
              <ItemLabelFlex>
                {earningsCustom ? (<InputBlock><Input
                  type="number"
                  placeholder="Enter amount"
                  autoFocus
                  value={fromEarnings}
                  sx={inputStyle1Num}
                  onChange={(e) => {
                    setFromEarnings(e.target.value);
                  }}
                /></InputBlock>) : (<InputBlock  onClick={() => setEarningsCustom(true)}>
                 <InputText>Custom</InputText> 
                </InputBlock>
                )}%
              </ItemLabelFlex>
              <ItemTip>{fromPrice ? <div>{Number(fromPrice) * Number(fromEarnings || 0) / 100} {chainCurrency?.name}(${usdPrice})</div>: null}</ItemTip> 
            </ItemRadioOne>
          </ItemLabelFlex>  <ItemRadio11 isShow={true}>
              <ItemLabel>
                Show your support for this creator
              </ItemLabel>
              <ItemTip>
                They suggested creator earnings of 10%.
              </ItemTip>
            </ItemRadio11></>
        ) : null} */}


      </CardItem>
      {/* <CardItem>
        <ItemValueBottom style={{ border: 'none' }}>
          <div>Total potential earnings</div>
          <div>{total || '--'}{chainCurrency?.name}</div>
        </ItemValueBottom>
      </CardItem> */}
      {/* <ButtonPurple padding={'10px'} $borderRadius={'4px'} width={'100%'} onClick={() =>  onChange({ visible: !value?.visible })}>Complete listing</ButtonPurple></StyledModal> */}
      <ButtonPurple padding={'10px'} $borderRadius={'4px'} width={'100%'} onClick={() => save()}>{isLoading ? 'Loading...' : 'Complete listing'}</ButtonPurple>

    </>
  );
}
