import { Input } from "@rebass/forms";
import { useWeb3React } from "@web3-react/core";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDarkModeManager } from "theme/components/ThemeToggle";

import {
  useCollectionInfoQuery,
  useCollectionItemFilterMutation,
} from "apis/collection";
import NoData from "components/NoData";
import Paging from "components/Paging";
import { useClientWidth } from "state/application/hooks";
import { setOpenMenu } from "state/application/reducer";
import { useAppDispatch } from "state/hooks";

import ethBlick from "assets/eth_blick.png";
import ethBlickN from "assets/eth_blick_n.png";
import fliterIcon from "assets/fliter_icon.png";
import fliterIconN from "assets/fliter_icon_n.png";
import nftDefault from "assets/nft/nft_defult.png";
import arrowU from "assets/tool/arrow_icon.png";
import arrowUn from "assets/tool/arrow_iconN.png";
import checkfilled from "assets/tool/check_filled.png";
import checknone from "assets/tool/check_none.png";
import closeIcon from "assets/tool/close_icon.png";
import closeIconN from "assets/tool/close_icon_n.png";
import filterIcon from "assets/tool/filter_icon.png";
import filterIconN from "assets/tool/filter_icon_n.png";
import searchIcon from "assets/tool/search_icon.png";
import ListSelect from "components/ListSelect";
import { priceText } from "utils";
import {
  ApplyBtn, BtnOne1, BtnOne2, BtnOne3, BtnRow,
  BtnText,
  FilterImg, FilterSearch, FilterSearchTel, InfoBottom, InfoBottomBuy, InfoLine, InfoTop,
  ItemBottomImg, ItemNumText,
  LeftSearchTitle, MainBB,
  MainBBl,
  MainBbr,
  MainBottom, MainBtns, MainGroup, MainItem,
  MainItemBlock,
  MainItemImg,
  MainItemInfo, MainItemLoad, MainItemNone, PopupMain, PopupView, PropArrowImg, PropGroup, PropItem,
  PropItemIcon, PropItemName, PropItemRight, PropMain, PropTop, ReselectionBtn, SearchIcon, SearchInput, SearchL, SearchM,
  SearchMTel, SearchR, SearchRTel, SearchRTel1, TitleLine, TitleLineC, TitleLineL, TitleLineR, WebMain
} from "../../components_";

import "pages/index.scss";

export default function NftItems() {
  const dispatch = useAppDispatch()

  const { account } = useWeb3React();
  const clientWidth = useClientWidth();
  const { chain, contract_address } = useParams();
  let navigate = useNavigate();
  const [darkMode] = useDarkModeManager();
  const [collectInfo, setCollectInfo] = useState<CollectionInfo | null>();

  const [filterKey, setFilterKey] = useState("");
  const [isFilter, setIsFilter] = useState(true);
  const [isShowPopup, setIsShowPopup] = useState(false);
  const [total, setTotal] = useState(0);
  const [current, setCurrent] = useState(1);
  const [traitValues, setTraitValues] = useState([]);
  const [isbuynow, setIsbuynow] = useState(false);
  const [isMine, setIsMine] = useState(false);
  const [isLoad, setIsLoad] = useState(true);
  const [itemsList, setItemsList] = useState<Item[] | null>();
  const [minPrice, setMinPrice] = useState('');
  const [maxPrice, setMaxPrice] = useState('');
  const [priceChange, setPriceChange] = useState(false);
  const [sorttext, setSorttext] = useState('price_desc');
  const [direct, setDirect] = useState("Low");

  const list = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
  ];

  const [propList, setPropList] = useState<{
    name: string,
    isShow: boolean,
    list: { name1: string, num: number, isCheck: boolean }[]
  }[]>([]);
  const getTraitList = (arr: Array<{}>) => {
    let allList = arr ? arr : JSON.parse(JSON.stringify(propList));
    let traitTemp: any = [];
    allList.forEach((el: any) => {
      let itemObj = {
        name: el.name,
        values: ["none"],
      };
      el.list.forEach((childEl: any) => {
        if (childEl.isCheck) {
          if (itemObj.values[0] == "none") {
            itemObj.values[0] = childEl.name1;
          } else {
            itemObj.values.push(childEl.name1);
          }
        }
      });
      traitTemp.push(itemObj);
    });
    let traitTemp1: any = [];
    traitTemp.forEach((el: any, index: number) => {
      if (el.values[0] !== "none") {
        traitTemp1.push(el);
      }
    });
    setTraitValues(traitTemp1);
  };

  const getNum = (item: CollectionInfo) => {
    if (item.traits) {
      let obj: any = item.traits;
      let list: any = [];
      for (let key in obj) {
        let el = {
          name: key,
          isShow: false,
          list: [{ name1: "default", num: 1, isCheck: true }],
        };
        for (let childKey in obj[key]) {
          if (el.list[0].name1 == "default") {
            el.list[0] = {
              name1: childKey,
              num: obj[key][childKey],
              isCheck: false,
            };
          } else {
            el.list.push({
              name1: childKey,
              num: obj[key][childKey],
              isCheck: false,
            });
          }
        }
        list.push(el);
      }
      setPropList(list);
    }
    setCollectInfo(item);
  };
  const [getItem] = useCollectionItemFilterMutation();

  const getItems = async (current: number, traitValues: any) => {
    // if(isLoad){
    //   return
    // }
    setIsLoad(true)
    if (current === 1) {
      setItemsList([]);
    }
    let status = undefined
    let owner_address = undefined
    if (isbuynow) {
      status = 'listed'
    }
    if (isMine) {
      owner_address = account || undefined
    }
    let paramdata = {
      max_price: maxPrice ? Number(maxPrice) : undefined,
      min_price: minPrice ? Number(minPrice) : undefined,
      chain: chain as string,
      contract_address: contract_address as string,
      current: current,
      page_size: 20,
      status: status,
      owner_address: owner_address,
      sort: sorttext,
      trait_values: traitValues.length < 1 ? undefined : traitValues,
      key: filterKey || undefined,
    }
    const res = await getItem(paramdata).unwrap();
    if (res.success) {
      setTotal(res.total);
      setItemsList(res.data);
      setIsLoad(false);
    } else {
      console.log("res", res);
    }
  };

  useEffect(() => {
    getItems(current, traitValues);
  }, [current, traitValues, filterKey, isbuynow, isMine, priceChange, sorttext, chain, contract_address]);

  const { data } = useCollectionInfoQuery({
    chain: chain as string,
    contract_address: contract_address as string,
  });



  useEffect(() => {
    if (data && data.success && !collectInfo?.id) {
      getNum(data.data);
    }
  }, [data]);


  const changeShow = (index: number) => {
    let list = JSON.parse(JSON.stringify(propList));
    list[index].isShow = !list[index].isShow;
    setPropList([]);
    setPropList(list);
  };

  const changeFilter = (index: number, childindex: number) => {
    if(isLoad){
      return
    }
    let allList = JSON.parse(JSON.stringify(propList));
    allList[index].list[childindex].isCheck =
      !allList[index].list[childindex].isCheck;
    setCurrent(1);
    setPropList([]);
    setPropList(allList);
    getTraitList(allList);
  };
  const backToAll = () => {
    if(isLoad){
      return
    }
    setMaxPrice('')
    setMinPrice('')
    setFilterKey('')
    setIsbuynow(false)
    setIsMine(false)
    setCurrent(1)
  }
  return (
    <>
      <WebMain>
        <MainBottom>
          {Number(clientWidth) < 801 ? (<>
            <FilterSearchTel>
              <SearchMTel>
                <SearchIcon src={searchIcon} />
                <SearchInput
                  id="email"
                  name="email"
                  type="text"
                  placeholder="Name or ID in the collection"
                  value={filterKey}
                  onChange={(e) => {
                    setFilterKey(e.target.value);
                  }}
                />
              </SearchMTel>
            </FilterSearchTel>
            <FilterSearchTel>
              <SearchRTel>
                <ListSelect
                  value={direct}
                  optionArr={["Low", "Height",]}
                  typeValues={["Low", "Height",]}
                  onChange={(val: string) => setDirect(val)}
                  size=""
                />
              </SearchRTel>
              <SearchRTel1
                onClick={() => {
                  setIsShowPopup(true); dispatch(setOpenMenu(true))
                }}
              >
                <ItemNumText>{total} object</ItemNumText>
                <FilterImg src={darkMode ? filterIconN : filterIcon} />
              </SearchRTel1>
            </FilterSearchTel>
            <ItemNumText>{total} object</ItemNumText></>) : (<>
              <FilterSearch>
                <SearchL
                  src={darkMode ? fliterIconN : fliterIcon}
                  onClick={() => {
                    setIsFilter(!isFilter);
                  }}
                />

                <SearchM>
                  <SearchIcon src={searchIcon} />
                  <SearchInput
                    id="email"
                    name="email"
                    type="text"
                    placeholder="Name or ID in the collection"
                    value={filterKey}
                    onChange={(e) => {
                      setFilterKey(e.target.value);
                      setCurrent(1);
                    }}
                  />
                </SearchM>
                <SearchR>
                  <ListSelect
                    value={direct}
                    optionArr={["Low", "Height",]}
                    typeValues={["Low", "Height",]}
                    onChange={(val: string) => setDirect(val)}
                    size=""
                  />
                </SearchR>
              </FilterSearch></>)}

          <MainBB>
            {Number(clientWidth) < 801 ? null : (<MainBBl isFilter={isFilter}>
              <LeftSearchTitle>Status</LeftSearchTitle>
              <BtnRow>
                <BtnOne2 isAct={!isbuynow} onClick={() => { setCurrent(1); setIsbuynow(false) }}>All</BtnOne2>
                <BtnOne2 isAct={isbuynow} onClick={() => { setCurrent(1); setIsbuynow(true) }}>Buy now</BtnOne2>
              </BtnRow>
              <LeftSearchTitle>Range</LeftSearchTitle>
              <BtnRow>
                <BtnOne3>
                  <BtnText>                  {collectInfo?.payment_tokens?.[0]?.symbol || 'ETH'}
                  </BtnText>
                </BtnOne3>
                <BtnOne3>
                  <Input
                    id="email"
                    name="email"
                    type="number"
                    placeholder="min"
                    value={minPrice}
                    sx={{
                      outline: "none",
                      width: "calc(100% + 23px)",
                      height: "40px",
                      border: "none",
                      textAlign: "left",
                    }}
                    onChange={(e) => {
                      setMinPrice(e.target.value);
                    }}
                  />
                </BtnOne3>
                <div>To</div>
                <BtnOne3>
                  <Input
                    id="email"
                    name="email"
                    type="number"
                    placeholder="max"
                    value={maxPrice}
                    sx={{
                      outline: "none",
                      width: "calc(100% + 23px)",
                      height: "40px",
                      border: "none",
                      textAlign: "left",
                    }}
                    onChange={(e) => {
                      setMaxPrice(e.target.value);
                    }}
                  />
                </BtnOne3>
              </BtnRow>
              <BtnRow>
                <BtnOne1 isAct={minPrice && maxPrice ? true : false} onClick={() => { setCurrent(1); setPriceChange(!priceChange) }}>Apply</BtnOne1>
              </BtnRow>
              <LeftSearchTitle>Owners</LeftSearchTitle>
              <BtnRow>
                <BtnOne2 isAct={!isMine} onClick={() => { setCurrent(1); setIsMine(false) }}>All</BtnOne2>
                <BtnOne2 isAct={isMine} onClick={() => { setCurrent(1); setIsMine(true) }}>Mine</BtnOne2>
              </BtnRow>
              {propList && propList?.length > 0 ? <LeftSearchTitle>Properties</LeftSearchTitle> : null}

              {propList?.map((i: any, index) => (
                <PropGroup key={index} isAct={i.isShow}>
                  <PropTop
                    onClick={() => {
                      changeShow(index);
                    }}
                  >
                    <div>{i.name}</div>
                    <PropArrowImg
                      src={darkMode ? arrowUn : arrowU}
                      isAct={i.isShow}
                    />
                  </PropTop>
                  <PropMain>
                    {i.list.map((item: any, index1: number) => (
                      <PropItem
                        key={index1}
                        onClick={() => {
                          changeFilter(index, index1);
                        }}
                      >
                        <PropItemName>{item.name1}</PropItemName>
                        <PropItemRight>
                          <div>{item.num}</div>
                          <PropItemIcon
                            src={item.isCheck ? checkfilled : checknone}
                          />
                        </PropItemRight>
                      </PropItem>
                    ))}
                  </PropMain>
                </PropGroup>
              ))}
            </MainBBl>)}

            <MainBbr isFilter={isFilter}>
              {!isLoad && itemsList?.map((i: Item, index: number) => (
                <MainItem className="main-item"
                  key={index}
                  onClick={() =>
                    navigate(
                      `/nfts/assets/${i.chain_identifier}/${i.contract_address}/${i.token_id}`
                    )
                  }
                >
                  <MainItemBlock><MainItemImg src={i.image_url || nftDefault} alt="" /></MainItemBlock>

                  <MainItemInfo className={i.current_price ? "item-bottom-none" : ''} style={{ position: "relative", padding: '0' }}></MainItemInfo>
                  <MainItemInfo className={i.current_price ? "item-bottom" : ''} style={i.current_price ? { padding: '0' } : { padding: '0', bottom: '0', justifyContent: 'flex-start' }}>
                    <InfoTop style={{ marginLeft: '5px', marginTop: '5px', marginRight: '5px' }}>
                      <div>{i.name ? i.name : collectInfo?.name || '--'}</div>
                      {/* <ItemTopImg src={attestationIcon} alt="" /> */}
                    </InfoTop>

                    <InfoLine style={{ marginLeft: '5px', marginTop: '5px', marginBottom: '10px', marginRight: '5px', overflow: 'inherit' }}>#{i.token_id ? i.token_id.length > 10 ? i.token_id.substring(0, 10) + '...' : i.token_id : "--"}</InfoLine>
                    <InfoBottom style={i.name ? { marginLeft: '5px', marginBottom: '5px', marginRight: '5px' } : { marginLeft: '5px', marginBottom: '5px', marginRight: '5px' }}>

                      {i?.current_price?.currency?.image_url ? <ItemBottomImg
                        src={darkMode ? ethBlickN : ethBlick}
                        alt=""
                        style={i.current_price ? {} : { opacity: '0' }}
                      /> : null}
                      <div>
                        <span>
                          {i.current_price && i.current_price.currency ? priceText(i.current_price) : '--'}
                        </span>
                      </div>
                    </InfoBottom>

                    {i.current_price ? (<InfoBottomBuy className="buy-btn">
                      Buy
                    </InfoBottomBuy>) : ''}
                  </MainItemInfo>
                </MainItem>
              ))}
              {isLoad ? list.map(() => <MainItemLoad></MainItemLoad>) : ""}
              {!isLoad && total < 1 ? (
                <NoData onChange={() => backToAll()}></NoData>


              ) : ('')}
              <MainItemNone></MainItemNone>
              <MainItemNone></MainItemNone>
              <MainItemNone></MainItemNone>
              <Paging value={{total:total, current:current, pagesize:20, isLoad:isLoad}} onChangeCurrent={(val) => setCurrent(val)}/>
              
            </MainBbr>
          </MainBB>
        </MainBottom>
      </WebMain>
      <PopupView isShow={isShowPopup}>
        <PopupMain style={{ width: clientWidth }}>
          <TitleLine>
            <TitleLineL></TitleLineL>
            <TitleLineC>Select</TitleLineC>
            <TitleLineR
              src={darkMode ? closeIconN : closeIcon}
              onClick={() => {
                setIsShowPopup(false); dispatch(setOpenMenu(false))
              }}
            />
          </TitleLine>
          <MainGroup>
            <LeftSearchTitle>Status</LeftSearchTitle>
            <BtnRow onClick={() => setIsShowPopup(false)}>
              <BtnOne2 isAct={!isbuynow} onClick={() => { setCurrent(1); setIsbuynow(false) }}>All</BtnOne2>
              <BtnOne2 isAct={isbuynow} onClick={() => { setCurrent(1); setIsbuynow(true) }}>Buy now</BtnOne2>
            </BtnRow>
            <LeftSearchTitle>Range</LeftSearchTitle>
            <BtnRow>
              <BtnOne3>                  {collectInfo?.payment_tokens?.[0]?.symbol || 'ETH'}
              </BtnOne3>
              <BtnOne3>
                <Input
                  id="email"
                  name="email"
                  type="number"
                  placeholder="min"
                  value={minPrice}
                  sx={{
                    outline: "none",
                    width: "calc(100% + 23px)",
                    height: "40px",
                    border: "none",
                    textAlign: "left",
                  }}
                  onChange={(e) => {
                    setMinPrice(e.target.value);
                  }}
                />
              </BtnOne3>
              <div>To</div>
              <BtnOne3>
                <Input
                  id="email"
                  name="email"
                  type="number"
                  placeholder="max"
                  value={maxPrice}
                  sx={{
                    outline: "none",
                    width: "calc(100% + 23px)",
                    height: "40px",
                    border: "none",
                    textAlign: "left",
                  }}
                  onChange={(e) => {
                    setMaxPrice(e.target.value);
                  }}
                />
              </BtnOne3>
            </BtnRow>
          </MainGroup>
          <MainBtns>
            <ReselectionBtn onClick={() => {
              setCurrent(1); setMaxPrice(''); setMinPrice(''); setPriceChange(!priceChange); setIsShowPopup(false)
            }}>Reselection</ReselectionBtn>
            <ApplyBtn
              onClick={() => {
                setCurrent(1); setPriceChange(!priceChange); setIsShowPopup(false)
              }}
            >
              Apply
            </ApplyBtn>
          </MainBtns>
        </PopupMain>
      </PopupView>
    </>
  );
}
