import { Connection } from '../connection/types'
import { atom } from 'jotai'
import {
  useWeb3React
} from "@web3-react/core";
import { useAtomValue, useSetAtom } from 'jotai'
import { useCallback } from 'react'
import { useLocation } from 'react-router-dom'
import { useAppDispatch } from '../state/hooks'
import { updateSelectedWallet,selectAccounts } from '../state/user/reducer'
import { getCurrentPageFromLocation } from '../utils/urlRoutes'
import { useAccount, useRefAddress } from "../state/user/hooks";
import { v1 as uuidv1 } from "uuid";
import { useLoginMutation } from "../apis/account";
import { didUserReject } from './utils'

export enum ActivationStatus {
  PENDING,
  ERROR,
  IDLE,
}

type ActivationPendingState = { status: ActivationStatus.PENDING; connection: Connection }
type ActivationErrorState = { status: ActivationStatus.ERROR; connection: Connection; error: any }
const IDLE_ACTIVATION_STATE = { status: ActivationStatus.IDLE } as const
type ActivationState = ActivationPendingState | ActivationErrorState | typeof IDLE_ACTIVATION_STATE

const activationStateAtom = atom<ActivationState>(IDLE_ACTIVATION_STATE)

function useTryActivation() {
  const dispatch = useAppDispatch()
  const [login] = useLoginMutation();
  const ref=useRefAddress()
  const { account,provider } = useWeb3React();
  const setActivationState = useSetAtom(activationStateAtom)
  const { pathname } = useLocation()
  const currentPage = getCurrentPageFromLocation(pathname)
  const [acc, setAccount] = useAccount();
  return useCallback(
    async (connection: Connection, onSuccess: () => void) => {
      // Skips wallet connection if the connection should override the default
      // behavior, i.e. install MetaMask or launch Coinbase app
      if (connection.overrideActivate?.()) return
      try {

        setActivationState({ status: ActivationStatus.PENDING, connection })

        console.log(`Connection activating: ${connection.getName()}`)
        await connection.connector.activate()
        
    

        console.log(`Connection activated: ${connection.getName()}`)
   
       console.log('account',account)
        dispatch(updateSelectedWallet({ wallet: connection.type }))
        if(account&&!acc){
          console.log('qianming')
          var nonce = uuidv1();
          var msg = `Welcome to Zooker!\n\nClick to sign in \n\nThis request will not trigger a blockchain transaction or cost any gas fees.\n\nYour authentication status will reset after 24 hours.\n\nWallet address:\n${account}\n\nNonce:\n${nonce}`;
          const fun = async () => {
            const result = await provider?.getSigner(account).signMessage(msg);
            let signaturestr = result ? result : "";
            const { data } = await login({
              msg: msg,
              signature: signaturestr,
              ref: ref,
            }).unwrap();
            setAccount(data);
            console.log(JSON.stringify(data));
          };
          fun()
        }
        // Clears pending connection state
        setActivationState(IDLE_ACTIVATION_STATE)

        onSuccess()
      } catch (error) {
        // Gracefully handles errors from the user rejecting a connection attempt
        if (didUserReject(connection, error)) {
          setActivationState(IDLE_ACTIVATION_STATE)
          return
        }

        // TODO(WEB-3162): re-add special treatment for already-pending injected errors & move debug to after didUserReject() check
        console.debug(`Connection failed: ${connection.getName()}`)
        console.error(error)

        // Failed Connection events are logged here, while successful ones are logged by Web3Provider
        // sendAnalyticsEvent(InterfaceEventName.WALLET_CONNECT_TXN_COMPLETED, {
        //   result: WalletConnectionResult.FAILED,
        //   wallet_type: connection.getName(),
        //   page: currentPage,
        // })
        setActivationState({ status: ActivationStatus.ERROR, connection, error })
      }
    },
    [currentPage, dispatch, setActivationState]
  )
}

function useCancelActivation() {
  const setActivationState = useSetAtom(activationStateAtom)
  return useCallback(
    () =>
      setActivationState((activationState) => {
        if (activationState.status !== ActivationStatus.IDLE) activationState.connection.connector.deactivate?.()
        return IDLE_ACTIVATION_STATE
      }),
    [setActivationState]
  )
}

export function useActivationState() {
  const activationState = useAtomValue(activationStateAtom)
  const tryActivation = useTryActivation()
  const cancelActivation = useCancelActivation()

  return { activationState, tryActivation, cancelActivation }
}