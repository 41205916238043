import Paging from "components/Paging";
import styled from "styled-components/macro";

import { useWeb3React } from "@web3-react/core";
import {
  useActivityListMutation,
  useCollectionInfoQuery
} from "apis/collection";
import { ButtonPurple, ButtonTransparent } from "components/Button";
import ListSelect from "components/ListSelect";
import NoData from "components/NoData";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useClientWidth } from "state/application/hooks";
import { setOpenMenu } from "state/application/reducer";
import { useAppDispatch } from "state/hooks";
import { timestampToTime } from "utils";
import { ActivityLoadRow, ActivityRow, ActivityTitleRow } from "../TableRow";

import { useGetChainQuery } from "apis/chain";
import fliterIcon from "assets/fliter_icon.png";
import fliterIconN from "assets/fliter_icon_n.png";
import arrowU from "assets/tool/arrow_icon.png";
import arrowUn from "assets/tool/arrow_iconN.png";
import checkfilled from "assets/tool/check_filled.png";
import checknone from "assets/tool/check_none.png";
import closeIcon from "assets/tool/close_icon.png";
import closeIconN from "assets/tool/close_icon_n.png";
import filterIcon from "assets/tool/filter_icon.png";
import filterIconN from "assets/tool/filter_icon_n.png";
import searchIcon from "assets/tool/search_icon.png";
import "pages/index.scss";
import { CardTableMain } from "pages/Nft/NftDetail/components";
import { useDarkModeManager } from "theme/components/ThemeToggle";
import {
  ApplyBtn,
  CloseImg,
  FilterImg, FilterSearch, FilterSearchTel,
  FlexBlock,
  ItemNumText, LeftSearchTitle, MainBB, MainBBl,
  MainBbr,
  MainBottom, MainBtns, MainGroup, PopupMain, PopupView, PropArrowImg, PropGroup, PropItem,
  PropItemIcon, PropItemName, PropItemRight, PropMain, PropTop, ReselectionBtn, SearchIcon, SearchInput, SearchL, SearchM, SearchMTel, SearchR, SearchRTel, SearchRTel1, TitleLine, TitleLineC, TitleLineL, TitleLineR, WebMain
} from "../../components_";

const CardMain = styled.div`
border-top: 1px solid ${({ theme }) => theme.border1};
padding: 0;
width: 100%;
height:auto;
overflow: hidden;
`;

interface statusItem { name: string, checked: boolean, type?: string }

export default function NftActivityList() {
  const dispatch = useAppDispatch()

  const { account } = useWeb3React();
  const clientWidth = useClientWidth();
  const { chain, contract_address } = useParams();
  const [darkMode] = useDarkModeManager();
  const [collectInfo, setCollectInfo] = useState<CollectionInfo | null>();
  const [getActivityList] = useActivityListMutation();

  const [chainCurrency, setChainCurrency] = useState<ChainInfo | null>();
  const [filterKey, setFilterKey] = useState("");
  const [isFilter, setIsFilter] = useState(true);
  const [isShowPopup, setIsShowPopup] = useState(false);

  const [showIndex, setShowIndex] = useState(-1);
  const [total, setTotal] = useState(-1);
  const [current, setCurrent] = useState(1);
  const [traitValues, setTraitValues] = useState([]);
  const [isLoad, setIsLoad] = useState(false);
  const [activityList, setActivityList] = useState<ActivityItem[]>([]);
  const [time, setTime] = useState<string>("");
  const [fliterList, setFliterList] = useState<statusItem[]>([{ name: 'Clear all', checked: false }]);

  const [statusList, setStatusList] = useState<statusItem[]>([{ name: 'All', checked: true }, { name: 'Sale', checked: false }, { name: 'Listing', checked: false }, { name: 'Transfer', checked: false },]);

  const list = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  const [propList, setPropList] = useState<{
    name: string,
    isShow: boolean,
    list: { name1: string, num: number, isCheck: boolean }[]
  }[]>([]);
  const currencydata = useGetChainQuery({
    chain: chain as string,
  });
  const getTraitList = (arr: Array<{}>) => {
    let allList = arr ? arr : JSON.parse(JSON.stringify(propList));
    let traitTemp: any = [];
    allList.forEach((el: any) => {
      let itemObj = {
        name: el.name,
        values: ["none"],
      };
      el.list.forEach((childEl: any) => {
        if (childEl.isCheck) {
          if (itemObj.values[0] == "none") {
            itemObj.values[0] = childEl.name1;
          } else {
            itemObj.values.push(childEl.name1);
          }
        }
      });
      traitTemp.push(itemObj);
    });
    let traitTemp1: any = [];
    traitTemp.forEach((el: any, index: number) => {
      if (el.values[0] !== "none") {
        traitTemp1.push(el);
      }
    });
    setTraitValues(traitTemp1);
  };
  const getActivityListFun = async () => {
    if (isLoad) {
      return
    }
    setIsLoad(true)
    var filterTypesString = ""
    statusList.forEach((e) => {
      if (e.name === "All" && e.checked === true) {
        filterTypesString = ""
        return ""
      }
      if (e.checked === true) {
        filterTypesString = filterTypesString + "," + e.name
      }
    })
    if (filterTypesString.length > 0) {
      filterTypesString = filterTypesString.substring(1,)
    }

    const { data, success, total } = await getActivityList({
      chain: chain,
      address: contract_address,
      pageSize: 10,
      type: filterTypesString,
      current: current,
    }).unwrap();
    if (success) {
      let now = new Date().getTime();
      // data
      let list = data?.map((q: ActivityItem) => Object.assign({}, q));
      list?.map((el) => {
        let str = timestampToTime(Number(el.timestamp) || 0);
        if ((Number(el.timestamp) * 1000) > now) {
          let time = (Number(el.timestamp) * 1000) - now;
          if (Math.ceil(time / 86400000) > 29) {
            str = str.split(' ')[0]
          } else if (time / 86400000 < 1) {
            str = "in " + Math.ceil(time / 3600000) + " hours";
          } else {
            str = "in " + Math.ceil(time / 86400000) + " days";
          }
        } else {
          str = str.split(' ')[0]
        }

        el.timestr = str;
      });
      setTotal(total)
      // setActivityList([...activityList, ...list])
      setActivityList(list)
      setIsLoad(false)
    } else {
      setIsLoad(false)
    }
  };

  const getNum = (item: CollectionInfo) => {
    if (item.traits) {
      let obj: any = item.traits;
      let list: any = [];
      for (let key in obj) {
        let el = {
          name: key,
          isShow: false,
          list: [{ name1: "default", num: 1, isCheck: true }],
        };
        for (let childKey in obj[key]) {
          if (el.list[0].name1 == "default") {
            el.list[0] = {
              name1: childKey,
              num: obj[key][childKey],
              isCheck: false,
            };
          } else {
            el.list.push({
              name1: childKey,
              num: obj[key][childKey],
              isCheck: false,
            });
          }
        }
        list.push(el);
      }
      setPropList(list);
    }
    setCollectInfo(item);
  };


  useEffect(() => {

    if (!isLoad) {
      if (current === 1) {
        setActivityList([])
      }
      getActivityListFun()
    }

  }, [current, filterKey, fliterList, time]);

  const { data } = useCollectionInfoQuery({
    chain: chain as string,
    contract_address: contract_address as string,
  });


  useEffect(() => {
    if (currencydata && currencydata.data?.success) {
      if (currencydata?.data?.data) {
        setChainCurrency(currencydata?.data.data);
      }
    }
  }, [currencydata]);




  useEffect(() => {
    if (data && data.success && !collectInfo?.id) {
      getNum(data.data);
    }
  }, [data]);

  // const useFn = () => {
  //   let onPullUpHeight =
  //     window.pageYOffset ||
  //     document.body.scrollTop ||
  //     document.documentElement.scrollTop;
  //   let documentHeight = window.innerHeight;
  //   if (!isLoad) {

  //     if (onPullUpHeight > (1600 * current - 400)) {
  //       if (activityList?.length && activityList?.length < total) {
  //         setCurrent(current + 1);
  //       }
  //     }
  //   }

  // };
  // useEffect(() => {
  //   // 开启侦听器,监听页面滚动
  //   window.addEventListener("scroll", useFn);

  //   // 组件销毁时移除侦听器
  //   return () => {
  //     window.removeEventListener("scroll", useFn);
  //   };
  // });

  const changeFilterList = (index: number) => {
    let temp = [...fliterList]
    let tempStatus = [...statusList]
    let tempTraits = [...propList]
    if (temp[index].checked) {
      if (temp[index].type === 'status') {
        let checknum = 0
        tempStatus.forEach(el => {
          if (el.name === temp[index].name) {
            el.checked = false
          }
          if (el.checked) {
            checknum++
          }
        });
        if (checknum < 1) {
          tempStatus[0].checked = true
        }
        setStatusList(tempStatus)
      } else {//Traits
        let name = temp[index].name.split('：')[0]
        let name1 = temp[index].name.split('：')[1]
        tempTraits.forEach(el => {
          if (el.name === name) {
            el.list.forEach(item => {
              if (item.name1 === name1) {
                item.isCheck = false
              }
            })
          }
        })
        setPropList(tempTraits)
      }
    } else {//clear all
      tempStatus.forEach(el => {
        el.checked = false
      });
      tempStatus[0].checked = true
      setStatusList(tempStatus)
      tempTraits.forEach(el => {
        if (el.list) {
          el.list.forEach(item => {
            item.isCheck = false
          })
        }
      })
      setPropList(tempTraits)
    }
  };
  const changeStatus = (index: number) => {
    let temp = [...statusList]
    if (temp[index].name == 'All') {
      if (!temp[index].checked) {
        temp.forEach(el => {
          el.checked = false
        });
        temp[index].checked = true
      }
    } else {
      temp[0].checked = false
      temp[index].checked = !temp[index].checked
    }
    setStatusList(temp)
  };
  useEffect(() => {
    let temp: statusItem[] = [{ name: 'Clear all', checked: false }]
    if (!statusList[0].checked) {
      statusList.forEach(el => {
        if (el.checked) {
          temp.unshift({ name: el.name, checked: true, type: 'status' })
        }
      })
    }
    if (propList) {

      propList.forEach(el => {
        if (el.list) {
          el.list.forEach(item => {
            if (item.isCheck) {
              temp.unshift({ name: el.name + '：' + item.name1, checked: true, type: 'Traits' })
            }
          })
        }
      })
    }
    setFliterList(temp)
  }, [statusList, propList]);
  const changeShow = (index: number) => {
    let list = JSON.parse(JSON.stringify(propList));
    list[index].isShow = !list[index].isShow;
    setPropList([]);
    setPropList(list);
  };

  const changeFilter = (index: number, childindex: number) => {
    let allList = JSON.parse(JSON.stringify(propList));
    allList[index].list[childindex].isCheck =
      !allList[index].list[childindex].isCheck;
    setCurrent(1);
    setPropList([]);
    setPropList(allList);
    getTraitList(allList);
  };
  const rowChange = (val: number) => {
    if (val === showIndex) {
      setShowIndex(-1)
    } else {
      setShowIndex(val)
    }

  };

  const backToAll = () => {
    let tempStatus = [...statusList]
    let tempTraits = [...propList]
    tempStatus.forEach(el => {
      el.checked = false
    });
    tempStatus[0].checked = true
    setStatusList(tempStatus)
    tempTraits.forEach(el => {
      if (el.list) {
        el.list.forEach(item => {
          item.isCheck = false
        })
      }
    })
    setPropList(tempTraits)
    setFilterKey('')
    setCurrent(1)

  }
  return (
    <>
      <WebMain style={{ paddingBottom: '0' }}>
        <MainBottom>
          {Number(clientWidth) < 801 ? (<>
            <FilterSearchTel>
              <SearchMTel>
                <SearchIcon src={searchIcon} />
                <SearchInput
                  id="email"
                  name="email"
                  type="text"
                  placeholder="Name or ID in the collection"
                  value={filterKey}
                  onChange={(e) => {
                    setFilterKey(e.target.value);
                  }}
                />
              </SearchMTel>
            </FilterSearchTel>
            <FilterSearchTel>
              <SearchRTel>

                <ListSelect
                  value={time}
                  optionArr={["All time", "Last hour", "Last 6 hour", "Last 24 hour", "Last 7 days",]}
                  typeValues={["", "Last hour", "Last 6 hour", "Last 24 hour", "Last 7 days",]}
                  onChange={(value: string) => {
                    setTime(value);
                  }}
                  size=""
                />
              </SearchRTel>
              <SearchRTel1
                onClick={() => {
                  setIsShowPopup(true); dispatch(setOpenMenu(true))
                }}
              >
                <ItemNumText>{total} object</ItemNumText>
                <FilterImg src={darkMode ? filterIconN : filterIcon} />
              </SearchRTel1>
            </FilterSearchTel>
            {/* <ItemNumText>{total} object</ItemNumText> */}
          </>) : (<>
            <FilterSearch>
              <SearchL
                src={darkMode ? fliterIconN : fliterIcon}
                onClick={() => {
                  setIsFilter(!isFilter);
                }}
              />

              <SearchM>
                <SearchIcon src={searchIcon} />
                <SearchInput
                  id="email"
                  name="email"
                  type="text"
                  placeholder="Name or ID in the collection"
                  value={filterKey}
                  onChange={(e) => {
                    setFilterKey(e.target.value);
                    setCurrent(1);
                  }}
                />
              </SearchM>
              <SearchR>
                <ListSelect
                  value={time}
                  optionArr={["All time", "Last hour", "Last 6 hour", "Last 24 hour", "Last 7 days",]}
                  typeValues={["", "Last hour", "Last 6 hour", "Last 24 hour", "Last 7 days",]}
                  onChange={(value: string) => {
                    setTime(value);
                  }}
                  size=""
                />
              </SearchR>
            </FilterSearch></>)}

          <MainBB>
            {Number(clientWidth) < 801 ? null : (<MainBBl isFilter={isFilter} style={isFilter?{minWidth:'170px'}:{}}>
              <LeftSearchTitle>Event Type</LeftSearchTitle>
              <FlexBlock>
                {statusList.map((i: statusItem, index) => (
                  i.checked ? <ButtonPurple
                    padding="10px 20px"
                    $borderRadius="8px"
                    width="auto"
                    margin="0 20px 10px 0"
                    onClick={() => changeStatus(index)}
                  >
                    {i.name}
                  </ButtonPurple> :
                    <ButtonTransparent
                      padding="10px 20px"
                      $borderRadius="8px"
                      width="auto"
                      margin="0 20px 10px 0"
                      onClick={() => changeStatus(index)}
                    >
                      {i.name}
                    </ButtonTransparent>))}
              </FlexBlock>
              {propList && propList.length > 0 ? (<LeftSearchTitle>Traits</LeftSearchTitle>) : null}

              {propList?.map((i: any, index) => (
                <PropGroup key={index} isAct={i.isShow}>
                  <PropTop
                    onClick={() => {
                      changeShow(index);
                    }}
                  >
                    <div>{i.name}</div>
                    <PropArrowImg
                      src={darkMode ? arrowUn : arrowU}
                      isAct={i.isShow}
                    />
                  </PropTop>
                  <PropMain>
                    {i.list.map((item: any, index1: number) => (
                      <PropItem
                        key={index1}
                        onClick={() => {
                          changeFilter(index, index1);
                        }}
                      >
                        <PropItemName>{item.name1}</PropItemName>
                        <PropItemRight>
                          <div>{item.num}</div>
                          <PropItemIcon
                            src={item.isCheck ? checkfilled : checknone}
                          />
                        </PropItemRight>
                      </PropItem>
                    ))}
                  </PropMain>
                </PropGroup>
              ))}
            </MainBBl>)}

            <MainBbr isFilter={isFilter} style={isFilter?{width:'calc(100% - 190px)'}:{}}>
              {fliterList.length !== 1 ? (
                <FlexBlock>
                  {fliterList.map((i: statusItem, index) => (
                    <ButtonTransparent
                      padding="10px 20px"
                      $borderRadius="8px"
                      width="auto"
                      margin="0 20px 10px 0"
                      onClick={() => changeFilterList(index)}
                    >
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div> {i.name}</div> {i.checked ? (<CloseImg
                          src={darkMode ? closeIconN : closeIcon}
                          onClick={() => {
                            setIsShowPopup(false); dispatch(setOpenMenu(false))
                          }}
                        />) : null}</div>
                    </ButtonTransparent>
                  ))}</FlexBlock>) : null}
              <CardMain style={{ border: 'none' }}>

                <ActivityTitleRow />

                {
                  isLoad ? list?.map((i: number, index: number) => (
                    <ActivityLoadRow />
                  )) :
                    activityList && activityList.length > 0 ? (
                      <>

                        <CardTableMain id='actmain'>
                          {activityList?.map((i: ActivityItem, index: number) => (
                            i && chainCurrency ? (<ActivityRow i={i} index={index} showIndex={showIndex} chainCurrency={chainCurrency} collectInfo={collectInfo} onChange={(val) => rowChange(val)}></ActivityRow>
                            ) : null
                          ))}

                        </CardTableMain></>
                    ) :
                      total < 1 && total !== -1 ? <NoData onChange={() => backToAll()}></NoData> : null
                }

              </CardMain>
              <Paging value={{ total: total, current: current, pagesize:10, isLoad:isLoad }} onChangeCurrent={(val) => setCurrent(val)} />

          

            </MainBbr>
          </MainBB>
        </MainBottom>
      </WebMain >
      <PopupView isShow={isShowPopup}>
        <PopupMain style={{ width: clientWidth }}>
          <TitleLine>
            <TitleLineL></TitleLineL>
            <TitleLineC>Select</TitleLineC>
            <TitleLineR
              src={darkMode ? closeIconN : closeIcon}
              onClick={() => {
                setIsShowPopup(false); dispatch(setOpenMenu(false))
              }}
            />
          </TitleLine>
          <MainGroup>
            <LeftSearchTitle>Event Type</LeftSearchTitle>
            <FlexBlock>
              {statusList.map((i: statusItem, index) => (
                i.checked ? <ButtonPurple
                  padding="10px 20px"
                  $borderRadius="8px"
                  width="auto"
                  margin="0 20px 10px 0"
                  onClick={() => changeStatus(index)}
                >
                  {i.name}
                </ButtonPurple> :
                  <ButtonTransparent
                    padding="10px 20px"
                    $borderRadius="8px"
                    width="auto"
                    margin="0 20px 10px 0"
                    onClick={() => changeStatus(index)}
                  >
                    {i.name}
                  </ButtonTransparent>))}
            </FlexBlock>
            {propList && propList.length > 0 ? (<LeftSearchTitle>Traits</LeftSearchTitle>) : null}

            {propList?.map((i: any, index) => (
              <PropGroup key={index} isAct={i.isShow}>
                <PropTop
                  onClick={() => {
                    changeShow(index);
                  }}
                >
                  <div>{i.name}</div>
                  <PropArrowImg
                    src={darkMode ? arrowUn : arrowU}
                    isAct={i.isShow}
                  />
                </PropTop>
                <PropMain>
                  {i.list.map((item: any, index1: number) => (
                    <PropItem
                      key={index1}
                      onClick={() => {
                        changeFilter(index, index1);
                      }}
                    >
                      <PropItemName>{item.name1}</PropItemName>
                      <PropItemRight>
                        <div>{item.num}</div>
                        <PropItemIcon
                          src={item.isCheck ? checkfilled : checknone}
                        />
                      </PropItemRight>
                    </PropItem>
                  ))}
                </PropMain>
              </PropGroup>
            ))}
          </MainGroup>
          <MainBtns style={{ opacity: '0' }}>
            <ReselectionBtn >Reselection</ReselectionBtn>
            <ApplyBtn >
              Apply
            </ApplyBtn>
          </MainBtns>
        </PopupMain>
      </PopupView>
    </>
  );
}
