import { keccak256 } from "ethers/lib/utils";
import { MerkleTree } from "merkletreejs";
import { Buffer } from 'buffer';

import {

  BigNumber,
  BigNumberish,

} from "ethers";

export type PromiseOrValue<T> = T | Promise<T>;
export type MintParamsStruct = {
  mintPrice: PromiseOrValue<BigNumberish>;
  maxTotalMintableByWallet: PromiseOrValue<BigNumberish>;
  startTime: PromiseOrValue<BigNumberish>;
  endTime: PromiseOrValue<BigNumberish>;
  dropStageIndex: PromiseOrValue<BigNumberish>;
  maxTokenSupplyForStage: PromiseOrValue<BigNumberish>;
  feeBps: PromiseOrValue<BigNumberish>;
  restrictFeeRecipients: PromiseOrValue<boolean>;
}

export const createMerkleTree = (leaves: Buffer[]) =>
  new MerkleTree(leaves, keccak256, {
    hashLeaves: true,
    sortLeaves: true,
    sortPairs: true,
  });

const toPaddedBuffer = (data: any) =>
  Buffer.from(
    BigNumber.from(data).toHexString().slice(2).padStart(64, "0"),
    "hex"
  );

export const allowListElementsBuffer = (
  leaves: Array<[minter: string, mintParams: MintParamsStruct]>
) =>
  leaves.map(([minter, mintParams]) =>
    Buffer.concat(
      [
        minter,
        mintParams.mintPrice,
        mintParams.maxTotalMintableByWallet,
        mintParams.startTime,
        mintParams.endTime,
        mintParams.dropStageIndex,
        mintParams.maxTokenSupplyForStage,
        mintParams.feeBps,
        mintParams.restrictFeeRecipients ? 1 : 0,
      ].map(toPaddedBuffer)
    )
  );


  export const createLeaf = (minter: string, mintParams: MintParamsStruct) =>{
    const leaf= Buffer.concat(
      [
        minter,
        mintParams.mintPrice,
        mintParams.maxTotalMintableByWallet,
        mintParams.startTime,
        mintParams.endTime,
        mintParams.dropStageIndex,
        mintParams.maxTokenSupplyForStage,
        mintParams.feeBps,
        mintParams.restrictFeeRecipients ? 1 : 0,
      ].map(toPaddedBuffer)
    )
    return keccak256(leaf)
  }