import {  useNavigate } from "react-router-dom";

import testFlight from "../../assets/h5/test_flight.png";
import downApk from "../../assets/down_apk.png";
import mainImg6 from "../../assets/main_img6.png";
import mainImg6N from "../../assets/main_img6_n.png";
import mainImg1 from "../../assets/main_img1.png";
import flexImg1 from "../../assets/flex_img1.png";
import flexImg2 from "../../assets/flex_img2.png";
import startImg from "../../assets/start.png";

import "./index.scss";
import { useDarkModeManager } from "theme/components/ThemeToggle";
export default function Home() {
    let navigate = useNavigate();
    const [darkMode] = useDarkModeManager();
    console.log('darkMode',darkMode)
    return (<>
     <div className={darkMode?"home-page home-page-n":"home-page"}>
      <div className="top-main">
        <div className="top-left">
          <div className="top-text">Access to web3 crypto wallets</div>
          <div className="top-sub-text">
            Support multiple blockchains, manage your Nft and defi and use one
            wallet to access all web3
          </div>
          <div className="top-btns">
            <div>
              <img src={testFlight} alt="" />
              <div>
                <div>Download on the</div>
                <div>Test Flight</div>
              </div>
            </div>
            <div>
              <img src={downApk} alt="" />
              <div>
                <div>Download APK</div>
                <div>Android</div>
              </div>
            </div>
          </div>
        </div>
        <div className="top-right"></div>
      </div>
      <div className="pg-main2">
        <div className="main-text">
          <div className="main-title"> Support Multi-Chain Wallet</div>
        </div>
        <div className="main-img">
          <div className="main-center">
            <div>
              <div className="purple-l"></div>
              <div className="purple-s"></div>
              <img src={mainImg1} alt="" />
            </div>
          </div>
          <img src={darkMode?mainImg6N:mainImg6} alt="" className="img6" />
        </div>
      </div>
      <div className="pg-main3">
        <div className="main-text">
          <div className="main-title">Mastering Crypto Wallets</div>

          <div className="main-sub-title">
            Explore the <span>web3</span> world
          </div>
        </div>
        <div className="main-flex">
          <div>
            <div className="item-left">
              <div className="item-title">Safe and Convenient</div>
              <div className="item-text">
                A non-custodial wallet that gives you full control over your
                private keys and seed phrase. You can access your assets using
                an encrypted private key, which is stored in a secure area on
                your device.
              </div>
            </div>
            <div className="item-img">
              <img src={flexImg1} alt="" />
            </div>
          </div>
          <div>
            <div className="item-img">
              <img src={flexImg2} alt="" />
            </div>
            <div className="item-right">
              <div className="item-title">NFT MARKET</div>
              <div className="item-text">
                Equipped with all-platform search capability to optimize your
                NFT investment journey. The aggregated multi chain NFT market
                allows users to make a purchase with any token.
              </div>
              <div className="item-btn">Try It Now</div>
            </div>
          </div>
        </div>
      </div>
      <div className="pg-main4">
      <div className="top-main4">
        <div className="top-right"></div>
          <div className="top-left">
            <div className="top-text">
           
            <div> Download application</div>
              <img src={startImg} alt="" />
              
            </div>
            <div className="top-sub-text">
            Download the application, get your encrypted wallet, and imagine the web3 world
            </div>
            <div className="top-btns">
              <div>
                <img src={testFlight} alt=""/>
                <div>
                  <div>Download on the</div>
                  <div>Test Flight</div>
                </div>
              </div>
              <div>
                <img src={downApk} alt=""/>
                <div>
                  <div>Download APK</div>
                  <div>Android</div>
                </div>
              </div>
            </div>
          </div>
         
       
      </div>
      </div>
    </div>
    </>)
}
