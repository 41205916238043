import RadixPopover from "components/Popover";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch } from "state/hooks";
import styled from "styled-components/macro";

import { useIsDarkMode } from "theme/components/ThemeToggle";
// import {Tradingview } from "components/Tradingview/Tradingview";
import { useAddWatchListMutation, useDelWatchOneMutation, useIsWatchingQuery } from "apis/ranking";
import { formatNumber,  NumberType } from "utils/formatNumbers";
import moreIcon from "assets/tool/more.png";
import {IconStar} from "components/Icons/Star"
import { WebMain } from "pages/Nft/NftItems/components_";
import {
  AccountTable,
  BlockBg,
  BtnImg,
  ChangeTag,
  ColLink,
  ColVal,
  CopyBlock,

  DataCol2,
  DataDesc,
  DataRow,
  DropDown,
  InfoBlock,
  MoreBlock,
  MoreIcon,
  OptionText,
  PageBg,
  PageTitle,
  TableCol,
  TableColLast,
  TableMain,
  TableRow,
  TableTitle,
  TableTitleItem,
  TableTitleItemLast,
  TextDesc,
  TextName,
  TitleItem,
  TitleLine,
  TopLeft,
  TopRight
} from "./components_";

import btnTwicon from "assets/btn_twicon.png";
import btnTwiconN from "assets/btn_twicon_n.png";
import addressIcon from "assets/nft/link1_icon.png";
import teleIcon from "assets/nft/link3_icon.png";
import copyIcon from "assets/nft_copy.png";

import { useTokenDetailQuery } from "apis/token";
import TokenAvatar from "components/TokenAvatar";
import {
  LARGE_MEDIA_BREAKPOINT,
  MIN_WIDTH_MEDIA_BREAKPOINT,
} from "constants/width";
import { useSelectChain } from "state/chain/hooks";

import TradeHistoryList from "./components/TradeHistoryList";
import { Box, Button } from "rebass";
import IconButton from "components/AccountDrawer/IconButton";
import { ButtonEmpty } from "components/Button";
import { useWeb3React } from "@web3-react/core";
import { useChainAccountQuery } from "apis/chain_account";
import { Token } from "core";

const BlockFlex = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    flex-wrap: wrap;
  }
`;

const InfoWrapper = styled(BlockFlex)`
  width: auto;
  > div {
    margin-right: 15px;
  }
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    margin: 5px 0;
  }
`;
const TokenData = styled(BlockBg)`
  width: 100%;
  padding: 0;
  min-height: 500px;
  overflow: hidden;
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    width: 100%;
  }
`;

 const DataCol = styled.div`
  color: ${({ theme }) => theme.text3};
  > div {
    font-size: 14px;
    max-width: 100%;
    overflow: hidden;
    // text-overflow: ellipsis; //溢出用省略号显示
    white-space: nowrap; //溢出不换行
    
    margin: 1px 0;
  }
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    width: 100%;
  }
`;
const TokenBtns = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    width: 100%;
    transform: scale(0.8);
    transform-origin: right;
    flex-direction: row-reverse;
  }
`;

const TokenDetail = () => {
  const { account } = useWeb3React();
  const isDarkMode = useIsDarkMode();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [delWatchOne] = useDelWatchOneMutation();

  const [addWatchList] = useAddWatchListMutation();

  const [openMore, setOpenMore] = useState(false);
  const [isWatching,setIsWatching]=useState(false)

  let { chain, address } = useParams();
  const [list] = useState([
    { account: "sdklfnmdgvkmsdgf232", val: "", amount: 0, symbol: "SQL" },
  ]);
  const [cardTab, setCardTab] = useState("info");
  const [tokenDetail, setTokenDetail] = useState<TokenInfo | null>(null);
  const [chainAccount, setChainAccount] = useState<ChainAccount | null>(null);

  const chainInfo = useSelectChain(chain ? chain : "");

  const {data:watchingData}=useIsWatchingQuery({chain:chain,contract_address:address,account:account})
  useEffect(()=>{
    if(watchingData){
      setIsWatching(watchingData.is_watching)
    }
  },[watchingData])

  const { data, isSuccess } = useTokenDetailQuery({
    chain: chain,
    address: address,
  });
  useEffect(() => {
    if (isSuccess && data.success) {
      setTokenDetail(data.data);
    }
  }, [isSuccess, data]);

  const { data:chainAccountData, isSuccess:isSuccessChainAccountQ } = useChainAccountQuery({
    chain: chain,
    address: address,
  });
  useEffect(() => {
    if (isSuccessChainAccountQ && chainAccountData.success) {
      setChainAccount(chainAccountData.data);
    }
  }, [isSuccessChainAccountQ, chainAccountData]);


  const handleWatching=async ({chain,address}:{chain:string,address:string})=>{
    if(isWatching){
      const res = await delWatchOne({
        chain: chain,
        address: address,
        type:'CRYPTO',
      }).unwrap();
      if(res.success){
        setIsWatching(false)
      } 
    }else{
      const res = await addWatchList({
        chain: chain,
        address: address,
        type:'CRYPTO',
      }).unwrap();
      if(res.success){
        setIsWatching(true)
      }
    }

  }

  return (
    <>
      <PageBg>
        <WebMain>
          <PageTitle>
            <div
              onClick={() => {
                navigate(-1);
              }}
            >
              cryptos
            </div>
            <div> /{tokenDetail?.symbol}</div>
          </PageTitle>
          <BlockBg>
            <BlockFlex>
              <TopLeft>
                {tokenDetail ? (
                  <TokenAvatar
                    size={36}
                    token={
                      new Token(tokenDetail.chain,tokenDetail.address,tokenDetail.decimals,tokenDetail.symbol,tokenDetail.name,tokenDetail.image_url)
                      }
                  />
                ) : null}
                <div style={{ marginLeft: "10px" }}>
                  <TextName>{tokenDetail?.symbol}</TextName>
                  <TextDesc>{tokenDetail?.name}</TextDesc>
                </div>
              </TopLeft>
              <TopRight>
                <InfoWrapper>
                  <div>
                    <TextDesc>change</TextDesc>
                    <TextName>
                      $ {formatNumber({input:tokenDetail?.usd_price,type:NumberType.SwapPrice})}
                      <ChangeTag>
                        {tokenDetail?.stats?.one_day_change}%
                      </ChangeTag>
                    </TextName>
                  </div>
                  <div>
                    <TextDesc>price</TextDesc>
                    <TextName>
                      {tokenDetail?.native_price}{" "}
                      {chainInfo && chainInfo.currency.symbol}
                    </TextName>
                  </div>
                </InfoWrapper>
                <TokenBtns>

               <ButtonEmpty onClick={()=>{
                  chain && address&& handleWatching({chain,address})
               }}><IconStar check={isWatching} size="16px"/></ButtonEmpty>
                  {/* <ButtonTransparent
                    padding="5px 10px"
                    margin="0 0 0 0"
                    $borderRadius="8px"
                    width="auto"
                    onClick={() => {}}
                  >
                    接收
                  </ButtonTransparent>
                  <ButtonTransparent
                    padding="5px 10px"
                    margin="0 20px 0 20px"
                    $borderRadius="8px"
                    width="auto"
                    onClick={() => {}}
                  >
                    发送
                  </ButtonTransparent>
                  <MoreBlock>
                    <MoreIcon
                      src={moreIcon}
                      onClick={() => {
                        setOpenMore(!openMore);
                      }}
                    />
                    <DropDown isopen={openMore}>
                      <OptionText selected={false} onClick={() => {}}>
                        外部网址
                      </OptionText>
                    </DropDown>
                  </MoreBlock> */}
                </TokenBtns>
              </TopRight>
            </BlockFlex>
          </BlockBg>
          <BlockFlex>
            <TokenData>
              <TitleLine>
                {/* <TitleItem
                  isAct={cardTab === "chart"}
                  onClick={() => setCardTab("chart")}
                >
                  图表
                </TitleItem> */}
                <TitleItem
                  isAct={cardTab === "info"}
                  onClick={() => setCardTab("info")}
                >
                  Info
                </TitleItem>
                {/* <TitleItem
                  isAct={cardTab === "holders"}
                  onClick={() => setCardTab("holders")}
                >
                  Holders
                </TitleItem> */}
                <TitleItem
                  isAct={cardTab === "limit_order"}
                  onClick={() => setCardTab("limit_order")}
                >
                  Limit Order
                </TitleItem>

                <TitleItem
                  isAct={cardTab === "trade_history"}
                  onClick={() => setCardTab("trade_history")}
                >
                  Trade History
                </TitleItem>
              </TitleLine>
              <AccountTable isAct={cardTab === "account"}>
                <TableTitle>
                  <TableTitleItem>你的账户</TableTitleItem>
                  <TableTitleItem>值</TableTitleItem>
                  <TableTitleItem>余额</TableTitleItem>
                  <TableTitleItemLast></TableTitleItemLast>
                </TableTitle>
                <TableMain>
                  {list?.map((i: any, index: number) => (
                    <TableRow>
                      <TableCol>
                        <RadixPopover
                          val={i.account}
                          label={
                            i.account.substring(0, 7) +
                            "..." +
                            i.account.substr(i.account.length - 5)
                          }
                        ></RadixPopover>
                      </TableCol>
                      <TableCol>{i.val}</TableCol>
                      <TableCol>
                        {i.amount} {i.symbol}
                      </TableCol>
                      <TableColLast>
                        <CopyBlock>
                          {" "}
                          <MoreIcon src={copyIcon} onClick={() => {}} />
                        </CopyBlock>
                        <MoreBlock>
                          <MoreIcon
                            src={moreIcon}
                            onClick={() => {
                              setOpenMore(!openMore);
                            }}
                          />
                          <DropDown isopen={openMore}>
                            <OptionText selected={false} onClick={() => {}}>
                              外部网址
                            </OptionText>
                            <OptionText
                              selected={false}
                              style={{ color: "#eb4b6d" }}
                              onClick={() => {}}
                            >
                              关闭账户
                            </OptionText>
                          </DropDown>
                        </MoreBlock>
                      </TableColLast>
                    </TableRow>
                  ))}
                </TableMain>
              </AccountTable>
              <InfoBlock isAct={cardTab === "info"}>
              <DataRow>
                  <DataCol>
                    <div>Network</div>
                    <ColVal>{tokenDetail?.chain}</ColVal>
                  </DataCol>
                </DataRow>
                <DataRow>
                  <DataCol>
                    <div>Contract Address</div>
                    <ColVal>{tokenDetail?.address}</ColVal>
                  </DataCol>
                </DataRow>
                <DataRow>
                  {/* <DataCol>
                    <div>Market Cap</div>
                    <ColVal>$ {tokenDetail?.fully_diluted_market_cap}</ColVal>
                  </DataCol> */}
                  <DataCol>
                    <div>Fully Diluted Market Cap</div>
                    <ColVal>$ {tokenDetail?.fully_diluted_market_cap}</ColVal>
                  </DataCol>
                  <DataCol>
                    <div>24h Trading Vol</div>
                    <ColVal>$ {tokenDetail?.stats?.one_day_volume}</ColVal>
                  </DataCol>
                  <DataCol>
                    <div>Total Supply</div>
                    <ColVal>{tokenDetail?.total_supply}</ColVal>
                  </DataCol>
                </DataRow>
                <DataRow>
                <DataCol>
                    <div>Swap Pairs Count</div>
                    <ColVal>{tokenDetail?.swap_pair_count}</ColVal>
                  </DataCol>
                  <DataCol>
                    <div>Liquidity</div>
                    <ColVal>{tokenDetail?.liquidity}</ColVal>
                  </DataCol>
                </DataRow>
                <DataDesc>
                  <TextDesc>Description</TextDesc>
                  <ColVal>{tokenDetail?.description}</ColVal>
                </DataDesc>
                <DataRow>
                  <DataCol2>
                    <div>Web Site</div>
                    <ColLink>
                      <div>
                        <BtnImg src={addressIcon} />
                        <div>{tokenDetail?.external_link}</div>
                      </div>
                    </ColLink>
                  </DataCol2>
                  <DataCol2>
                    <div>Community</div>
                    <ColLink>
                      <div>
                        <BtnImg src={isDarkMode ? btnTwiconN : btnTwicon} />
                        <div>Twitter</div>
                      </div>
                      <div>
                        <BtnImg src={teleIcon} />
                        <div>Telegram</div>
                      </div>
                    </ColLink>
                  </DataCol2>
                </DataRow>
              </InfoBlock>
              {/* Tradingview */}
              {/* <Tradingview></Tradingview> */}
              <InfoBlock isAct={cardTab === "trade_history"}>
              <TradeHistoryList chain={chain} address={address}/>
              </InfoBlock>
            </TokenData>
          </BlockFlex>
          {/* <MyTradingViewChart /> */}
        </WebMain>
      </PageBg>
    </>
  );
};

export default TokenDetail;
