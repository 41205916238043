import { useGetChainListQuery } from "apis/chain";
import { formatWithArray } from "utils";

import { ButtonPurple } from "components/Button";
import {
  PopCollectionLoadItem,
  TrendLoadItemTel,
} from "pages/Nft/components/loadCompents";
import {
  CollectInfoLogo,
  PopCollectionImg,
  PopCollectionImgBlock,
  PopCollectionItem,
  PopCollectionLine,
  PopCollectionList,
  PopCollectionName,
  TitleItem,
  TitleRight,
  TrendImgTel,
  TrendImgTelBlock,
  TrendIndex,
  TrendItemTel,
  TrendLine,
  TrendLineText,
  TrendLineText1,
  TrendListTel,
  TrendMainTel,
  TrendRight,
  TrendRightBottom,
  TrendRightTop
} from "pages/Nft/components_";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useHomeChain, useHomePeriod } from "state/stats/hooks";

import NoData from "components/NoData";

import { useClientWidth } from "state/application/hooks";

import { useTopListQuery } from "apis/ranking";

import nftDefault from "assets/nft/nft_defult.png";
import ChainSelect from "components/ChainSelect";
import ListSelect from "components/ListSelect";
import { useDarkModeManager } from "theme/components/ThemeToggle";


const App: React.FC = () => {
  const clientWidth = useClientWidth();
  const [darkMode] = useDarkModeManager();

  const [homePeriod, setHomePeriod] = useHomePeriod();
  const [homeChain, setHomeChain] = useHomeChain();
  const [posts, setPosts] = useState<TopCollection[] | null>(null);

  const [trendNone] = useState([1, 2, 3, 4, 5, 6, 7, 8, 9, 10]);
  const [trendNoneTel] = useState([1, 2, 3, 4, 5]);
  let navigate = useNavigate();
  const [next, setNext] = useState(1);
  const [idList, setIdList] = useState<string[]>([]);

  const { data } = useGetChainListQuery({});
  useEffect(() => {
    if (data) {
      if (data.data) {
        let idList: string[] = [];
        data.data.forEach((el) => {
          idList.push(el.id || "");
        });
        setIdList(idList);
      }
    }
  }, [data]);
  const handleChainChange = (value: string) => {
    setHomeChain(value);
  };

  const handleTimeChange = (value: string) => {
    setHomePeriod(value);
  };
  const backToAll = () => {
    setHomePeriod("twentyfour_hour");
    setHomeChain("");
    setNext(next + 1);
  };
  const { data: topListResult, status } = useTopListQuery({
    page: 1,
    pageSize: 10,
    chain: homeChain || "",
    sort: "volume",
    direct: "desc",
    next: next,
    period: homePeriod || "",
  });
  useEffect(() => {
    if (status === "fulfilled") {
      if (topListResult?.data && topListResult.data !== posts) {
        setPosts(topListResult.data);
      } else {
        setPosts([]);
      }
    }
  }, [status, topListResult]);

  return (
    <>
      <div style={{ width: "100%" }}>
        {Number(clientWidth) < 801 ? (
          <>
            {" "}
            <TitleItem>
              <TitleRight>
                <div style={{ whiteSpace: "nowrap" }}>Top collections</div>
                <div style={{ paddingTop: "2px" }}>
                  {" "}
                  <ListSelect
                    value={homePeriod || ""}
                    optionArr={["1h", "6h", "24h", "7d", "30d"]}
                    typeValues={[
                      "one_hour",
                      "six_hour",
                      "twentyfour_hour",
                      "seven_day",
                      "thirty_day",
                    ]}
                    onChange={handleTimeChange}
                    size=""
                  />
                </div>
              </TitleRight>
              <TitleRight>
                <ChainSelect
                  value={homeChain || ""}
                  onChange={handleChainChange}
                  size={""}
                />
              </TitleRight>
            </TitleItem>
            {status === "pending" ? (
              <TrendListTel>
                {trendNoneTel.map(() => (
                  <TrendLoadItemTel></TrendLoadItemTel>
                ))}
              </TrendListTel>
            ) : posts && posts.length > 0 ? (
              <TrendListTel>
                {posts?.map((i: any, index: any) =>
                  index > 5 ? null : (
                    <TrendItemTel
                      key={i.id}
                      onClick={() =>
                        navigate(
                          `/nfts/collection/${i.chain}/${i.contract_address}`
                        )
                      }
                    >
                      <TrendIndex>{index + 1}</TrendIndex>
                      <TrendImgTelBlock>
                        {i.image_url ? (
                          <TrendImgTel src={i.image_url || nftDefault} alt="" />
                        ) : (
                          <div style={{ width: "100%", textAlign: "center" }}>
                            {i.name.slice(0, 1)}
                          </div>
                        )}
                        {idList.indexOf(i.chain) === -1 ? null : (
                          <CollectInfoLogo
                            src={data?.data?.[idList.indexOf(i.chain)].logo}
                          />
                        )}
                        {/* <TrendLineImg src={attestationIcon} alt="" style={{ position: 'absolute', right: '3px', bottom: '3px' }} /> */}
                      </TrendImgTelBlock>

                      {/* <TrendImgTel src={i.image_url || nftDefault} alt="" /> */}
                      <TrendMainTel>
                        <TrendLine>
                          <TrendLineText>{i.name}</TrendLineText>
                          {/* <TrendLineImg src={attestationIcon} alt="" /> */}
                        </TrendLine>
                        <TrendLine>
                          <TrendLineText1 style={{ marginRight: "4px" }}>
                            Floor price
                          </TrendLineText1>
                          {/* {i.payment_tokens?.[0].image_url ? (<TrendLineImg
                      src={i.payment_tokens?.[0].image_url}
                      alt="" style={i.payment_tokens?.[0].image_url ? { opacity: '1' } : { opacity: '0' }}
                    />) : null} */}

                          <TrendLineText1 style={{ lineHeight: "10px" }}>
                            <span>
                              {formatWithArray(i.native_floor_price)}{" "}
                            </span>{" "}
                            {i.native_symbol}
                            {/* <span>{i.stats.floor_price.toFixed(4)} </span> */}
                          </TrendLineText1>
                        </TrendLine>
                      </TrendMainTel>
                      <TrendRight>
                        <TrendRightTop>
                          <TrendLineText1>{i.native_symbol}</TrendLineText1>
                          <div style={{ width: "4px" }}></div>
                          <div> {formatWithArray(i.native_volume)}</div>

                          {/* <TrendRightImg src={darkMode ? ethlN : ethl} alt="" /> */}
                        </TrendRightTop>
                        <div>
                          {i.voume_change ? (
                            <TrendRightBottom
                              style={
                                i.voume_change >= 0 ? {} : { color: "#eb4b6d" }
                              }
                            >
                              {i.voume_change >= 0 ? "+" : ""}
                              {i.voume_change.toFixed(2)} %
                            </TrendRightBottom>
                          ) : (
                            "--"
                          )}
                        </div>
                        {/* <TrendRightBottom style={i.stats.one_day_change >= 0 ? {} : { color: "#eb4b6d" }}>{i.stats.one_day_change >= 0 ? "+" : ""}
                    {i.stats.one_day_change.toFixed(2)} %</TrendRightBottom> */}
                      </TrendRight>
                    </TrendItemTel>
                  )
                )}
                <ButtonPurple
                  padding="10px 20px"
                  $borderRadius="20px"
                  width="100%"
                  margin="20px 0 0 0"
                  onClick={() => {
                    navigate(`/nfts/rankings/top`);
                  }}
                >
                  View All
                </ButtonPurple>
              </TrendListTel>
            ) : (
              <NoData onChange={() => backToAll()}></NoData>
            )}
          </>
        ) : (
          <>
            {" "}
            <TitleItem>
              <TitleRight>
                <div style={{ whiteSpace: "nowrap" }}>Top collections</div>
                <div style={{ paddingTop: "5px" }}>
                  {" "}
                  <ListSelect
                    value={homePeriod || ""}
                    optionArr={["1h", "6h", "24h", "7d", "30d"]}
                    typeValues={[
                      "one_hour",
                      "six_hour",
                      "twentyfour_hour",
                      "seven_day",
                      "thirty_day",
                    ]}
                    onChange={handleTimeChange}
                    size=""
                  />
                </div>
              </TitleRight>
              <TitleRight>
                <ChainSelect
                  value={homeChain || ""}
                  onChange={handleChainChange}
                  size={""}
                />
              </TitleRight>
            </TitleItem>
            <PopCollectionList>
              <PopCollectionItem style={{ cursor: "auto" }}>
                <PopCollectionLine>
                  <div>Collection</div>
                  <div>Volume</div>
                  <div>Volume growth</div>
                  <div>Floor price</div>
                </PopCollectionLine>
              </PopCollectionItem>
              {status === "pending" ? (
                trendNone.map(() => (
                  <PopCollectionLoadItem></PopCollectionLoadItem>
                ))
              ) : posts && posts.length > 0 ? (
                <>
                  {posts?.map((i: TopCollection, index: number) => (
                    <PopCollectionItem
                      key={i.id}
                      onClick={() =>
                        navigate(
                          `/nfts/collection/${i.chain}/${i.contract_address}`
                        )
                      }
                    >
                      <PopCollectionLine>
                        <div>
                          <TrendIndex>{index + 1}</TrendIndex>
                          <PopCollectionImgBlock>
                            {i.image_url ? (
                              <PopCollectionImg
                                src={i.image_url || nftDefault}
                                alt=""
                              />
                            ) : (
                              <div
                                style={{ width: "100%", textAlign: "center" }}
                              >
                                {i.name.slice(0, 1)}
                              </div>
                            )}
                            {idList.indexOf(i.chain) === -1 ? null : (
                              <CollectInfoLogo
                                src={data?.data?.[idList.indexOf(i.chain)].logo}
                              />
                            )}
                            {/* <TrendLineImg src={attestationIcon} alt="" style={{ position: 'absolute', right: '3px', bottom: '3px' }} /> */}
                          </PopCollectionImgBlock>
                          <PopCollectionName>{i.name}</PopCollectionName>
                        </div>
                        <div>
                          <TrendLineText1>
                            <span>{formatWithArray(i.native_volume)} </span>{" "}
                            {i.native_symbol}
                          </TrendLineText1>
                        </div>
                        <div>
                          {i.voume_change ? (
                            <TrendRightBottom
                              style={
                                i.voume_change >= 0 ? {} : { color: "#eb4b6d" }
                              }
                            >
                              {i.voume_change >= 0 ? "+" : ""}
                              {i.voume_change.toFixed(2)} %
                            </TrendRightBottom>
                          ) : (
                            "--"
                          )}
                        </div>
                        <div>
                          {/* {i.payment_tokens?.[0].image_url ? (<TrendLineImg src={i.payment_tokens?.[0].image_url} alt="" style={i.payment_tokens?.[0].image_url ? { opacity: '1', marginRight: '2px' } : { opacity: '0', marginRight: '2px' }} />) : null} */}

                          <TrendLineText1>
                            <span>
                              {formatWithArray(i.native_floor_price)}{" "}
                            </span>{" "}
                            {i.native_symbol}
                            {/* {i.payment_tokens[0].symbol} */}
                          </TrendLineText1>
                        </div>
                      </PopCollectionLine>
                    </PopCollectionItem>
                  ))}
                  <ButtonPurple
                    padding="10px 20px"
                    $borderRadius="20px"
                    width="calc(100% - 40px)"
                    margin="20px 20px 0 20px"
                    onClick={() => {
                      navigate(`/nfts/rankings/top`);
                    }}
                  >
                    View All
                  </ButtonPurple>
                </>
              ) : null}
              {status === "fulfilled" && (!posts || posts.length < 1) ? (
                <NoData onChange={() => backToAll()}></NoData>
              ) : null}
            </PopCollectionList>
          </>
        )}
      </div>
    </>
  );
};
export default App;
