import { Input } from "@rebass/forms";
import { useWeb3React } from "@web3-react/core";
import { ethers } from "ethers";
import { useEffect, useState } from "react";
import { Seaport } from "@opensea/seaport-js";
import {useToastOpen } from 'state/stats/hooks'
import { useGetChainQuery } from "apis/chain";
import { ButtonPurple } from "components/Button";
import ListSelect from "components/ListSelect";
import { formatWithArray, priceText } from "utils"
import { CardItem, InputBlock, ItemLabel, ItemLabelFlex, ItemRadio11, ItemTip, ItemValue, ItemValueIcon, PriceSymbol } from "pages/Nft/EditCollection/components_";
import "pages/index.scss";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDarkModeManager } from "theme/components/ThemeToggle";
import { useCollectionInfoQuery } from "apis/collection"
import {
  DurationSelect,
  ItemRadioOne,
  ItemValueBottom,ItemFlex,
  TipIcon,
  inputStyle1, InputText,
  inputStyle1Num,
  inputStyleNum
} from "../../components_";
// import "../../index.scss";
import { ItemType } from "@opensea/seaport-js/lib/constants";
import { OrderWithCounter } from "@opensea/seaport-js/lib/types";
import nftDefault from "assets/nft/nft_defult.png";

import "pages/index.scss";
import { Currency } from "types/token";

export interface OfferForm {

  visible?: boolean;
  isEarning?: boolean;
  asset?:Assets;
  earningNum?: number;
  chain_identifier?: string
  contract_address?: string
  token_id?: string
  floorPrice?: string
  offerPrice?: string
}

export interface ListingOrder {
  order: OrderWithCounter;
  orderHash: string;
}

interface OfferFormProps {
  value?: OfferForm;
  onChange: (value: ListingOrder) => void;
}

export default function OfferForm({
  value,
  onChange,
}: OfferFormProps) {

  const { account, provider } = useWeb3React();

  const [darkMode] = useDarkModeManager();
  const [,,, setToastOpen] = useToastOpen();
  const [isLoading, setIsLoading] = useState(false);
  const [earningsCustom, setEarningsCustom] = useState(false);
  const [startTime, setStartTime] = useState(new Date().getTime());
  const [endTime, setEndTime] = useState(new Date());
  const [fromPrice, setFromPrice] = useState('');
  const [fromEarnings, setFromEarnings] = useState('');

  const [total, setTotal] = useState(0);
  const [usdPrice, setUsdPrice] = useState(0);
  const [balance, setBalance] = useState('0');
  const [durationTime, setDurationTime] = useState('1month');
  const [chainCurrency, setChainCurrency] = useState<Currency>();

  const handleDurationChange = (value: string) => {
    setDurationTime(value);
  };
  const collectionRes = useCollectionInfoQuery({
    chain: value?.chain_identifier || '',
    contract_address: value?.contract_address as string || '',
  });
  const { data } = useGetChainQuery({ chain: value?.chain_identifier as string });
  useEffect(() => {
    if (data?.success) {
      setChainCurrency(data?.data?.currency)
    }
    if (collectionRes && collectionRes.status == "fulfilled" && collectionRes.data && collectionRes.data.success && collectionRes.data.data) {//seller_fees  Creator earnings
      console.log('collectionRes.data.data', collectionRes.data.data)
    }
  }, [data, collectionRes]);
  useEffect(() => {
    let dateTime = Date.parse(new Date().toString())
    let num = 0
    if (durationTime) {
      num = Number(durationTime)
      if (durationTime === "1month") {
        let myDate = new Date();
        let tMonth = myDate.getMonth();
        let m = tMonth + 1;
        let day31 = [1, 3, 5, 7, 8, 10, 12]
        let time = 0
        if (m === 2) {
          time = 86400 * 28
        } else if (day31.indexOf(m) === -1) {
          time = 86400 * 30
        } else {
          time = 86400 * 31
        }
        num = time
      }
    }
    let duration = dateTime + num * 1000
    setEndTime(new Date(duration))
  }, [durationTime]);

  useEffect(() => {
    const fun = async () => {
      if (account && chainCurrency) {
        try {
          const balance = await provider?.getBalance(account);

          let priceV = Number(BigInt(balance?.toString() || '0')) / Number(BigInt(1 * 10 ** chainCurrency.decimals))
          console.log('balance', formatWithArray(priceV))
          let str = formatWithArray(priceV)
          setBalance(str as string)
          // ethers.BigNumber.from(currentParams.mintPrice).mul(mintNum);
        } catch (error) {
          console.log('errorerror', error)
        }

      }

    }
    fun()
  }, [account, chainCurrency]);



  const ondataChange = (
    value: any
  ) => {
    console.log('data', value)
    setEndTime(new Date(value.toString()))
  };
  const save = async () => {
    setToastOpen({open:true,type:'error',label:"接口读取异常"})

    // if (isLoading) {
    //   mes.open({ type: "error", content: "Loading..." });
    //   return
    // }
    // if (fromPrice && fromPrice !== '') {
    //   setIsLoading(true)
    //   try {
    //     const seaport = new Seaport(provider as any);
    //     const offerer = account;
    //     const { executeAllActions } = await seaport.createOrder(
    //       {
    //         startTime:(startTime/1000).toFixed(0),
    //         endTime:  (endTime.getTime()/1000).toFixed(0),
    //         offer: [
    //           {
    //             itemType: ItemType.ERC721,
    //             token: value?.contract_address as string,
    //             identifier: value?.token_id as string,
    //           },
    //         ],
    //         consideration: [
    //           {
    //             amount: ethers.utils.parseEther(fromPrice).toString(),
    //             recipient: offerer,
    //           },
    //         ],
    //       },
    //       offerer,
    //     );

    //     const order = await executeAllActions();
    //     const orderHash = seaport.getOrderHash(order.parameters);
    //     onChange && onChange({ orderHash, order })
    //   } catch (error) {
    //     console.log('errorerror', error)
    //     mes.open({ type: "error", content: "error" });
    //   }
    //   setIsLoading(false)
    // } else {
    //   mes.open({ type: "error", content: "Plase enter amount" });
    // }




  };
  return (
    <>
      <CardItem>
        <ItemFlex>
          <img src={value?.asset?.image_url||nftDefault}/>
          <div>
          <div>{value?.asset?.name}</div>
          <div>#{value?.asset?.token_id}</div>
            </div>
        </ItemFlex>



      </CardItem>
      <CardItem>
        <ItemLabel></ItemLabel>
        <ItemValueBottom>
          <div>Balance</div>
          <div>{String(balance) || '--'}{' '}{chainCurrency?.name}</div>
        </ItemValueBottom>
        <ItemValueBottom>
          <div>Floor price</div>
          {value?.floorPrice ? <div>{value.floorPrice}</div> : <div>{'-- '}{chainCurrency?.name}</div>}

        </ItemValueBottom>
        <ItemValueBottom style={{ border: 'none' }}>
          <div>Best offer</div>
          {value?.offerPrice ? <div>{value.offerPrice}</div> : <div>{'-- '}{chainCurrency?.name}</div>}

        </ItemValueBottom>


      </CardItem>
      <CardItem>
        {/* <ItemLabel>Starting price</ItemLabel> */}
        <ItemValueIcon>
          <InputBlock style={{ paddingLeft: '8px' }}>
            <Input
              type="number"
              placeholder="Amount"
              value={fromPrice}
              sx={inputStyleNum}
              onChange={(e) => {
                setFromPrice(e.target.value);
              }}
            /></InputBlock>
          <PriceSymbol>{chainCurrency?.name}</PriceSymbol>
        </ItemValueIcon>
        {/* <ItemTip style={{ padding: '5px 0 0' }}>${fromPrice ? fromPrice : '0'} Total</ItemTip> */}
      </CardItem>
      <CardItem>
        <ItemLabel>Duration</ItemLabel>
        <ItemLabelFlex>
          <DurationSelect style={{ marginLeft: '0' }}>
            <ListSelect
              value={durationTime}
              optionArr={["Custom", "1 hour", "6 hour", "1 day", "3 days", "7 days", "1 month"]}
              typeValues={["", "3600", "21600", "86400", "259200", "604800", "1month"]}
              onChange={handleDurationChange}
              size=""
            /></DurationSelect>
          <ItemValue className={darkMode ? 'date-picker-divw' : 'date-picker-div'}>
            <DatePicker
              selected={endTime}
              onChange={(date) => ondataChange(date)}
              timeInputLabel="Time:"
              dateFormat="MM/dd/yyyy h:mm aa"
              className={darkMode ? 'date-picker-w' : 'date-picker'}
              showTimeInput
            />
          </ItemValue>

        </ItemLabelFlex>
      </CardItem>

      {/* <CardItem>
        <ItemValueBottom style={{ border: 'none' }}>
          <div>Total potential earnings</div>
          <div>{total || '--'}{chainCurrency?.name}</div>
        </ItemValueBottom>
      </CardItem> */}
      {/* <ButtonPurple padding={'10px'} $borderRadius={'4px'} width={'100%'} onClick={() =>  onChange({ visible: !value?.visible })}>Complete listing</ButtonPurple></StyledModal> */}
      <ButtonPurple padding={'10px'} $borderRadius={'4px'} width={'100%'} onClick={() => save()}>{isLoading ? 'Loading...' : 'Make offer'}</ButtonPurple>

    </>
  );
}
