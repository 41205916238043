
import { createContext, Dispatch, ReactNode, SetStateAction } from 'react'
import { Field } from 'components/swap/constants'

import { InterfaceTrade, RouterPreference, TradeState } from 'state/routing/types'
import { parsedQueryString } from 'hooks/useParsedQueryString'
import { ParsedQs } from 'qs'
import { Currency, CurrencyAmount, Percent } from 'core'
export type SwapInfo = {
  currencies: { [field in Field]?: Currency }


  parsedAmount?: CurrencyAmount<Currency>


}
export interface SerializedCurrencyState {
  inputCurrencyId?: string
  outputCurrencyId?: string
  chainId?: number
}

// shared state between Swap and Limit
export interface CurrencyState {
  inputCurrency?: Currency
  outputCurrency?: Currency
}
export enum SwapTab {
  Swap = 'swap',
  Limit = 'limit',
  Send = 'send',
}

type SwapAndLimitContextType = {
  currencyState: CurrencyState
  prefilledState: {
    inputCurrency?: Currency
    outputCurrency?: Currency
  }
  setCurrencyState: Dispatch<SetStateAction<CurrencyState>>
  currentTab: SwapTab
  setCurrentTab: Dispatch<SetStateAction<SwapTab>>
  // The chainId of the page/context - can be different from the connected Chain ID if the
  // page is displaying content for a different chain or if multichain UX is enabled
  chainInfo?: ChainInfo
  multichainUXEnabled?: boolean
}
type SwapContextType = {
  swapState: SwapState
  derivedSwapInfo: SwapInfo
  setSwapState: Dispatch<SetStateAction<SwapState>>
}
export const SwapAndLimitContext = createContext<SwapAndLimitContextType>({
  currencyState: {
    inputCurrency: undefined,
    outputCurrency: undefined,
  },
  setCurrencyState: () => undefined,

  prefilledState: {
    inputCurrency: undefined,
    outputCurrency: undefined,
  },

  setCurrentTab: () => undefined,
  multichainUXEnabled: false,
  currentTab: SwapTab.Limit
})
function parseTokenAmountURLParameter(urlParam: any): string {
  return typeof urlParam === 'string' && !isNaN(parseFloat(urlParam)) ? urlParam : ''
}
function parseIndependentFieldURLParameter(urlParam: any): Field {
  return typeof urlParam === 'string' && urlParam.toLowerCase() === 'output' ? Field.OUTPUT : Field.INPUT
}

export function queryParametersToSwapState(parsedQs: ParsedQs): SwapState {
  const typedValue = parseTokenAmountURLParameter(parsedQs.exactAmount)
  const independentField = parseIndependentFieldURLParameter(parsedQs.exactField)

  return {
    typedValue,
    independentField,
  }
}

export const initialSwapState: SwapState = queryParametersToSwapState(parsedQueryString())
export const EMPTY_DERIVED_SWAP_INFO: SwapInfo = Object.freeze({
  currencies: {},
  currencyBalances: {},
  inputTax: new Percent(0),
  outputTax: new Percent(0),
  autoSlippage: new Percent(0),
  allowedSlippage: new Percent(0),
  trade: {
    state: TradeState.LOADING,
  },
})
export interface SwapState {
  readonly independentField: Field
  readonly typedValue: string
  routerPreferenceOverride?: RouterPreference.API
}
export const SwapContext = createContext<SwapContextType>({
  swapState: initialSwapState,
  derivedSwapInfo: EMPTY_DERIVED_SWAP_INFO,
  setSwapState: () => undefined,
})