import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppState } from "..";

const currentTimestamp = () => new Date().getTime()
interface StatsState {
  homePeriod:string;
  tokenTopPeriod:string;
  tokenTopPeriodLabel:string;
  tokenWatchPeriod:string;
  tokenWatchPeriodLabel:string;
  tokenTrendingPeriod:string;
  tokenTrendingPeriodLabel:string;
  collectionTopPeriod: string;
  collectionTrendingPeriod: string;
  collectionWatchPeriod:string;
  homeChain:string;
  tokenTopChain:string;
  tokenTrendingChain:string;  
  tokenWatchChain:string;
  collectionTopChain:string;
  collectionTrendingChain:string;  
  collectionWatchChain:string;
  toastOpen:boolean;
  toastType:string;
  toastLabel:string;
}

const initialState = {
  homePeriod:'twentyfour_hour',
  tokenTopPeriod: 'one_hour',
  tokenTopPeriodLabel:'1h',
  tokenWatchPeriod: 'one_hour',
  tokenWatchPeriodLabel:'1h',
  tokenTrendingPeriodLabel:'1h',
  tokenTrendingPeriod:'one_hour',
  collectionTopPeriod: 'one_hour',
  collectionTrendingPeriod: 'one_hour',
  collectionWatchPeriod: 'one_hour',
  homeChain:'',
  tokenTopChain:'',
  tokenTrendingChain:'',
  tokenWatchChain:'',
  collectionTopChain:'',
  collectionTrendingChain:'',
  collectionWatchChain:'',
  toastOpen:false,
  toastType:'',
  toastLabel:'',
} as StatsState;

export const statsSlice = createSlice({
  name: "stats",
  initialState: initialState,
  reducers: {
    updateHomePeriod: (
      state:StatsState,
      { payload: period }: PayloadAction<string>
    ) => {
      state.homePeriod = period;
    }, 
    updateTokenTopPeriod: (
      state:StatsState,
      { payload: {period,label} }: PayloadAction<{period:string,label:string}>
    ) => {
      console.log('period,label',period,label)
      state.tokenTopPeriod = period;
      state.tokenTopPeriodLabel = label;
    }, 
    updateTokenWatchPeriod: (
      state:StatsState,
      { payload: {period,label} }: PayloadAction<{period:string,label:string}>
    ) => {
      state.tokenWatchPeriod = period;
      state.tokenWatchPeriodLabel = label;
    }, 
    updateTokenTrendingPeriod: (
      state:StatsState,
      { payload: {period,label} }: PayloadAction<{period:string,label:string}>
    ) => {
      state.tokenTrendingPeriod = period;
      state.tokenTrendingPeriodLabel = label;

    }, 
    updateCollectionTopPeriod: (
      state:StatsState,
      { payload: period }: PayloadAction<string>
    ) => {
      console.log("reducer:",JSON.stringify(period))
      state.collectionTopPeriod = period;
    }, 
    updateCollectionTrendingPeriod: (
      state:StatsState,
      { payload: period }: PayloadAction<string>
    ) => {
      console.log("reducer:",JSON.stringify(period))
      state.collectionTrendingPeriod = period;
    }, 
    updateCollectionWatchPeriod: (
      state:StatsState,
      { payload: period }: PayloadAction<string>
    ) => {
      state.collectionWatchPeriod = period;
    }, 
    updateHomeChain: (
      state:StatsState,
      { payload: chain }: PayloadAction<string>
    ) => {
      state.homeChain = chain;
    }, 
    updateTokenTopChain: (
      state:StatsState,
      { payload: chain }: PayloadAction<string>
    ) => {
      state.tokenTopChain = chain;
    }, 
    updateTokenTrendingChain: (
      state:StatsState,
      { payload: chain }: PayloadAction<string>
    ) => {
      state.tokenTrendingChain = chain;
    }, 
    updateTokenWatchChain: (
      state:StatsState,
      { payload: chain }: PayloadAction<string>
    ) => {
      state.tokenWatchChain = chain;
    }, 
    updateCollectionTopChain: (
      state:StatsState,
      { payload: chain }: PayloadAction<string>
    ) => {
      state.collectionTopChain = chain;
    }, 
    updateCollectionTrendingChain: (
      state:StatsState,
      { payload: chain }: PayloadAction<string>
    ) => {
      state.collectionTrendingChain = chain;
    }, 
    updateCollectionWatchChain: (
      state:StatsState,
      { payload: chain }: PayloadAction<string>
    ) => {
      state.collectionWatchChain = chain;
    }, 
    updateToast: (
      state:StatsState,
      { payload: {open,type,label} }: PayloadAction<{open:boolean,type:string,label:string}>
    ) => {
      state.toastOpen = open;
      state.toastType = type;
      state.toastLabel = label;
    }, 
  },
});
export const selectTokenTopPeriod = (state: AppState) => state.stats.tokenTopPeriod;
export const selectTokenTrendingPeriod = (state: AppState) => state.stats.tokenTrendingPeriod;
export const selectCollectionTopPeriod = (state: AppState) => state.stats.collectionTopPeriod;
export const selectCollectionTrendingPeriod = (state: AppState) => state.stats.collectionTrendingPeriod;

export const { updateHomePeriod,updateTokenTopPeriod,updateTokenWatchPeriod,updateTokenTrendingPeriod,updateCollectionTopPeriod,updateCollectionTrendingPeriod,updateCollectionWatchPeriod,
  updateHomeChain,updateTokenTopChain,updateTokenTrendingChain,updateTokenWatchChain,updateCollectionTopChain,updateCollectionTrendingChain,updateCollectionWatchChain,updateToast
} = statsSlice.actions;
export default statsSlice.reducer;
