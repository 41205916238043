import { useDropzone } from 'react-dropzone';
import styled from "styled-components/macro";
import React, { useCallback, useState,useEffect } from 'react'
import { useUploadImgMutation } from 'apis/file';
import { useToastOpen } from 'state/stats/hooks'

const UploadBox = styled.div<{ width?: string, height?: string, borderRadius?: string }>`
width:${({ width }) => (width ? width : "100%")};
height:${({ height }) => (height ? height : "100%")};
position:relative;
// border: 1px dashed ${({ theme }) => theme.border1};
border-radius:${({ borderRadius }) => (borderRadius ? borderRadius : "8px")};
cursor: pointer;
overflow:hidden;
margin-bottom:10px;
:hover{
  // border: 1px dashed ${({ theme }) => theme.purple1};
}
`;
const UploadImg = styled.img<{ width?: string, height?: string }>`
width:${({ width }) => (width ? width : "100%")};
height:${({ height }) => (height ? height : "100%")};
position:absolute;
left:0;
top:0;
object-fit: cover;
`;
const UploadTip = styled.div<{ width?: string, height?: string }>`
width:${({ width }) => (width ? width : "100%")};
height:${({ height }) => (height ? height : "100px")};
display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size:16px;
color:${({ theme }) => theme.text3};
>div:first-child{
  font-size:25px;
  // text-align:center;
  // padding:20px;
}
:hover{
  color:${({ theme }) => theme.purple1};
}
`;

interface UploadProps {
  url?: string;
  width?: string;
  height?: string;
  borderRadius?: string;
  urlChange: (data: FormData) => void;
}
export default function Upload({
  url,
  width,
  height, borderRadius,
  urlChange
}: UploadProps) {
  const [, , , setToastOpen] = useToastOpen();

  const [uploadImg] = useUploadImgMutation()
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (url&&url!==''&&loading) {
      setLoading(false)
    }
  }, [url]);
  const postUploadImg = async (file: File) => {
    if (file.type == 'text/csv') {
      const formData = new FormData();
      formData.append('file', file as File);
      urlChange(formData)
      // const { data, success } = await uploadImg({ data: formData }).unwrap();
      // if (data && data.url) {
        // setLoading(false)
      //   urlChange(data.url)
      // }
    } else {
      setToastOpen({ open: true, type: 'error', label: "file type error" })
    }

  }
  const onDrop = useCallback((acceptedFiles: File[]) => {
    setLoading(true)
    postUploadImg(acceptedFiles[0])
  }, [])

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: {
      'text/csv': []
    }, onDrop
  });

  return (
    <section className="container" >
      <UploadBox width={width} height={height} borderRadius={borderRadius} {...getRootProps({ className: 'dropzone' })}>
        <input {...getInputProps()} />
        {url ? (<UploadTip><div></div><div style={{padding:'0 20px'}}>{url}</div></UploadTip>) : loading ? (<UploadTip width={width} height={height}>loading</UploadTip>) : (<UploadTip width={width} height={height}>
          <div>+</div>
          <div>Drag and drop a CSV file</div>
        </UploadTip>)}
      </UploadBox>
      {/* <aside>
        <h4>Files</h4>

      </aside> */}
    </section>
  );
}