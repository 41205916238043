import { useDropzone } from 'react-dropzone';
import styled from "styled-components/macro";
import { ButtonPurple } from 'components/Button';

import {MAX_WIDTH_MEDIA_BREAKPOINT,MIN_WIDTH_MEDIA_BREAKPOINT,LARGE_MEDIA_BREAKPOINT} from "constants/width"
const NoDataBox = styled.div`

  width: 100%;
  padding:50px 0;
  border:1px solid ${({ theme }) => theme.border1};
  color: ${({ theme }) => theme.text1};
  
  border-radius:8px;
  margin:20px auto;
  >div:first-child{
    width:100%;
    text-align:center;
    font-size:28px;
  }
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}){
    >div:first-child{
      width:100%;
      text-align:center;
      font-size:20px;
    }
   }
`;

interface NoDataProps {
  size?: string;
  onChange: () => void;
}
export default function NoData({
  size, onChange
}: NoDataProps) {


  return (


    <NoDataBox>
      <div style={size && size == 'small' ? { fontSize: '14px' } : {}}>No items found for this search</div>
      <div style={size && size == 'small' ? {
        transform: 'scale(0.7)'
      } : {}}>                <ButtonPurple padding='10px 20px' $borderRadius='4px' margin='20px auto 0' width='auto' onClick={() => onChange()}>Back to all items</ButtonPurple>
      </div>

    </NoDataBox>
  );
}
