import { Page } from "components/Layout";
import { Box, Flex, Text } from "rebass";

import { useSaveListingOrderMutation } from "apis/orders";
import { useTokenDetailQuery } from "apis/token";
import {
  LARGE_MEDIA_BREAKPOINT,
  MIN_WIDTH_MEDIA_BREAKPOINT,
} from "constants/width";
import styled from "styled-components";

import { Currency } from "core";
import { useLocation } from "react-router-dom";
import { useInitialCurrencyState } from "state/swap/hooks";
import {
  SwapAndLimitContextProvider,
  SwapContextProvider,
} from "state/swap/SwapContext";
import { CurrencyState, SwapAndLimitContext } from "state/swap/types";
import LimitForm from "../LimitForm";

const Title = styled(Box)`
  margin-bottom: 52px;
`;

const PageRow = styled(Flex)`
  max-width: 1052px;
  flex-wrap: nowrap;
  justify-content: space-around;
  > div:first-child {
    @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
      width: 100%;
    }
  }
`;

const OrderDetailWrap = styled(Box)`
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    display: None;
  }
`;

export default function Home() {
  const location = useLocation();
  const multichainUXEnabled = false;

  const { initialInputCurrency, initialOutputCurrency, chainInfo } =
    useInitialCurrencyState();

  const [createlistingOrder] = useSaveListingOrderMutation();

  const { data: defautQuoteData } = useTokenDetailQuery({
    chain: chainInfo?.id,
    address: chainInfo?.usd_token,
  });
  const { data: defautBaseData } = useTokenDetailQuery({
    chain: chainInfo?.id,
    address: chainInfo?.wrapped_token,
  });

  return <><Swap syncTabToUrl={false} initialInputCurrency={initialInputCurrency} initialOutputCurrency={initialOutputCurrency} /></>;
}

export function Swap({
  className,
  initialInputCurrency,
  initialOutputCurrency,
  chainInfo,
  onCurrencyChange,
  multichainUXEnabled = false,
  disableTokenInputs = false,
  compact = false,
  syncTabToUrl,
}: {
  className?: string;
  chainInfo?: ChainInfo;
  onCurrencyChange?: (selected: CurrencyState) => void;
  disableTokenInputs?: boolean;
  initialInputCurrency?: Currency;
  initialOutputCurrency?: Currency;
  compact?: boolean;
  syncTabToUrl: boolean;
  multichainUXEnabled?: boolean;
}) {
  return (
    <>
      <SwapAndLimitContextProvider
        chainInfo={chainInfo ? chainInfo : undefined}
        initialInputCurrency={initialInputCurrency}
        initialOutputCurrency={initialOutputCurrency}
        multichainUXEnabled={multichainUXEnabled}
      >
        <SwapAndLimitContext.Consumer>
        {({ currentTab }) => (
        <SwapContextProvider>
        <Page>
              <Title>
                <Text pb={1} fontSize={36}>
                  Limit Order
                </Text>
                <Text>Create order to buy or sell the crypto you prefer</Text>
              </Title>
              <Box>
              <LimitForm onCurrencyChange={onCurrencyChange} />
                <PageRow>
                  <OrderDetailWrap>
                    {/* <OrderDetail order={order} /> */}
                  </OrderDetailWrap>
                </PageRow>
              </Box>
            </Page>
          </SwapContextProvider>)}
        </SwapAndLimitContext.Consumer>
      </SwapAndLimitContextProvider>
    </>
  );
}
