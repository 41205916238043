import { useWeb3React } from "@web3-react/core";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useToastOpen } from 'state/stats/hooks';

import {
  useChangeCollectFeaturedMutation,
  useChangeCollectHiddenMutation,
  useCollectionInfoQuery,
} from "apis/collection";
import {
  useAddWatchListMutation,
  useDelWatchOneMutation,
  useIsWatchingQuery,
} from "apis/ranking";
import { openWindow } from "utils";

import {
  useChangeDropDisplayMutation,
  useChangeDropFeaturedMutation,
  useGetDropPublishMutation
} from "apis/drop";
import { useClientWidth } from "state/application/hooks";

import nftDefault from "assets/nft/nft_defult.png";
import copyIcon from "assets/nft_copy.png";
import nftIcon1 from "assets/nft_icon1.png";
import nftIcon1N from "assets/nft_icon1_n.png";
import nftIcon2 from "assets/nft_icon2.png";
import nftIcon2N from "assets/nft_icon2_n.png";
import arrowU from "assets/tool/arrow_icon.png";
import arrowUn from "assets/tool/arrow_iconN.png";
import likeNone from "assets/tool/star_b.png";
import likeFilled from "assets/tool/star_f.png";
import likeNonew from "assets/tool/star_w.png";
import {
  AddressText,
  AdimnImg,
  ArrowImg,
  AttesImg,
  CollectRoles,
  DescBtn,
  IItemBottom,
  IItemTop,
  InfoDesc,
  InfoDescLoad,
  InfoDescLoadTel,
  InfoGroup,
  InfoGroupL,
  InfoImg,
  InfoImgTelLoad,
  InfoItem,
  InfoNameCol,
  InfoNameRow,
  LinkImg,
  MainBottomTitle, MainInfo, MainInfoTop, NameText, PageTitle,
} from "../../components_";

import checkFilled from "assets/tool/check_filled.png";
import checkNone from "assets/tool/check_none.png";
import "pages/index.scss";
import { useDarkModeManager } from "theme/components/ThemeToggle";


export default function NftTop() {
  const [addWatchList] = useAddWatchListMutation()
  
  const [delWatchOne] = useDelWatchOneMutation()
  const [getDropPublish] = useGetDropPublishMutation();
  const [changeCollectHidden] = useChangeCollectHiddenMutation()
  const [changeCollectFeatured] = useChangeCollectFeaturedMutation()  
  const [,,, setToastOpen] = useToastOpen();

  const [changeDropHidden] = useChangeDropDisplayMutation()
  const [changeDropFeatured] = useChangeDropFeaturedMutation()
  const [isAdmin, setIsAdmin] = useState(false);

  const { account } = useWeb3React();
  const { pathname } = useLocation();
  const clientWidth = useClientWidth();
  const { chain, contract_address } = useParams();
  let navigate = useNavigate();
  const [darkMode] = useDarkModeManager();
  const [collectInfo, setCollectInfo] = useState<CollectionInfo | null>();

  const [hidden, setHidden] = useState(false);
  const [featured, setFeatured] = useState(false);
  const [drophidden, setDrophidden] = useState(false);
  const [dropfeatured, setDropfeatured] = useState(false);
  const [address, setAddress] = useState("");
  const [addressStr, setAddressStr] = useState("");

  const [totalVolume, setTotalVolume] = useState("");
  const [floorPrice, setFloorPrice] = useState("");
  const [numOwners, setNumOwners] = useState("");
  const [numItems, setNumItems] = useState("");
  const [openDesc, setOpenDesc] = useState(false);
  const [isInfoLoad, setIsInfoLoad] = useState(true);
  const [isWatching, setIsWatching] = useState(false);

  const {data:watchingData} = useIsWatchingQuery({chain:chain,contract_address:contract_address,account:account})
  useEffect(()=>{
    if(watchingData){
      setIsWatching(watchingData.is_watching)
    }
  },[watchingData])

  const getNum = (item: CollectionInfo) => {

    let str =
      item.contract_address.substring(0, 7) +
      "..." +
      item.contract_address.substr(
        item.contract_address.length - 5
      );
    setAddress(str);
    setAddressStr(item.contract_address);
    // console.log('priceText',priceText(item.stats.total_volume))
    if (item.stats.total_volume > 1000) {
      let num = (item.stats.total_volume / 1000).toFixed(1);
      setTotalVolume(num + "k");
    } else if (item.stats.total_volume === 0) {
      setTotalVolume(String(item.stats.total_volume));
    } else {
      let num = item.stats.total_volume.toFixed(2);
      setTotalVolume(num);
    }
    if (item.stats.floor_price > 1000) {
      let num = (item.stats.floor_price / 1000).toFixed(1);
      setFloorPrice(num + "k");
    } else if (item.stats.floor_price === 0) {
      setFloorPrice(String(item.stats.floor_price));
    } else {
      let num = item.stats.floor_price.toFixed(2);
      setFloorPrice(num);


    }
    if (item.stats.num_owners > 1000) {
      let num = (item.stats.num_owners / 1000).toFixed(1);
      setNumOwners(num + "k");
    } else if (item.stats.num_owners === 0) {
      setNumOwners(String(item.stats.num_owners));
    } else {
      let num = item.stats.num_owners.toFixed(0);
      setNumOwners(num);
    }
    if (item.stats.count > 1000) {
      let num = (item.stats.count / 1000).toFixed(1);
      setNumItems(num + "k");
    } else if (item.stats.count === 0) {
      setNumItems(String(item.stats.count));
    } else {
      let num = item.stats.count.toFixed(0);
      setNumItems(num);
    }
    setCollectInfo(item);
    console.log("CollectInfo", collectInfo);
  };
  const getDropPublishInfo = async () => {
    let paramdata = {
      chain: chain as string,
      address: contract_address as string
    };
    const { success, message, data } = await getDropPublish(paramdata).unwrap();
    if (success) {


      if (data) {
        console.log('dropdata', data)
        setDrophidden(data.hidden || false)
        setDropfeatured(data.featured || false)
      }
    } else {
      setToastOpen({open:true,type:'error',label:message})
    }
  };
  const { data } = useCollectionInfoQuery({
    chain: chain as string,
    contract_address: contract_address as string,
  });

  useEffect(() => {
    //if (data && data.success && !collectInfo?.id) {  
    if (data && data.success) {
      if (data.data?.drop) { getDropPublishInfo() }
      getNum(data.data);
      setHidden(data.data.hidden || false)
      setFeatured(data.data.featured || false)
      setIsInfoLoad(false)
    }
  }, [data]);


  const addWatch = async () => {
    try {
      const res = await addWatchList({
        chain: chain,
        address: contract_address,
        type:'NFT'
      }).unwrap();
      if (res.success) {
        setToastOpen({open:true,type:'success',label:"success"})
        setIsWatching(!isWatching)
      } else {
        setToastOpen({open:true,type:'error',label:res.msg})
      }
    } catch (err) {
      setToastOpen({open:true,type:'error',label:(err as Error).message})

    }

  }
  const delWatch = async () => {
    try {
      const res = await delWatchOne({
        chain: chain,
        contract_address: contract_address
      }).unwrap();

      if (res.success) {
        setToastOpen({open:true,type:'success',label:"success"})

        setIsWatching(!isWatching)
      } else {
        setToastOpen({open:true,type:'error',label:res.msg})
      }
    } catch (err) {
      setToastOpen({open:true,type:'error',label:(err as Error).message})

    }

  }
  const changeDisplay = async () => {
    try {
      const { status, message } = await changeCollectHidden({ chain: collectInfo?.chain, address: collectInfo?.contract_address, value: !hidden }).unwrap();
      if (status === 'success') {
        setToastOpen({open:true,type:'success',label:"success"})
        setHidden(!hidden)
      } else {
        setToastOpen({open:true,type:'error',label:message})
      }
    } catch (err) {
      setToastOpen({open:true,type:'error',label:(err as Error).message})

    }



  }
  const changeFeatured = async () => {
    try {
      const { status, message } = await changeCollectFeatured({ chain: collectInfo?.chain, address: collectInfo?.contract_address, value: !featured }).unwrap();
      if (status === 'success') {
        setFeatured(!featured)
        setToastOpen({open:true,type:'success',label:"success"})
      } else {
        setToastOpen({open:true,type:'error',label:message})
      }
    } catch (err) {
      setToastOpen({open:true,type:'error',label:(err as Error).message})

    }


  }
  const changeDropDisplayFun = async () => {
    try {
      const { success, message } = await changeDropHidden({ chain: collectInfo?.chain, address: collectInfo?.contract_address, value: !drophidden }).unwrap();
      if (success) {
        setToastOpen({open:true,type:'success',label:"success"})
        setDrophidden(!drophidden)
      } else {
        setToastOpen({open:true,type:'error',label:message})
      }
    } catch (err) {
      setToastOpen({open:true,type:'error',label:(err as Error).message})
    }
  }
  const changeDropFeaturedFun = async () => {
    try {
      const { success, message } = await changeDropFeatured({ chain: collectInfo?.chain, address: collectInfo?.contract_address, featured: !dropfeatured }).unwrap();
      if (success) {
        setDropfeatured(!dropfeatured)
        setToastOpen({open:true,type:'success',label:"success"})
      } else {
        setToastOpen({open:true,type:'error',label:message})
      }
    } catch (err) {
      setToastOpen({open:true,type:'error',label:(err as Error).message})
    }


  }

  return (
    <>
      {Number(clientWidth) < 801 ? (<PageTitle>NFT Market</PageTitle>) : null}
      <MainInfoTop>
        <div> {isInfoLoad ? (<InfoImgTelLoad />) : (<InfoImg src={collectInfo?.image_url || nftDefault} />)}</div>
        <div> <InfoNameRow style={{ marginTop: 0 }}>
          <InfoNameCol>
            {isInfoLoad ? (
              <InfoDescLoad style={{ width: "200px" }}>{'   '}</InfoDescLoad>
            ) : (
              <>
                <NameText>{collectInfo?.name}</NameText>
                {/* <AttesImg src={attestationIcon} alt="" /> */}
              </>
            )}
          </InfoNameCol>
          {Number(clientWidth) < 801 ? null : (<InfoNameCol>
            <LinkImg onClick={() => { isWatching ? delWatch() : addWatch() }}
              src={isWatching ? likeFilled : darkMode ? likeNonew : likeNone}
              alt=""
            />
            {collectInfo && collectInfo.discord_url != "" ? (
              <LinkImg
                src={darkMode ? nftIcon1N : nftIcon1}
                alt=""
                onClick={() => {
                  openWindow(collectInfo.discord_url);
                }}
              />
            ) : (
              ""
            )}
            {collectInfo && collectInfo.external_link != "" ? (
              <LinkImg
                src={darkMode ? nftIcon2N : nftIcon2}
                alt=""
                onClick={() => {
                  openWindow(collectInfo.external_link);
                }}
              />
            ) : (
              ""
            )}
          </InfoNameCol>)}

        </InfoNameRow>
          {isInfoLoad ? (
            <InfoDescLoad style={{ width: "10%" }}>{'   '}</InfoDescLoad>
          ) : (
            <>
              <InfoNameRow>
                <InfoNameCol>
                  <AddressText>  chain: {collectInfo?.chain}</AddressText>
                </InfoNameCol>
              </InfoNameRow>
              <InfoNameRow>
                <InfoNameCol onClick={() =>{ navigator.clipboard.writeText(addressStr);setToastOpen({open:true,type:'success',label:"success"})} } style={{ cursor: 'pointer' }}>
                  Contract: <AddressText>{address}</AddressText>
                  <AttesImg src={copyIcon} alt="" />
                </InfoNameCol>
              </InfoNameRow>

            </>
          )}
          {Number(clientWidth) < 801 ? null : (<>{isInfoLoad ? (
            <InfoDescLoad>{'   '}</InfoDescLoad>
          ) : (
            <InfoDesc openDesc={true}>
              {openDesc ? collectInfo?.description : (collectInfo && collectInfo.description && collectInfo.description.length > 70 ? collectInfo?.description?.slice(0, 70) + '...' : "--")}

            </InfoDesc>
          )}
            {collectInfo && collectInfo.description && collectInfo.description.length > 70 ? (<DescBtn
              onClick={() => {
                setOpenDesc(!openDesc);
              }}
            >
              <div>{openDesc ? "收起" : "展开"}</div>
              <ArrowImg src={darkMode ? arrowUn : arrowU} style={openDesc ? { transform: 'rotate(180deg)' } : {}} alt="" />
            </DescBtn>) : null}</>)}

        </div>
      </MainInfoTop>
      <MainInfo>
        <InfoGroup>
          <InfoGroupL>
            <InfoItem>
              {isInfoLoad ? (
                <InfoDescLoad>{'   '}</InfoDescLoad>
              ) : (
                <IItemTop>
                  {/* <EthImg src={darkMode ? ethBlickN : ethBlick} alt="" /> */}

                  <div>
                    {/* {collectInfo?.stats?.total_volume} */}
                    {totalVolume}
                  </div>
                </IItemTop>
              )}

              <IItemBottom>Total volume</IItemBottom>
            </InfoItem>
            <InfoItem>
              {isInfoLoad ? (
                <InfoDescLoad>{'   '}</InfoDescLoad>
              ) : (
                <IItemTop>
                  {/* <EthImg src={darkMode ? ethBlickN : ethBlick} alt="" /> */}
                  <div>{floorPrice}</div>
                </IItemTop>
              )}


              <IItemBottom>Floor price</IItemBottom>
            </InfoItem>
            <InfoItem>
              {isInfoLoad ? (
                <InfoDescLoad>{'   '}</InfoDescLoad>
              ) : (
                <IItemTop>
                  <div>{numOwners}</div>
                </IItemTop>
              )}

              <IItemBottom>Owners</IItemBottom>
            </InfoItem>
            <InfoItem>
              {isInfoLoad ? (
                <InfoDescLoad>{'   '}</InfoDescLoad>
              ) : (
                <IItemTop>
                  <div>{numItems}</div>
                </IItemTop>
              )}

              <IItemBottom>Items</IItemBottom>
            </InfoItem>
          </InfoGroupL>
          <div>{isAdmin ? <>
            {pathname.includes("/overview") ? null : <CollectRoles>
              <div onClick={() => changeDisplay()}>
                <div>hidden collection</div>
                <AdimnImg src={hidden ? checkFilled : checkNone} />
              </div>
              <div onClick={() => changeFeatured()}>
                <div>featured collection</div>
                <AdimnImg src={featured ? checkFilled : checkNone} />
              </div></CollectRoles>}
            {collectInfo?.drop && pathname.includes("/overview") ? <CollectRoles>
              <div onClick={() => changeDropDisplayFun()}>
                <div>hidden drop</div>
                <AdimnImg src={drophidden ? checkFilled : checkNone} />
              </div>
              <div onClick={() => changeDropFeaturedFun()}>
                <div>featured drop</div>
                <AdimnImg src={dropfeatured ? checkFilled : checkNone} />
              </div>
            </CollectRoles> : null}</> : null}</div>
        </InfoGroup>
      </MainInfo>
      {Number(clientWidth) < 801 ? (<>

        <InfoNameCol style={{ paddingLeft: '0' }}>
          <LinkImg onClick={() => { isWatching ? delWatch() : addWatch() }}
            src={isWatching ? likeFilled : darkMode ? likeNonew : likeNone}
            alt=""

          />

          {collectInfo && collectInfo.discord_url != "" ? (
            <LinkImg
              src={darkMode ? nftIcon1N : nftIcon1}
              alt=""
              onClick={() => {
                openWindow(collectInfo.discord_url);
              }}
            />
          ) : (
            ""
          )}
          {collectInfo && collectInfo.external_link != "" ? (
            <LinkImg
              src={darkMode ? nftIcon2N : nftIcon2}
              alt=""
              onClick={() => {
                openWindow(collectInfo.external_link);
              }}
            />
          ) : (
            ""
          )}
        </InfoNameCol>
        {isInfoLoad ? (<InfoDescLoadTel>{'   '}</InfoDescLoadTel>) : (<InfoDesc openDesc={true}>
          {openDesc ? collectInfo?.description : (collectInfo && collectInfo.description && collectInfo.description.length > 70 ? collectInfo?.description?.slice(0, 70) + '...' : "--")}
        </InfoDesc>)}

        {collectInfo && collectInfo.description && collectInfo.description.length > 70 ? (<DescBtn
          onClick={() => {
            setOpenDesc(!openDesc);
          }}
        >
          <div>{openDesc ? "收起" : "展开"}</div>
          {/* <ArrowImg src={darkMode ? arrowUn : arrowU} alt="" /> */}
          <ArrowImg src={darkMode ? arrowUn : arrowU} style={openDesc ? { transform: 'rotate(180deg)' } : {}} alt="" />

        </DescBtn>) : null}</>) : null}
      {collectInfo?.drop ? (<MainBottomTitle isAct={pathname.includes("/overview")} onClick={() => navigate(`/nfts/collection/${chain}/${contract_address}/overview`)}>Overview</MainBottomTitle>) : null}

      <MainBottomTitle isAct={!pathname.includes("/overview") && !pathname.includes("/activity")} onClick={() => navigate(`/nfts/collection/${chain}/${contract_address}`)}>Items</MainBottomTitle>
      <MainBottomTitle isAct={pathname.includes("/activity")} onClick={() => navigate(`/nfts/collection/${chain}/${contract_address}/activity`)}>Activity</MainBottomTitle>


    </>
  );
}
