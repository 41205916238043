import { useState, useEffect } from "react";
import { Input} from "@rebass/forms";
import { useParams, useLocation } from "react-router-dom";
import { ButtonPurple } from "components/Button";
import TransferImg from "./components/TransferImg";
import { PageAll } from "./components_";
import {
  CardMain,
  CardTitle,
  CardItem,
  ItemLabel,
  ItemValue,
  inputStyle,
} from "../EditCollection/components_";
import { useAssetsListQuery } from "apis/assets";

import { useTransferHelperContract } from "hooks/useTranferHelper";
import { TransferHelperItemsWithRecipientStruct,TransferHelperItemStruct } from "abis/types/TransferHelper";

export default function Home() {
  const params = useParams();
  const location = useLocation();

  const [address, setAddress] = useState("");
  const [list, setList] = useState<Assets[]>([]);
  const { data } = useAssetsListQuery({ assets: location.search });
  const [contracts, setContracts] = useState<string[]>([])  //合约数量，用于检查需要授权操作
  const transferHelper = useTransferHelperContract(true);

  //批量转账方法 
  const transferItems = () => {
    const items: TransferHelperItemsWithRecipientStruct[] = []
    const conduitKey = ""
     try {
      transferHelper.bulkTransfer(items, conduitKey);
    } catch (error) {
      console.log('11111111')
    }
    
    // console.log('el',list)
    // console.log('transferHelper',transferHelper)
    // for (let i in list) {
    //   let item:TransferHelperItemStruct={
    //     itemType:1,
    //     token:list[i].token_id,
    //     identifier:list[i].chain_identifier,
    //     amount:0
    //   }
    //     console.log('el',list[i].contract_address)
    //     items.push(item)  //?
    //   }
   
  };

  useEffect(() => {
    if (data?.success) {
      console.log(data, "data");
      setList(data.data);
    }
  }, [data]);
  return (
    <>
      <PageAll>
        <CardMain>
          <CardTitle>Transfer items</CardTitle>
          <CardItem>
            <TransferImg list={list} />
          </CardItem>
          <CardItem>
            <ItemLabel>Address</ItemLabel>
            <ItemValue>
              <Input
                type="text"
                placeholder="e.g. 0x1ed3... or destination.eth, destination.lens"
                value={address}
                sx={inputStyle}
                onChange={(e) => {
                  setAddress(e.target.value);
                }}
              />
            </ItemValue>
          </CardItem>
          <ButtonPurple
            padding={"10px"}
            $borderRadius={"4px"}
            width={"100%"}
            onClick={() => transferItems()}
          >
            Transfer {list.length} items
          </ButtonPurple>
        </CardMain>
      </PageAll>
    </>
  );
}
