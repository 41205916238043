import { useCallback } from 'react'
import { useAppDispatch, useAppSelector } from '../hooks'

import { setCurrent } from './reducer'


export function useSupportChains(): [ChainInfo[], ChainInfo|null ,(chain: ChainInfo|null) => void] {
  const dispatch = useAppDispatch()
  const supportChains = useAppSelector((state) => state.chain.supportChains)
  const setCurrentChain = useCallback(
    (chain: ChainInfo|null) => {
      dispatch(setCurrent(chain))
    },
    [dispatch]
  )
  const current = useAppSelector((state) => state.chain.current)
  return [supportChains,current, setCurrentChain]
}

export function useSelectChain(chain_identifier?:string): ChainInfo|null {
    const supportChains = useAppSelector((state) => state.chain.supportChains)
     const a= supportChains.filter(e=>e.id===chain_identifier)
     return a[0]

  }
  export function useSelectChainByChainId(chainId:number|undefined): ChainInfo|null {
    const supportChains = useAppSelector((state) => state.chain.supportChains)
     const a= supportChains.filter(e=>e.chain_id===chainId?.toString())
     return a[0]

  }
  export function useCurrentChain(): ChainInfo|null {
    const current = useAppSelector((state) => state.chain.current)

     return current

  }