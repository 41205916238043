import ListSelect from "components/ListSelect";
import {
  LARGE_MEDIA_BREAKPOINT,
  MIN_WIDTH_MEDIA_BREAKPOINT,
} from "constants/width";
import { useEffect, useState } from "react";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import styled from "styled-components/macro";
import { useClientWidth } from "../../state/application/hooks";

import { useDarkModeManager } from "theme/components/ThemeToggle";
import topLogo from "../../assets/top_logo.png";
import { HeaderTop, MenuItem, TopRight, TopTelLeftText } from "./components_";

import "./index.css";

import { useSearchQuery } from "apis/search";
import Search from "components/Search";
import Web3Status from "components/Web3Status";
import searchIcon from "assets/tool/search_icon.png";

const TopLine = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 70px;
  display: flex;
  padding: 12px 4.2vw 12px;
  align-items: center;
  justify-content: space-between;
  background: ${({ theme }) => theme.modalBG1};
  backdrop-filter: blur(5px);
  box-sizing: border-box;
  z-index: 15;

  :last-child {
    margin-left: auto;
  }
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    width: 100vw;
    height: 70px;
    opacity: 1;
  }
`;

const LogoWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  > img {
    width: 40px;
    height: 40px;
  }
`;
const TopNav = styled.div`
  float: left;
  display: flex;
  align-items: center;
  min-width: 100px;
  margin-right: auto;
  flex-shrink: 0;
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    display: none;
  }
`;

const SearchWrapper = styled.div`
  width: 500px;
  float: left;
  > img {
    display: none;
    width: 25px;
    height: 25px;
    transform: revert;
  }
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    width: auto;
    float: right;
    text-align: right;
    > div {
      display: none;
    }
    > img {
      margin-right: auto;

      display: flex;
    }
  }
`;

export default function Header(prop: any) {
  let location = useLocation();
  const [darkMode] = useDarkModeManager();

  const clientWidth = useClientWidth();
  let navigate = useNavigate();
  const [isTel, setIsTel] = useState(false);
  const [name, setName] = useState("");
  const [isAddNft, setIsAddNft] = useState(false);
  const [isNft, setIsNft] = useState(true);
  const [showSearch, setShowSearch] = useState(true);
  const [isScroll, setIsScroll] = useState(false);
  const [isDropEditor, setIsDropEditor] = useState(false);

  if (isScroll !== prop.isScroll) {
    setIsScroll(prop.isScroll);
  }

  let [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    var tab = searchParams.get("tab");
    if (tab === "Drop page") {
      setIsDropEditor(true);
    } else {
      setIsDropEditor(false);
    }
  }, [searchParams]);

  if (Number(clientWidth) < 801 && !isTel) {
    setIsTel(true);
  } else if (Number(clientWidth) > 800 && isTel) {
    setIsTel(false);
  }
  useEffect(() => {
    let url = window.location.pathname;
    if (url === "/creat-nft") {
      setIsAddNft(true);
    } else {
      setIsAddNft(false);
    }
    if (
      url === "/nfts" ||
      url === "/" ||
      url === "/drop" ||
      url === "/droplist"
    ) {
      setIsNft(true);
    } else {
      setIsNft(false);
    }
  }, []);

  return (
    <>
      <HeaderTop>
        {/* <TopLine>
          <Link to={"/"}>
            {" "}
            <TopTelLeft isShow={!isAddNft}>
              <TopTelLeftImg src={topLogo} style={{ marginRight: "5px" }} />
              <TopTelLeftText className="fontFrk3">zooker.io</TopTelLeftText>
            </TopTelLeft>
          </Link>
          <FlexBlock>
            {" "}
            <SearchImg
              src={searchIcon}
              style={{ marginRight: "5px" }}
              onClick={() => setIsSearch(true)}
            />
            <Web3Status />
          </FlexBlock>
        </TopLine> */}
        <TopLine>
          <Link to={"/"}>
            <LogoWrapper>
              <img
                src={topLogo}
                style={{ marginRight: "5px" }}
                alt="zooker.io"
              />
              <TopTelLeftText className="fontFrk3">zooker.io</TopTelLeftText>
            </LogoWrapper>
          </Link>

          <div style={{ float: "left", textAlign: "left" }}>
            <TopNav>
              <MenuItem
                onClick={() => {
                  navigate("/nfts");
                }}
              >
                NFTs
              </MenuItem>
              <MenuItem onClick={() => navigate("/cryptos")}>Crypto</MenuItem>
              <div style={{ width: "auto" }}>
                <ListSelect
                  value={"value"}
                  defaultText={"Stats"}
                  optionArr={["Token Stats", "NFT Stats", "Account Stats"]}
                  typeValues={[
                    "/tokens/rankings/top",
                    "/nfts/rankings/top",
                    "",
                  ]}
                  onChange={(str) => {
                    navigate(str);
                  }}
                  size=""
                />
                {/* Stats */}
              </div>
            </TopNav>
            <SearchWrapper>
              <div style={{ display: showSearch ? "" : "none" }}>
                <Search />
              </div>
              <img
                alt=""
                src={searchIcon}
                style={{ marginRight: "5px" }}
                onClick={() => setShowSearch(true)}
              />
            </SearchWrapper>
          </div>
          <TopRight>
            <Web3Status />
          </TopRight>
        </TopLine>
      </HeaderTop>
    </>
  );
}
