import styled from "styled-components";
import { Input } from "@rebass/forms";
import { useState, useEffect } from "react";
import { useSwitchChain } from 'hooks/useSwitchChain';

import { useWeb3React } from "@web3-react/core";
import { useNavigate } from "react-router-dom";
import { useContractFactory } from "hooks/useContract";
import { useContractDeployedMutation } from "apis/account";
import ABI from "abis/erc721SeaDrop.json";
import bytecode from "bytecode/erc721SeaDropBytecode.json";
import closeIcon from "assets/tool/close_icon.png";
import ChainIDSelect from "components/ChainIDSelect";

import {useToastOpen } from 'state/stats/hooks'
import { useGetChainListQuery } from "apis/chain"
import ButtonLoadingSpinner from "components/Button/LoadingButtonSpinner";
import { Box } from "rebass";


export const DeployContractMain = styled.div`
  width: 500px;
  padding: 25px;
  box-sizing: border-box;
  background: ${({ theme }) => theme.bg1};
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  font-family: Inter-Medium, Inter;
  font-weight: 500;
  color: ${({ theme }) => theme.text1};
  line-height: 35px;
  text-align: center;
`;
const PopupTitle = styled.div`
  font-size: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;
`;
const inputStyle = {
  outline: "none",
  width: "100%",
  height: "40px",
  border: "none",
  textAlign: "left",
  background: "transparent",
};
const PopupClose = styled.img`
  width: 1.5rem;
  height: 1.5rem;
`;
const FromLable = styled.div`
  font-size: 1.2rem;
  text-align: left;
  margin-top: 10px;
`;
const FromInput = styled.div`
  width: 100%;
  height: 38px;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.border1};
  overflow: hidden;
`;
const PopupBtn = styled.button`
  align-items: center;

  border-radius: 16px;
  border: 0;

  cursor: pointer;
  display: flex;
  flex-direction: row;

  font-weight: 535;
  gap: 12px;
  justify-content: center;

  position: relative;
  transition: 150ms ease opacity;
  user-select: none;
  width: 100%;
  height: 38px;
  color: ${({ theme }) => theme.bg0};
  border-radius: 4px;
  background: #6a5bda;
  margin-top: 20px;
`;

interface ChainSelectProps {
  size: string;
  value: string;
  onClose: () => void;
  onChange: (value: string) => void;
}

export default function CreateErc721AForm({
  onChange,
  onClose,
}: ChainSelectProps) {
  const { chainId, connector } = useWeb3React();
  let navigate = useNavigate();
  const switchChain = useSwitchChain()
  const [,,, setToastOpen] = useToastOpen();

  const [contractDeployed] = useContractDeployedMutation();

  const [name, setName] = useState("");
  const [symbol, setSymbol] = useState("");

  const [loading, setLoading] = useState(false);
  const [chainList, setChainList] = useState<ChainInfo[]>([]);
  const [selectChain, setSelectChain] = useState<ChainInfo>();

  const seadropErc721Factory = useContractFactory(ABI, bytecode);
  const { data, status } = useGetChainListQuery({})
  const handleCreate = async () => {
    if (name === "" && symbol === "") {
      return;
    }
    if(!chainId){
      setToastOpen({open:true,type:'error',label:"plase connect wallet"})

      return
    }
    if (selectChain?.seadrop_impl) {
      if(selectChain.chain_id!==String(chainId)){
        setToastOpen({open:true,type:'error',label:'plase switch chain'})

        try {
          await switchChain(connector, Number(selectChain.chain_id),selectChain)
        } catch (err) {
          setToastOpen({open:true,type:'error',label:(err as Error).message})

        }
      }else{
        console.log('======================')

        let chain = selectChain?.id
        setLoading(true);
        const seadrop_address =selectChain?.seadrop_impl;
        console.log('+++++++++++++++++++++++',seadrop_address)

        try {
          let contract = await seadropErc721Factory?.deploy(name, symbol, [
            seadrop_address,
          ]);
          const response = await contract?.deployTransaction.wait();
          if (response != null) {
            console.log('response', response)
            console.log('chainId', chainId)
  
            const { success, message, status } = await contractDeployed({
              chain:selectChain?.id ||'',
              block_number: response?.blockNumber,
              chain_id: chainId as number,
              chain_type: "evm",
              event: "SeaDropTokenDeployed",
              transaction_hash: response.transactionHash,
              name: name,
              symbol: symbol,
              contract_address: response.contractAddress,
              token_standard: "erc721",
            }).unwrap();
            if (success) {
              let contractAddress=response.contractAddress.toLocaleLowerCase()
              navigate(`/nfts/${chain}/${contractAddress}/edit?tab=Details`);
              onChange('')
            } else {
              setToastOpen({open:true,type:'error',label:message})

            }
          }
        } catch (err) {
          setToastOpen({open:true,type:'error',label:(err as Error).message})

        }
        setLoading(false);
      }
     
    } else {
      setToastOpen({open:true,type:'error',label:"该chain不支持"})

    }


  };
  useEffect(() => {
    if (status === "fulfilled" && data?.data && data.data !== chainList) {
      console.log('data', data)
      setChainList(data.data)
      data.data.forEach(el => {
        if (el.chain_id === String(chainId)) {
          setSelectChain(el)
        }
      })
    }
  }, [data, status]);

  return (
    <>
      <DeployContractMain>
        <PopupTitle>
          <div>Create Contract</div>
          <PopupClose src={closeIcon} onClick={() => onClose()} alt="" />
        </PopupTitle>
        <FromLable>Chain</FromLable>
        <ChainIDSelect
          size={""}
          optionArr={chainList}
          value={selectChain?.chain_id as string}
          onChange={async function (value: ChainInfo): Promise<void> {
            console.log(value);
            setSelectChain(value)
          }}
        />

        <FromLable>Name</FromLable>
        <FromInput>
          <Input
            disabled={loading}
            type="text"
            placeholder="My Collection Name"
            value={name}
            sx={inputStyle}
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
        </FromInput>
        <FromLable>Symbol</FromLable>

        <FromInput>
          <Input
            disabled={loading}
            type="text"
            placeholder="MCN"
            value={symbol}
            sx={inputStyle}
            onChange={(e) => {
              setSymbol(e.target.value);
            }}
          />
        </FromInput>
        <PopupBtn
          style={
            symbol !== "" && name !== "" ? { opacity: 1 } : { opacity: 0.7 }
          }
          disabled={loading}
          onClick={() => handleCreate()}
        >
          <span>Apply</span>
          <Box sx={{ float: "right", position: "absolute", right: 0 }}>
            {loading ? <ButtonLoadingSpinner /> : null}
          </Box>
        </PopupBtn>
      </DeployContractMain>
    </>
  );
}
