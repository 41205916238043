import { Box, Button, Flex, Text } from "rebass";
import { ChevronDown } from "react-feather";
import { Z_INDEX } from "theme/zIndex";
import styled from "styled-components";
import { ButtonPrimary } from "components/Button";
import { useState } from "react";
import DateTimePicker from "components/DateTimePicker";

const Container = styled(Flex)`
  border-radius: 8px;
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
  background: ${({ theme }) => theme.modalBG1};
  border: 1px solid ${({ theme }) => theme.bg2};
  justify-content: flex-start;
  padding: 18px 24px;
  flex-direction: column;
  > div {
    flex-direction: row;
  }
  > input {
    border: 0;
    text-align: right;
    font-weight: 700;
    margin: 0;
    padding: 0;
  }
`;

const ButtonWrap = styled.button`
  padding: 8px 12px;
  align-items: center;
  align-content: center;
  display: flex;
  cursor: pointer;
`;

const ValueList = styled(Flex)`
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  > button {
    margin: 0 5px;
    padding: 2px 8px;
  }
`;

const ValueItem = styled.button<{ selected?: boolean }>`
  background: ${({ theme, selected }) => (selected ? theme.bg0 : theme.bg2)};
  border-radius: 8px;
  color: ${({ theme, selected }) => (selected ? theme.text1 : theme.text4)};
  cursor: pointer;
`;

export default function DurationInput({
  value,
  onChange,
}: {
  value: number | string;
  onChange: (value: number | string) => void;
}) {

  return (
    <>
      <ValueList style={{ position: "relative" }}>
        <ValueItem selected={value==="1day"?true:false} onClick={()=>onChange("1day")}>1 day</ValueItem>
        <ValueItem selected={value==="1week"?true:false}  onClick={()=>onChange("1week")}>1 week</ValueItem>
        <ValueItem selected={value==="1month"?true:false}  onClick={()=>onChange("1month")}>1 month</ValueItem>
        <ValueItem  selected={value==="1year"?true:false}  onClick={()=>onChange("1year")}>1 year</ValueItem>
      </ValueList>
    </>
  );
}
