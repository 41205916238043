import React, { useMemo } from 'react'
import { Text, TextProps as TextPropsOriginal } from 'rebass'
import styled, {
  createGlobalStyle,
  css,keyframes,
  DefaultTheme,
  ThemeProvider as StyledComponentsThemeProvider,
} from 'styled-components/macro'
import { useIsDarkMode } from 'theme/components/ThemeToggle'
import { Colors } from './styled'
import { colors, darkTheme, lightTheme } from './colors'
import { transitions } from './styles'
import { opacify } from './utils'
export * from './components'
export * from './components/text'

export const MEDIA_WIDTHS = {
  upToExtraSmall: 500,
  upToSmall: 720,
  upToMedium: 960,
  upToLarge: 1280,
}

export const BREAKPOINTS = {
  xs: 396,
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  xxl: 1536,
  xxxl: 1920,
}
const gapValues = {
  xs: '4px',
  sm: '8px',
  md: '12px',
  lg: '24px',
  xl: '32px',
}
export type Gap = keyof typeof gapValues
// Migrating to a standard z-index system https://getbootstrap.com/docs/5.0/layout/z-index/
// Please avoid using deprecated numbers
export enum Z_INDEX {
  deprecated_zero = 0,
  deprecated_content = 1,
  dropdown = 1000,
  sticky = 1020,
  fixed = 1030,
  modalBackdrop = 1040,
  offcanvas = 1050,
  modal = 1060,
  popover = 1070,
  tooltip = 1080,
}

const mediaWidthTemplates: { [width in keyof typeof MEDIA_WIDTHS]: typeof css } = Object.keys(MEDIA_WIDTHS).reduce(
  (accumulator, size) => {
    ;(accumulator as any)[size] = (a: any, b: any, c: any) => css`
      @media (max-width: ${(MEDIA_WIDTHS as any)[size]}px) {
        ${css(a, b, c)}
      }
    `
    return accumulator
  },
  {}
) as any

const white = '#FFFFFF'
const black = '#000000'

function getColors(darkMode: boolean): Colors {
  return {
    darkMode,
    // base
    white,
    black,

    // text
    
    text1: darkMode ? '#FFFFFF' : '#000000',
    text2: darkMode ? '#C3C5CB' : '#565A69',
    text3: darkMode ? '#8F96AC' : '#6E727D',
    text4: darkMode ? '#B2B9D2' : '#C3C5CB',
    text5: darkMode ? '#2C2F36' : '#EDEEF2',

    // backgrounds / greys
    bg0: darkMode ? '#191B1F' : '#FFF',
    bg1: darkMode ? '#212429' : '#F7F8FA',
    bg2: darkMode ? '#2C2F36' : '#EDEEF2',
    bg3: darkMode ? '#40444F' : '#CED0D9',
    bg4: darkMode ? '#565A69' : '#888D9B',
    bg5: darkMode ? '#6C7284' : '#888D9B',
    bg6: darkMode ? '#1A2028' : '#6C7284',
    btnbg1: darkMode ? '#6a5bda' : '#1f1c35',
    btnbg2: darkMode ? '#1f1c35' : '#7766f3',
    btnbg3: darkMode ? '#4b4b4b' : '#eeeeee',
    border1:darkMode ? '#626262' : '#dddddd',
    border2:darkMode ? '#9183F6' : '#dddddd',

    //specialty colors
    modalBG: darkMode ? 'rgba(0,0,0,.425)' : 'rgba(0,0,0,0.3)',
    modalBG1: darkMode ? 'rgba(0, 0, 0, 0.5)' : 'rgba(255, 255, 255, 0.5)',
    modalBG2: darkMode ?  'rgba(255, 255, 255, 0.2)': 'rgba(0, 0, 0, 0.5)' ,
    modalDanger: 'rgba(255, 0, 0, 0.15)',
    advancedBG: darkMode ? 'rgba(0,0,0,0.1)' : 'rgba(255,255,255,0.6)',

    //primary colors
    primary1: darkMode ? '#FF4343' : '#DA2D2B',
    primary2: darkMode ? '#3680E7' : '#FF8CC3',
    primary3: darkMode ? '#4D8FEA' : '#FF99C9',
    primary4: darkMode ? '#376bad70' : '#F6DDE8',
    primary5: darkMode ? '#153d6f70' : '#FDEAF1',

    // color text
    primaryText1: darkMode ? '#5090ea' : '#D50066',

    // secondary colors
    secondary1: darkMode ? '#2172E5' : '#E8006F',
    secondary2: darkMode ? '#17000b26' : '#F6DDE8',
    secondary3: darkMode ? '#17000b26' : '#FDEAF1',

    // other
    red1: darkMode ? '#FF4343' : '#DA2D2B',
    red2: darkMode ? '#F82D3A' : '#DF1F38',
    red3: '#D60000',
    red4: '#e62929',

    green1: darkMode ? '#27AE60' : '#007D35',
    yellow1: '#E3A507',
    yellow2: '#FF8F00',
    yellow3: '#F3B71E',
    purple1:'#6a5bda',
    black1:"#4d4d4d",
    blue1: darkMode ? '#2172E5' : '#0068FC',
    blue2: darkMode ? '#5199FF' : '#0068FC',
    error: darkMode ? '#FD4040' : '#DF1F38',
    success: darkMode ? '#27AE60' : '#007D35',
    warning: '#FF8F00',

    // dont wanna forget these blue yet
    blue4: darkMode ? '#153d6f70' : '#C4D9F8',
    // blue5: darkMode ? '#153d6f70' : '#EBF4FF',
    backgroundSurface:darkMode ?  colors.gray900 :colors.white,
    accentActionSoft:darkMode ? opacify(24, colors.blue400): opacify(12, colors.pink400),
    accentAction:darkMode ?  colors.blue400 : colors.pink400,
    backgroundOutline:darkMode ?  darkTheme.backgroundOutline : lightTheme.backgroundOutline,
    backgroundScrim:darkMode ?  darkTheme.backgroundScrim : lightTheme.backgroundScrim,

    deepShadow:darkMode ?  darkTheme.deepShadow : lightTheme.deepShadow,
    textSecondary:darkMode ?  darkTheme.textSecondary : lightTheme.textSecondary,
    stateOverlayHover:darkMode ?  darkTheme.stateOverlayHover : lightTheme.stateOverlayHover,
    textPrimary:darkMode ?  darkTheme.textPrimary : lightTheme.textPrimary,
    textTertiary:darkMode ?  darkTheme.textTertiary : lightTheme.textTertiary,
    backgroundInteractive:darkMode ?  darkTheme.backgroundInteractive : lightTheme.backgroundInteractive,
    backgroundModule:darkMode ?  darkTheme.backgroundModule : lightTheme.backgroundModule,
    accentActive:darkMode ?  darkTheme.accentActive : lightTheme.accentActive,

    hoverState: darkMode ?  darkTheme.hoverState : lightTheme.hoverState,
    hoverDefault: darkMode ?  darkTheme.hoverDefault : lightTheme.hoverDefault,
  }
}
const opacities = {
  hover: 0.6,
  click: 0.4,
  disabled: 0.5,
  enabled: 1,
}

function theme(darkMode: boolean): DefaultTheme {
  return {
    ...getColors(darkMode),
    // darkMode,
    //...(darkMode ? darkTheme : lightTheme),
    grids: gapValues,
    opacity: opacities,
    breakpoint: BREAKPOINTS,
    breakpoints:["800px","1440px"],
    //shadows
    shadow1: darkMode ? '#000' : '#2F80ED',

    // media queries
    mediaWidth: mediaWidthTemplates,
    transition: transitions,
    // css snippets
    flexColumnNoWrap: css`
      display: flex;
      flex-flow: column nowrap;
    `,
    flexRowNoWrap: css`
      display: flex;
      flex-flow: row nowrap;
    `,
  }
}




export default function ThemeProvider({ children }: { children: React.ReactNode }) {
  const darkMode = useIsDarkMode()

  const themeObject = useMemo(() => theme(darkMode), [darkMode])
  console.log("themeObject.primary1:",themeObject.primary1)
  return <StyledComponentsThemeProvider theme={themeObject}>{children}</StyledComponentsThemeProvider>
}

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

const SpinnerCss = css`
  animation: 2s ${rotate} linear infinite;
`

export const SpinnerSVG = styled.svg`
  ${SpinnerCss}
`

/**
 * Preset styles of the Rebass Text component
 */


export const ThemedGlobalStyle = createGlobalStyle`
html {
  color: ${({ theme }) => theme.text1};
  background-color: ${({ theme }) => theme.bg1} !important;
}
body {

  overflow-x:hidden
}
summary::-webkit-details-marker {
  display:none;
}
div {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}
a {
  color: ${({ theme }) => theme.accentAction}; 
  text-decoration:none;
}
&:focus {
  outline: none;
}
* {
  box-sizing: border-box;
  margin:0;
  padding:0;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}
`
