
import { ShortToken } from 'types/token';
import { emptySplitApi } from './emptySplitApi'


export interface CreateCollectionResponse {
  data: {
    user: User;
    token: string;
  };

  msg: null | string;
  code: "fail" | "ok";
}

export interface CryptoTradeActivity {
  chain:string;
  timestamp:number;
  type: string;
  tx_hash: string;
  from_account: string;
  to_account: string;
  amount0:number;
  amount1:number;
  base_symbol:string;
  base_currency:string;
  token0:ShortToken;
  token1:ShortToken;

  price: number;
  usd_price: number;
  usd_volume:number

}


const activityApi = emptySplitApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({   
    activityList: builder.query<{

        data: ActivityItem[]
        total: number;
        message: null | string;
        success: boolean;
      
    }, Record<string, any>>({
      query: (params) => ({
        url: `/activity?page=${params.current}&pageSize=${params.pageSize}&chain=${params.chain}&contract_address=${params.address}${params?.tokenid?'&token_id='+params?.tokenid:''}${params.type?'&type='+params.type:''}`,
        method: "GET",
      }),
    }),
    cryptoTradeHistoryList: builder.query<{

      data: CryptoTradeActivity[]
      total: number;
      message: null | string;
      success: boolean;
    
  }, Record<string, any>>({
    query: (params) => ({
      url: `/activity/crypto-trade-history?page=${params.current}&pageSize=${params.pageSize}&chain=${params.chain}&contract_address=${params.address}`,
      method: "GET",
    }),
  }),

  }),
});

export const {

  useActivityListQuery,
  useCryptoTradeHistoryListQuery,
} = activityApi;
