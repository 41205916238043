import { useState, useEffect } from "react";
import { Input, Textarea, } from "@rebass/forms";
import UploadDropzone from "components/Form/upload"




import {
  CardBox,CardMainFlex,CardMainLeft,CardItem,CardTitle,ItemValue,ItemValueArea,ItemLabel,CardMainRight,ItemTip,ItemUpImg,areaStyle,inputStyle,UploadBox
} from "../../components_"
import { useUploadImgMutation } from 'apis/file';

export interface PreReveal {
  preName?: string;
  preDesc?:string;
  preImg?:string;
}

interface PreRevealFormProps {
  visible: boolean;
  value?: PreReveal;
  onChange: (value: PreReveal) => void;
}

export default function LinksForm({
  visible,
  value,
  onChange,
}: PreRevealFormProps) {
  const [uploadImg]=useUploadImgMutation()
  
  const [loading, setLoading] = useState(false);
  const [preImg, setPreImg] = useState<string>('');


  useEffect(() => {
    if(value&&value.preImg){
      setPreImg(value.preImg)
    }
  }, [value]);
  return <> <CardBox isAct={visible}>
  <CardMainFlex>
    <CardMainLeft>
    <CardTitle>Pre-reveal</CardTitle>
    <CardItem>
    <ItemTip>
      Each NFT in your collection will show your pre-reveal media until you upload and reveal your final assets. Learn more
      </ItemTip>
    </CardItem>
    <CardItem>
      <ItemLabel>Description</ItemLabel>
      <ItemTip>
      This will be shown on your item pages
      </ItemTip>
      <ItemValueArea>
        <Textarea
          id="comment"
          name="comment"
          placeholder="Enter a description"
          value={value?.preDesc}
          sx={areaStyle}
          onChange={(e) => {
            onChange({preDesc:e.target.value});
          }}
        />
      </ItemValueArea>
    </CardItem>
    
    </CardMainLeft>
    <CardMainRight>
    <CardItem>
      
      <ItemUpImg>
      <UploadBox>
            <UploadDropzone url={preImg} urlChange={(url) => onChange({ preImg: url })}></UploadDropzone>
          </UploadBox>
       
      </ItemUpImg>
     
    </CardItem>
    <CardItem> <ItemTip style={{textAlign:'center'}}>Drag and drop or click to upload</ItemTip><ItemTip style={{textAlign:'center'}}>

Recommended size: 1:1 aspect ratio. 1000px x 1000px. Supported image formats: JPG, PNG, SVG or GIF</ItemTip></CardItem>
    <CardItem>
      <ItemLabel>Name</ItemLabel>
      <ItemValue>
        <Input
          type="text"
          placeholder="Enter a custom name"
          value={value?.preName}
          sx={inputStyle}
          onChange={(e) => {
            onChange({preName:e.target.value});
          }}
        />
      </ItemValue>
    </CardItem>
    </CardMainRight>
   

   
  </CardMainFlex>
</CardBox></>;
}
