import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useWeb3React } from "@web3-react/core";
import { useToastOpen } from 'state/stats/hooks';
import { openWindow, priceText, timestampToTime } from "utils";

import { ButtonPurple, ButtonTransparent } from "components/Button";
import RadixPopover from "components/Popover";

import "../../index.scss";

import { OrderComponents, OrderWithCounter } from "@opensea/seaport-js/lib/types";
import actBuy from "assets/h5/act_Buy.png";
import actSell from "assets/h5/act_Sell.png";
import actTransfer from "assets/h5/act_Transfer.png";
import nftDefault from "assets/nft/nft_defult.png";
import copyIcon from "assets/nft_copy.png";
import arrowU from "assets/tool/arrow_u.png";
import arrowUn from "assets/tool/arrow_u_n.png";
import refresh from "assets/tool/refresh.png";
import refreshN from "assets/tool/refresh_n.png";
import {
  ActivityIcon, ActivityIconRotate
} from "pages/Nft/components_";
import {
  WebMain
} from "pages/Nft/NftItems/components_";
import { useDarkModeManager } from "theme/components/ThemeToggle";
import {
  AddressImg,
  AILine1,
  AILine2,
  AILine3,
  AttributeCard,
  AttributeItem,
  AttributeItemLoad,
  AttributeItemNone,
  BuyBtn, BuyBtnTransparent,
  CardItem,
  CardMain,
  CardTableMain, CardTableMainRow, CardTableTitleRow,
  CardTableTitleRow2,
  CardTop,
  CardTopImg,
  DetailCard,
  DetailItem,
  DetailItemL,
  DetailItemR,
  DetailItemR1,
  InfoDescLoad,
  PriceCard,
  PriceCardL,
  PriceCardLine,
  PriceCardLText1,
  PriceCardLText2,
  RAddress,
  RTitle,
  RTitleLoad,
  TabItem,
  TabLine,
  WebBg,
  WebMainTop,
  WebMainTopDiv,
  WebMainTopImg,
  WebMainTopImgLoad,
  WebMainTopL,
  WebMainTopR
} from "./components";

export interface DetailFormsWeb {
  recommendLoad?: boolean;
  recommendList?: Assets[];
  address?: string;
  itemInfo?: Assets | null;
  itemInfoLoad?: boolean;
  listings?: OrderItem[];
  offers?: OrderItem[];
  actList?: ActivityItem[];
  chainCurrency?: ChainInfo | null;
  isRefresh?: boolean
}
interface DetailFormPropsWeb {
  fullfillOrder: (order: OrderWithCounter, hash: string, proaddress: string) => void;
  cancelOrder: (order: OrderComponents[]) => void;
  visible?: boolean;
  value?: DetailFormsWeb;
  onChange: () => void;
  openSale: () => void;
  openOffer: () => void;
}

export default function DetailFormWeb({
  fullfillOrder, cancelOrder, openOffer,
  value,
  onChange, openSale
}: DetailFormPropsWeb) {

  let navigate = useNavigate();

  const { account } = useWeb3React();
  const [, , , setToastOpen] = useToastOpen();
  const [recommendListNone, setRecommendListNone] = useState([1, 1, 1, 1, 1, 1]);
  // const [recommendLoad, setRecommendLoad] = useState(true);
  const [itemInfoLoad, setItemInfoLoad] = useState(true);

  const [darkMode] = useDarkModeManager();
  const [attributeList, setAttributeList] = useState<ItemTraits[] | null>();
  const [itemInfo, setItemInfo] = useState<Assets | null>();
  const [address, setAddress] = useState('');

  const [showCard1, setShowCard1] = useState(true);
  const [cardType, setCardType] = useState('buy');//sell buy
  const [showCard2, setShowCard2] = useState(true);
  const [showCard3, setShowCard3] = useState(true);
  const [showCard6, setShowCard6] = useState(true);
  const [showCard8, setShowCard8] = useState(true);
  const [showCard9, setShowCard9] = useState(true);
  const [actList, setActList] = useState<ActivityItem[]>([]);
  const [chainCurrency, setChainCurrency] = useState<ChainInfo | null>();

  // const [list1, setList1] = useState<Assets[]>([]);

  var div = document.getElementById('actmain');


  useEffect(() => {
    if (value && value.itemInfo !== itemInfo) {
      setItemInfo(value.itemInfo);
      setItemInfoLoad(value.itemInfoLoad || false);
      let arr = value?.itemInfo?.traits
      let list: any = []

      arr?.forEach((el: any) => {
        var num = 0
        let item: any = {
          trait_count: el.trait_count,
          trait_type: el.trait_type,
          value: el.value,

        }
        if (value.itemInfo?.collection?.stats?.count) {
          num = (el.trait_count / value.itemInfo.collection.stats.count) * 100
          item = {
            trait_count: el.trait_count,
            trait_type: el.trait_type,
            value: el.value,
            num: num,
          }
        }


        list.push(item)
      })
      setAttributeList(list)
      setAddress(value?.itemInfo?.contract_address || '')

    }
    if (value && value.actList !== actList) {
      setActList(value.actList || []);
    }
    if (value && value.chainCurrency != chainCurrency) {
      setChainCurrency(value.chainCurrency)
    }
    // if (value && value.recommendList != list1) {
    //   let list = value.recommendList || []
    //   let templist: Assets[] = []
    //   list?.forEach((el: any, index: number) => {
    //     if (index < 6) {
    //       templist.push(el)
    //     }
    //   });
    //   setList1(list)
    //   setRecommendList(templist);
    //   setRecommendLoad(value.recommendLoad || false);
    // }
    // if (value && value.recommendLoad != recommendLoad) {
    //   setRecommendLoad(value.recommendLoad || false);
    // }
  }, [value]);





  const toPage = (e: string) => {
    navigate(-1)
    setTimeout(() => {
      navigate(e)
    }, 50);
  }






  return (
    <><div>
      <WebBg></WebBg>

      <WebMain>
        <WebMainTop>
          <WebMainTopL>
            {itemInfoLoad ? (<WebMainTopImgLoad />) : (
              <WebMainTopDiv><WebMainTopImg src={itemInfo?.image_url || nftDefault} alt="" /></WebMainTopDiv>
            )}

            <CardItem style={attributeList && attributeList.length > 0 ? { marginBottom: '0', borderBottom: 'none', borderBottomLeftRadius: '0', borderBottomRightRadius: '0' } : { height: '0', overflow: 'hidden', marginBottom: '0', border: 'none', borderBottomLeftRadius: '0', borderBottomRightRadius: '0' }}>
              <CardTop onClick={() => setShowCard1(!showCard1)}>
                <div>Attribute</div>
                <CardTopImg
                  src={darkMode ? arrowUn : arrowU}
                  style={
                    showCard1
                      ? { transform: "rotateX(180deg)" }
                      : { marginBottom: "6px" }
                  }
                />
              </CardTop>

              {itemInfoLoad ? (<AttributeCard isShow={showCard1}>

                {recommendListNone?.map(() => (
                  <AttributeItemLoad>
                  </AttributeItemLoad>
                ))}
                <AttributeItemNone />
                <AttributeItemNone />
              </AttributeCard>) :
                attributeList && attributeList.length > 0 ? (<AttributeCard isShow={showCard1}>

                  {attributeList?.map((i: any, index: number) => (
                    <AttributeItem
                      key={index}
                      style={darkMode ? { background: "transparent" } : {}}
                    >
                      <AILine1 style={darkMode ? { color: "#CACACA" } : {}}>
                        {i.trait_type}
                      </AILine1>
                      <AILine2>{i.value}</AILine2>
                      <AILine3 style={darkMode ? { color: "#CACACA" } : {}}>
                        {i.num ? `${i.num.toFixed(2)}% have this trait` : null}
                      </AILine3>
                    </AttributeItem>
                  ))}
                  <AttributeItemNone />
                  <AttributeItemNone />
                  <AttributeItemNone />
                  <AttributeItemNone />
                </AttributeCard>) : ('')
              }

            </CardItem>
            <CardItem style={attributeList && attributeList.length > 0 ? { borderTopLeftRadius: '0', borderTopRightRadius: '0' } : {}}>
              <CardTop onClick={() => setShowCard3(!showCard3)}>
                <div>Details</div>
                <CardTopImg
                  src={darkMode ? arrowUn : arrowU}
                  style={
                    showCard3
                      ? { transform: "rotateX(180deg)" }
                      : { marginBottom: "6px" }
                  }
                />
              </CardTop>
              <DetailCard isShow={showCard3}>
                <DetailItem style={{ marginTop: "0" }}>
                  <DetailItemL>Contract</DetailItemL>
                  {itemInfoLoad ? (<InfoDescLoad>{'        '}</InfoDescLoad>) : (<DetailItemR1>
                    <div>{address.substring(0, 3) + '...' + address.substr(address.length - 5,)}</div>
                    {/* {itemInfo?.asset_contract.address} */}
                    <AddressImg src={copyIcon} alt="" />
                  </DetailItemR1>)}

                </DetailItem>
                <DetailItem>
                  <DetailItemL>TOKEN ID</DetailItemL>
                  <DetailItemR>   {itemInfoLoad ? (<InfoDescLoad>{'        '}</InfoDescLoad>) : itemInfo ? itemInfo.token_id.length > 10 ? itemInfo.token_id.substring(0, 3) + '...' + itemInfo.token_id.substr(itemInfo.token_id.length - 5,) : itemInfo.token_id : ''}</DetailItemR>
                </DetailItem>
                <DetailItem>
                  <DetailItemL>Token Standard</DetailItemL>
                  <DetailItemR> {itemInfoLoad ? (<InfoDescLoad>{'        '}</InfoDescLoad>) : itemInfo?.token_standard}

                  </DetailItemR>
                </DetailItem>
                <DetailItem>
                  <DetailItemL>Network</DetailItemL>
                  <DetailItemR>
                    {itemInfoLoad ? (<InfoDescLoad>{'        '}</InfoDescLoad>) : itemInfo?.chain_identifier}

                  </DetailItemR>
                </DetailItem>
                <DetailItem>
                  <DetailItemL>Source</DetailItemL>
                  <DetailItemR> {itemInfoLoad ? (<InfoDescLoad>{'        '}</InfoDescLoad>) : itemInfo?.source}</DetailItemR>
                </DetailItem>
              </DetailCard>
            </CardItem>
          </WebMainTopL>
          <WebMainTopR>
            {itemInfoLoad ? (<RTitleLoad>{'        '}</RTitleLoad>) : (
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <RTitle style={{ cursor: 'pointer', maxWidth: 'calc(100% - 30px)' }} onClick={() => navigate(
                  `/nfts/collection/${itemInfo?.chain_identifier}/${itemInfo?.contract_address}`
                )}>
                  {itemInfo?.name ? itemInfo?.name : '# ' + itemInfo?.token_id}
                  {/* {itemInfo?.name} #{itemInfo?.token_id} */}
                </RTitle>
                {
                  value?.isRefresh ? (
                    <ActivityIconRotate src={darkMode ? refreshN : refresh} />

                  ) : (<ActivityIcon onClick={() => onChange()} src={darkMode ? refreshN : refresh} />
                  )
                }

              </div>
            )}
            {itemInfoLoad ? (<RTitleLoad>{'        '}</RTitleLoad>) : (<RAddress onClick={() => { navigator.clipboard.writeText(address); setToastOpen({ open: true, type: 'success', label: "success" }) }}>
              <div>{address.substring(0, 7) + '...' + address.substr(address.length - 5,)}</div>
              <AddressImg src={copyIcon} alt="" />
            </RAddress>)}
            <CardItem>
              <PriceCard isShow={true}>
                {value?.itemInfo?.owner_address && account && ((value.itemInfo.owner_address)?.toUpperCase() === account?.toUpperCase()) ? (<div style={{ width: '100%' }}>

                  <TabLine>
                    <TabItem isAct={cardType === 'sell'} onClick={() => setCardType('sell')}>Sell</TabItem>
                    <TabItem isAct={cardType === 'buy'} onClick={() => setCardType('buy')}>Buy</TabItem>
                    {/* <ButtonTransparent padding={'5px 10px'} $borderRadius={'4px'} width={'auto'} border="none" onClick={() => setCardType('buy')}>Buy</ButtonTransparent> */}
                  </TabLine>
                  {cardType === 'sell' ? (<>
                    <BuyBtn onClick={() => { openSale() }}>
                      List 1 item</BuyBtn></>) : (
                    <> <PriceCardL>
                      <PriceCardLText1>{value?.listings?.[0] ? 'price' : value?.offers?.[0] ? 'Best Offer' : ''}</PriceCardLText1>
                      {/* <div style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}> */}
                      {itemInfoLoad ? (<RTitleLoad style={{ width: '100%' }}>{'   '}</RTitleLoad>) : (<><PriceCardLine style={{ justifyContent: 'space-between' }}>
                        <PriceCardLText2>
                          <div>
                            {value?.listings?.[0]?.price && value?.listings?.[0]?.end_time && value?.listings?.[0]?.start_time
                              && (value?.listings?.[0]?.end_time > (new Date().getTime() / 1000)) && (value?.listings?.[0]?.start_time < (new Date().getTime() / 1000))
                              ? priceText(value.listings[0].price) : 'Not listed...'}
                          </div>
                        </PriceCardLText2>

                      </PriceCardLine>
                        <PriceCardLine style={{ width: '100%', justifyContent: 'space-between', marginTop: '10px' }}>
                          {value?.listings?.[0]?.price && value?.listings?.[0]?.end_time && value?.listings?.[0]?.start_time
                            && (value?.listings?.[0]?.end_time > (new Date().getTime() / 1000)) && (value?.listings?.[0]?.start_time < (new Date().getTime() / 1000))
                            ? (
                              <BuyBtn style={{ width: '45%' }} onClick={() => { fullfillOrder(value?.listings?.[0].protocol_data, value?.listings?.[0].order_hash || '', value?.listings?.[0].protocal_address || '') }}>
                                Buy</BuyBtn>) : ('')}
                          <BuyBtnTransparent onClick={() => { openOffer() }}>
                            Make offer</BuyBtnTransparent></PriceCardLine></>
                      )}
                    </PriceCardL>
                    </>
                  )}
                </div>) : (
                  <> <PriceCardL style={{ width: "100%" }}>
                    <PriceCardLText1>{value?.listings?.[0] ? 'price' : value?.offers?.[0] ? 'Best Offer' : ''}</PriceCardLText1>
                    {/* <div style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}> */}
                    {itemInfoLoad ? (<RTitleLoad style={{ width: '100%' }}>{'   '}</RTitleLoad>) : (<><PriceCardLine>
                      <PriceCardLText2>

                        <div>
                          {value?.listings?.[0]?.price && value?.listings?.[0]?.end_time && value?.listings?.[0]?.start_time && (value?.listings?.[0]?.end_time > (new Date().getTime() / 1000)) && (value?.listings?.[0]?.start_time < (new Date().getTime() / 1000)) ? priceText(value.listings[0].price) : 'Not listed...'}
                        </div>
                      </PriceCardLText2>

                    </PriceCardLine>  <PriceCardLine style={{ width: '100%', justifyContent: 'space-between', marginTop: '10px' }}>
                        {value?.listings?.[0]?.price && value?.listings?.[0]?.end_time && value?.listings?.[0]?.start_time && (value?.listings?.[0]?.end_time > (new Date().getTime() / 1000)) && (value?.listings?.[0]?.start_time < (new Date().getTime() / 1000)) ? (
                          <BuyBtn style={{ width: '45%' }} onClick={() => { fullfillOrder(value?.listings?.[0].protocol_data, value?.listings?.[0].order_hash || '', value?.listings?.[0].protocal_address || '') }}>
                            Buy</BuyBtn>) : ('')}
                        <BuyBtnTransparent onClick={() => { openOffer() }}>
                          Make offer</BuyBtnTransparent></PriceCardLine></>)}
                  </PriceCardL>
                  </>
                )
                }





              </PriceCard>
            </CardItem>



            <CardItem>
              <CardTop onClick={() => setShowCard9(!showCard9)}>
                <div>Listings</div>
                <CardTopImg
                  src={darkMode ? arrowUn : arrowU}
                  style={
                    showCard9
                      ? { transform: "rotateX(180deg)" }
                      : { marginBottom: "6px" }
                  }
                />
              </CardTop>
              <CardMain isShow={showCard9} style={{ padding: '0' }}>
                {value?.listings && value?.listings.length > 0 ? (
                  <>
                    <CardTableTitleRow2>
                      {/* <div>Event</div> */}
                      <div>Price</div>
                      <div>USD Price</div>
                      <div>Quantity</div>
                      <div>From</div>
                      <div style={{ width: '20%' }}>Expiration</div>
                      <div style={{ width: '12%' }}></div>
                    </CardTableTitleRow2>
                    <CardTableMain id='actmain' style={{ maxHeight: '250px', overflowY: 'scroll' }}>
                      {value?.listings?.map((i: OrderItem, index: number) => (
                        <CardTableTitleRow2>
                          <div>
                            <RadixPopover val={i?.price ? priceText(i.price) : '--'} label={i?.price ? priceText(i.price) : '--'}></RadixPopover>

                            {/* <Popover content={(<div> {i?.price ? priceText(i.price) : '--'}</div>)}>
                              {i?.price ? priceText(i.price) : '--'}
                            </Popover> */}
                          </div>
                          <div>--</div>
                          <div>
                            {i.quantity || '--'}

                          </div>
                          <div>
                            {i?.maker?.address ? i?.maker?.address.substring(0, 3) + '...' + i?.maker?.address.substr(i?.maker?.address.length - 5,) : "--"}
                          </div>
                          <div style={{ width: '20%' }}>
                            <RadixPopover val={'End: ' + timestampToTime(i.end_time || 0)} label={i.timestr}></RadixPopover>

                            {/* <Popover content={(<div>End: {timestampToTime(i.end_time || 0)}</div>)}>
                              {i.timestr}
                            </Popover> */}

                            {/* {i?.maker?.address||'--'} */}
                          </div>
                          <div style={{ width: '12%', transform: 'scale(0.8)' }}>

                            {i?.maker?.address && account && (account.toUpperCase() === i.maker.address.toUpperCase()) ?
                              (<ButtonTransparent padding={'5px 10px'} $borderRadius={'4px'} width={'auto'} onClick={() => { cancelOrder([i.protocol_data.parameters]) }}>Cancel</ButtonTransparent>)
                              :
                              i?.end_time && i?.start_time && (i.end_time > (new Date().getTime() / 1000)) && (i.start_time < (new Date().getTime() / 1000)) ?
                                (<ButtonPurple padding={'5px 10px'} $borderRadius={'4px'} width={'auto'} onClick={() => { fullfillOrder(i.protocol_data, i.order_hash, i.protocal_address || '') }}> Buy </ButtonPurple>) : null}


                          </div>
                        </CardTableTitleRow2>
                      ))}

                    </CardTableMain></>
                ) : (<div style={{ padding: '14px' }}>none</div>)}

              </CardMain>
            </CardItem>
            <CardItem>
              <CardTop onClick={() => setShowCard8(!showCard8)}>
                <div>Activity</div>
                <CardTopImg
                  src={darkMode ? arrowUn : arrowU}
                  style={
                    showCard8
                      ? { transform: "rotateX(180deg)" }
                      : { marginBottom: "6px" }
                  }
                />
              </CardTop>
              <CardMain isShow={showCard8} style={{ padding: '0' }}>
                {actList && actList.length > 0 ? (
                  <>
                    <CardTableTitleRow>
                      <div>Event</div>
                      <div>Price</div>
                      <div>From</div>
                      <div>To</div>
                      <div>Date</div>
                    </CardTableTitleRow>
                    <CardTableMain id='actmain' style={{ maxHeight: '250px', overflowY: 'scroll' }}>
                      {actList?.map((i: ActivityItem, index: number) => (
                        <CardTableMainRow>
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <ActivityIcon src={i.type == 'sale' ? actSell : i.type == 'transfer' ? actTransfer : actBuy} />
                            <div style={{ marginLeft: '4px' }}>{i.from_account === '0x0000000000000000000000000000000000000000' ? 'Mint' : i.type}</div>

                          </div>
                          <div> {i?.unit_price ? priceText(i.unit_price) : '--'}</div>
                          <div>

                            {i.from_account ? i.from_account.substring(0, 3) + '...' + i.from_account.substr(i.from_account.length - 5,) : "--"}

                          </div>
                          <div>
                            {i.to_account ? i.to_account.substring(0, 3) + '...' + i.to_account.substr(i.to_account.length - 5,) : "--"}


                          </div>
                          <div style={{ cursor: "pointer" }} onClick={() => openWindow(`${chainCurrency?.block_explorer_url}/tx/${i.tx_hash}`)}>{i.timestamp}</div>

                        </CardTableMainRow>
                      ))}

                    </CardTableMain></>
                ) : (<div style={{ padding: '14px' }}>none</div>)}

              </CardMain>
            </CardItem>
          </WebMainTopR>
        </WebMainTop>
        {/* <MainBottom>
          <CardItem style={recommendList && recommendList.length > 0 ? {} : { height: '0', overflow: 'hidden', border: 'none', }}>
            <CardTop onClick={() => setShowCard6(!showCard6)}>
              <div>Recommend</div>
              <CardTopImg
                src={darkMode ? arrowUn : arrowU}
                style={
                  showCard6
                    ? { transform: "rotateX(180deg)" }
                    : { marginBottom: "6px" }
                }
              />
            </CardTop>
            {recommendLoad ? (<CardMain isShow={showCard6}>
              <CardMainUi>
                {recommendListNone.map(() => (
                  <RecommendItemLoad></RecommendItemLoad>
                ))}
              </CardMainUi>

            </CardMain>) : (<CardMain isShow={showCard6}>
              <CardMainUi>
                {recommendList.map((i: any, index: number) => (
                  <RecommendItem key={index} onClick={() =>
                    toPage(`/nfts/assets/${i.chain_identifier}/${i.contract_address}/${i.token_id}`)}>
                    <RecomImg src={i.image_url || nftDefault} alt="" />

                    <RecomBottom>

                      <RecomBottomName>
                        <div>{i.name ? i.name : i?.asset_contract?.name}</div>
                      </RecomBottomName>
                      <RecomBottomCode>#{' '}{i.token_id}</RecomBottomCode>
                      <RecomBottomPrice>
                        <span>
                          {i && i.current_price ? priceText(i.current_price) : 'No quote yet'}

                        </span>
                      </RecomBottomPrice>
                    </RecomBottom>
                  </RecommendItem>
                ))}
              </CardMainUi>
              <CardMainBtn
                isShow={recommendList.length == list1.length ? false : true}
              >
                <BuyBtn1 onClick={() => setRecommendList(list1)}>View All</BuyBtn1>
              </CardMainBtn>
            </CardMain>)}

          </CardItem>
        </MainBottom> */}
      </WebMain>
    </div>
    </>
  );
}
