
import { DEFAULT_LOCAL_CURRENCY, LOCAL_CURRENCY_SYMBOL_DISPLAY_TYPE, SupportedLocalCurrency } from "constants/localCurrencies"
import { DEFAULT_LOCALE, SupportedLocale } from "constants/locales"
import { Currency, CurrencyAmount, Percent, Price } from "core"
import { useCallback, useMemo } from "react"




type Nullish<T> = T | null | undefined
type NumberFormatOptions = Intl.NumberFormatOptions

// Number formatting follows the standards laid out in this spec:
// https://www.notion.so/uniswaplabs/Number-standards-fbb9f533f10e4e22820722c2f66d23c0
const TWO_DECIMALS: NumberFormatOptions = {
    notation: 'standard',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  }
  const SHORTHAND_TWO_DECIMALS: NumberFormatOptions = {
    notation: 'compact',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }
  
  const SHORTHAND_TWO_DECIMALS_NO_TRAILING_ZEROS: NumberFormatOptions = {
    notation: 'compact',
    maximumFractionDigits: 2,
  }
  const TWO_DECIMALS_CURRENCY: NumberFormatOptions = {
    notation: 'standard',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
    currency: 'USD',
    style: 'currency',
  }
const FIVE_DECIMALS_MAX_TWO_DECIMALS_MIN: NumberFormatOptions = {
    notation: 'standard',
    maximumFractionDigits: 5,
    minimumFractionDigits: 2,
  }
  const TWO_DECIMALS_NO_TRAILING_ZEROS: NumberFormatOptions = {
    notation: 'standard',
    maximumFractionDigits: 2,
  }
  const FIVE_DECIMALS_MAX_TWO_DECIMALS_MIN_NO_COMMAS: NumberFormatOptions = {
    notation: 'standard',
    maximumFractionDigits: 5,
    minimumFractionDigits: 2,
    useGrouping: false,
  }
  const THREE_DECIMALS: NumberFormatOptions = {
    notation: 'standard',
    maximumFractionDigits: 3,
    minimumFractionDigits: 3,
  }
  const SIX_SIG_FIGS_TWO_DECIMALS_NO_COMMAS: NumberFormatOptions = {
    notation: 'standard',
    maximumSignificantDigits: 6,
    minimumSignificantDigits: 3,
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
    useGrouping: false,
  }
  const SIX_SIG_FIGS_TWO_DECIMALS: NumberFormatOptions = {
    notation: 'standard',
    maximumSignificantDigits: 6,
    minimumSignificantDigits: 3,
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  }
  
  
  export const SIX_SIG_FIGS_NO_COMMAS: NumberFormatOptions = {
    notation: 'standard',
    maximumSignificantDigits: 6,
    useGrouping: false,
  }
  
  const NO_DECIMALS: NumberFormatOptions = {
    notation: 'standard',
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  }
  const ONE_SIG_FIG_CURRENCY: NumberFormatOptions = {
    notation: 'standard',
    minimumSignificantDigits: 1,
    maximumSignificantDigits: 1,
    currency: 'USD',
    style: 'currency',
  }
  
  const THREE_SIG_FIGS_CURRENCY: NumberFormatOptions = {
    notation: 'standard',
    minimumSignificantDigits: 3,
    maximumSignificantDigits: 3,
    currency: 'USD',
    style: 'currency',
  }
  
  const SEVEN_SIG_FIGS__SCI_NOTATION_CURRENCY: NumberFormatOptions = {
    notation: 'scientific',
    minimumSignificantDigits: 7,
    maximumSignificantDigits: 7,
    currency: 'USD',
    style: 'currency',
  }

  const SHORTHAND_CURRENCY_TWO_DECIMALS: NumberFormatOptions = {
    notation: 'compact',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    currency: 'USD',
    style: 'currency',
  }
// each rule must contain either an `upperBound` or an `exact` value.
// upperBound => number will use that formatter as long as it is < upperBound
// exact => number will use that formatter if it is === exact
// if hardcodedinput is supplied it will override the input value or use the hardcoded output
type HardCodedInputFormat =
  | {
      input: number
      prefix?: string
      hardcodedOutput?: undefined
    }
  | {
      input?: undefined
      prefix?: undefined
      hardcodedOutput: string
    }
type FormatterBaseRule = { formatterOptions: NumberFormatOptions }
type FormatterExactRule = { upperBound?: undefined; exact: number } & FormatterBaseRule
type FormatterUpperBoundRule = { upperBound: number; exact?: undefined } & FormatterBaseRule
export type FormatterRule = (FormatterExactRule | FormatterUpperBoundRule) & { hardCodedInput?: HardCodedInputFormat }

export enum NumberType {
    // used for token quantities in non-transaction contexts (e.g. portfolio balances)
    TokenNonTx = 'token-non-tx',
  
    // used for token quantities in transaction contexts (e.g. swap, send)
    TokenTx = 'token-tx',
  
    // this formatter is used for displaying swap price conversions
    // below the input/output amounts
    SwapPrice = 'swap-price',

    // this formatter is only used for displaying the swap trade output amount
    // in the text input boxes. Output amounts on review screen should use the above TokenTx formatter
    SwapTradeAmount = 'swap-trade-amount',
  
    // fiat prices in any component that belongs in the Token Details flow (except for token stats)
    // FiatTokenDetails = 'fiat-token-details',
  
    // fiat prices everywhere except Token Details flow
    FiatTokenPrice = 'fiat-token-price',
  
    // // fiat values for market cap, TVL, volume in the Token Details screen
    // FiatTokenStats = 'fiat-token-stats',
  
    // // fiat price of token balances
    // FiatTokenQuantity = 'fiat-token-quantity',
  
    // // fiat gas prices
    // FiatGasPrice = 'fiat-gas-price',
  
    // // portfolio balance
    // PortfolioBalance = 'portfolio-balance',
  
    // // nft floor price denominated in a token (e.g, ETH)
    // NFTTokenFloorPrice = 'nft-token-floor-price',
  
    // // nft collection stats like number of items, holder, and sales
    // NFTCollectionStats = 'nft-collection-stats',
  
    // // nft floor price with trailing zeros
    // NFTTokenFloorPriceTrailingZeros = 'nft-token-floor-price-trailing-zeros',
  }
  

// these formatter objects dictate which formatter rule to use based on the interval that
// the number falls into. for example, based on the rule set below, if your number
// falls between 1 and 1e6, you'd use TWO_DECIMALS as the formatter.
const tokenNonTxFormatter: FormatterRule[] = [
    { exact: 0, formatterOptions: NO_DECIMALS },
    { upperBound: 0.001, hardCodedInput: { input: 0.001, prefix: '<' }, formatterOptions: THREE_DECIMALS },
    { upperBound: 1, formatterOptions: THREE_DECIMALS },
    { upperBound: 1e6, formatterOptions: TWO_DECIMALS },
    { upperBound: 1e15, formatterOptions: SHORTHAND_TWO_DECIMALS },
    {
      upperBound: Infinity,
      hardCodedInput: { input: 999_000_000_000_000, prefix: '>' },
      formatterOptions: SHORTHAND_TWO_DECIMALS_NO_TRAILING_ZEROS,
    },
  ]

  
  
  const tokenTxFormatter: FormatterRule[] = [
    { exact: 0, formatterOptions: NO_DECIMALS },
    {
      upperBound: 0.00001,
      hardCodedInput: { input: 0.00001, prefix: '<' },
      formatterOptions: FIVE_DECIMALS_MAX_TWO_DECIMALS_MIN,
    },
    { upperBound: 1, formatterOptions: FIVE_DECIMALS_MAX_TWO_DECIMALS_MIN },
    { upperBound: 10000, formatterOptions: SIX_SIG_FIGS_TWO_DECIMALS },
    { upperBound: Infinity, formatterOptions: TWO_DECIMALS },
  ]

  const swapTradeAmountFormatter: FormatterRule[] = [
    { exact: 0, formatterOptions: NO_DECIMALS },
    { upperBound: 0.1, formatterOptions: SIX_SIG_FIGS_NO_COMMAS },
    { upperBound: 1, formatterOptions: FIVE_DECIMALS_MAX_TWO_DECIMALS_MIN_NO_COMMAS },
    { upperBound: Infinity, formatterOptions: SIX_SIG_FIGS_TWO_DECIMALS_NO_COMMAS },
  ]

  const swapPriceFormatter: FormatterRule[] = [
    { exact: 0, formatterOptions: NO_DECIMALS },
    {
      upperBound: 0.00001,
      hardCodedInput: { input: 0.00001, prefix: '<' },
      formatterOptions: FIVE_DECIMALS_MAX_TWO_DECIMALS_MIN,
    },
    ...swapTradeAmountFormatter,
  ]

  const fiatTokenPricesFormatter: FormatterRule[] = [
    { exact: 0, formatterOptions: TWO_DECIMALS_CURRENCY },
    {
      upperBound: 0.00000001,
      hardCodedInput: { input: 0.00000001, prefix: '<' },
      formatterOptions: ONE_SIG_FIG_CURRENCY,
    },
    { upperBound: 1, formatterOptions: THREE_SIG_FIGS_CURRENCY },
    { upperBound: 1e6, formatterOptions: TWO_DECIMALS_CURRENCY },
    { upperBound: 1e16, formatterOptions: SHORTHAND_CURRENCY_TWO_DECIMALS },
    { upperBound: Infinity, formatterOptions: SEVEN_SIG_FIGS__SCI_NOTATION_CURRENCY },
  ]

type FormatterType = NumberType | FormatterRule[]

const TYPE_TO_FORMATTER_RULES = {
    [NumberType.TokenNonTx]: tokenNonTxFormatter,
    [NumberType.TokenTx]: tokenTxFormatter,
  [NumberType.SwapPrice]: swapPriceFormatter,
  [NumberType.SwapTradeAmount]: swapTradeAmountFormatter,
  [NumberType.FiatTokenPrice]: fiatTokenPricesFormatter,


}


function getFormatterRule(input: number, type: FormatterType, conversionRate?: number): FormatterRule {
    const rules = Array.isArray(type) ? type : TYPE_TO_FORMATTER_RULES[type]
    for (const rule of rules) {
      const shouldConvertInput = rule.formatterOptions.currency && conversionRate
      const convertedInput = shouldConvertInput ? input * conversionRate : input
      if (
        (rule.exact !== undefined && convertedInput === rule.exact) ||
        (rule.upperBound !== undefined && convertedInput < rule.upperBound)
      ) {
        return rule
      }
    }

  
    throw new Error(`formatter for type ${type} not configured correctly`)
  }
  
  interface FormatNumberOptions {
    input: Nullish<number>
    type?: FormatterType
    placeholder?: string
    locale?: SupportedLocale
    localCurrency?: SupportedLocalCurrency
    conversionRate?: number
  }

export function formatNumber({
    input,
    type = NumberType.TokenNonTx,
    placeholder = '-',
    locale = DEFAULT_LOCALE,
    localCurrency = DEFAULT_LOCAL_CURRENCY,
    conversionRate,
  }: FormatNumberOptions): string {
    if (input === null || input === undefined) {
      return placeholder
    }
  
    const { hardCodedInput, formatterOptions } = getFormatterRule(input, type, conversionRate)
  
    if (formatterOptions.currency) {
      input = conversionRate ? input * conversionRate : input
      formatterOptions.currency = localCurrency
      formatterOptions.currencyDisplay = LOCAL_CURRENCY_SYMBOL_DISPLAY_TYPE[localCurrency]
    }
  
    if (!hardCodedInput) {
      return new Intl.NumberFormat(locale, formatterOptions).format(input)
    }
  
    if (hardCodedInput.hardcodedOutput) {
      return hardCodedInput.hardcodedOutput
    }
  
    const { input: hardCodedInputValue, prefix } = hardCodedInput
    if (hardCodedInputValue === undefined) return placeholder
    return (prefix ?? '') + new Intl.NumberFormat(locale, formatterOptions).format(hardCodedInputValue)
  }
  
  interface FormatCurrencyAmountOptions {
    amount: Nullish<CurrencyAmount<Currency>>
    type?: FormatterType
    placeholder?: string
    locale?: SupportedLocale
    localCurrency?: SupportedLocalCurrency
    conversionRate?: number
  }
  
  export function formatCurrencyAmount({
    amount,
    type = NumberType.TokenNonTx,
    placeholder,
    locale = DEFAULT_LOCALE,
    localCurrency = DEFAULT_LOCAL_CURRENCY,
    conversionRate,
  }: FormatCurrencyAmountOptions): string {
    return formatNumber({
      input: amount ? parseFloat(amount.toSignificant()) : undefined,
      type,
      placeholder,
      locale,
      localCurrency,
      conversionRate,
    })
  }

  function formatPercent(percent: Percent | undefined, locale: SupportedLocale = DEFAULT_LOCALE) {
    if (!percent) {
      return '-'
    }
  
    return `${Number(percent.toFixed(3)).toLocaleString(locale, {
      maximumFractionDigits: 3,
      useGrouping: false,
    })}%`
  }

  export function useFormatterLocales(): {
    formatterLocale: SupportedLocale
    formatterLocalCurrency: SupportedLocalCurrency
  } {

  
    return {
      formatterLocale: DEFAULT_LOCALE,
      formatterLocalCurrency: DEFAULT_LOCAL_CURRENCY,
    }
  }


  interface FormatPriceOptions {
    price: Nullish<Price<Currency, Currency>>
    type?: FormatterType
    locale?: SupportedLocale
    localCurrency?: SupportedLocalCurrency
    conversionRate?: number
  }
  
  function formatPrice({
    price,
    type = NumberType.FiatTokenPrice,
    locale = DEFAULT_LOCALE,
    localCurrency = DEFAULT_LOCAL_CURRENCY,
    conversionRate,
  }: FormatPriceOptions): string {
    if (price === null || price === undefined) {
      return '-'
    }
  
    return formatNumber({ input: parseFloat(price.toSignificant()), type, locale, localCurrency, conversionRate })
  }


  export function useFormatter() {
    const { formatterLocale, formatterLocalCurrency } = useFormatterLocales()
  

  
    const formatPercentWithLocales = useCallback(
      (percent: Percent | undefined) => formatPercent(percent, formatterLocale),
      [formatterLocale]
    )
  
    
  
    return useMemo(
      () => ({
        formatPercent: formatPercentWithLocales,
      }),
      [
        formatPercentWithLocales,
      ]
    )
  }