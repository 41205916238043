import styled from "styled-components/macro";

export const TextInput = styled.input<{ padding?:string }>`
    width:100%;
    font-size: 1em;
    color: ${({  theme }) =>  theme.text2};
    border: 1px solid ${({ theme }) => theme.border1};
    border-radius: 0.5em;
    background:000;
    /* here we use the dynamically computed prop */
    padding: ${props => props.padding};
  `;