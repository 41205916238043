
import { emptySplitApi } from './emptySplitApi'
export interface ChainResponse {
  data: ChainInfo[],
  message: null | string;
  success: boolean;
}


const chainApi = emptySplitApi.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    getChainList: builder.query<ChainResponse, Record<string, any>>({
      query: (params) => ({
        url: `/chain`,
        method: "GET"
      }),
    }),
    getChain: builder.query<{
      data: ChainInfo,
      message: null | string;
      success: boolean;
    }, Record<string, any>>({
      query: (params) => ({
        url: `/chain/${params.chain}`,
        method: "GET"
      }),
    }),


  }),
});

export const {
  useGetChainListQuery,
  useGetChainQuery
} = chainApi;
