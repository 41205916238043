import { v1 as uuidv1 } from "uuid";
import {useToastOpen } from 'state/stats/hooks'
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useClientWidth } from "../../../state/application/hooks";
import UploadDropzone from "components/Form/upload"


import { ButtonPurple } from "components/Button";
import { Input } from "@rebass/forms";
import ChainSelect from "components/ChainSelect";
import { useImportCollectionMutation } from "../../../apis/collection";
import { useVerifyContractCreatorMutation } from "../../../apis/contract";
import { useUploadImgMutation } from "../../../apis/file";
import arrowL from "../../../assets/tool/arrow_u.png";
import arrowLN from "../../../assets/tool/arrow_u_n.png";
import {
  VerificationBtn,
} from "./components_";
import {
  WebMain,MenuItem,WebLeft,MenuTitle,  AddImgText1,UploadBox,
  BackImg,
  CardBox,
  CardItem,
  CardMain,
  CardTitle,
  ItemLabel,
  ItemTip,
  ItemUpImg,
  ItemValue,
  ItemValueIcon,
  WebRight,
  inputStyle,
} from "../EditCollection/components_";

import { useDarkModeManager } from "theme/components/ThemeToggle";

import "../../index.scss";
import "./index.css";
import { TextInput } from "components/Form/input";
import { useWeb3React } from "@web3-react/core";

type NFT = {
  name: string;
  chain: string;
  contract_address: string;
  token_standard: string;
  image: string;
};

export default function SubmitNFT() {
  const { chainId, account,provider } = useWeb3React();
  const [,,, setToastOpen] = useToastOpen();

  const [importCollection] = useImportCollectionMutation();
  const [verifyContractCreator] = useVerifyContractCreatorMutation();
  const clientWidth = useClientWidth();
  const [uploadImg] = useUploadImgMutation();
  const [darkMode] = useDarkModeManager();

  const [step, setStep] = useState(1);
  const [name, setName] = useState("");
  const [contractAddress, setContractAddress] = useState("");
  const [txHash,setTxHash]= useState("");

  let navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const [iconUrl, setIconUrl] = useState<string>();

  const [chain, setChain] = useState<string>("");

  useEffect(() => {}, [chainId]);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<NFT>();

  const save = async () => {
    if (iconUrl === "") {
      setToastOpen({open:true,type:'error',label:"please upload image"})

      return;
    }
    if (contractAddress === "") {
      setToastOpen({open:true,type:'error',label:"please input contract address"})
      return;
    }
    if (name === "") {
      setToastOpen({open:true,type:'error',label:"please input collection name"})
      return;
    }
    if (chain === "") {
      setToastOpen({open:true,type:'error',label:"please select chain"})
      return;
    }
    if (iconUrl && name && chain && contractAddress) {
      const { success, message, data } = await importCollection({
        chain: chain,
        contract_address: contractAddress,
        image_url: iconUrl,
        name: name,
      }).unwrap();
      if (success) {
        navigate(`/edit-nft/${data.id}`);
        setToastOpen({open:true,type:'success',label:"success"})
      } else {
        setToastOpen({open:true,type:'error',label:message})
      }
    }
  };
  const handleChainChange = (value: string) => {
    console.log("value", value);
    setChain(value);
  };



  function goBack() {
    if (step < 10) {
      // 返回
      navigate(-1);
    } else {
      setStep(4);
    }
  }

  const handleVerifyContract = async () => {
    if (chain === "") {
      setToastOpen({open:true,type:'error',label:"please select chain"})

      return;
    }
    if (contractAddress === "") {
      setToastOpen({open:true,type:'error',label:"please input contract address"})
      return;
    }
    const {data,success,message}=await verifyContractCreator({"chain":chain,"contract_address":contractAddress,"tx_hash":txHash}).unwrap();
    if(success===false){
      setToastOpen({open:true,type:'error',label:message})

    }
  };
  return (
    <>
      <WebMain
        style={
          Number(clientWidth) > 800
            ? {}
            : { width: "100%", minWidth: "0", padding: "0" }
        }
      >
        {Number(clientWidth) > 800 ? (
          <WebLeft>
            <MenuTitle onClick={() => goBack()}>
              <BackImg src={darkMode ? arrowLN : arrowL} />
              <div>My Collecttions</div>
            </MenuTitle>
            <MenuItem isAct={true} style={{marginLeft:'15%',width:'70%',whiteSpace:'nowrap',textOverflow: 'ellipsis'}}>
              Importing a collection
            </MenuItem>
          </WebLeft>
        ) : null}

        <WebRight
          style={
            Number(clientWidth) > 800
              ? {}
              : { minWidth: "0", padding: "15px", flex: "1" }
          }
        >
          <CardBox
            isAct={step === 1 ? true : false}
            style={
              Number(clientWidth) > 800
                ? { maxWidth: "100% " }
                : {
                    width: "100%",
                    border: "none",
                    padding: "0",
                    overflow: "visible",
                  }
            }
          >
            <CardMain
              style={
                Number(clientWidth) > 800
                  ? { maxWidth: "calc(100% - 40px)", margin: "50px auto" }
                  : { width: "100%", padding: "0" }
              }
            >
              <CardTitle>Collection info</CardTitle>

              <CardItem>
                <ItemLabel>Upload the icon</ItemLabel>
                <ItemTip>
                  This image will also be used for display purposes.
                </ItemTip>
                <ItemUpImg>
                <UploadBox>
                  <UploadDropzone url={iconUrl} urlChange={(url) => setIconUrl(url)}></UploadDropzone>
                </UploadBox>
                <AddImgText1>Recommended size: 350 x 350</AddImgText1>
                  {/* <div style={{ margin: "20px auto 0", width: "110px" }}>
                    <Upload
                      name="iconUrl"
                      listType="picture-card"
                      className="avatar-uploader"
                      showUploadList={false}
                      beforeUpload={beforeUploadIcon}
                      onChange={handleChange}
                    >
                      {iconUrl ? (
                        <img
                          src={iconUrl}
                          alt="iconUrl"
                          style={{ width: "100%" }}
                        />
                      ) : (
                        uploadButton
                      )}
                    </Upload>
                  </div>
                  <AddImgText1>Recommended size: 350 x 350</AddImgText1> */}
                </ItemUpImg>
              </CardItem>
              <CardItem>
                <ItemLabel>Collection Name</ItemLabel>
                <TextInput
                  padding="1em"
                  name="name"
                  value={name}
                  onChange={(v) => {
                    setName(v.target.value);
                  }}
                />
              </CardItem>
              <CardItem>
                <ItemLabel>Collection Mainnet</ItemLabel>
                <ItemValue style={{ overflow: "visible" }}>
                  <ChainSelect
                    value={chain}
                    onChange={handleChainChange}
                    size={"filed"}
                  />
                </ItemValue>
              </CardItem>
              <CardItem>
                <ItemLabel>Creation Transaction Hash</ItemLabel>
                <TextInput
                  padding="1em"
                  name="txHash"
                  value={txHash}
                  onChange={(v) => {
                    setTxHash(v.target.value);
                  }}
                />
              </CardItem>
              <CardItem>
                <ItemLabel>Contract Address</ItemLabel>
                <ItemValueIcon>
                  <Input
                    type="text"
                    placeholder=""
                    value={contractAddress}
                    sx={inputStyle}
                    onChange={(e) => {
                      setContractAddress(e.target.value);
                    }}
                  />
                  <VerificationBtn onClick={handleVerifyContract}>
                    contract verification
                  </VerificationBtn>
                </ItemValueIcon>
              </CardItem>

              <ButtonPurple
                padding="10px"
                $borderRadius="4px"
                width="auto"
                onClick={() => save()}
              >
                save
              </ButtonPurple>
            </CardMain>
          </CardBox>
        </WebRight>
      </WebMain>
    </>
  );
}
