import { Percent } from '@uniswap/sdk-core'
import Row from 'components/Row'

import { X } from 'react-feather'
import styled, { css } from 'styled-components'
import { ClickableStyle,  } from 'theme/components'
import { useFormatter } from 'utils/formatNumbers'


interface LimitPriceButtonProps {
  priceAdjustmentPercentage: number
  disabled?: boolean
  selected?: boolean
  onSelect: (priceAdjustmentPercentage: number) => void
}

const containerBorderCss = css`
  border-radius: 999px;
  border: 1px solid ${({ theme }) => theme.modalBG};
`

const highlightedBorderCss = css`
  border-radius: 999px 0px 0px 999px;
  border-top: 1px solid ${({ theme }) => theme.modalBG};
  border-bottom: 1px solid ${({ theme }) => theme.modalBG};
  border-left: 1px solid ${({ theme }) => theme.modalBG};
  border-right: 0px;
`

const Container = styled.button<{ $selected?: boolean; $disabled?: boolean; $highlighted: boolean }>`
  color: ${({ theme, $selected, $disabled, $highlighted }) => {
    if ($highlighted) {
      return theme.text1
    }
    if ($selected && !$disabled) {
      return theme.text2
    }
    return theme.text2
  }};
  padding: 2px 8px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme, $highlighted, $selected }) => {
    if ($highlighted || $selected) {
      return theme.modalBG
    }
    return 'unset'
  }};
  ${({ $highlighted }) => ($highlighted ? highlightedBorderCss : containerBorderCss)};
  ${({ $disabled }) => !$disabled && ClickableStyle};
`

const HighlightedContainerXButton = styled.button`
  ${ClickableStyle}
  background-color: ${({ theme }) => theme.modalBG};
  color: ${({ theme }) => theme.modalBG};
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 6px 6px 6px 4px;
  height: 28px;
  border-radius: 0px 999px 999px 0px;
  border-top: 1px solid ${({ theme }) => theme.modalBG};
  border-bottom: 1px solid ${({ theme }) => theme.modalBG};
  border-right: 1px solid ${({ theme }) => theme.modalBG};
  border-left: 1px solid transparent;
`

export function LimitPresetPriceButton({
  priceAdjustmentPercentage,
  selected,
  disabled,
  onSelect,
}: LimitPriceButtonProps) {
  const { formatPercent } = useFormatter()
  const sign = priceAdjustmentPercentage > 0 ? '+' : '-'
  return (
    <Container
      $selected={selected}
      $disabled={disabled}
      $highlighted={false}
      onClick={() => !disabled && onSelect(priceAdjustmentPercentage)}
    >
      {priceAdjustmentPercentage === 0 ? (
          <>Market Price</>
      ) : (
        <>
          {sign}
          {formatPercent(new Percent(Math.abs(priceAdjustmentPercentage), 100))}
        </>
      )}
    </Container>
  )
}

/**
 * A button to reset the price to the market price (i.e. an adjustment of 0%)
 * When defined, this button displays customAdjustmentPercentage instead of "Market"
 */
export function LimitCustomMarketPriceButton({
  customAdjustmentPercentage,
  selected,
  disabled,
  onSelect,
}: Omit<LimitPriceButtonProps, 'priceAdjustmentPercentage'> & {
  customAdjustmentPercentage?: number
}) {
  const onSetAdjustmentPercentage = () => !disabled && onSelect(0)
  const { formatPercent } = useFormatter()
  return (
    <Row width="unset" gap="1px">
      <Container
        $selected={selected}
        $disabled={disabled}
        $highlighted={customAdjustmentPercentage !== undefined}
        onClick={onSetAdjustmentPercentage}
      >
        {!customAdjustmentPercentage ? (
          <>Market Price</>
        ) : (
          <>
            {customAdjustmentPercentage > 0 ? '+' : ''}
            {formatPercent(new Percent(customAdjustmentPercentage, 100))}
          </>
        )}
      </Container>
      {customAdjustmentPercentage && (
        <HighlightedContainerXButton onClick={onSetAdjustmentPercentage}>
          <X size={16} />
        </HighlightedContainerXButton>
      )}
    </Row>
  )
}
