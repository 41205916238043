
import styled from "styled-components";


export const FilterR = styled.div<{ transparent: boolean }>`
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border: 1px solid ${({ theme,transparent }) => transparent?'transparent':theme.border1};
  border-radius: 6px;
  overflow: hidden;
`;
export const FilterRItem = styled.div<{ isAct: boolean }>`
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  font-size: 20px;
  // border-radius: 6px;
  // border: 1px solid ${({ theme, isAct }) => (isAct ? theme.bg2 : theme.bg0)};
  background: ${({ theme, isAct }) => (isAct ? theme.bg2 : theme.bg0)};
  color: ${({ theme, isAct }) => (isAct ? theme.text1 : theme.text3)};
  cursor: pointer;
  
  transition: background 250ms ease;
`;
export const FilterRm = styled.div<{ transparent: boolean }>`
  width: 148px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border: 1px solid ${({ theme,transparent }) => transparent?'transparent':theme.border1};
  border-radius: 6px;
  overflow: hidden;
`;
export const FilterRItemm = styled.div<{ isAct: boolean }>`
  width: 37px;
  height: 37px;
  line-height: 37px;
  text-align: center;
  font-size: 14px;
  // border-radius: 6px;
  // border: 1px solid ${({ theme, isAct }) => (isAct ? theme.bg2 : theme.bg0)};

  background: ${({ theme, isAct }) => (isAct ? theme.bg2 : theme.bg0)};
  color: ${({ theme, isAct }) => (isAct ? theme.text1 : theme.text3)};
  cursor: pointer;
  transition: background 250ms ease;

`;
interface ChainSelectProps {
  size: string;
  value: string;
  isTransparent?:boolean;
  onChange: (value: string) => void;
}

export default function PeriodSelect({
  size,
  value,
  isTransparent,
  onChange,
}: ChainSelectProps) {
  const periods = ["one_hour", "six_hour", "one_day", "seven_day"];
  const periodLabels = ["1h", "6h", "24h", "7d"];
  const periods5 = ["one_hour", "six_hour", "twentyfour_hour", "seven_day", "thirty_day"];
  const periodLabels5 = ["1h", "6h", "24h", "7d", "30d"];
  return (
    <>
      {size === "big5" ?(<FilterR transparent={isTransparent||false} style={{width:'200px'}}>
          {periods5.map((item, index) => {
            return (
              <FilterRItem
                isAct={value === item}
                onClick={() => {
                  onChange(item);
                }}
              >
                {periodLabels5[index]}
              </FilterRItem>
            );
          })}
        </FilterR>):size === "big" ? (
        <FilterR transparent={isTransparent||false}>
          {periods.map((item, index) => {
            return (
              <FilterRItem
                isAct={value === item}
                onClick={() => {
                  onChange(item);
                }}
              >
                {periodLabels[index]}
              </FilterRItem>
            );
          })}
        </FilterR>
      ) : (
        <FilterRm transparent={isTransparent||false}>
          {periods.map((item, index) => {
            return (
              <FilterRItemm
                isAct={value === item}
                onClick={() => {
                  onChange(item);
                }}
              >
                {periodLabels[index]}
              </FilterRItemm>
            );
          })}
        </FilterRm>
      )}
    </>
  );
}
