
import styled from "styled-components";
import {FilterR,FilterRItem,FilterRm,FilterRItemm} from "./index"
import {MAX_WIDTH_MEDIA_BREAKPOINT,MIN_WIDTH_MEDIA_BREAKPOINT,LARGE_MEDIA_BREAKPOINT} from "constants/width"

interface ChainSelectProps {
  size: string;
  value: string;
  isTransparent?:boolean;
  onChange: (value: string,label:string) => void;
}
export const FilterR1 = styled(FilterR)<{ transparent: boolean }>`
  width:400px;
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    width:280px;
  }
`;
export const FilterRItem1 = styled(FilterRItem)<{ isAct: boolean }>`
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    font-size: 12px;
    width: 35px;
    height: 50px;
    line-height: 50px;
  }
`;
export default function PeriodSelect({
  size,
  value,
  isTransparent,
  onChange,
}: ChainSelectProps) {
  const periods = ["one_hour", "six_hour", "one_day", "seven_day"];
  const periodLabels = ["1h", "6h", "24h", "7d"];
  const periods5 = ["five_minute", "thirty_minute", "one_hour", "six_hour", "one_day", "one_week", "one_month", "six_month"];
  const periodLabels5 = ["5m","30m","1h", "6h", "1d", "7d", "30d", "6M"];
  return (
    <>
      {size === "big5" ?(<FilterR1 transparent={isTransparent||false}>
          {periods5.map((item, index) => {
            return (
              <FilterRItem1
                isAct={value === item}
                onClick={() => {
                  onChange(item,periodLabels5[index]);
                }}
              >
                {periodLabels5[index]}
              </FilterRItem1>
            );
          })}
        </FilterR1>):size === "big" ? (
        <FilterR transparent={isTransparent||false}>
          {periods.map((item, index) => {
            return (
              <FilterRItem
                isAct={value === item}
                onClick={() => {
                  onChange(item,periodLabels5[index]);
                }}
              >
                {periodLabels[index]}
              </FilterRItem>
            );
          })}
        </FilterR>
      ) : (
        <FilterRm transparent={isTransparent||false}>
          {periods.map((item, index) => {
            return (
              <FilterRItemm
                isAct={value === item}
                onClick={() => {
                  onChange(item,periodLabels5[index]);
                }}
              >
                {periodLabels[index]}
              </FilterRItemm>
            );
          })}
        </FilterRm>
      )}
    </>
  );
}
