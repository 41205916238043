import starB from "assets/tool/star_b.png";
import starF from "assets/tool/star_f.png";
import starW from "assets/tool/star_w.png";
import styled from "styled-components";
import { useIsDarkMode } from "theme/components/ThemeToggle";


const ImgWrapper=styled.img`

`

export function IconStar({ check,size }: { check: boolean,size:string}) {
    const isDarkMode = useIsDarkMode()
    
  return (
    <>
    {check?<ImgWrapper src={starF} height={size} width={size}/>:(<ImgWrapper height={size} width={size} src={isDarkMode?starW:starB} />)}
    </>
    

  )
}