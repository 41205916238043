import { useEffect, useState } from "react";
import styled from "styled-components/macro";
import NftTop from "./components/top";
import DropMintForm from "pages/Drop/DropDetail/overview";
import {MIN_WIDTH_MEDIA_BREAKPOINT,LARGE_MEDIA_BREAKPOINT} from "constants/width"

const WebMain = styled.div`
  max-width: 1440px;
  min-width: 800px;
  height: auto;
  margin: 0 auto;
  text-align: left;
  overflow: hidden;
  position: relative;
  z-index: 10;
  @media screen and (min-width: ${LARGE_MEDIA_BREAKPOINT}){
    width: calc(100vw - 40px);
    max-width: 1440px !important;
    min-width: 760px !important;
  }
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    width: calc(100vw - 40px) !important;
    max-width: 800px !important;
    min-width: 0px !important;
  }
`;
export default function Home() {

  const [tab] = useState('overview');

  return (
    <>
      <WebMain>
        <NftTop />
      </WebMain>
      {tab === "overview" ? <DropMintForm
      visible={tab === 'overview'}
      /> : null}


    </>
  );
}
