
import { Connector } from '@web3-react/types'
import { networkConnection, walletConnectV2Connection } from 'connection'
import { useCallback } from 'react'
import { useAppDispatch } from 'state/hooks'
import { endSwitchingChain, startSwitchingChain } from 'state/wallets/reducer'


function getRpcUrl(chainId: number): string {
    return ""
    // Attempting to add a chain using an infura URL will not work, as the URL will be unreachable from the MetaMask background page.
    // MetaMask allows switching to any publicly reachable URL, but for novel chains, it will display a warning if it is not on the "Safe" list.
    // See the definition of FALLBACK_URLS for more details.
}

export function useSwitchChain() {
  const dispatch = useAppDispatch()

  return useCallback(
    async (connector: Connector, chainId: number,selectChain?:ChainInfo) => {
      // if (!isSupportedChain(chainId)) {
      //   console.log('connector',connector)
      //   throw new Error(`Chain ${chainId} not supported for connector (${typeof connector})`)
      // } else {
        dispatch(startSwitchingChain(chainId))
        try {
          if (
            [
              walletConnectV2Connection.connector,
     
              networkConnection.connector,
            ].includes(connector)
          ) {
            await connector.activate(chainId)
          } else {
            // const info = getChainInfo(chainId)
            // const addChainParameter = {
            //   chainId,
            //   chainName: info.label,
            //   rpcUrls: [getRpcUrl(chainId)],
            //   nativeCurrency: info.nativeCurrency,
            //   blockExplorerUrls: [info.explorer],
            // }
            const addChainParameter = {
              chainId,
              chainName: selectChain?.name,
              rpcUrls: selectChain?.rpc,
              nativeCurrency: selectChain?.currency,
              blockExplorerUrls: selectChain?.block_explorer_url,
            }
            await connector.activate(addChainParameter)
          }
        } catch (error) {
          // In activating a new chain, the connector passes through a deactivated state.
          // If we fail to switch chains, it may remain in this state, and no longer be usable.
          // We defensively re-activate the connector to ensure the user does not notice any change.
          try {
            await connector.activate()
          } catch (error) {
            console.error('Failed to re-activate connector', error)
          }
          throw error
        } finally {
          dispatch(endSwitchingChain())
        }
      // }
    },
    [dispatch]
  )
}
