import styled, { keyframes } from "styled-components/macro";
import Modal from "styled-react-modal";
import {MAX_WIDTH_MEDIA_BREAKPOINT,MIN_WIDTH_MEDIA_BREAKPOINT,LARGE_MEDIA_BREAKPOINT} from "constants/width"

export const StyledModal = Modal.styled`
background: ${(props: { theme: any }) => props.theme.bg1};
border-radius: 8px;
padding: 0px 40px 20px;
box-sizing: border-box;
font-size: 16px;
font-family: Inter-Medium, Inter;
font-weight: 500;
width: 800px;
min-height:auto;
height:auto;
overflow: hidden;
opacity: 1;
margin: 100px auto;
@media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
  width: 90vw;
  margin: 10vh auto;
  max-height: 80vh;
  overflow: scroll;
}
`;

//index
export const WebMain = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.border1};
  width: 100%;
  height: auto;
  min-width: 1000px;
  min-height: calc(100vh - 370px);
  margin: 0 auto;
  flex-shrink: 0;
  display: flex;
  z-index: 11;
  box-sizing: border-box;
  overflow: hidden;
  @media screen and (min-width: ${LARGE_MEDIA_BREAKPOINT}) and (max-width: 1100px) {
    width: 100vw;
    min-width: 0px;
  }
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    width: 100vw;
    min-width: 0px;
    min-height: 100vh;
    background: ${({ theme }) => theme.bg0};
  }
`;
export const WebLeft = styled.div`
  display: block;
  min-height: calc(100vh - 70px);
  width: 30vw;
  max-width: 350px;
  flex-shrink: 0;
  overflow: hidden;
  border-right: 1px solid ${({ theme }) => theme.border1};
  background: ${({ theme }) => theme.bg0};
  padding-bottom: 30px;
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    width: 0;
    max-width: 0;
    display: none;
  }
`;
export const WebRight = styled.div`
  display: flex;
  flex-direction: column;
  width: 70vw;
  min-width: 700px;
  flex: 1;
  overflow: hidden;
  @media screen and (min-width: ${LARGE_MEDIA_BREAKPOINT}) and (max-width: 1100px) {
    min-width: 0px;
    margin: 0 auto;
  }
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    width: 95vw;
    min-width: 0px;
    margin: 0 auto;
  }
`;

export const RightBottom = styled.div`
  width: 100%;
  height: 90px;
  border-top: 1px solid ${({ theme }) => theme.border1};
  background: ${({ theme }) => theme.bg0};
`;
export const RightBottomMain = styled.div<{ isAct: boolean }>`
  margin: 0 auto;
  height: 90px;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  padding-right: 30px;
  box-sizing: bordeer-box;
  opacity: ${({ isAct }) => (isAct ? "1" : "0.6")};
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    width: 95vw;
    padding-right: 0;
  }
`;
export const DropBtn = styled.div<{ isShow: boolean }>`
  font-size: 16px;
  font-family: Inter-Medium, Inter;
  font-weight: 500;
  color: ${({ theme }) => theme.text1};
  line-height: 38px;
  margin-left: 30px;
  width: auto;
  height: auto;
  overflow: hidden;
  cursor: pointer;
  display: ${({ isShow }) => (isShow ? " " : "none")};
`;
export const TopLine = styled.div`
  height: 47px;
  width: 100vw;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  border-bottom: 1px solid ${({ theme }) => theme.border1};

  @media screen and (min-width: ${LARGE_MEDIA_BREAKPOINT}) {
    display: none;
  }
`;
export const TopText = styled.div`
  font-size: 15px;
  font-family: Inter-Medium, Inter;
  font-weight: 600;
  color: ${({ theme }) => theme.text1};
`;
export const TopImg = styled.img`
  height: 20px;
  width: 20px;
`;
export const MenuDiv = styled.div<{ isShow: boolean }>`
  background: ${({ theme }) => theme.bg0};
  position: fixed;
  top: 70px;
  left: 0;
  z-index: 999;
  height: 100vw;
  width: 100vh;
  overflow: hidden;
  display: ${({ isShow }) => (isShow ? " " : "none")};
`;
export const BackLine = styled.div`
  height: 47px;
  width: 100vw;
  padding: 0 20px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
`;
export const TopText1 = styled.div`
  font-size: 15px;
  font-family: Inter-Medium, Inter;
  font-weight: 500;
  color: ${({ theme }) => theme.text3};
`;
export const TopText2 = styled.div`
  font-size: 15px;
  font-family: Inter-Medium, Inter;
  font-weight: bold;
  line-height: 40px;
  padding: 0 20px;
  color: ${({ theme }) => theme.text1};
`;
//index end


//SettingsForm
export const CardBox = styled.div<{ isAct: boolean }>`
  height: ${({ isAct }) => (isAct ? "auto" : "0")};
  overflow: hidden;
  opacity: ${({ isAct }) => (isAct ? "1" : "0")};
`;
export const CardMain = styled.div`
  width: 720px;

  min-height: 50vh;
  box-sizing: border-box;
  margin: 50px auto;
  font-size: 16px;
  font-family: Inter-Medium, Inter;
  font-weight: 500;
  @media screen and (min-width: ${LARGE_MEDIA_BREAKPOINT}) and (max-width: 1100px) {
    width: 500px;
    min-width: 0px;
    margin: 0 auto;
  }
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    width: 95vw;
    min-width: 0px;
    margin: 0 auto;
  }
`;
export const CardItem = styled.div`
  margin-bottom: 20px;
  position: relative;
`;
export const CardTitle = styled.div`
  padding: 30px 0 10px;
  font-size: 28px;
  font-family: Inter-Semi Bold, Inter;
  font-weight: 600;
  color: ${({ theme }) => theme.text1};
`;
export const ItemValue = styled.div`
  width: 100%;
  height: 38px;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.border1};
  overflow: hidden;
`;
export const ItemValuePos = styled.div`
  width: 40%;
  height: 36px;
  border-radius: 4px;
  border: 1px solid transparent;
  background: ${({ theme }) => theme.bg1};
  overflow: hidden;
  position: absolute;
  bottom: 1px;
  left: 1px;
}
`;

export const CardInfo = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
`;
export const ItemLabel = styled.div`
  margin: 10px 0;
  font-weight: 600;
  > span {
    color: #6a5bda;
    cursor: pointer;
  }
`;
export const ItemLabelFlex = styled.div`
  margin: 10px 0;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const ItemTip = styled.div`
  font-size: 14px;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  color: ${({ theme }) => theme.text3};
  line-height: 16px;
  margin-bottom: 10px;
`;
export const CardInfoImg = styled.img`
  width: 74px;
  height: 74px;
  border-radius: 4px;
  overflow: hidden;
  margin-right: 16px;
`;
export const ItemRadio = styled.div`
  width: 100%;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.border1};
`;
export const styleRadio = { width: "20px", height: "20px" };
export const ItemRadioOne = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.border1};
  padding: 0 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const ItemRadio11 = styled.div<{ isShow: boolean }>`
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.border1};
  padding:0 16px;
  box-sizing: border-box;
  width: 100%;
  height:auto;
  overflow: hidden;
  display: ${({ isShow }) => (isShow ? "auto" : "none")};
`;
export const CardItem1 = styled.div`
  margin-bottom: 20px;
  width: auto;
  height: auto;
  overflow: hidden;
`;
export const ItemRadio1 = styled.div`
  width: 100%;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.border1};
  padding: 0 16px;
  box-sizing: border-box;
`;
export const PopupDiv = styled.div<{ isShow: boolean }>`
  width: ${({ isShow }) => (isShow ? "100vw" : "0")};
  height: ${({ isShow }) => (isShow ? "100vh" : "0")};
  overflow: hidden;
  opacity: ${({ isShow }) => (isShow ? "1" : "0")};
  background: ${({ theme }) => theme.modalBG2};
  dispaly:${({ isShow }) => (isShow ? "auto" : "none")};
  position: fixed;
  top: 0;
  left: 0;
`;
export const PopupMain = styled.div<{ isShow: boolean }>`
  background: ${({ theme }) => theme.bg1};
  border-radius: 8px;
  padding: 0 40px 40px;
  box-sizing: border-box;
  font-size: 16px;
  font-family: Inter-Medium, Inter;
  font-weight: 500;
  width: ${({ isShow }) => (isShow ? "800px" : "0")};
  min-height: ${({ isShow }) => (isShow ? "auto" : "0")};
  height: ${({ isShow }) => (isShow ? "auto" : "0")};
  overflow: hidden;
  opacity: ${({ isShow }) => (isShow ? "1" : "0")};
  margin: 100px auto;
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    width: 90vw;
    margin: 10vh auto;
    max-height: 80vh;
    overflow: scroll;
  }
`;
export const CloseImg = styled.img`
  width: 25px;
  height: 25px;
  cursor: pointer;
`;
export const ItemValueIcon = styled.div`
  width: 100%;
  height: 38px;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.border1};
  overflow: hidden;
  display: flex;
  align-items: center;
`;
export const PriceSymbol = styled.div`
  margin-right: 10px;
  color: ${({ theme }) => theme.text3};
`;

export const InputBlock = styled.div`
flex:1;
overflow:hidden
`;
export const inputStyle = {
  outline: "none",
  width: "100%",
  height: "40px",
  border: "none",
  textAlign: "left",
  background: "transparent",
};
export const inputStyleNum = {
  outline: "none",
  width: "calc(100% + 23px)",
  height: "40px",
  border: "none",
  textAlign: "left",
  background: "transparent",
};
export const ItemValueThree = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  > div {
    width: 30%;
    height: 38px;
    border-radius: 4px;
    border: 1px solid ${({ theme }) => theme.border1};
    overflow: hidden;
  }
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    flex-wrap: wrap;
    > div {
      width: 100%;
      height: 38px;
      border-radius: 4px;
      border: 1px solid ${({ theme }) => theme.border1};
      overflow: hidden;
      margin-bottom: 10px;
    }
  }
`;
export const ItemValueThree3 = styled.div`
  width: 30%;
  height: 38px;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.border1};
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  > div {
    width: 54px;
    margin-right: 16px;
    font-size: 14px;
    font-family: Inter-Regular, Inter;
    font-weight: 400;
    line-height: 38px;
    color: #828282;
    text-align: right;
  }
`;
export const inputStyle2 = {
  outline: "none",
  width: "calc(100% - 86px)",
  height: "40px",
  border: "none",
  textAlign: "left",
  background: "transparent",
};
export const inputStyle2Num = {
  outline: "none",
  width: "calc(100% + 23px)",
  height: "40px",
  border: "none",
  textAlign: "left",
  background: "transparent",
};
export const ItemUpImg = styled.div`
  width: 100%;
  height: 167px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.border1};
  overflow: hidden;
`;
export const UploadBox = styled.div`
  width: 100%;
  display: flex;
  justify-content:center;
  margin: 20px auto 0;
`;

export const AddImgText = styled.div`
  width: 69px;
  height: 69px;
  border-radius: 6px;
  border: 1px dotted ${({ theme }) => theme.border1};
  margin: 30px auto 10px;
  text-align: center;
  line-height: 69px;
  font-size: 30px;
  color: ${({ theme }) => theme.text3};
`;
export const AddImgText1 = styled.div`
  width: 100%;
  text-align: center;
  font-size: 14px;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  color: ${({ theme }) => theme.text3};
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    font-size: 12px;
  }
`;
// SettingsForm end


//PreRevealForm 
export const CardMainFlex = styled.div`
  width: 720px;

  min-height: 50vh;
  box-sizing: border-box;
  margin: 50px auto;
  font-size: 16px;
  font-family: Inter-Medium, Inter;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  @media screen and (min-width: ${LARGE_MEDIA_BREAKPOINT}) and (max-width: 1100px) {
    width: calc(100% - 40px);
    min-width: 0px;
    margin: 0 auto;
  }
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    width: 95vw;
    display: block;
    min-width: 0px;
    margin: 0 auto;
  }
`;
export const CardMainLeft = styled.div`
  width: 60%;
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    width: 100%;
  }
`;
export const ItemValueArea = styled.div`
  width: 100%;
  height: 113px;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.border1};
  overflow: hidden;
`;
export const CardMainRight = styled.div`
  width: calc(40% - 20px);
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    width: 100%;
  }
`;
export const areaStyle = {
  outline: "none",
  width: "100%",
  height: "113px",
  border: "none",
  textAlign: "left",
  background: "transparent",
  resize:"none"
};
// PreRevealForm end

// LinksForm
export const LinkImg = styled.img`
  width: 16px;
  height: 16px;
  margin-right: 7px;
  margin-left: 16px;
`;
export const inputStyle1 = {
  outline: "none",
  width: "calc(100% - 39px)",
  height: "40px",
  border: "none",
  textAlign: "left",
  background: "transparent",
};
// LinksForm end

// ImagesForm
// ImagesForm end


// EarningsForm
export const CardRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
`;
export const CardRowL = styled.div`
  width: 60%;
  height: 38px;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.border1};
  overflow: hidden;
`;
export const CardRowM = styled.div`
  width: 25%;
  height: 38px;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.border1};
  overflow: hidden;
  display: flex;
  align-items: center;
  > div {
    width: 40px;
    margin-right: 16px;
    text-align: right;
  }
`;
export const CardRowR = styled.img`
  width: 18px;
  height: 18px;
  cursor: pointer;
`;
// EarningsForm end

// DropPageForm
// DropPageForm end

// DropEarningsForm
export const CardRowL1 = styled.div`
  width: 70%;
  height: 38px;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.border1};
  overflow: hidden;
`;
// DropEarningsForm end

// DetailForm
export const DelImg = styled.img`
  width: 10px;
  height: 10px;
  cursor: pointer;
`;
export const ItemTipR = styled.div`
position: absolute;
bottom: 0;
right: 0;
height: 49px;
display: flex;
align-items: center;
`;
export const ItemTag = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
export const ItemTipL = styled.div`
  font-size: 14px;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  color: ${({ theme }) => theme.text3};
  line-height: 16px;
  margin-bottom: 10px;
  max-width: calc(100% - 50px);
`;
export const OneTag = styled.div`
  // width: 51px;
  height: 33px;
  padding:0 10px;
  line-height: 33px;
  background: ${({ theme }) => theme.bg2};
  border-radius: 4px;
  text-align: center;
  font-size: 14px;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  color: ${({ theme }) => theme.text2};
  margin: 0 18px 10px 0;
  display: flex;
    align-items: center;
`;
export const TagDel = styled.img`
  width: 12px;
  height: 12px;
  cursor: pointer;
  margin-left:5px;
  
`;
// DetailForm end

//Menu
export const MenuItem = styled.div<{ isAct: boolean }>`
  width:60%;
  height: 63px;
  opacity: 1;
  overflow: hidden;
  line-height: 63px;
  font-size: 1.2rem;
  font-family: Inter-Medium, Inter;
  font-weight: 500;
  color: ${({ isAct }) => (isAct ? "#6A5BDA" : ({ theme }) => theme.text1)};
  padding:0 20px ;
  margin-left: 20%;
  box-sizing: border-box;
  border-radius: 10px;
  cursor: pointer;
  background: ${({ isAct }) => (isAct ? " #E2DEFF" : "")};
  :hover{
    background: ${({ isAct }) => (isAct ? " #E2DEFF" : ({ theme }) => theme.bg2)};
  }
`;
export const MenuTitle = styled.div`
  display: flex;
  align-items: center;
  width: 30vw;
  min-width: 200px;
  max-width: 350px;
  height: 99px;
  cursor: pointer;
  padding-left: 50px;
  box-sizing: border-box;
  > div {
    font-size: 1.2rem;
    font-family: Inter-Medium, Inter;
    font-weight: 500;
    color: ${({ theme }) => theme.text1};
  }
`;
export const BackImg = styled.img`
  width: 16px;
  height: 16px;
  transform: rotate(90deg);
`;
//Menu end

