import { Input } from "@rebass/forms";
import { useWeb3React } from "@web3-react/core";
import { ethers } from "ethers";
import { useState, useEffect } from "react";
import ListSelect from "components/ListSelect";
import { useGetChainQuery } from "apis/chain";
import {useToastOpen } from 'state/stats/hooks'

import { Seaport } from "@opensea/seaport-js";
import { ButtonPurple, ButtonTransparent, ButtonDanger } from "components/Button"
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "pages/index.scss"
import {
  DurationSelect, inputStyleNum, StyledModal, TipIcon
} from "../../components_";
import { ItemLabelFlex, CardTitle, CloseImg, CardItem, ItemLabel, ItemValue, PriceSymbol, ItemValueIcon, ItemTip, InputBlock } from "pages/Nft/EditCollection/components_"
import closeIcon from "assets/tool/close_icon.png";
import closeIconN from "assets/tool/close_icon_n.png";
import { useDarkModeManager } from "theme/components/ThemeToggle";
// import "../../index.scss";
import { ItemType } from "@opensea/seaport-js/lib/constants";
import { OrderWithCounter } from "@opensea/seaport-js/lib/types";
import {
  useGetOrderBestMutation, GetListOrderRequest
} from "apis/orders";
import "pages/index.scss";
import { Currency } from "core";

export interface QuickListForm {

  visible?: boolean;
  isEarning?: boolean;
  earningNum?: number;
  chain_identifier?: string
  contract_address?: string
  token_id?: string
  fromPrice?: string
}
export interface ListingOrder {
  order: OrderWithCounter;
  orderHash: string;
}
interface QuickListFormProps {
  value?: QuickListForm;
  onChange: (value: ListingOrder) => void;
  onClose: () => void
}

export default function QuickListEdit({
  value,
  onChange, onClose
}: QuickListFormProps) {

  const { account, provider } = useWeb3React();
  const [darkMode] = useDarkModeManager();
  const [,,, setToastOpen] = useToastOpen();

  const [getOrderBest] = useGetOrderBestMutation()
  const [showPopup, setShowPopup] = useState(true);
  const [earningsCustom, setEarningsCustom] = useState(false);
  const [startTime, setStartTime] = useState(new Date().getTime());
  const [endTime, setEndTime] = useState(new Date());
  const [fromPrice, setFromPrice] = useState('');
  const [floorPrice, setFloorPrice] = useState('');
  const [total, setTotal] = useState(0);
  const [durationTime, setDurationTime] = useState('');

  const [isLoading, setIsLoading] = useState(false);
  const [chainCurrency, setChainCurrency] = useState<Currency>();
  const handleDurationChange = (value: string) => {

    setDurationTime(value);
  };

  const { data } = useGetChainQuery({ chain: value?.chain_identifier as string });
  useEffect(() => {
    if (data?.success) {
      setChainCurrency(data?.data?.currency)
    }

  }, [data]);
  useEffect(() => {
    let dateTime = Date.parse(new Date().toString())
    let num = 0
    if (durationTime) {
      num = Number(durationTime)
      if (durationTime === "1month") {
        let myDate = new Date();
        let tMonth = myDate.getMonth();
        let m = tMonth + 1;
        let day31 = [1, 3, 5, 7, 8, 10, 12]
        let time = 0
        if (m === 2) {
          time = 86400 * 28
        } else if (day31.indexOf(m) === -1) {
          time = 86400 * 30
        } else {
          time = 86400 * 31
        }
        num = time
      }
    }
    let duration = dateTime + num * 1000
    setEndTime(new Date(duration))
  }, [durationTime]);
  useEffect(() => {
    if (fromPrice) {
      let num = value?.earningNum || 0
      let total = Number(fromPrice) * (1 - num - 0.025)
      setTotal(total)
    }
  }, [value, fromPrice]);
  const getBest = async () => {

    let ordersparams: GetListOrderRequest = {
      chain: value?.chain_identifier || "",
      contractAddress: value?.contract_address || "",
      tokenID: value?.token_id || "",
    }
    const { data, success } = await getOrderBest(ordersparams).unwrap();
    if (data && success) {
      let item: OrderItem = data
      let num = (Number(item?.price?.value) / Number(BigInt(1 * 10 ** item?.price?.currency?.decimals))).toString()
      // setEndTime(new Date(item.end_time * 1000))
      setFloorPrice(num)
    }

  }
  useEffect(() => {
    getBest()
    handleDurationChange("1month")
  }, []);
  const save = async () => {
    if (isLoading) {
      setToastOpen({open:true,type:'error',label:"Loading..." })

      return
    }
    if (fromPrice && fromPrice !== '') {
      // if (floorPrice && Number(fromPrice) <= Number(floorPrice)) {
      //   mes.open({ type: "error", content: "This amount is below the collection floor price. Consider a higher price" });
      //   return
      // }
      setIsLoading(true)
      try {
        const seaport = new Seaport(provider as any);
        const offerer = account;
        const { executeAllActions } = await seaport.createOrder(
          {
            startTime: (startTime / 1000).toFixed(0),
            endTime: (endTime.getTime() / 1000).toFixed(0),
            offer: [
              {
                itemType: ItemType.ERC721,
                token: value?.contract_address as string,
                identifier: value?.token_id as string,
              },
            ],
            consideration: [
              {
                amount: ethers.utils.parseEther(fromPrice).toString(),
                recipient: offerer,
              },
            ],
          },
          offerer,
        );

        const order = await executeAllActions();
        const orderHash = seaport.getOrderHash(order.parameters);
        onChange && onChange({ orderHash, order })
      } catch (err) {
        console.log('errorerror', err)
        setToastOpen({open:true,type:'error',label:(err as Error).message})

      }
      setIsLoading(false)
    } else {
      setToastOpen({open:true,type:'error',label:"Plase enter amount"})

    }




  };

  const ondataChange = (
    value: any
  ) => {
    console.log('data', value)
    setEndTime(new Date(value.toString()))
  };
  return (
    <>
      {/* <ItemLabelFlex>
        <CardTitle>Edit listing</CardTitle>
        <CloseImg
          src={darkMode ? closeIconN : closeIcon}
          onClick={() => onClose()}
        />
      </ItemLabelFlex> */}

      <CardItem>
        <ItemLabel>Set new price</ItemLabel>

        {/* <ItemTip>If you want to increase the price, you will be prompted to cancel all of your existing listings first. This will cost gas. Learn more.</ItemTip> */}

        <ButtonTransparent padding={'10px'} $borderRadius={'4px'} width={'100%'} onClick={() => setFromPrice(floorPrice || '')}>Floor {floorPrice || '0'} {chainCurrency?.name}</ButtonTransparent>

      </CardItem>
      <CardItem>

        <ItemValueIcon>
          <InputBlock style={{ paddingLeft: '8px' }}>
            <Input
              type="number"
              placeholder="Amount"
              value={fromPrice}
              sx={inputStyleNum}
              onChange={(e) => {
                setFromPrice(e.target.value);
              }}
            /></InputBlock>
          <PriceSymbol>{chainCurrency?.name}</PriceSymbol>
        </ItemValueIcon>
        {/* {fromPrice && floorPrice && Number(fromPrice) <= Number(floorPrice) ? (
          <ItemTip style={{ padding: '5px 0 0' }}><TipIcon style={{ marginTop: '0' }}>!</TipIcon>This amount is below the collection floor price. Consider a higher price.</ItemTip>
        ) : null} */}
      </CardItem>
      <CardItem>
        <ItemLabel>Use previous listing expiration date</ItemLabel>
        <ItemLabelFlex>
          <DurationSelect style={{ marginLeft: '0' }}>
            <ListSelect
              value={durationTime}
              optionArr={["Custom", "1 hour", "6 hour", "1 day", "3 days", "7 days", "1 month"]}
              typeValues={["", "3600", "21600", "86400", "259200", "604800", "1month"]}
              onChange={handleDurationChange}
              size=""
            /></DurationSelect>
          <ItemValue className={darkMode ? 'date-picker-divw' : 'date-picker-div'}>
            <DatePicker
              selected={endTime}
              onChange={(date) => ondataChange(date)}
              timeInputLabel="Time:"
              dateFormat="MM/dd/yyyy h:mm aa"
              className={darkMode ? 'date-picker-w' : 'date-picker'}
              showTimeInput
            />
          </ItemValue>

        </ItemLabelFlex>
      </CardItem>

      <ButtonDanger padding={'10px'} margin={'0 0 20px 0'} $borderRadius={'4px'} width={'100%'} onClick={() => onClose()}>Cancel all listings</ButtonDanger>
      <ButtonPurple padding={'10px'} $borderRadius={'4px'} width={'100%'} onClick={() => save()}>{isLoading ? 'Loading...' : 'Continue'}</ButtonPurple>

    </>
  );
}
