import { useEffect, useState } from "react";
import { useAppDispatch } from 'state/hooks';
import { useToastOpen, useTokenTrendingChain, useTokenTrendingPeriod } from 'state/stats/hooks';

import { useWeb3React } from "@web3-react/core";
import { useAddWatchListMutation, useDelWatchOneMutation, useTokensTrendListQuery, useWatchListIdsQuery } from "apis/ranking";
import sortFilled from "assets/tool/sort_filled.png";
import sortIcon from "assets/tool/sort_icon.png";
import NoData from "components/NoData";
import { ApplicationModal, setOpenModal } from 'state/application/reducer';
import { useToken } from 'state/user/hooks';
import {
  CardTableMain,
  CardTableTitleColFirst,
  CardTableTitleColLast,
  CardTableTitleColTop, CardTableTitleColTop1,
  CardTableTitleRow,
  ChainSelectBlock,
  FilterLine,
  OrderImg,
  PageLine,
  WebAll
} from "../components_";

import {
  MainBB, MainBottom,
  WebMain,
} from "pages/Nft/NftItems/components_";

import ChainSelect from "components/ChainSelect";
import PeriodSelect from "components/PeriodSelect/indexmore";

import CardTableLoadRow from "../components/CardTableLoadRow/index";
import CardTableMainRow from "../components/CardTableMainRow/topIndex";


import Tab from "../components/Tab";

export function TokensTrending() {

  const dispatch = useAppDispatch()
  const [delWatchOne] = useDelWatchOneMutation()
  const [tokenTrendingPeriod,tokenTrendingPeriodLabel,setTokenTrendingPeriod] = useTokenTrendingPeriod()
  const [tokenTrendingChain,setTokenTrendingChain] = useTokenTrendingChain()
  const [,,, setToastOpen] = useToastOpen();

  const [addWatchList] = useAddWatchListMutation()
  const { account } = useWeb3React();
  const [page, setPage] = useState(1);
  const [sortby, setSortby] = useState("");
  const [direct, setDirect] = useState("desc");
  const [total, setTotal] = useState(0);
  const [next, setNext] = useState<number>(1);//再次获取watchids
  const [loadList] = useState([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]);
  const token = useToken();
  const [isLoad,setIsLoad] = useState(false);

  const [trendList, setTrendList] = useState<TopToken[]>([]);
  const [watchIdMap, setWatchIdMap] = useState<Record<string, any>>();
  const handleChainChange = (value: string) => {
    setTokenTrendingChain(value);
  };
  const handleTimeChange = (value: string,label: string) => {
    setTokenTrendingPeriod({period:value,label:label});
    setPage(1);
  };

  const {
    data: trendingListResult,
    status, isLoading
  } = useTokensTrendListQuery({
    // page: page,
    pageSize: 20,
    sort: sortby,
    chain: tokenTrendingChain||'',
    direct: direct,
    period: tokenTrendingPeriod||''
    // peruid
  });

  const { data: watchIdsResult } = useWatchListIdsQuery({ account: account,type:'CRYPTO', next: next });
  const getSign = () => {
    dispatch(setOpenModal(ApplicationModal.ACCEPT_SIGN));
  }
  const addOne = async (i: TopToken, index: number) => {
    if (token) {
      try {
        const res = await addWatchList({
          chain: i.chain,
          address: i.contract_address,
          type:'CRYPTO',

        }).unwrap();
        if (res.success) {
          setToastOpen({open:true,type:'success',label:'success'})

          setNext(next + 1)
        } else {
          setToastOpen({open:true,type:'error',label:res.msg})
        }
      } catch (err) {
        setToastOpen({open:true,type:'error',label:(err as Error).message})
      }

    } else {//没sign
      getSign()
    }

  }
  const delOne = async (i: TopToken, index: number) => {
    if (token) {
      try {
        const res = await delWatchOne({
          chain: i.chain,
          contract_address: i.contract_address
        }).unwrap();
        if (res.success) {
          setToastOpen({open:true,type:'success',label:'success'})
          setNext(next + 1)
        } else {
          setToastOpen({open:true,type:'error',label:res.msg})
        }
      } catch (err) {
        setToastOpen({open:true,type:'error',label:(err as Error).message})

      }
    } else {
      getSign()

    }
  }
  useEffect(() => {
    var watchIdMap: Record<string, any> = {}
    watchIdsResult && watchIdsResult.data && watchIdsResult.data.forEach((v) => {
      watchIdMap[v] = true
    })
    setWatchIdMap(watchIdMap)

  }, [watchIdsResult])

  //监听拉取数据变化，处理出渲染数据
  useEffect(() => {
    const newTrendList: TopToken[] = [];
    if (trendingListResult) {
      if (trendingListResult.success && trendingListResult.data) {
        if (watchIdMap) {
          trendingListResult.data.forEach((e) => {
            let iswatch = false;
            let id = e.chain + '/' + e.contract_address
            if (watchIdMap[id.toString()]) {
              // if (watchIdMap[e.id.toString()]) {
              iswatch = true;
            }
            newTrendList.push({ ...e, iswatch });
          });
        } else {
          trendingListResult.data.forEach((e) => {
            newTrendList.push({ ...e, iswatch: false });
          });
        }
      }
      setTrendList(newTrendList);
      setIsLoad(false)

      setTotal(trendingListResult.total)
      if (!trendingListResult.success) {
        setToastOpen({open:true,type:'error',label:trendingListResult.message})
      }
    }

  }, [watchIdMap, trendingListResult]);


  useEffect(() => {
    
    setTrendList([]);
    setNext(next + 1)
    setIsLoad(true)
  }, [ tokenTrendingPeriod, sortby, direct, tokenTrendingChain]);
  const changeSort = (str: string) => {
    if (sortby === str) {
      if (direct === "asc") {
        setPage(1);
        setDirect("desc");
      } else if (direct === "desc") {
        setPage(1);
        setSortby("");
        setDirect("");
      } else if (direct === "") {
        setPage(1);
        setDirect("asc");
      }
    } else {
      setPage(1);
      setSortby(str);
      setDirect("asc");
    }
  };

  return (
    <>
      <WebAll>
        <WebMain>
          <PageLine>Tokens Stats</PageLine>

          <MainBottom>
            <Tab current="trending" />

            <MainBB
              style={{ display: "flex", flexDirection: "column" }}
            >
              <FilterLine>
                <ChainSelectBlock>
                  <ChainSelect
                    value={tokenTrendingChain||''}
                    onChange={handleChainChange}
                    size="big"
                  /></ChainSelectBlock>
                <PeriodSelect
                  value={tokenTrendingPeriod||''}
                  onChange={handleTimeChange}
                  size="big5"
                />
              </FilterLine>
              <CardTableTitleRow>
                <CardTableTitleColFirst>Token</CardTableTitleColFirst>
                <CardTableTitleColTop1 style={{cursor:'auto'}}
                >
                  <div>Price</div>
                </CardTableTitleColTop1>
                <CardTableTitleColTop
                onClick={() => {
                  changeSort("change");
                }}
                >
                  <div>Change({tokenTrendingPeriodLabel})</div>
                  <OrderImg
                    src={sortby === "change" ? sortFilled : sortIcon}
                    style={
                      direct === "desc" ? {} : { transform: "rotate(180deg)" }
                    }
                  />
                </CardTableTitleColTop>
                <CardTableTitleColTop
                onClick={() => {
                  changeSort("volume");
                }}
                >
                  <div>Volume({tokenTrendingPeriodLabel})</div>
                  <OrderImg
                    src={sortby === "volume" ? sortFilled : sortIcon}
                    style={
                      direct === "desc" ? {} : { transform: "rotate(180deg)" }
                    }
                  />
                </CardTableTitleColTop>
             
                <CardTableTitleColTop
                onClick={() => {
                  changeSort("liquidity");
                }}
                >
                  <div>Liquidity</div>
                  <OrderImg
                    src={sortby === "liquidity" ? sortFilled : sortIcon}
                    style={
                      direct === "desc" ? {} : { transform: "rotate(180deg)" }
                    }
                  />
                </CardTableTitleColTop>
                <CardTableTitleColTop
                  onClick={() => {
                    changeSort("fully_diluted_market_cap");
                  }}
                >
                  <div>Fully Diluted Market Cap</div>
                  <OrderImg
                    src={sortby === "fully_diluted_market_cap" ? sortFilled : sortIcon}
                    style={
                      direct === "desc" ? {} : { transform: "rotate(180deg)" }
                    }
                  />
                </CardTableTitleColTop>
                <CardTableTitleColTop
                  onClick={() => {
                    changeSort("holders");
                  }}
                >
                  <div>Holders</div>
                  <OrderImg
                    src={sortby === "holders" ? sortFilled : sortIcon}
                    style={
                      direct === "desc" ? {} : { transform: "rotate(180deg)" }
                    }
                  />
                </CardTableTitleColTop>
                {/* <CardTableTitleColTop style={{ cursor: 'auto' }}>Sales</CardTableTitleColTop> */}
                {/* <CardTableTitleColTop style={{ cursor: 'auto' }}>Owners</CardTableTitleColTop> */}
                <CardTableTitleColLast></CardTableTitleColLast>
              </CardTableTitleRow>

              {!isLoad&& status === "fulfilled" ? (!trendList || trendList.length < 1 ? (<NoData onChange={() => setTokenTrendingChain('')}></NoData>) : <> <CardTableMain>
                {trendList?.map((i: TopToken, index: number) => (
                  <CardTableMainRow
                    i={i}
                    iswatch={i.iswatch || false}
                    type={""}
                    onDelete={() => { delOne(i, index) }}
                    onAdd={() => addOne(i, index)}
                  />
                ))}
              </CardTableMain>                            
              {/* <Paging value={{ total: total, current: page, pagesize: 20, isLoad:false }} onChangeCurrent={(val) => setPage(val)} /> */}
              </>
              ) : (<CardTableMain>
                {loadList?.map(() => (
                  <CardTableLoadRow />
                ))}
              </CardTableMain>

              )}

            </MainBB>

          </MainBottom>
        </WebMain>
      </WebAll>
    </>
  );
}
