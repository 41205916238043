import JSBI from "jsbi";
import {  useEffect, useMemo, useState } from "react";
import { useERC20 } from "./useContract";
import { BigNumber } from "@ethersproject/bignumber";
import { Currency, CurrencyAmount } from "core";
import { useCurrentChain } from "state/chain/hooks";
export default function useCurrencyBalance(
  account?: string,
  currency?: Currency
): CurrencyAmount<Currency> | undefined {
  const [balance, setBalance] = useState<BigNumber | undefined>(undefined);

  const chainInfo=useCurrentChain()

  const address=chainInfo && currency&& chainInfo.id===currency.chain?currency.address:undefined
  const contract = useERC20(address);
  
  useEffect(() => {
    async function fetch() {
      if (account && contract) {
        console.log("start call balanceOf")
        const balance = await contract.balanceOf(account);
        console.log("call balance",balance.toString())
        setBalance(balance);
      } 
    }

    fetch();
  }, [account, contract]);

  return useMemo(() => {
    if (account && currency && balance) {
      return CurrencyAmount.fromRawAmount(currency, JSBI.BigInt(balance));
    }
  }, [account, balance, currency]);
}
