import { useWeb3React } from '@web3-react/core'
import Column from '../../Column'

import styled from 'styled-components/macro'



import userCollect from "assets/wallet/user_collect.png";
import userCollectN from "assets/wallet/user_collect_n.png";
import userFavourites from "assets/tool/watch_b.png";
import userFavouritesN from "assets/tool/watch_w.png";
import userOffers from "assets/wallet/user_offers.png";
import userOffersN from "assets/wallet/user_offers_n.png";
import userSettings from "assets/wallet/user_settings.png";
import userSettingsN from "assets/wallet/user_settings_n.png";

import userBulk from "assets/wallet/user_bulk.png";
import userBulkN from "assets/wallet/user_bulk_n.png";
import Option, { Menu } from './Option';
import OptionWithoutToken from './OptionWithoutToken';

import { useClientWidth } from "state/application/hooks";


const Tellist = [
  {
    text: "NFTs", img: '',
    imgn: '',
    route: "/nfts",
  },
  {
    text: "Drop", img: '',
    imgn: '',
    route: "/drop",
  },
  {
    text: "Stats", img: '',
    imgn: '',
    route: "/nfts/rankings/top",
  },
];
const OptionGrid = styled.div`
  display: grid;
  grid-gap: 2px;
  border-radius: 12px;
  overflow: hidden;

`

const DefaultMenuWrap = styled(Column)`
  width: 100%;
  height: 100%;
`

function DefaultMenu() {

  const clientWidth = useClientWidth();
  const { account } = useWeb3React()
  const isAuthenticated = !!account
  const menulist = [
    {
      text: "My collections",
      img: userCollect,
      imgn: userCollectN,
      route: "/my-collection",
    },
    {
      text: "Settings",
      img: userSettings,
      imgn: userSettingsN,
      route: `/account/${account}/settings`,
    },
    {
      text: "My Nfts",
      img: userBulk,
      imgn: userBulkN,
      route: `/account/${account}/collected`,

    },
    {
      text: "Offers",
      img: userOffers,
      imgn: userOffersN,
      route: `/account/${account}/offers`,
    },
    {
      text: "Watchlist",
      img: userFavourites,
      imgn: userFavouritesN,
      route: "/nfts/rankings/watchlist",
    },
  ];
  return (
    <DefaultMenuWrap>


      {isAuthenticated ? <OptionGrid data-testid="option-grid">

        {menulist.map((v: Menu) => (
          <OptionWithoutToken key={v.text} menu={v} />
        ))}
        {Number(clientWidth) < 801 ? Tellist.map((v: Menu) => (
          <OptionWithoutToken key={v.text} menu={v} />
        )) : null}
      </OptionGrid> : null}
    </DefaultMenuWrap>
  )
}

export default DefaultMenu