/**
 * SupportedChainId must be defined inline, without using @uniswap/sdk-core, so that its members are their own types
 * {@see https://www.typescriptlang.org/docs/handbook/enums.html#union-enums-and-enum-member-types}. This allows the
 * derived const arrays and their types (eg {@link L1_CHAIN_IDS}, {@link SupportedL1ChainId}) to be narrowed and used
 * to enforce chain typing.
 *
 * Because this is not explicitly derived from @uniswap/sdk-core, there is a unit test to enforce conformance.
 */
import { ChainId, SUPPORTED_CHAINS, SupportedChainsType } from '@uniswap/sdk-core'
export enum SupportedChainId {
    MAINNET = 1,
    GOERLI = 5,
    ARBITRUM_ONE = 42161,
    ARBITRUM_GOERLI = 421613,
    POLYGON = 137,
    POLYGON_MUMBAI = 80001,
    BNB = 56,
  }

  
  export const UniWalletSupportedChains = [
    SupportedChainId.MAINNET,
    SupportedChainId.ARBITRUM_ONE,
    SupportedChainId.POLYGON,
  ]
  
  export const CHAIN_IDS_TO_NAMES = {
    [SupportedChainId.MAINNET]: 'mainnet',
    [SupportedChainId.GOERLI]: 'goerli',
    [SupportedChainId.POLYGON]: 'polygon',
    [SupportedChainId.POLYGON_MUMBAI]: 'polygon_mumbai',
    [SupportedChainId.ARBITRUM_ONE]: 'arbitrum',
    [SupportedChainId.ARBITRUM_GOERLI]: 'arbitrum_goerli',
    [SupportedChainId.BNB]: 'bnb',
  }
  
  export const CHAIN_IDS_TO_LABELS = {
    [SupportedChainId.MAINNET]: 'mainnet',
    [SupportedChainId.GOERLI]: 'goerli',
    [SupportedChainId.POLYGON]: 'polygon',
    [SupportedChainId.POLYGON_MUMBAI]: 'polygon_mumbai',
    [SupportedChainId.ARBITRUM_ONE]: 'arbitrum',
    [SupportedChainId.ARBITRUM_GOERLI]: 'arbitrum_goerli',
    [SupportedChainId.BNB]: 'bnb',
  }

  /**
   * Array of all the supported chain IDs
   */
  export const ALL_SUPPORTED_CHAIN_IDS: SupportedChainId[] = Object.values(SupportedChainId).filter(
    (id) => typeof id === 'number'
  ) as SupportedChainId[]
  
  export function isSupportedChain(chainId: number | null | undefined): chainId is SupportedChainId {
    return !!chainId && !!SupportedChainId[chainId]
  }
  
  export const SUPPORTED_GAS_ESTIMATE_CHAIN_IDS = [
    SupportedChainId.MAINNET,
    SupportedChainId.POLYGON,
    SupportedChainId.ARBITRUM_ONE,
    SupportedChainId.BNB,
  ] as const
  
  /**
   * Unsupported networks for V2 pool behavior.
   */

  
  export const TESTNET_CHAIN_IDS = [
    SupportedChainId.GOERLI,
    SupportedChainId.POLYGON_MUMBAI,
    SupportedChainId.ARBITRUM_GOERLI,
  ] as const
  export type SupportedInterfaceChain = Exclude<SupportedChainsType, ChainId.BASE_GOERLI>
  export type SupportedTestnetChainId = typeof TESTNET_CHAIN_IDS[number]
  
  /**
   * All the chain IDs that are running the Ethereum protocol.
   */
  export const L1_CHAIN_IDS = [
    SupportedChainId.MAINNET,
    SupportedChainId.GOERLI,

    SupportedChainId.POLYGON,
    SupportedChainId.POLYGON_MUMBAI,

    SupportedChainId.BNB,
  ] as const
  
  export type SupportedL1ChainId = typeof L1_CHAIN_IDS[number]
  
  /**
   * Controls some L2 specific behavior, e.g. slippage tolerance, special UI behavior.
   * The expectation is that all of these networks have immediate transaction confirmation.
   */
  export const L2_CHAIN_IDS = [
    SupportedChainId.ARBITRUM_ONE,
    SupportedChainId.ARBITRUM_GOERLI,

  ] as const
  
  export type SupportedL2ChainId = typeof L2_CHAIN_IDS[number]
  
  export function isPolygonChain(chainId: number): chainId is SupportedChainId.POLYGON | SupportedChainId.POLYGON_MUMBAI {
    return chainId === SupportedChainId.POLYGON || chainId === SupportedChainId.POLYGON_MUMBAI
  }