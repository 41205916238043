
import styled, { keyframes } from "styled-components/macro";

import {MAX_WIDTH_MEDIA_BREAKPOINT,MIN_WIDTH_MEDIA_BREAKPOINT,LARGE_MEDIA_BREAKPOINT} from "constants/width"


export const PriceCardMainTel = styled.div`
  display: flex;
  align-items: flex-end;
  margin: 10px 0;
`;
export const TabLine = styled.div`
  display:flex;
  background: ${({ theme }) => theme.bg1};
  padding:2px;
  border-radius:4px;
  width:100px;
  margin-bottom:10px;
`;
export const TabItem = styled.div<{isAct:boolean}>`
  background: ${({ theme,isAct }) => isAct?theme.bg0:'transparent'};
    width:50px;
    text-align:center;
    padding:5px 0;
    border-radius:4px;
    
    cursor: pointer;
  color:${({ theme,isAct }) => isAct?theme.text1:theme.text3};
  :hover{
    background: ${({ theme,isAct }) => isAct?theme.bg0:theme.bg2};
  }
`;

export const PriceCardMainTelL = styled.div`
  display: flex;
  align-items: flex-end;
  font-family: Arial-Regular, Arial;
  font-weight: 400;
  > div {
    font-size: 26px;
    color: ${({ theme }) => theme.text1};
  }
`;
export const NftImgTel = styled.img`
width: 91.6vw;
// height: 91.6vw;
margin-bottom: 5px;
object-fit: contain;
}`;
export const NftImgTelNone = styled.div`
width: 91.6vw;
height:50vh;
background: ${({ theme }) => theme.bg2};
margin-bottom: 5px;
}`;

export const BuyBtnTel = styled.div`
  display: block;
  width: 100%;
  height: 38px;
  background: #6a5bda;
  border-radius: 4px;
  line-height: 38px;
  text-align: center;
  font-size: 16px;
  font-family: Inter-Bold, Inter;
  font-weight: 500;
  color: #ffffff;
`;
export const RecommendTitle = styled.div`
  font-size: 20px;
  font-family: Inter-Medium, Inter;
  font-weight: 500;
  color: ${({ theme }) => theme.text1};
  padding: 10px 0;
`;
export const TabCard = styled.div``;
export const TabUl = styled.div`
  display: flex;
  font-size: 18px;
  font-family: Inter-Medium, Inter;
  font-weight: 500;
`;
export const TabLi = styled.div<{ isAct: boolean }>`
  padding: 26px 0 10px;
  margin: 0 30px 0 6px;
  color: ${({ isAct }) =>
    isAct ? ({ theme }) => theme.text1 : ({ theme }) => theme.text3};
  border-bottom: 2px solid
    ${({ isAct }) => (isAct ? ({ theme }) => theme.text1 : "transparent")};
`;
export const DetailCardTel = styled.div<{ isShow: boolean }>`
  border-top: 1px solid ${({ theme }) => theme.border1};
  border-bottom: 1px solid ${({ theme }) => theme.border1};
  padding-bottom: ${({ isShow }) => (isShow ? "10px" : "0")};
  border: ${({ isShow }) => (isShow ? "" : "none")};
  width: ${({ isShow }) => (isShow ? "auto" : "0")};
  height: ${({ isShow }) => (isShow ? "auto" : "0")};
  opacity: ${({ isShow }) => (isShow ? "1" : "0")};
  overflow: hidden;
`;

export const DetailItemTel = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
`;
export const DetailItemTelR = styled.div`
  font-size: 12px;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  color: ${({ theme }) => theme.text1};
  display: flex;
  align-items: center;
  cursor: pointer;
`;
export const DetailItemTelL = styled.div`
  font-size: 13px;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  color: ${({ theme }) => theme.text3};
`;
export const ActUl = styled.div<{ isShow: boolean }>`
  width: ${({ isShow }) => (isShow ? "auto" : "0")};
  height: ${({ isShow }) => (isShow ? "auto" : "0")};
  opacity: ${({ isShow }) => (isShow ? "1" : "0")};
  overflow: hidden;
`;
export const ActItem = styled.div`
  border-top: 1px solid ${({ theme }) => theme.border1};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 13px 0;
`;
export const ActItemImg = styled.img`
  width: 29px;
  height: 29px;
`;
export const ActItemR = styled.div`
  width: calc(100% - 35px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
export const ActItemRBottom = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 13px;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  color: ${({ theme }) => theme.text3};
`;
export const ActItemRTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 15px;
  font-family: Inter-Medium, Inter;
  font-weight: 500;
  color: ${({ theme }) => theme.text1};
  > div:last-child {
    display: flex;
    align-items: center;
  }
`;












export const WebMain = styled.div`
  max-width:1440px;
  min-width:800px;
  min-height: calc(100vh - 370px);
  overflow: hidden;
  background: ${({ theme }) => theme.bg0};
  margin: 20px auto 0;
  z-index: 11;
  position: relative;
`;
export const WebBg = styled.div`
  width:100%;
  height:100%;
  background: ${({ theme }) => theme.bg0};
  z-index: 1;
  top: 0;
  left: 0;
  position: fixed;
`;
export const TelMain = styled.div`
  width: 91.6vw;
  margin: 0 auto;
  overflow: hidden;
  min-height:calc(100vh - 270px)
  padding-top:20px
`;
export const MainBottom=styled.div``
export const CardItem = styled.div`
  width: 100%;
  border: 1px solid ${({ theme }) => theme.border1};
  background: ${({ theme }) => theme.bg0};
  border-radius: 6px;
  overflow: hidden;
  margin-bottom: 20px;
  box-sizing: border-box;
`;
export const CardTop = styled.div`
  height: 46px;
  background: ${({ theme }) => theme.bg0};
  padding: 0 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  > div {
    font-size: 16px;
    font-family: Inter-Regular, Inter;
    font-weight: 400;
    color: ${({ theme }) => theme.text1};
  }
`;

export const CardMain = styled.div<{ isShow: boolean }>`
  border-top: 1px solid ${({ theme }) => theme.border1};
  padding: ${({ isShow }) => (isShow ? "14px" : "0")};
  width: ${({ isShow }) => (isShow ? "auto" : "0")};
  height: ${({ isShow }) => (isShow ? "auto" : "0")};
  opacity: ${({ isShow }) => (isShow ? "1" : "0")};
  overflow: hidden;
`;
export const CardTableMain = styled.div`
 
`;
export const CardTableMainRow = styled.div`
display:flex;
align-items:center;
border-top:1px solid ${({ theme }) => theme.border1};
padding:0 14px;
box-sizing:border-box;
height:37px;
>div{
  width:20%;
  white-space:nowrap;
  font-size:14px;
  overflow:hidden;
  text-overflow: ellipsis; //溢出用省略号显示
  white-space: nowrap; //溢出不换行
}
`;

export const CardTableMainRow1 = styled.div`
display:flex;
align-items:center;
border-top:1px solid ${({ theme }) => theme.border1};
padding:0 14px;
box-sizing:border-box;
height:37px;
>div{
  width:16.5%;
  white-space:nowrap;
  font-size:14px;
  overflow:hidden;
  text-overflow: ellipsis; //溢出用省略号显示
  white-space: nowrap; //溢出不换行
}
`;
export const CardTableTitleRow = styled.div`
display:flex;
align-items:center;
padding:0 14px;
box-sizing:border-box;
height:37px;
color: ${({ theme }) => theme.text3};
>div{
  width:20%;
  white-space:nowrap;
  font-size:14px;
  overflow:hidden
}
`;
export const CardTableTitleRow2 = styled.div`
display:flex;
align-items:center;
justify-content: space-between;

padding:0 14px;
box-sizing:border-box;
height:37px;
color: ${({ theme }) => theme.text3};

position: relative;
>div{
  width:16%;
  white-space:nowrap;
  text-overflow: ellipsis; //溢出用省略号显示
  font-size:14px;
  overflow:hidden
}
>div:last-child{
  height:35px;
  display: flex;
  flex-direction: row-reverse;
}
`;
export const CardTableTitleRow3 = styled.div`
display:flex;
align-items:center;
justify-content: space-between;

padding:0 14px;
box-sizing:border-box;
height:37px;
color: ${({ theme }) => theme.text3};

position: relative;
>div{
  display:flex;
align-items:center;
justify-content: space-between;
  >div{
    width:16%;
    white-space:nowrap;
    font-size:14px;
    overflow:hidden
  }
  >div:last-child{
    display: flex;
    flex-direction: row-reverse;
  }
}

`;
export const CardTableTitleRow1 = styled.div`
display:flex;
align-items:center;
padding:0 14px;
box-sizing:border-box;
height:37px;
color: ${({ theme }) => theme.text3};
>div{
  width:16.5%;
  white-space:nowrap;
  font-size:14px;
  overflow:hidden
}
`;
export const CardMainTel = styled.div<{ isShow: boolean }>`

  width:91.6vw;
 
  overflow: hidden;
`;
export const AttributeCard = styled.div<{ isShow: boolean }>`
  border-top: 1px solid ${({ theme }) => theme.border1};
  padding: ${({ isShow }) => (isShow ? "14px" : "0")};
  padding-left:0;
  display: ${({ isShow }) => (isShow ? "flex" : "none")};
  flex-wrap: wrap;
  justify-content: space-around;
  width: ${({ isShow }) => (isShow ? "auto" : "0")};
  height: ${({ isShow }) => (isShow ? "auto" : "0")};
  opacity: ${({ isShow }) => (isShow ? "1" : "0")};
  overflow: hidden;
`;
export const AttributeItem = styled.div`
  background:${({ theme }) => theme.bg1};
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.border1};
  width: 30%;
  margin-bottom: 14px;
  padding: 10px 12px;
  box-sizing: border-box;
  overflow:hidden;
  min-width: 120px;
  flex:1;
  margin-left:14px;
`;
export const AttributeItemLoad = styled.div`
  background:${({ theme }) => theme.bg1};
  border-radius: 4px;
  width: 30%;
  margin-bottom: 14px;
  padding-top: 30%;
  box-sizing: border-box;
  min-width: 120px;
`;

export const AttributeItemNone = styled.div`
  width: 30%;
  opacity: 0;
  min-width: 120px;
  flex:1;
  margin-left:14px;
  padding: 10px 12px;
  box-sizing: border-box;
`;
export const AILine1 = styled.div`
  font-size: 12px;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  color: ${({ theme }) => theme.text2};
  line-height: 14px;
`;
export const AILine2 = styled.div`
  font-size: 14px;
  font-family: Inter-Bold, Inter;
  font-weight: bold;
  color: ${({ theme }) => theme.text1};
  line-height: 16px;
  margin-bottom: 5px;
`;
export const AILine3 = styled.div`
  font-size: 12px;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  color: #afafaf;
  line-height: 14px;
  margin-bottom: 0;
`;
export const WebMainTop = styled.div`
padding-top:20px;
  display: flex;
  justify-content: space-between;
`;
export const WebMainTopR = styled.div`
// width: 36vw;
width: calc(64% - 20px);
// max-width: 880px;
  // min-width: 440px;
`;
export const WebMainTopL = styled.div`
  // width: 26vw;
  width: 36%;
  // max-width: 640px;
  // min-width: 320px;
`;
export const WebMainTopDiv = styled.div`
  width: 100%;
 padding-top: 100%;
 overflow:hidden;
 margin-bottom: 20px;
 
 border:5px solid ${({ theme }) => theme.bg0};
 border-radius:14px;
 border-radius:14px;
 position: relative;
`;
export const WebMainTopImg = styled.img`
  width:100%;
  height:100%;
  position: absolute;
  top:0;
  left:0;
  box-sizing:border-box;
  transition: all 0.8s;
  object-fit: contain;
  :hover{
    transform: scale(1.2);
  }
`;
export const WebMainTopImgLoad = styled.div`
  width:100%;
  margin-bottom: 20px;
  background: ${({ theme }) => theme.bg1};
  padding-top:100%;
  border-radius:14px;
  box-sizing:border-box;
`;

export const DescTopImg1 = styled.img`
  width: 12px;
  height: 12px;
  border-radius: 50%;
`;
export const DescTopName = styled.div`
  margin: 0 4px;
  font-size: 14px;
  font-family: Inter-Regular, Inter;
  font-weight: normal;
  color: ${({ theme }) => theme.text1};
`;
export const PriceCard = styled.div<{ isShow: boolean }>`
  display: ${({ isShow }) => (isShow ? "flex" : "none")};
  justify-content: space-between;
  align-items: center;
  // border-top: 1px solid ${({ theme }) => theme.border1};
  padding: ${({ isShow }) => (isShow ? "14px" : "0")};
  width: ${({ isShow }) => (isShow ? "auto" : "0")};
  height: ${({ isShow }) => (isShow ? "auto" : "0")};
  opacity: ${({ isShow }) => (isShow ? "1" : "0")};
  overflow: hidden;
`;
export const BuyBtn = styled.div`
  display: block;
  width: 241px;
  height: 46px;
  background: ${({ theme }) => theme.purple1};
  border-radius: 4px;
  line-height: 46px;
  text-align: center;
  font-size: 16px;
  font-family: Inter-Bold, Inter;
  font-weight: bold;
  color: ${({ theme }) => theme.bg0};
  cursor: pointer;
`;
export const BuyBtnTransparent = styled(BuyBtn)`
width: 50%;

background:transparent;

color: ${({ theme }) => theme.text2};
border:1px solid ${({ theme }) => theme.border1};
@media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
  width: 100%;
  margin-top:10px;
}

`;
export const BuyBtn1 = styled.div`
  display: block;
  width: 100px;
  height: 38px;
  cursor: pointer;
  background: ${({ theme }) => theme.purple1};
  border-radius: 4px;
  line-height: 38px;
  text-align: center;
  font-size: 14px;
  font-family: Inter-Bold, Inter;
  font-weight: 500;
  color: ${({ theme }) => theme.bg0};
  margin: 0 auto;
`;

export const PriceCardL = styled.div`
  // display: flex;
  // align-items: flex-end;
`;
export const PriceCardLine = styled.div`
  display: flex;
  align-items: flex-end;
`;
export const PriceCardLText2 = styled.div`
  display: flex;
  align-items: center;
  > div {
    font-size: 24px;
    font-family: Arial-Regular, Arial;
    font-weight: 400;
    color: ${({ theme }) => theme.text1};
  }
`;
export const PriceCardImg = styled.img`
  width: 16px;
  height: 16px;
`;
export const PriceCardLText1 = styled.div`
display: inline-block;
  font-size: 12px;
  font-family: Arial-Regular, Arial;
  font-weight: normal;
  color: ${({ theme }) => theme.text3};
  padding-bottom: 5px;
  margin-left: 5px;
`;
export const RTitle = styled.div`
  font-size: 30px;
  font-family: Inter-Bold, Inter;
  font-weight: bold;
  color: ${({ theme }) => theme.text1};
  line-height: 35px;
  height: 40px;
  margin-bottom: 12px;
  white-space:nowrap;
  overflow:hidden;
  text-overflow:ellipsis
`;
export const RTitleLoad = styled.div`
white-space:pre;
  font-size: 30px;
  font-family: Inter-Bold, Inter;
  font-weight: bold;
  color: ${({ theme }) => theme.bg1};
  background: ${({ theme }) => theme.bg1};
  line-height: 35px;
  margin-bottom: 12px;
  width: 50%;
`;

export const RTitleTel = styled.div`
font-family: Inter-Bold, Inter;
font-weight: bold;
color: ${({ theme }) => theme.text1};
line-height: 35px;
margin-bottom: 12px;
max-width: calc(91.6vw - 22px);
        overflow: hidden;
        text-overflow: ellipsis; //溢出用省略号显示
        white-space: nowrap; //溢出不换行
`;
export const RAddress = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  cursor: pointer;
  > div {
    font-size: 14px;
    font-family: Arial-Regular, Arial;
    font-weight: 400;
    color: ${({ theme }) => theme.text2};
    margin-right: 12px;
  }
`;
export const PriceCardTel=styled.div`
width: 100%;
background: ${({ theme }) => theme.bg2};
border-radius: 7px;
margin-top:5px;
padding:18px 15px;
box-sizing:border-box;
border: 1px solid ${({ theme }) => theme.border1};
`
export const PriceCardTitleTel=styled.div`
font-size: 18px;
font-family: Inter-Regular, Inter;
font-weight: 400;
color:${({ theme }) => theme.text3};
`
export const AddressImg = styled.img`
  width: 16px;
  height: 16px;
`;
export const DetailCard = styled.div<{ isShow: boolean }>`
  border-top: 1px solid ${({ theme }) => theme.border1};
  padding: ${({ isShow }) => (isShow ? "14px" : "0")};
  width: ${({ isShow }) => (isShow ? "auto" : "0")};
  height: ${({ isShow }) => (isShow ? "auto" : "0")};
  opacity: ${({ isShow }) => (isShow ? "1" : "0")};
  overflow: hidden;
`;
export const DetailItem = styled.div`
  display: flex;
  align-items: center;
  // justify-content: space-between;
  margin-top: 10px;
`;

export const DetailItemL = styled.div`
  font-size: 14px;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  width:40%;
  color: ${({ theme }) => theme.text3};
`;
export const DetailItemR = styled.div`
  font-size: 14px;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  width:60%;
  color: ${({ theme }) => theme.text1};
  text-align:right;
`;
export const DetailItemR1 = styled.div`
  display: flex;
  align-items: center;
  width:60%;
  justify-content: flex-end;
  cursor: pointer;
  > div {
    font-size: 14px;
    font-family: Inter-Regular, Inter;
    font-weight: 400;
    color: ${({ theme }) => theme.text1};
  }
`;
export const FlexLine = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  > div:first-child {
    width: calc(50% - 10px);
  }

  > div:last-child {
    width: calc(50% - 10px);
  }
`;
export const CardTopImg = styled.img`
  width: 18px;
  height: 18px;
`;
export const RecommendItem = styled.div`
  width: calc(16.7% - 15px);
  background: ${({ theme }) => theme.bg0};
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.border1};
  overflow: hidden;
  margin-bottom: 10px;
  cursor: pointer;
`;
export const RecommendItemLoad = styled.div`
  width: calc(16.7% - 15px);
  height:200px;
  background: ${({ theme }) => theme.bg2};
  border-radius: 4px;
  margin-bottom: 10px;
`;
export const RecommendItemTel = styled.div`
  width: calc(45.8vw - 10px);
  background: ${({ theme }) => theme.bg0};
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.border1};
  overflow: hidden;
  margin-bottom: 10px;
`;

export const RecommendItemTelLoad = styled.div`
  width: calc(50% - 10px);
  height:200px;
  background: ${({ theme }) => theme.bg2};
  border-radius: 4px;
  overflow: hidden;
  margin-bottom: 10px;
`;

export const CardMainUi = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const InfoDescLoadTel = styled.div`
  white-space:pre;
  color: ${({ theme }) => theme.bg2};
  background: ${({ theme }) => theme.bg2};
`;
export const InfoDescLoad = styled.div`
white-space:pre;
  color: ${({ theme }) => theme.bg1};
  background: ${({ theme }) => theme.bg1};
`;
export const CardMainBtn = styled.div<{ isShow: boolean }>`
  padding: ${({ isShow }) => (isShow ? "20px" : "0")};
  width: ${({ isShow }) => (isShow ? "auto" : "0")};
  height: ${({ isShow }) => (isShow ? "auto" : "0")};
  opacity: ${({ isShow }) => (isShow ? "1" : "0")};
  overflow: hidden;
`;
export const RecomImg = styled.img`
  width: 100%;
  overflow: hidden;
`;
export const RecomBottom = styled.div`
  padding: 6px 9px;
  box-sizing: border-box;
  font-size: 13px;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  color: ${({ theme }) => theme.text3};
  line-height: 16px;
`;
export const RecomBottomName = styled.div`
  display: flex;
  align-items: center;
  > div {
    max-width: calc(100% - 15px);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
export const RecomBottomNameImg = styled.img`
  width: 11px;
  height: 11px;
  margin-left: 4px;
`;
export const RecomBottomCode = styled.div`
  font-size: 13px;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  color: ${({ theme }) => theme.text1};
  padding: 3px 0;
  line-height: 16px;overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
export const RecomBottomPrice = styled.div`
  font-size: 10px;
  display: flex;
  align-items: center;
  > span {
    font-size: 13px;
    color: ${({ theme }) => theme.text1};
  }
`;
export const PageTitle = styled.div`
  font-size: 20px;
  font-family: Inter-Bold, Inter;
  font-weight: bold;
  color: ${({ theme }) => theme.text1};
  line-height: 50px;
`;
