import { OrderWithCounter } from '@opensea/seaport-js/lib/types';
import { emptySplitApi } from './emptySplitApi'



export interface CreatListOrderResponse {
  data: {
    order: OrderWithCounter;
    token: string;
  };

  msg: null | string;
  code: "fail" | "ok";
}

export interface CreateListOrderRequest {
  chain:string
  protocol:string
  order:OrderWithCounter
  orderHash:string
 }

 export interface GetListOrderRequest {
  chain:string
  contractAddress:string
  tokenID?:string
  side?:string
 }
 export interface FulfillmentDataRequest {
  fulfiller:{
    address:string
  };
  listing:{chain:string
  hash?:string
  protocol_address?:string}
 }
 
 
const ordersApi = emptySplitApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({

    saveListingOrder: builder.mutation<{
      data: Assets[];
      total: number;
      message: null | string;
      success: boolean;

    }, CreateListOrderRequest>({
      query: (params) => ({
        url: `/orders/${params.chain}/${params.protocol}/listings`,
        method: "POST",
        body: { order:params.order,order_hash:params.orderHash },
      }),
    }),
    updateListingOrder: builder.mutation<{
      data: Assets[];
      total: number;
      message: null | string;
      success: boolean;

    }, CreateListOrderRequest>({
      query: (params) => ({
        url: `/orders/${params.chain}/${params.protocol}/listings`,
        method: "POST",
        body: { order:params.order,order_hash:params.orderHash },
      }),
    }),
    fulfillmentData: builder.mutation<{
      data: {
        fulfillment_data:{
          orders:OrderWithCounter[]
        };
        protocol: string;

      };
      total: number;
      message: null | string;
      success: boolean;

    }, FulfillmentDataRequest>({
      query: (params) => ({
        url: `/listings/fulfillment_data`,
        method: "POST",
        body: params,
      }),
    }),
    getOrders: builder.mutation<{
      data: OrderItem[];
      total: number;
      message: null | string;
      success: boolean;

    }, GetListOrderRequest>({
      query: (params) => ({
        url: `/orders?chain=${params.chain}&contractAddress=${params.contractAddress}&tokenID=${params.tokenID}&side=${params.side}`,
        method: "GET",
      }),
    }),
    getOrderBest: builder.mutation<{
      data: OrderItem;
      total: number;
      message: null | string;
      success: boolean;

    }, GetListOrderRequest>({
      query: (params) => ({
        // url: `/listings/collection/${params.chain}/${params.contractAddress}/nft/${params.tokenID}/best`,
        url: `/listings/collection/${params.chain}/${params.contractAddress}/best`,
        method: "GET",
      }),
    }),

  }),
});

export const {
  useSaveListingOrderMutation,
  useUpdateListingOrderMutation,
  useGetOrdersMutation,
  useFulfillmentDataMutation,
  useGetOrderBestMutation
} = ordersApi;
