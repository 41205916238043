import { i18n } from '@lingui/core'
import { I18nProvider } from '@lingui/react'
import { DEFAULT_LOCALE, DEFAULT_MESSAGES, SupportedLocale } from './constants/locales'
import { initialLocale, useActiveLocale } from './hooks/useActiveLocale'

import { useEffect } from 'react'
import { ReactNode } from 'react'
import { useUserLocaleManager } from './state/user/hooks'

async function dynamicActivate(locale: SupportedLocale) { 
  const { messages } = locale === DEFAULT_LOCALE ? { messages: DEFAULT_MESSAGES } : await import(`./locales/${locale}`)
  i18n.load(locale, messages)
  i18n.activate(locale)
}

dynamicActivate(initialLocale)
export function LanguageProvider({ children }: { children: ReactNode }) {
  const locale = useActiveLocale()
  const [, setUserLocale] = useUserLocaleManager()

  useEffect(() => {
    dynamicActivate(locale)
      .then(() => {
        document.documentElement.setAttribute('lang', locale)
        setUserLocale(locale) // stores the selected locale to persist across sessions
      })
      .catch((error) => {
        console.error('Failed to activate locale', locale, error)
      })
  }, [locale, setUserLocale])

  return (
    <I18nProvider i18n={i18n}>
      {children}
    </I18nProvider>
  )
}
