import { Input, Select } from "@rebass/forms";
import {
  LARGE_MEDIA_BREAKPOINT,
  MIN_WIDTH_MEDIA_BREAKPOINT
} from "constants/width";
import {
  PageCenter
} from "pages/Drop/components_";
import { useNavigate } from "react-router";
import { Box, Button, Flex } from "rebass";
import { useToggleModal } from "state/application/hooks";
import { ApplicationModal } from "state/application/reducer";
import styled from "styled-components/macro";

const LinearGradient = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  width: 100%;
  border-radius: 8px;
  color: ${({ theme }) => theme.text1};
  font-size: 18px;
  font-weight: 600;
  background: linear-gradient(
    transparent,
    ${({ theme }) => theme.bg0},
    ${({ theme }) => theme.bg0}
  );
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    padding-bottom: 10px;
  }
`;

const CollectDesc = styled.div`
  color: ${({ theme }) => theme.text2};
  margin-top: 6px;
  font-size: 18px;
  font-weight: 400;
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    font-size: 14px;
  }
`;
const BigTitle = styled.div`
  color: ${({ theme }) => theme.text1};
  margin-top: 6px;
  font-size: 60px;
  font-weight: 600;
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    font-size: 36px;
  }
`;
const InfoArea = styled.div`
  width: 100%;

  border-radius: 8px;
  background: ${({ theme }) => theme.bg0};

  padding: 35px;
  box-sizing: bordedr-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    padding: 30px 15px;
    height: auto;
    flex-wrap: wrap;
   
  }
`;

const SearchFlex=styled(Flex)`
align-items: center;
justify-content: flex-start;
@media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
  flex-direction:column;
  > div {
    margin-top: 5px;
  }
}
`

const SearchBox = styled(Box)`
  height: 60px;
  border-radius: 30px;
  background: ${({ theme }) => theme.modalBG1};
  border: 1px solid ${({ theme }) => theme.bg2};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;

  position: relative;
  :hover {
    border: 1px solid ${({ theme }) => theme.border1};
  }
  @media screen and (min-width: ${LARGE_MEDIA_BREAKPOINT}) {
    min-width: 650px;

  }
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    width: 100%;

  }
`;

//width: calc(100% - 40px);
const SearchInput = styled(Input)`
  outline: none;
  height: 60px;
  border: none;
`;
const SearchSelect = styled(Select)`
  border: none;
  overflow: scroll;
  width: 10em;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s,
    -webkit-box-shadow ease-in-out 0.15s;
`;
const SearchButton = styled(Button)`
  background: ${({ theme }) => theme.blue1};
  border-radius: 20px;
  width:120px;
  @media screen and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    display:none;
  }

`;

const PlaceOrderButton = styled(Button)`
  cursor: pointer;
  background: ${({ theme }) => theme.blue1};
  border-radius: 25px;

`;

const InfoAreaR = styled.div`
  width: 40%;
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  border: 2px solid ${({ theme }) => theme.bg2};
  cursor: pointer;
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    width: 100%;
  }
`;
export default function Home(props: any) {

  let navigate = useNavigate();
  const toggleModal = useToggleModal(ApplicationModal.PLACE_ORDER);

const handlePlaceOrderClick=()=>{
  toggleModal()
}

  return (
    <>

        <>
          <PageCenter>
            <InfoArea>
              <Flex flexWrap="wrap" >
                <Box width={1}>
                  <BigTitle>Buy & Sell crypto at <br/> your price</BigTitle>
                  {/* <CollectDesc>
                  Trade directly: safe and smooth decentralized transactions
                  </CollectDesc> */}
                </Box>
                <SearchFlex width={"100%"}  paddingBottom={60} paddingTop={50} >
                  <SearchBox >
                    <SearchSelect value="">
                      <option>i want buy</option>
                      <option>i want sell</option>
                    </SearchSelect>
                    <SearchInput placeholder="Search by crypto or contract address" />
                    <SearchButton>Search</SearchButton>
                  </SearchBox>
                    <Box p={2}>Or</Box>
                    <Box ><PlaceOrderButton onClick={()=>navigate("/cryptos/listing/limit-order")}>Place order</PlaceOrderButton></Box>
                  
                </SearchFlex>
              </Flex>
            </InfoArea>
          </PageCenter>
        </>
 
    </>
  );
}
