import NftTop from "../components/top";
import NftItems from "../components/items";
import {
  MainBottom,
  WebMain
} from "../components_";


export default function Home() {

  return (
    <>
      
      <WebMain>
        <NftTop />
        <MainBottom>
          <NftItems />
        </MainBottom>
      </WebMain>

    </>
  );
}
