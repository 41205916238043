import {
  useWeb3React,
  Web3ReactHooks,
  Web3ReactProvider,
} from "@web3-react/core";
import { Connector } from "@web3-react/types";
import { ReactNode, useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { useLoginMutation } from "../../apis/account";
import { getConnection } from "../../connection";
import { isSupportedChain } from "../../constants/chains";
import { RPC_PROVIDERS } from "../../constants/providers";
import useEagerlyConnect from "../../hooks/useEagerlyConnect";
import useOrderedConnections from "../../hooks/useOrderedConnections";
import usePrevious from "../../hooks/usePrevious";
import { useAppDispatch } from "../../state/hooks";
import { useAccount } from "../../state/user/hooks";
import { logout } from "../../state/user/reducer";
import { useConnectedWallets } from "../../state/wallets/hooks";
import { getCurrentPageFromLocation } from "../../utils/urlRoutes";
import { useSelectChainByChainId, useSupportChains } from "state/chain/hooks";
export default function Web3Provider({ children }: { children: ReactNode }) {
  useEagerlyConnect();

  const connections = useOrderedConnections();
  const connectors: [Connector, Web3ReactHooks][] = connections.map(
    ({ hooks, connector }) => [connector, hooks]
  );

  const key = useMemo(
    () => connections.map((connection) => connection.getName()).join("-"),
    [connections]
  );

  return (
    <Web3ReactProvider connectors={connectors} key={key}>
      <Updater />
      {children}
    </Web3ReactProvider>
  );
}

/** A component to run hooks under the Web3ReactProvider context. */
function Updater() {
  const { account, chainId, connector, provider } = useWeb3React();
  const { pathname } = useLocation();
  const [login] = useLoginMutation();
  const [acc, setAccount] = useAccount();
  const dispatch = useAppDispatch();
  const  [supportChains,current, setCurrentChain] =useSupportChains()
  
  const chainInfo=useSelectChainByChainId(chainId)
  setCurrentChain(chainInfo)
  useEffect(()=>{
   
  },[chainId])
  const currentPage = getCurrentPageFromLocation(pathname);
  // Trace RPC calls (for debugging).

  const networkProvider = isSupportedChain(chainId)
    ? RPC_PROVIDERS[chainId]
    : undefined;

  useEffect(() => {
    return () => {
      provider?.off("debug", trace);
      networkProvider?.off("debug", trace);
    };
  }, [networkProvider, provider]);

  const previousAccount = usePrevious(account);
  const [connectedWallets, addConnectedWallet] = useConnectedWallets();
  useEffect(() => {
    if (account && account !== previousAccount) {
      const walletType = getConnection(connector).getName();
      const isReconnect = connectedWallets.some(
        (wallet) =>
          wallet.account === account && wallet.walletType === walletType
      );
      if (acc?.address?.toUpperCase() !== account?.toUpperCase()) {
        dispatch(logout());
      }
      addConnectedWallet({ account, walletType });
    }
  }, [account, acc, setAccount, previousAccount, addConnectedWallet, login, currentPage, chainId, connectedWallets, connector, provider, dispatch]);

  return null;
}

function trace(event: any) {
  if (!event?.request) return;
  const { method, id, params } = event.request;
  console.groupCollapsed(method, id);
  console.debug(params);
  console.groupEnd();
}
