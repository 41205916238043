import { AppState } from "../../state"
import { useAppDispatch, useAppSelector } from "../hooks"
import {  ApplicationModal,   setOpenModal } from './reducer'
import { useCallback, useMemo } from 'react'

export function useModalIsOpen(modal: ApplicationModal): boolean {
  const openModal = useAppSelector((state: AppState) => state.application.openModal)
  return openModal === modal
}


export function useOpenModal(modal: ApplicationModal): () => void {
  const dispatch = useAppDispatch()
  return useCallback(() => dispatch(setOpenModal(modal)), [dispatch, modal])
}

export function useToggleModal(modal: ApplicationModal): () => void {
  const isOpen = useModalIsOpen(modal)
  const dispatch = useAppDispatch()
  return useCallback(() => dispatch(setOpenModal(isOpen ? null : modal)), [dispatch, modal, isOpen])
}

export function useCloseModal(): () => void {
  const dispatch = useAppDispatch()
  return useCallback(() => dispatch(setOpenModal(null)), [dispatch])
}



// get the list of active popups
export function useActivePopups(): AppState['application']['popupList'] {
    const list = useAppSelector((state: AppState) => state.application.popupList)
    return useMemo(() => list.filter((item) => item.show), [list])
  }
  


  
  export function useShowSidebar(): boolean {
    const showSidebar = useAppSelector((state: AppState) => state.application.showSidebar)
    return showSidebar
  }
  export function useOpenMenu(): boolean {
    const openMenu = useAppSelector((state: AppState) => state.application.openMenu)
    return openMenu
  }
  export function useClientWidth(): string {
    const clientWidth = useAppSelector((state: AppState) => state.application.clientWidth)||''
    return clientWidth
  }
  