import { getAddress } from '@ethersproject/address'
import { AddressZero } from '@ethersproject/constants'
import { Contract, ContractFactory } from '@ethersproject/contracts'
import { Web3Provider } from '@ethersproject/providers'
import { Price } from 'types/token'



export * from './escapeRegExp'
export function isAddress(value: any): string | false {
  try {
    return getAddress(value)
  } catch {
    return false
  }
}


// shorten the checksummed version of the input address to have 0x + 4 characters at start and end
export function shortenAddress(address: string, chars = 4): string {
  const parsed = isAddress(address)
  if (!parsed) {
    throw Error(`Invalid 'address' parameter '${address}'.`)
  }
  return `${parsed.substring(0, chars + 2)}...${parsed.substring(42 - chars)}`
}
export function getContract(address: string, ABI: any, provider: Web3Provider, account?: string): Contract {
  if (!isAddress(address) || address === AddressZero) {
    throw Error(`Invalid 'address' parameter '${address}'.`)
  }

  return new Contract(address, ABI, provider.getSigner())
  // provider as any)
}

export function openWindow(address: string) {
  window.open(address)
}

export function copyStr(str: string) {

  navigator.clipboard.writeText(str);
  // message.open({ type: "success", content: "success" });
}

export function getContractFactory(ABI: any, byteCode: any, signer?: any): ContractFactory {
  console.log(ContractFactory, 'ContractFactory')
  console.log(signer, 'signer')
  const factory = new ContractFactory(ABI, byteCode, signer)
  console.log(factory, 'factory')
  return factory
}
/* 时间戳转换为时间 */
export function timestampToTime(timestamp: number) {
  // let time = timestamp 
  // console.log('time',time)
  let str = String(timestamp)
  let date = new Date(timestamp);//时间戳为10位需*1000，时间戳为13位的话不需乘1000

  if (str.length == 10) {
    date = new Date(timestamp * 1000)
  }
  let Y = date.getFullYear() + '-';
  let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
  let D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' ';
  let h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
  let m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
  let s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
  return Y + M + D + h + m + s;
}
export function scientificNotationToString(param: number) {

  // let strParam = String(param)
  // let flag = /e/.test(strParam)
  // if (!flag) return param

  // // 指数符号 true: 正，false: 负
  // let sysbol = true
  // if (/e-/.test(strParam)) {
  //   sysbol = false
  // }
  // // 指数
  // let index = Number(strParam.match(/\d+$/)?.[0])
  // // 基数
  // let basis = strParam.match(/^[\d\.]+/)?.[0].replace(/\./, '')

  // if (sysbol) {
  //   return basis?.padEnd(index + 1, 0)
  // } else {
  //   return basis?.padStart(index + basis.length, 0).replace(/^0/, '0.')
  // }
}
export function formatWithArray(number: number | string, fixed?: number) {
  if (typeof (number) === "string") {
    number = Number(number)
  } else {
    let str = number + ''
    if ((str.includes('e') && number < 1) || (number < 0.0001 && number >= 0)) {
      let numstr = number.toFixed(18).replace(/\.0+$/, "").replace(/(\.\d+[1-9])0+$/, "$1")
      let split1 = numstr.split('0.0')[1]
      if (split1) {
        let arr = String(split1).split('')
        let num = 0
        arr.forEach((el, index) => {
          if (index === 0 && el === '0') {
            num = 1
          } else if (arr[index - 1] === '0' && el === '0') {
            num = num + 1
          }
        })
        if (num !== 0) {

          return '0.0' + '{' + num + '}' + split1.substr(num, 4);
        } else {
          return numstr;
        }
      }

      //拿到保留指定的小数

      // return '<0.001'

    } else {
      if ((number + '').includes('e')) {
        number = number.toFixed(18).replace(/\.0+$/, "").replace(/(\.\d+[1-9])0+$/, "$1")
      }
      if (fixed) {
        number = Number(number).toFixed(fixed)
      }
      let arr = (number + '').split('.');

      let int = arr[0].split('');
      let fraction = arr[1] || '';
      if (fraction.length > 4) {
        fraction = fraction.slice(0, 4)
      }
      let r = '';
      int.reverse().forEach(function (v, i) {
        if (i !== 0 && i % 3 === 0) {
          r = v + ',' + r;
        } else {
          r = v + r;
        }
      });
      let str = r + (!!fraction ? '.' + fraction : '')
      if (str === "0.000") { str = '<0.0001' }
      return str;
    }


  }


}
export function formatWithUnit(number: number | string, fixed?: number) {
  if (typeof (number) === "string") {
    number = Number(number)
  } else {
    let str = number + ''
    if ((str.includes('e') && number < 1) || (number < 0.0001 && number >= 0)) {
      let numstr = number.toFixed(18).replace(/\.0+$/, "").replace(/(\.\d+[1-9])0+$/, "$1")
      let split1 = numstr.split('0.0')[1]
      let arr = String(split1).split('')
      let num = 0
      arr.forEach((el, index) => {
        if (index === 0 && el === '0') {
          num = 1
        } else if (arr[index - 1] === '0' && el === '0') {
          num = num + 1
        }
      })
      if (num !== 0) {

        return '0.0' + '{' + num + '}' + split1.substr(num, 4);
      } else {
        return numstr;
      }


    } else {
      if ((number + '').includes('e')) {
        number = number.toFixed(18).replace(/\.0+$/, "").replace(/(\.\d+[1-9])0+$/, "$1")
      }
      let num = Number(number)
      if (num > 100000000) {
        str = (num / 1000000000).toFixed(2) + 'b'
      } else if (num > 1000000) {
        str = (num / 1000000).toFixed(2) + 'm'
      } else if (num > 1000) {
        str = (num / 1000).toFixed(2) + 'k'
      } else {
        str = num.toFixed(fixed || 4)
      }
      return str;
    }


  }


}
export function timestampToLocaleString(timestamp: number) {
  let date = new Date(timestamp); // convert it to a Date object
  let dateString = date.toLocaleString();
  return dateString
}
export function matchFileSuffixType(fileName: string) {
  // 后缀获取
  let suffix: string = ''
  // 获取类型结果
  let result: string | boolean = ''
  try {
    var flieArr = fileName.split('.')
    suffix = flieArr[flieArr.length - 1]
  } catch (err) {
    suffix = ''
  }
  // fileName无后缀返回 false
  if (!suffix) {
    result = false
    return result
  }
  // 图片格式
  var imglist = ['png', 'jpg', 'jpeg', 'bmp', 'gif', 'webp']
  // 进行图片匹配
  result = imglist.some(function (item) {
    return item == suffix
  })
  if (result) {
    result = 'image'
    return result
  }
  // 匹配txt
  var txtlist = ['txt']
  result = txtlist.some(function (item) {
    return item == suffix
  })
  if (result) {
    result = 'txt'
    return result
  }
  // 匹配 excel
  var excelist = ['xls', 'xlsx']
  result = excelist.some(function (item) {
    return item == suffix
  })
  if (result) {
    result = 'excel'
    return result
  }
  // 匹配 word
  var wordlist = ['doc', 'docx']
  result = wordlist.some(function (item) {
    return item == suffix
  })
  if (result) {
    result = 'word'
    return result
  }
  // 匹配 pdf
  var pdflist = ['pdf']
  result = pdflist.some(function (item) {
    return item == suffix
  })
  if (result) {
    result = 'pdf'
    return result
  }
  // 匹配 ppt
  var pptlist = ['ppt']
  result = pptlist.some(function (item) {
    return item == suffix
  })
  if (result) {
    result = 'ppt'
    return result
  }
  // 匹配 视频
  var videolist = ['mp4', 'm2v', 'mkv']
  result = videolist.some(function (item) {
    return item == suffix
  })
  if (result) {
    result = 'video'
    return result
  }
  // 匹配 音频
  var radiolist = ['mp3', 'wav', 'wmv']
  result = radiolist.some(function (item) {
    return item == suffix
  })
  if (result) {
    result = 'radio'
    return result
  }
  // 其他 文件类型
  result = 'other'
  return result
}

export function ISO8601DateStr2Date(string: string) {

  var regexp = "([0-9]{4})(-([0-9]{2})(-([0-9]{2})" +
    "(T([0-9]{2}):([0-9]{2})(:([0-9]{2})(\.([0-9]+))?)?" +
    "(Z|(([-+])([0-9]{2}):([0-9]{2})))?)?)?)?";
  console.log('string', string, String(string).length)
  if (string) {
    let newstring = String(string)
    // if(newstring.length===10){
    //   newstring=newstring+'000'
    // }
    var d: any = String(newstring).match(new RegExp(regexp));
    var offset = 0;
    var date = new Date(d[1], 0, 1);
    if (d[3]) {
      date.setMonth(d[3] - 1);
    }
    if (d[5]) {
      date.setDate(d[5]);
    }
    if (d[7]) {
      date.setHours(d[7]);
    }
    if (d[8]) {
      date.setMinutes(d[8]);
    }
    if (d[10]) {
      date.setSeconds(d[10]);
    }
    if (d[12]) {
      date.setMilliseconds(Number("0." + d[12]) * 1000);
    }
    if (d[14]) {
      offset = (Number(d[16]) * 60) + Number(d[17]);
      offset *= ((d[15] === '-') ? 1 : -1);
    }
    offset -= date.getTimezoneOffset();
    var time = (Number(date) + (offset * 60 * 1000));
    // console.log('time',time)
    return time;
  } else {
    return null;
  }
}


export function priceText(price: Price): string {
  const value: string = price.value ? price.value : "0"
  const decimals = price.currency.decimals
  const priceV = Number(BigInt(value)) / Number(BigInt(1 * 10 ** decimals))
  const result = priceV.toString(10) + " " + price.currency.symbol
  return result
}


