

import { useState } from "react";
import "../index.scss";
import {
  PageAll, TabWeb, TabItemWeb
} from "./components_";
import DropBanner from "./components/DropBanner";
import ActiveUpcoming from "./components/ActiveUpcoming";
export default function Home() {
  const [tab, setTab] = useState('active');

  return (
    <>

      <DropBanner />
      <PageAll>
        <TabWeb>
          <div>
            <TabItemWeb isAct={tab == 'active'} onClick={() => setTab('active')}>Active & Upcoming</TabItemWeb>
            <TabItemWeb isAct={tab == 'past'} onClick={() => setTab('past')}>Past</TabItemWeb>
          </div>
        </TabWeb>
        <ActiveUpcoming />


      </PageAll>
    </>
  );
}

