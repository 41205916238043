import styled from "styled-components/macro";

export const PageBg = styled.div`
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.bg0};
`;

export const RightTitle = styled.div`
  font-size: 38px;
  font-family: Inter-Semi Bold, Inter;
  font-weight: normal;
  color: ${({ theme }) => theme.text1};
  line-height: 45px;
`;
export const ItemTitle = styled.div`
  font-size: 20px;
  font-family: Inter-Semi Bold, Inter;
  font-weight: normal;
  color: ${({ theme }) => theme.text1};
  margin: 30px 0 14px;
  line-height: 23px;
`;

export const Address = styled.div`
  display: flex;
  font-size: 16px;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  color: ${({ theme }) => theme.text3};
  align-items: center;
  cursor: pointer;
`;
export const AddressIcon = styled.img`
  width: 20px;
  height: 20px;
  margin-left: 7px;
`;