import { TransferHelper } from "abis/types";
import { useContract } from "./useContract";
import TransferHelper_ABI from "../abis/TransferHelper.json";
import { TRANSFER_HELPER_ADDRESS } from "constants/addresses";
  export function useTransferHelperContract(withSignerIfPossible?: boolean) {
    const address = TRANSFER_HELPER_ADDRESS;
    return useContract<TransferHelper>(
      address,
      TransferHelper_ABI,
      withSignerIfPossible
    ) as TransferHelper;
  }