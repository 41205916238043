
import styled from 'styled-components/macro'
import { flexColumnNoWrap, flexRowNoWrap } from 'theme/styles'



import { useAccountDrawer } from '..'
import { useNavigate } from 'react-router-dom'
import { useIsDarkMode } from 'theme/components/ThemeToggle'

const OptionCardLeft = styled.div`
  ${flexColumnNoWrap};
  flex-direction: row;
  align-items: center;
`

const OptionCardClickable = styled.button`
  align-items: center;
  background-color: unset;
  border: none;
  cursor: pointer;
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
  justify-content: space-between;
  opacity: '1';
  padding: 18px;

`

const HeaderText = styled.div`
  ${flexRowNoWrap};
  align-items: center;
  justify-content: center;
  color: ${(props) => (props.color === 'blue' ? ({ theme }) => theme.primaryText1 : ({ theme }) => theme.primaryText1)};
  font-size: 16px;
  font-weight: 600;
  padding: 0 8px;
`
const IconWrapper = styled.div`
  ${flexColumnNoWrap};
  align-items: center;
  justify-content: center;
  & > img,
  span {
    height: 24px;
    width: 24px;
  }

`

const TOGGLE_SIZE = 24
const WCv2PopoverToggle = styled.button`
  align-items: center;
  background-color: transparent;
  border: none;

  cursor: pointer;
  display: flex;
  height: ${TOGGLE_SIZE}px;
  justify-content: center;
  margin: 0;
  max-width: 48px;
  padding: 0;
  position: absolute;
  right: 16px;
  top: calc(50% - ${TOGGLE_SIZE / 2}px);
  width: ${TOGGLE_SIZE}px;

  &:hover {
    opacity: 0.6;
  }
`
const Wrapper = styled.div<{ disabled: boolean }>`
  align-items: stretch;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  width: 100%;



  &:hover {
    cursor: ${({ disabled }) => !disabled && 'pointer'};

  }
  &:focus {

  }
`

export type Menu = {
  text: string;
  img: string;
  imgn: string;
  route: string;
} 





interface OptionProps {
  menu: Menu
}
export default function OptionWithoutToken({ menu }: OptionProps) {
  const [accountDrawerOpen, toggleAccountDrawerOpen] = useAccountDrawer()
  const navigate = useNavigate()
  const isDarkMode = useIsDarkMode()
  const isSomeOptionPending=false
  const toPage=(route:string)=>{
    toggleAccountDrawerOpen()
    navigate(menu.route)
  }

  return (
    <Wrapper disabled={isSomeOptionPending}>

<OptionCardClickable onClick={()=>toPage(menu.route)}>
          <OptionCardLeft>
            <IconWrapper style={menu.img?{}:{opacity:0}}>
              <img src={isDarkMode?menu.imgn:menu.img} alt={menu.text} />
            </IconWrapper>
            <HeaderText>{menu.text}</HeaderText>
          </OptionCardLeft>
          </OptionCardClickable>
    </Wrapper>
  )
}

