import React, { useEffect, useState } from 'react';
import { useCurrentAccountMutation } from '../../../apis/account';
import { useWeb3React } from "@web3-react/core";
import { useAccount, useUpdateAccount } from "state/user/hooks";
import ProfileDetailsFrom, { ProfileDetails } from "./components/ProfileDetailsFrom";
import "../../index.scss";
import {
  PageBg,
} from "./components_";
import {
  WebLeft, WebMain, WebRight, MenuItem,
} from "../../Nft/EditCollection/components_";
import {useToastOpen } from 'state/stats/hooks'

export default function Home() {  
  const { account } = useWeb3React();
  const [,,, setToastOpen] = useToastOpen();
  const [currentAccount] = useCurrentAccountMutation()
  const [profileDetails, setProfileDetails] = useState<ProfileDetails>();
  const getAccountInfo = async () => {
      try {
        const { data, success ,message} = await currentAccount({account:account as string}).unwrap();
        if (success) {
          setProfileDetails({
            address: data.address,
            imageUrl: data.user.avatar,
            email: data.user.email,
            links: data.user.links,
            bio: data.user.bio,
            userName: data.user.nickname,
          })
        }else{
          setToastOpen({open:true,type:'error',label:message})
        }
      } catch (err) {
        console.log('err',err)
        setToastOpen({open:true,type:'error',label:(err as Error).message})

      }
   

  }
  useEffect(() => {
    if(account){
      getAccountInfo()
      
    }
   
  }, [account]);

  return (
    <>

      <PageBg>
        <WebMain>
          <WebLeft style={{paddingTop:'20px'}}>
            <MenuItem isAct={false} style={{ opacity: '0.5',cursor:'auto',background:'transparent' }} >Settings</MenuItem>
            <MenuItem isAct={true}>Proflie</MenuItem>
          </WebLeft>
          <WebRight>
            <ProfileDetailsFrom visible={true}
              value={profileDetails}
              onChange={(value) => setProfileDetails({ ...profileDetails, ...value })} />
          </WebRight>
        </WebMain>
      </PageBg>

    </>
  );
}
