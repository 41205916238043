
import { emptySplitApi } from './emptySplitApi'


export interface UserResponse {
  data: User;
  msg: null | string;
  success: boolean;
}



export interface CurrentAccountResponse {
  data: {
    address:string;
    user:{
      avatar:string|undefined;
      bio:string|undefined;
      email:string|undefined;
      links:string|undefined;
      nickname:string|undefined;
    }
   
  };
  message: null | string;
  success: boolean;
}

export interface LoginRequest {
  msg: string;
  ref:string|null;
  signature: string;
}
export interface LoginResponse {
  data:{account:Account,token:string,expires_at:number};
  msg: null | string;
  success: boolean;
}
export interface CollectionListResponse {

  data: Collection[]|null
  total: number;
  msg: null | string;
  success: boolean;
}
export interface UserRequest {
  data:string;
  signature:string;
  // nickname: string|undefined;
  // avatar: string|undefined;
  // email: string|undefined;
  // bio:string|undefined;
  // links:string|undefined;
  account:string|undefined;
}
export interface  DeployRequest {
  block_number: number;
  chain_id: number;
  chain: string;
  chain_type: string;
  event: string;
  transaction_hash: string;
  image_url?:string;
  name:string
  symbol:string
  contract_address:string;
  token_standard:string
}

export interface DeployResponse {
  data?:  {
    block_number: number,
    chain_id: number,
    chain_type: string,
    event: any,
    transaction_hash: string,
  }|null;
  message: null | string;
  success: boolean;
  status:string
}
export interface AccountResponse {
  data:Account;
  msg: null | string;
  success: boolean;
}
const accountApi = emptySplitApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    login: builder.mutation<LoginResponse, LoginRequest>({
      query: (credentials) => ({
        url: "/account/login",
        method: "POST",
        body: credentials,
      }),
    }),
    currentAccount: builder.mutation<CurrentAccountResponse, {account:string}>({
      query: (profile) => ({
        // url: "/account/current",
        url: `/account/${profile.account}/user`,
        method: "GET"
      }),
    }),
    updateUser: builder.mutation<UserResponse, UserRequest>({
      query: (profile) => ({
        url: `/account/${profile.account}/user`,
        method: "POST",
        body: { ...profile },
      }),
    }),
    accountCollections: builder.mutation<CollectionListResponse, {account:string,type:string,next?:number}>({
      query: (profile) => ({
        url: `/account/${profile.account}/collection?type=${profile.type}`,
        method: "GET",
      }),
    }),
    accountCollection: builder.query<CollectionListResponse, {account:string,type:string,next?:number}>({
      query: (params) => ({
        url: `/account/${params.account}/collection?type=${params.type}`,
        method: "GET",
      }),
    }),
    contractDeployed: builder.mutation<DeployResponse, DeployRequest>({
      query: (profile) => ({
        url: `/event/contract-deployed`,
        method: "POST",
        body: { ...profile },
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useCurrentAccountMutation,
  useUpdateUserMutation,
  useContractDeployedMutation,
  useAccountCollectionQuery,
  useAccountCollectionsMutation
} = accountApi;
