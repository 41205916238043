import styled from "styled-components/macro";
import Modal from "styled-react-modal";
import {MAX_WIDTH_MEDIA_BREAKPOINT,MIN_WIDTH_MEDIA_BREAKPOINT,LARGE_MEDIA_BREAKPOINT} from "constants/width"

export const StyledModal = Modal.styled`
background: ${(props: { theme: any }) => props.theme.bg1};
border-radius: 8px;
padding: 0px 40px 40px;
box-sizing: border-box;
font-size: 16px;
font-family: Inter-Medium, Inter;
font-weight: 500;
width: 800px;
min-height:auto;
height:auto;
overflow: inherit;
opacity: 1;
margin: 100px auto;
@media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
  width: 90vw;
  margin: 10vh auto;
  max-height: 80vh;
  overflow: scroll;
}
`;

export const InfoDescLoadTel = styled.div`
  white-space: pre;
  font-size: 14px;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  color: ${({ theme }) => theme.bg2};
  background: ${({ theme }) => theme.bg2};
  line-height: 16px;
`;

export const InfoBottomBuy = styled.div`
  background: ${({ theme }) => theme.purple1};
  color: ${({ theme }) => theme.bg0};
  width: 100%;
  font-size: 12px;
  text-align: center;
  height: 30px;
  line-height: 30px;
  border-radius: 2px 2px 2px 2px;
  opacity: 1;
  margin: 0 5px 5px;
  position: relative;
  left: -5px;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  > div:first-child {
    width: 84%;
    border-right: 1px solid ${({ theme }) => theme.bg0};
    box-sizing: bordedr-box;
  }
  > div:last-child {
    width: 16%;
  }
`;
export const ItemTop = styled.div`
  width: 100%;
  opacity: 0;
  position: absolute;
  left: -0px;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  z-index: 99;
  >div {
    background: ${({ theme }) => theme.modalBG2};
    color: ${({ theme }) => theme.bg0};
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-size: 23px;
    line-height: 35px;
    img {
      width: 30px;
      height: 30px;
    }
  }
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    > div {
      width: 20px;
      height: 20px;
      font-size: 15px;
      line-height: 18px;
       img {
        width: 15px;
        height: 15px;
      }
    }
  }
`;
export const CollectRoles = styled.div`
  opacity: 0;
  background: ${({ theme }) => theme.bg0};
  padding: 8px 0;
  border-radius: 8px;
  box-sizing: border-box;
  // height:60px;
  position: absolute;
  right: 5px;
  bottom: 35px;
  z-index: 99;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 255, 0.2);
  > div {
    font-size: 14px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 30px;
    color: ${({ theme }) => theme.text2};
    padding: 8px;
    :hover {
      color: ${({ theme }) => theme.text1};
      background: ${({ theme }) => theme.bg1};
    }
  }
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    height: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 20px;
    bottom: 15px;
    > div {
      width: 100%;
      height: 22px;
      padding-right: 10px;
      box-sizing: border-box;
      font-size: 12px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    > div:last-child {
      width: 50%;
      padding-right: 0;
      padding-left: 10px;
      box-sizing: border-box;
    }
  }
`;
export const MainItemNone = styled.div`
  width: calc(25% - 10px);
  height: 0;
  opacity: 0;
`;
export const AdimnImg = styled.img`
  width: 15px;
  height: 15px;
  margin-left: 8px;
`;
export const InfoGroupL = styled.div`
  display: flex;
  align-items: center;
`;
export const MainInfoTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  > div:last-child {
    width: calc(100% - 12.5vw);
  }
`;

export const MainItemLoad = styled.div`
  width: calc(25% - 10px);
  padding-top: 26%;
  background: ${({ theme }) => theme.bg2};
  border-radius: 6px;
  margin-bottom: 16px;
`;
export const InfoDescLoad = styled.div`
  width: 70%;
  white-space: pre;
  font-size: 14px;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  color: ${({ theme }) => theme.bg2};
  background: ${({ theme }) => theme.bg2};
  line-height: 16px;
  margin: 10px 0 5px;
`;

export const WebMainTop = styled.div`
  max-width: 1440px;
  min-width: 800px;
  min-height: 0;
  padding-top: 40px;
  margin: 0 auto;
  position: relative;
  z-index: 19;
  overflow: visible;

  @media screen and (min-width: ${LARGE_MEDIA_BREAKPOINT}){
    width: 95vw !important;
    max-width: 1440px !important;
    min-width: 760px !important;
  }
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    z-index: 11;
    width: 95vw !important;
    max-width: 800px !important;
    min-width: 0px !important;
  }
`;
export const WebMainB = styled.div`
  max-width: 1440px;
  min-width: 800px;
  min-height: calc(100vh - 100px);
  overflow: hidden;
  margin: 0 auto;
  z-index: 11;
  padding-bottom: 90px;
  @media screen and (min-width: ${LARGE_MEDIA_BREAKPOINT}){
    width: 95vw !important;
    max-width: 1440px !important;
    min-width: 760px !important;
  }
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    width: 95vw !important;
    max-width: 800px !important;
    min-width: 0px !important;
  }
`;

export const WebFooter = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  z-index: 11;
  height: 70px;
  background: ${({ theme }) => theme.bg0};
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    height: 90px;
  }
`;
export const FooterCenter = styled.div`
max-width: 1600px;
min-width: 800px;

height: 70px;
overflow: hidden;
margin: 0 auto;
display: flex;
align-items: center;
justify-content: space-between;
> div {
  display: flex;
  align-items: center;
}
> div:first-child {
  > div {
    margin-right: 10px;
    cursor: pointer;
    color: ${({ theme }) => theme.purple1};
  }
  > div:first-child {
    color: ${({ theme }) => theme.text1};
  }
  > div:last-child {
    border-left: 1px solid ${({ theme }) => theme.border1};
    padding-left: 10px;
  }
}
@media screen and (min-width: ${LARGE_MEDIA_BREAKPOINT}){
  width: 95vw !important;
  max-width: 1560px !important;
  min-width: 760px !important;
}
@media screen and (min-width: ${MAX_WIDTH_MEDIA_BREAKPOINT}) {
  max-width: 1600px !important;
  min-width: 1560px !important;
  width: 1560px !important;
}
@media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
  width: 95vw !important;
  max-width: 800px !important;
  min-width: 0px !important;
  flex-wrap: wrap;
  height: 90px;
  > div {
    width: 100%;
    height: 40px;
  }
  > div:last-child {
    justify-content: flex-end;
  }
}
`;
export const MainInfo = styled.div``;

export const InfoImg = styled.img`
  height: 11.5vw;
  width: 11.5vw;
  max-width: 220px;
  max-height: 220px;
  min-width: 100px;
  min-height: 100px;

  border-radius: 50%;
  border: 2px solid ${({ theme }) => theme.bg0};
`;
export const AttesImg = styled.img`
  width: 18px;
  height: 18px;
  margin-left: 4px;
  margin-right: 20px;
`;
export const AttesImg1 = styled.img`
  width: 14px;
  height: 14px;
  margin-right: 4px;
`;
export const InfoNameRow = styled.div`
  margin-top: 17px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    flex-wrap: wrap;
  }
`;
export const InfoNameCol = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
`;
export const AddressText = styled.div`
  margin: 0 3px;
  font-size: 14px;
  font-family: Inter-Bold, Inter;
  font-weight: normal;
  color: ${({ theme }) => theme.text3};
  line-height: 20px;
`;
export const NameText = styled.div`
  font-size: 24px;
  font-family: Inter-Bold, Inter;
  font-weight: bold;
  color: ${({ theme }) => theme.text1};
  line-height: 28px;
`;
export const NameTextTel = styled.div`
  font-size: 18px;
  font-family: Inter-Bold, Inter;
  font-weight: bold;
  color: ${({ theme }) => theme.text1};
  line-height: 21px;
`;
export const InfoNameImg = styled.img`
  margin-left: 24px;
  width: 24px;
  height: 24px;
`;
export const InfoNameImgTel = styled.img`
  margin-right: 13px;
  width: 18px;
  height: 18px;
`;

export const EthImg = styled.img`
  margin-right: 2px;
  width: 12px;
  height: 12px;
`;
export const ArrowImg = styled.img`
  margin-left: 2px;
  width: 12px;
  height: 12px;
`;

export const TabWeb = styled.div`
  width: 100%;
  margin: 20px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${({ theme }) => theme.border1};
  padding-bottom: 10px;
  > div {
    display: flex;
    align-items: center;
  }
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    > div {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      height: 70px;
    }
  }
`;

export const TabItemWeb = styled.div<{ isAct: boolean }>`
  height: 50px;
  text-align: center;
  font-size: 20px;
  line-height: 50px;
  padding: 0 15px;
  background: ${({ theme, isAct }) => (isAct ? theme.bg2 : theme.bg1)};
  border-radius: 6px;
  color: ${({ theme, isAct }) => (isAct ? theme.text1 : theme.text2)};
  margin-right: 15px;
  cursor: pointer;
  white-space: nowrap;
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    font-size: 14px;
    padding: 0 8px;
    height: 40px;
    margin-right: 10px;
    line-height: 40px;
  }
`;

export const MainBottomTitle = styled.div`
  margin: 25px 0 10px;
  font-size: 20px;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  color: ${({ theme }) => theme.text1};
`;
export const FilterSearch = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    flex-wrap: wrap;
    justify-content: space-between;
  }
`;
export const FilterSearchTel = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
`;
export const SearchL = styled.img`
  width: 42px;
  height: 42px;
  cursor: pointer;
`;
export const SearchM = styled.div`
  flex: 1;
  margin: 0 18px;
  border: 1px solid ${({ theme }) => theme.border1};
  display: flex;
  align-items: center;
  height: 42px;
  border-radius: 6px;
  > input:-internal-autofill-selected {
    -webkit-text-fill-color: ${({ theme }) => theme.text1} !important;
    transition: background-color 5000s ease-in-out 0s !important;
    box-shadow: 0 0 0 1000px ${({ theme }) => theme.bg1} inset;
  }
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    max-width: calc(100% - 166px);
    margin: 10px 18px 0 0 ;
  }
`;
export const CollectionsSearch = styled.div`
  width: 100%;
  border: 1px solid ${({ theme }) => theme.border1};
  display: flex;
  align-items: center;
  height: 42px;
  border-radius: 6px;
  > input:-internal-autofill-selected {
    -webkit-text-fill-color: ${({ theme }) => theme.text1} !important;
    transition: background-color 5000s ease-in-out 0s !important;
    box-shadow: 0 0 0 1000px ${({ theme }) => theme.bg1} inset;
  }
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    width: 100%;
    margin: 20px 0;
  }
`;
export const SearchSelect = styled.div`
  max-width: 200px;
  min-width: 150px;
  margin: 0 0 0 18px;
  border: 1px solid ${({ theme }) => theme.border1};
  height: 42px;
  border-radius: 6px;
  width: 20%;
  background: transparent;
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    width: calc(50% - 39px);
    max-width: calc(50% - 39px);
    margin-left: 0;
    // margin-right: 0px;
  }
`;
export const inputStyle1 = {
  outline: "none",
  width: "100%",
  height: "40px",
  border: "none",
  textAlign: "left",
  background: "transparent",
  padding: "0",
};
export const InputText = styled.div`
height: 40px;
line-height: 40px;
`

export const inputStyle1Num = {
  outline: "none",
  width: "calc(100% + 23px)",
  height: "40px",
  border: "none",
  textAlign: "left",
  background: "transparent",
  padding: "0",
};
export const inputStyleNum = {
  outline: "none",
  width: "calc(100% + 23px)",
  height: "40px",
  border: "none",
  textAlign: "left",
  background: "transparent",
  padding: "0",
};
export const ItemRadioOne = styled.div<{ isAct: boolean }>`
  border-radius: 6px;
  border: 1px solid
    ${({ theme, isAct }) => (isAct ? theme.text1 : theme.border1)};
  width: calc(50% - 10px);
  padding: 0 16px;
  cursor: pointer;
`;

export const ListSelectBlock = styled.div`
  width: 100px;
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    display: none;
  }
`;
export const TipIcon = styled.div`
  display: inline-block;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  line-height: 14px;
  font-size: 12px;
  text-align: center;
  margin: 3px 3px 0;
  cursor: pointer;
  color: ${({ theme }) => theme.bg0};
  background: ${({ theme }) => theme.text3};
`;
export const ItemValueBottom = styled.div`
  width: 100%;
  height: 38px;
  border-bottom: 1px solid ${({ theme }) => theme.border1};
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${({ theme }) => theme.text3};
  font-size: 14px;
  > div:first-child {
    display: flex;
    align-items: center;
  }
`;
export const ItemFlex = styled(ItemValueBottom)`
  width: 100%;
  height: 100px;
  border-bottom: none;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${({ theme }) => theme.text3};
  font-size: 14px;
  >img{
    width:70px;
    height:70px;
    border-radius:5px;
    object-fit:cover;
  }
  > div:last-child {
    width: calc(100% - 90px);
    align-items: flex-start;
    >div{
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis; //溢出用省略号显示
      white-space: nowrap; //溢出不换行
    }
    >div:first-child {
      color: ${({ theme }) => theme.text1};
    }
    @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
      width: calc(100% - 80px);
    }
  }

`;

export const DurationSelect = styled.div`
  max-width: 200px;
  min-width: 150px;
  margin: 0 20px 0 0;
  border: 1px solid ${({ theme }) => theme.border1};
  height: 40px;
  border-radius: 6px;
  width: 20%;
  background: transparent;
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    width: calc(50% - 39px);
    max-width: calc(50% - 39px);
    margin-left: 18px;
    margin-right: 0px;
  }
`;
export const SearchMTel = styled.div`
  flex: 1;
  margin: 0 0;
  border: 1px solid ${({ theme }) => theme.border1};
  display: flex;
  align-items: center;
  height: 42px;
  border-radius: 6px;
  > input:-internal-autofill-selected {
    -webkit-text-fill-color: ${({ theme }) => theme.text1} !important;
    transition: background-color 5000s ease-in-out 0s !important;
    box-shadow: 0 0 0 1000px ${({ theme }) => theme.bg1} inset;
  }
`;
export const ChooseItem = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
  font-size: 14px;
  cursor: pointer;
`;

export const SearchIcon = styled.img`
  width: 22px;
  height: 22px;
  margin: 0 10px;
`;
export const SearchIcon1 = styled.img`
  width: 22px;
  height: 22px;
  margin: 0 5px;
`;
export const SearchClose = styled.img`
  width: 15px;
  height: 15px;
  margin: 0 8px;
  cursor: pointer;
`;

export const SearchR = styled.div`
  width: 176px;
  height: 42px;
  border-radius: 6px;
  opacity: 1;
  cursor: pointer;
  position: relative;
  border: 1px solid ${({ theme }) => theme.border1};

  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    width: 148px;
    margin-left: 0;
    margin-top: 10px;
  }
`;
export const SearchRTel = styled.div`
  width: calc(45.8vw - 10px);
  height: 42px;
  border-radius: 6px;
  opacity: 1;
  // border: 1px solid ${({ theme }) => theme.border1};
  // display: flex;
  // justify-content: center;
  // align-items: center;
  cursor: pointer;
  position: relative;
`;
export const SearchRTel1 = styled.div`
  width: calc(45.8vw - 10px);
  height: 42px;
  border-radius: 6px;
  padding: 0 11px;
  box-sizing: border-box;
  opacity: 1;
  border: 1px solid ${({ theme }) => theme.border1};
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  position: relative;
  > div {
    width: calc(45.8vw - 60px);
    overflow: hidden;
    text-overflow: ellipsis; //溢出用省略号显示
    white-space: nowrap; //溢出不换行
  }
`;
export const SearchInput = styled.input`
  border: none;
  flex: 1;
  outline: none;
  background: transparent;
  font-size: 14px;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  color: ${({ theme }) => theme.text1};
  margin-right: 10px;
`;
export const SearchInput1 = styled.input`
  border: none;
  flex: 1;
  max-width:calc(100% - 64px)
  outline: none;
  background: transparent;
  font-size: 14px;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  color: ${({ theme }) => theme.text1};
`;


export const TypeItem = styled.div<{ isAct: boolean }>`
  padding: 12px 20px;
  border: 1px solid
    ${({ theme, isAct }) => (isAct ? theme.border1 : "transparent")};
  border-radius: 10px;
  opacity: ${({ isAct }) => (isAct ? "1" : "0.7")};
  background: ${({ theme, isAct }) => (isAct ? theme.bg2 : "")};
  display: inline-block;
  cursor: pointer;
`;

export const MainBBl = styled.div<{ isFilter: boolean }>`
  width: 222px;
  margin-right: ${({ isFilter }) => (isFilter ? "18px" : "0")};
  width: ${({ isFilter }) => (isFilter ? " " : "0")};
  height: ${({ isFilter }) => (isFilter ? " " : "0")};
  opacity: ${({ isFilter }) => (isFilter ? "1" : "0")};
  display: ${({ isFilter }) => (isFilter ? "" : "none")};
  overflow: hidden;
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    background: ${({ theme }) => theme.bg0};
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    width: 100vw !important;
    height: 70vh !important;
    padding: 10px 3vw 20px;
    box-sizing: border-box;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 9999;
  }
`;
export const LeftSearchTitle = styled.div`
  margin: 26px 0 12px;
  height: 24px;
  font-size: 20px;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  color: ${({ theme }) => theme.text1};
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`;
export const MainBBR = styled.div<{ isFilter: boolean }>`
  flex: 1;
  padding-top: ${({ isFilter }) => (isFilter ? "62px" : "20px")};
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
`;
export const BtnRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const BtnOne3 = styled.div`
  width: calc(30% - 12px);
  height: 40px;
  line-height: 40px;
  border-radius: 6px;
  text-align: center;
  border: 1px solid ${({ theme }) => theme.border1};
  font-size: 14px;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  color: ${({ theme }) => theme.text2};
  cursor: pointer;
`;
export const BtnOne2to = styled.div`
  width: calc(45% - 12px);
  height: 40px;
  line-height: 40px;
  border-radius: 6px;
  text-align: center;
  border: 1px solid ${({ theme }) => theme.border1};
  font-size: 14px;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  color: ${({ theme }) => theme.text2};
  cursor: pointer;
  overflow: hidden;
`;
export const BtnOne1 = styled.div<{ isAct: boolean }>`
  width: 100%;
  height: 40px;
  line-height: 40px;
  border-radius: 6px;
  text-align: center;
  font-size: 14px;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  background: #8371ec;
  color: ${({ theme }) => theme.bg0};
  cursor: pointer;
  margin-top: 10px;
  opacity: ${({ isAct }) => (isAct ? "1" : "0.3")};
`;

export const PropGroup = styled.div<{ isAct: boolean }>`
  width: 100%;
  height: ${({ isAct }) => (isAct ? "1" : "40px")};
  overflow: hidden;
  padding: 0 14px;
  box-sizing: border-box;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.border1};
  margin-bottom: 10px;
`;
export const PropTop = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  > div {
    max-width: 180px;
    font-size: 16px;
    font-family: Inter-Regular, Inter;
    font-weight: 400;
    color: ${({ theme }) => theme.text1};
  }
`;
export const PropArrowImg = styled.img<{ isAct: boolean }>`
  width: 15px;
  height: 15px;
  transform: ${({ isAct }) => (isAct ? "rotateX(180deg)" : "rotateX(0deg)")};
`;
export const PropMain = styled.div``;
export const PropItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  margin: 6px 0 14px;
`;
export const PropItemName = styled.div`
  max-width: 140px;
  // background:#f00;
  flex: 1;
  font-size: 12px;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  color: ${({ theme }) => theme.text3};
  line-height: 14px;
`;
export const PropItemRight = styled.div`
  display: flex;
  align-items: center;
  > div {
    font-size: 12px;
    font-family: Inter-Regular, Inter;
    font-weight: 400;
    color: ${({ theme }) => theme.text3};
    line-height: 14px;
  }
`;
export const PropItemIcon = styled.img`
  width: 14px;
  height: 14px;
  margin-left: 7px;
`;

export const MainItem = styled.div<{ isselect: boolean }>`
  width: calc(25% - 10px);
  background: ${({ theme }) => theme.bg0};
  border: 2px solid
    ${({ theme, isselect }) => (isselect ? theme.purple1 : theme.border1)};
  border-radius: 6px;
  margin-bottom: 16px;
  overflow: hidden;
  cursor: pointer;

  position: relative;
  :hover {
    ${InfoBottomBuy} {
      opacity: 1;
      position: relative;
      left: -5px;
      top: -30px;
    }

    ${CollectRoles} {
      opacity: 1;
    }
    ${ItemTop} {
      opacity: 1;
    }
  }
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    width: calc(50% - 10px);
    ${InfoBottomBuy} {
      opacity: 1;
      position: relative;
      left: -5px;
      top: -30px;
    }
  }
`;
export const MainItemInfo = styled.div`
  padding: 5px;
  font-size: 13px;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  color: ${({ theme }) => theme.text3};
  line-height: 15px;
  position: absolute;
  left: 0;
  bottom: 0;
  height: 70px;
  background: ${({ theme }) => theme.bg0};
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;
export const InfoTop = styled.div`
  display: flex;
  align-items: center;
  > div {
    max-width: calc(10vw - 15px);
    overflow: hidden;
    text-overflow: ellipsis; //溢出用省略号显示
    white-space: nowrap; //溢出不换行
  }
`;

export const InfoBottom = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  > div {
    max-width: calc(10vw - 15px);
    overflow: hidden;
    text-overflow: ellipsis; //溢出用省略号显示
    white-space: nowrap; //溢出不换行
    font-size: 13px;
    color: ${({ theme }) => theme.text3};
    > span {
      font-size: 13px;
      color: ${({ theme }) => theme.text1};
    }
  }
`;
export const ItemTopImg = styled.img`
  width: 10px;
  height: 10px;
  margin-top: 4px;
`;

export const ItemBottomImg = styled.img`
  width: 10px;
  height: 10px;
`;
export const InfoLine = styled.div`
  font-size: 15px;
  font-family: Arial-Regular, Arial;
  font-weight: 400;
  color: ${({ theme }) => theme.text1};
  margin: 2px 0 4px;
`;

export const MainItemImg = styled.img`
  width: 100%;
  
  height: 100%;
  object-fit: cover;
  position:absolute;
  top: 0;
  left: 0;
  :hover {
    transform: scale(1.2);
    transition: all 0.5s;
  }
`;

export const PageTitle = styled.div`
  font-size: 20px;
  font-family: Inter-Bold, Inter;
  font-weight: bold;
  color: ${({ theme }) => theme.text1};
  line-height: 50px;
`;
export const TelTop = styled.div`
  display: flex;
  align-items: center;
`;

export const ItemNumText = styled.div`
  font-size: 15px;
  font-family: Inter-Medium, Inter;
  font-weight: 500;
  color: ${({ theme }) => theme.text1};
`;
