

import { useState, useEffect } from "react";
import { ButtonPurple } from "components/Button"
import UploadDropzone from "components/Form/upload"
import { useDarkModeManager } from "theme/components/ThemeToggle";
import {
    CardTitle, TopBanner, BannerInfo, UpView, InfoView, DropName, DropBottom, StyledModal,RadioIcon
} from "../../components_";
import {
    ItemUpImg, ItemLabelFlex, CloseImg, CardItem, ItemTip, AddImgText1, ItemLabel, UploadBox
} from "../../../../components_";
import closeIcon from "assets/tool/close_icon.png";
import closeIconN from "assets/tool/close_icon_n.png";

export interface DropTopForms {
    bannerUrl?: string;
    avatarUrl?: string;
    bannerUrlTel?: string;
    name?: string;
    tabs?: string[] | null;
}

interface DropTopFormProps {
    visible?: boolean;
    value?: DropTopForms;
    onChange: (value: DropTopForms) => void;
}

export default function DropTopForm({
    visible,
    value,
    onChange,
}: DropTopFormProps) {
    const [darkMode] = useDarkModeManager();

    const [popuptype, setPopuptype] = useState<string>('imglist');
    const [showPopup, setShowPopup] = useState(false);
    const [tabs, setTabs] = useState<string[] | null>(['roadmap', 'team', 'faqs']);

    const [bannerType, setBannerType] = useState<string>('Pc');
    const [loading, setLoading] = useState(false);
    const [bannerUrl, setBannerUrl] = useState<string>('');
    const [bannerUrlTel, setBannerUrlTel] = useState<string>('');
    const [avatarUrl, setAvatarUrl] = useState<string>('');

    useEffect(() => {
        if (value && value.bannerUrl) {
            setBannerUrl(value.bannerUrl)
        }
        if (value && value.avatarUrl) {
            setAvatarUrl(value.avatarUrl)
        }
        if (value && value.bannerUrlTel) {
            setBannerUrlTel(value.bannerUrlTel)
        }
        if (value && value.tabs && value.tabs !== tabs) {
            setTabs(value.tabs)
        } else if (value && !value.tabs) {
            setTabs(null)
        }
    }, [value]);

    const changeTabs = (str: string) => {
        if (tabs) {
            let index = tabs.indexOf(str)
            let templist = [...tabs]
            if (index != -1) {
                templist.splice(index, 1)
                setTabs(templist)
                console.log('11111111', index, templist)
            } else {
                templist.push(str)
                setTabs(templist)
                console.log('222222222', templist)
            }

        } else {
            setTabs([str])
        }


    };

    return <>
        <TopBanner>

            <img src={value?.bannerUrl} style={{ width: "100%", height: '500px', objectFit: 'cover', background: '#b7b7b7' }} />

            <BannerInfo>
                <UpView onClick={() => {
                    setPopuptype('banner'); setShowPopup(true)
                }}>
                    Upload</UpView>
                <InfoView className="web-page" >
                    <div>
                        <UploadDropzone url={avatarUrl} urlChange={(url) => onChange({ avatarUrl: url })}></UploadDropzone>
                        <DropName> {value?.name || 'none'}</DropName>
                        <DropBottom><div>By You</div>
                            <div onClick={() => { setPopuptype('tab'); setShowPopup(true) }}>Manage tabs</div></DropBottom>


                    </div>

                </InfoView>
            </BannerInfo>


        </TopBanner>
        <StyledModal
            isOpen={showPopup && popuptype == "banner"}
            onBackgroundClick={() => setShowPopup(!showPopup)}
            onEscapeKeydown={() => setShowPopup(!showPopup)}
        >  <ItemLabelFlex>
                <CardTitle>Add banner media</CardTitle>
                <CloseImg
                    src={darkMode ? closeIconN : closeIcon}
                    onClick={() => setShowPopup(!showPopup)}
                />
            </ItemLabelFlex>
            <CardItem>
                <ItemTip>You can add an image or a short, looping video.</ItemTip></CardItem>
            <CardItem>
                <CardItem>
                    <span style={bannerType == 'Tel' ? { marginRight: '20px', opacity: 1, cursor: 'pointer' } : { marginRight: '20px', opacity: '0.5', cursor: 'pointer' }} onClick={() => setBannerType('Tel')}>Mobile</span>
                    <span style={bannerType == 'Pc' ? { opacity: 1, cursor: 'pointer' } : { opacity: '0.5', cursor: 'pointer' }} onClick={() => setBannerType('Pc')}>Pc</span>
                </CardItem>

                {bannerType == 'Pc' ?
                    (<ItemUpImg style={{ height: '231px' }}>
                        <UploadBox>
                            <UploadDropzone url={value?.bannerUrl} width="300px" height="100px" urlChange={(url) => onChange({ bannerUrl: url })}></UploadDropzone>
                        </UploadBox>
                        <AddImgText1>Drag and drop or click to upload</AddImgText1>
                        <AddImgText1>Recommended size: 12:5 aspect ratio. 2500px X 1042px. Supported video format: MP4, 10 seconds long without sound. Supported image formats: JPG, PNG, SVG or GIF</AddImgText1>
                    </ItemUpImg>) : null}
                {bannerType == 'Tel' ?
                    (<ItemUpImg style={{ height: '251px' }}>
                        <UploadBox>
                            <UploadDropzone url={value?.bannerUrlTel} height="120px" urlChange={(url) => onChange({ bannerUrlTel: url })}></UploadDropzone>
                        </UploadBox>
                        <AddImgText1>Drag and drop or click to upload</AddImgText1>
                        <AddImgText1>Recommended size: 4:5 aspect ratio. 1200px X 1500px. Supported video format: MP4, 10 seconds long without sound. Supported image formats: JPG, PNG, SVG or GIF</AddImgText1>
                    </ItemUpImg>) : null}




            </CardItem></StyledModal>
        <StyledModal
            isOpen={showPopup && popuptype == "tab"}
            onBackgroundClick={() => setShowPopup(!showPopup)}
            onEscapeKeydown={() => setShowPopup(!showPopup)}
        >     <ItemLabelFlex>
                <CardTitle>Manage tabs</CardTitle>
                <CloseImg
                    src={darkMode ? closeIconN : closeIcon}
                    onClick={() => setShowPopup(!showPopup)}
                />
            </ItemLabelFlex>
            <CardItem>
                <ItemTip>Select which tabs you want to show on your page.</ItemTip></CardItem>
            <CardItem onClick={() => changeTabs('roadmap')} style={{ cursor: 'pointer' }}>

                <ItemLabel style={{display:'flex',alignItems:'center'}}> <RadioIcon checked={tabs && tabs.includes('roadmap') || false}></RadioIcon>Roadmap</ItemLabel>
                <ItemTip>Tell your audience about your project roadmap.</ItemTip></CardItem>
            <CardItem onClick={() => changeTabs('team')} style={{ cursor: 'pointer' }}>

                <ItemLabel style={{display:'flex',alignItems:'center'}}><RadioIcon checked={tabs && tabs.includes('team') || false}></RadioIcon>Team</ItemLabel>
                <ItemTip>Tell your audience about the members of your team.</ItemTip></CardItem>
            <CardItem onClick={() => changeTabs('faqs')} style={{ cursor: 'pointer' }}>
                <ItemLabel style={{display:'flex',alignItems:'center'}}><RadioIcon checked={tabs && tabs.includes('faqs') || false}></RadioIcon>FAQ</ItemLabel>
                <ItemTip>Answer frequently asked questions.</ItemTip></CardItem>
            <ButtonPurple padding={'10px'} $borderRadius={'4px'} width={'100%'} onClick={() => { onChange({ tabs: tabs }); setShowPopup(false) }}>save</ButtonPurple>

        </StyledModal>

    </>;
}
