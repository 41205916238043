import { Trans } from '@lingui/macro'
import { useWeb3React } from '@web3-react/core'
import { ArrowDownRight, ArrowUpRight, Copy, CreditCard, IconProps, Info, LogOut, Settings } from 'react-feather'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch } from 'state/hooks'
import { logout, updateSelectedWallet } from '../../state/user/reducer'
import styled from 'styled-components/macro'
import { useToggleAccountDrawer } from '.'
import IconButton, { IconHoverText, IconWithConfirmTextButton } from './IconButton'
import { useCallback, useState } from 'react'
import { shortenAddress } from '../../utils/address'
import { CopyHelper } from 'theme'
import { ThemedText } from 'theme'
import AuthenticatedMenu from './AuthenticatedMenu'
import StatusIcon from 'components/Identicon/StatusIcon'
import { getConnection } from 'connection'


const AuthenticatedHeaderWrapper = styled.div`
  padding: 20px 16px;
  display: flex;
  flex-direction: column;
  flex: 1;
`

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  & > a,
  & > button {
    margin-right: 8px;
  }

  & > button:last-child {
    margin-right: 0px;
    ${IconHoverText}:last-child {
      left: 0px;
    }
  }
`

const CopyText = styled(CopyHelper).attrs({
  InitialIcon: Copy,
  CopiedIcon: Copy,
  gap: 4,
  iconSize: 14,
  iconPosition: 'right',
})``

const StatusWrapper = styled.div`
  display: inline-block;
  width: 70%;
  max-width: 70%;
  overflow: hidden;
  padding-right: 14px;
  display: inline-flex;
`

const AccountNamesWrapper = styled.div`
  overflow: hidden;
  white-space: nowrap;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  gap: 2px;
`

const StyledInfoIcon = styled(Info)`
  height: 12px;
  width: 12px;
  flex: 1 1 auto;
`

const HeaderWrapper = styled.div`
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`

const LogOutCentered = styled(LogOut)`
  transform: translateX(2px);
`

export default function AuthenticatedHeader({ account, openSettings }: { account: string; openSettings: () => void }) {
  const { connector, ENSName } = useWeb3React()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [showDisconnectConfirm, setShowDisconnectConfirm] = useState(false)
  const connection = getConnection(connector)
  const disconnect = useCallback(() => {
    if (connector && connector.deactivate) {
      connector.deactivate()
    }
    connector.resetState()
    dispatch(logout())
    dispatch(updateSelectedWallet({ wallet: undefined }))
  }, [connector, dispatch])

  const toggleWalletDrawer = useToggleAccountDrawer()

  return (
    <>
          {account && (

       
 
              <AuthenticatedHeaderWrapper>
             <HeaderWrapper>
     
         <StatusWrapper>
          <StatusIcon account={account} connection={connection} size={40} showMiniIcons={false}/>
          {account && (
            <AccountNamesWrapper>
              <ThemedText.SubHeader>
                <CopyText toCopy={ENSName ?? account}>{ENSName ?? shortenAddress(account)}</CopyText>
              </ThemedText.SubHeader>
              {/* Displays smaller view of account if ENS name was rendered above */}
              {ENSName && (
                <ThemedText.BodySmall color="neutral2">
                  <CopyText toCopy={account}>{shortenAddress(account)}</CopyText>
                </ThemedText.BodySmall>
              )}
            </AccountNamesWrapper>
          )}
        </StatusWrapper>
        <IconContainer>
          {!showDisconnectConfirm && (
            <IconButton data-testid="wallet-settings" onClick={openSettings} Icon={Settings} />
          )}

            <IconWithConfirmTextButton
              data-testid="wallet-disconnect"
              onConfirm={disconnect}
              onShowConfirm={setShowDisconnectConfirm}
              Icon={LogOutCentered}
              text="Disconnect"
              dismissOnHoverOut
            />
 
        </IconContainer>

        </HeaderWrapper>
        <>
        <AuthenticatedMenu />
        </>
        </AuthenticatedHeaderWrapper>
   
    

          )}


    </>
  )
}
