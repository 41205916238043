import styled from "styled-components/macro";
import {MAX_WIDTH_MEDIA_BREAKPOINT,MIN_WIDTH_MEDIA_BREAKPOINT,LARGE_MEDIA_BREAKPOINT} from "constants/width"

export const WebMain = styled.div`
  max-width: 1440px;
  min-width: 800px;
  min-height: calc(100vh - 165px);
  height: auto;
  margin: 0 auto;
  text-align: left;
  overflow: hidden;
  position: relative;
  z-index: 10;
  padding-bottom: 100px;
  @media screen and (min-width: ${LARGE_MEDIA_BREAKPOINT}) {
    // @media screen and (min-width: ${LARGE_MEDIA_BREAKPOINT}) and (max-width: ${MAX_WIDTH_MEDIA_BREAKPOINT}) {
      width: calc(100vw - 40px);
    max-width: 1440px !important;
    min-width: 760px !important;
  }
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    width: calc(100vw - 40px) !important;
    max-width: 800px !important;
    min-width: 0px !important;
  }
`;
export const MainInfo = styled.div``;
export const MainInfoTop = styled.div`
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  > div:last-child {
    width: calc(100% - 12.5vw);
  }
`;

export const InfoImg = styled.img`
  height: 11.5vw;
  width: 11.5vw;
  max-width: 220px;
  max-height: 220px;
  border-radius: 0.5vw;
  border: 4px solid ${({ theme }) => theme.bg0};
  object-fit: cover;
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    height: 73px;
    width: 73px;
    object-fit: cover;
    border: none;
  }
`;
export const AttesImg = styled.img`
  width: 18px;
  height: 18px;
  margin-left: 4px;
`;
export const AttesImg1 = styled.img`
  width: 14px;
  height: 14px;
  margin-right: 4px;
`;
export const InfoNameRow = styled.div`
  margin-top: 17px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const InfoNameCol = styled.div`
  display: flex;
  align-items: center;
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    padding-left: 15px;
  }
`;
export const AddressText = styled.div`
  margin: 0 3px;
  font-size: 14px;
  font-family: Inter-Bold, Inter;
  font-weight: normal;
  color: ${({ theme }) => theme.text3};
  line-height: 20px;
`;
export const NameText = styled.div`
  font-size: 24px;
  font-family: Inter-Bold, Inter;
  font-weight: bold;
  color: ${({ theme }) => theme.text1};
  line-height: 28px;
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    font-size: 18px;
    line-height: 21px;
  }
`;

export const InfoNameImg = styled.img`
  margin-left: 24px;
  width: 24px;
  height: 24px;
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    margin-left: 0;
    margin-right: 13px;
    width: 18px;
    height: 18px;
  }
`;
export const LinkImg = styled.img`
  margin-left: 24px;
  width: 24px;
  height: 24px;
  cursor: pointer;
  opacity: 0.7;
  :hover {
    opacity: 1;
  }
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    margin-left: 0;
    margin-right: 13px;
    width: 18px;
    height: 18px;
    opacity: 1;
  }
`;

export const EthImg = styled.img`
  margin-right: 2px;
  width: 12px;
  height: 12px;
`;
export const ArrowImg = styled.img`
  margin-left: 2px;
  margin-top: 5px;
  width: 12px;
  height: 12px;
`;
export const NoData = styled.div`
  width: 100%;
  padding: 50px 0;
  border: 1px solid ${({ theme }) => theme.border1};
  color: ${({ theme }) => theme.text1};

  border-radius: 8px;
  margin: 20px auto;
  > div:first-child {
    width: 100%;
    text-align: center;
    font-size: 28px;
  }
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    > div:first-child {
      width: 100%;
      text-align: center;
      font-size: 20px;
    }
  }
`;

export const InfoDescLoad = styled.div`
  width: 70%;
  white-space: pre;
  font-size: 14px;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  color: ${({ theme }) => theme.bg2};
  background: ${({ theme }) => theme.bg2};
  line-height: 16px;
  margin: 10px 0 5px;
`;
export const InfoDescLoadTel = styled.div`
  white-space: pre;
  font-size: 14px;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  color: ${({ theme }) => theme.bg2};
  background: ${({ theme }) => theme.bg2};
  line-height: 16px;
`;
export const InfoDesc = styled.div<{ openDesc: boolean }>`
  width: 70%;
  font-size: 14px;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  color: ${({ theme }) => theme.text3};
  line-height: 16px;
  margin: 10px 0 5px;
  overflow: ${({ openDesc }) => (openDesc ? "" : "hidden")};
  text-overflow: ${({ openDesc }) => (openDesc ? "" : "ellipsis")};
  white-space: ${({ openDesc }) => (openDesc ? "" : "nowrap")};
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    width: 100%;
    font-size: 13px;
  }
`;    
export const DescBtn = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  > div {
    font-size: 12px;
    font-family: Alibaba PuHuiTi 2-55 Regular, Alibaba PuHuiTi 20;
    font-weight: normal;
    color: ${({ theme }) => theme.text3};
    line-height: 12px;
    margin-top: 5px;
  }
`;
export const BtnText = styled.div`
white-space: nowrap;
overflow: hidden;
text-overflow: ellipsis;
max-width: 80%;
margin: 0 10%;
font-size: 12px;
text-align: center;
`;
export const CollectRoles = styled.div`
  background: ${({ theme }) => theme.modalBG1};
  padding: 8px;
  border-radius: 8px;
  box-sizing: border-box;
  height: 60px;
  font-size: 14px;
  cursor: pointer;
  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 22px;
    color: ${({ theme }) => theme.text2};
    :hover {
      color: ${({ theme }) => theme.text1};
    }
  }
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    height: auto;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    > div {
      width: 50%;
      padding-right: 10px;
      box-sizing: border-box;
    }
    > div:last-child {
      width: 50%;
      padding-right: 0;
      padding-left: 10px;
      box-sizing: border-box;
    }
  }
`;
export const AdimnImg = styled.img`
  width: 15px;
  height: 15px;
  margin-left: 8px;
`;
export const InfoGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    flex-wrap: wrap;
    // margin-bottom: 16px;
    > div {
      width: 100%;
    }
  }
`;
export const InfoGroupL = styled.div`
  display: flex;
  align-items: center;
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 16px;
  }
`;

export const InfoItem = styled.div`
  margin-top: 16px;
  min-width: 100px;
  width: 10vw;
  max-width: 152px;
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    margin-top: 12px;
    width: calc(50vw - 25px);
    min-width: 0;
    max-width: 999px;
    padding: 12px;
    box-sizing: border-box;
    border-radius: 4px;
    background: ${({ theme }) => theme.bg2};
  }
`;

export const IItemTop = styled.div`
  display: flex;
  align-items: center;
  > div {
    font-size: 18px;
    font-family: Arial-Regular, Arial;
    font-weight: 400;
    color: ${({ theme }) => theme.text1};
    line-height: 21px;
  }
`;

export const IItemBottom = styled.div`
  font-size: 14px;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  color: ${({ theme }) => theme.text3};
  line-height: 16px;
  margin-top: 5px;
  overflow: hidden;
  text-overflow: ellipsis; //溢出用省略号显示
  white-space: nowrap; //溢出不换行
`;

export const MainBottom = styled.div``;
export const MainBottomTitle = styled.div<{ isAct: boolean }>`
  margin: 15px 10px 10px 0;
  display: inline-block;
  font-size: 20px;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  padding:5px 10px;
  border-radius:5px;
  cursor: pointer;
  color: ${({ theme, isAct }) => (isAct ? theme.text1 : theme.text3)};
  background: ${({ theme, isAct }) => (isAct ? theme.bg2: '')};
  :hover {

    color: ${({ theme, isAct }) => (isAct ? theme.text1 : theme.text2)};
  }
`;

export const FilterSearch = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const FilterSearchTel = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
`;
export const SearchL = styled.img`
  width: 42px;
  height: 42px;
  cursor: pointer;
`;
export const SearchM = styled.div`
  flex: 1;
  margin: 0 18px;
  border: 1px solid ${({ theme }) => theme.border1};
  display: flex;
  align-items: center;
  height: 42px;
  border-radius: 6px;
  > input:-internal-autofill-selected {
    -webkit-text-fill-color: ${({ theme }) => theme.text1} !important;
    transition: background-color 5000s ease-in-out 0s !important;
    box-shadow: 0 0 0 1000px ${({ theme }) => theme.bg1} inset;
  }
`;
export const SearchMTel = styled.div`
  flex: 1;
  margin: 0 0;
  border: 1px solid ${({ theme }) => theme.border1};
  display: flex;
  align-items: center;
  height: 42px;
  border-radius: 6px;
  > input:-internal-autofill-selected {
    -webkit-text-fill-color: ${({ theme }) => theme.text1} !important;
    transition: background-color 5000s ease-in-out 0s !important;
    box-shadow: 0 0 0 1000px ${({ theme }) => theme.bg1} inset;
  }
`;
export const SearchIcon = styled.img`
  width: 22px;
  height: 22px;
  margin: 0 10px;
`;
export const SearchR = styled.div`
  width: 176px;
  height: 42px;
  border-radius: 6px;
  opacity: 1;
  border: 1px solid ${({ theme }) => theme.border1};

  // display: flex;
  // justify-content: center;
  // align-items: center;
  cursor: pointer;
  position: relative;
`;
export const SearchRTel = styled.div`
  width: calc(50vw - 30px);
  height: 42px;
  border-radius: 6px;
  opacity: 1;
  cursor: pointer;
  position: relative;
  border: 1px solid ${({ theme }) => theme.border1};

`;
export const SearchRTel1 = styled.div`
  width: calc(50vw - 30px);
  height: 42px;
  border-radius: 6px;
  padding: 0 11px;
  box-sizing: border-box;
  opacity: 1;
  border: 1px solid #dddddd;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  position: relative;
  > div {
    width: calc(45.8vw - 60px);
    overflow: hidden;
    text-overflow: ellipsis; //溢出用省略号显示
    white-space: nowrap; //溢出不换行
  }
`;
export const SearchInput = styled.input`
  border: none;
  flex: 1;
  outline: none;
  background: transparent;
  font-size: 14px;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  color: ${({ theme }) => theme.text1};
  margin-right: 10px;
`;
export const MainBB = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width:100%;
`;
export const MainBBl = styled.div<{ isFilter: boolean }>`
  // max-width: 170px;
  width: 222px;
  margin-right: ${({ isFilter }) => (isFilter ? "18px" : "0")};
  width: ${({ isFilter }) => (isFilter ? " " : "0")};
  height: ${({ isFilter }) => (isFilter ? " " : "0")};
  opacity: ${({ isFilter }) => (isFilter ? "1" : "0")};
  display: ${({ isFilter }) => (isFilter ? " " : "none")};
  overflow: hidden;
  // flex:1;
`;
export const LeftSearchTitle = styled.div`
  margin: 26px 0 12px;
  height: 24px;
  font-size: 20px;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  color: ${({ theme }) => theme.text1};
`;
export const MainBBR = styled.div<{ isFilter: boolean }>`
  flex: 1;
  padding-top: 62px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    padding-top: 20px;
  }
`;
export const MainBbr = styled.div<{ isFilter: boolean }>`
  flex: 1;
  // padding-top: 62px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-top: ${({ isFilter }) => (isFilter ? "62px" : "20px")};
  width:100%;
  padding-right: 5px;
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    padding-top: 10px;
  }
`;
export const BtnRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const BtnOne2 = styled.div<{ isAct: boolean }>`
  width: calc(50% - 9px);
  height: 40px;
  line-height: 40px;
  border-radius: 6px;
  text-align: center;
  border: 1px solid ${({ isAct }) => (isAct ? "#8371EC" : "#DDDDDD")};
  background: ${({ isAct }) =>
    isAct ? "rgb(131, 113, 236,0.1)" : "transparent"};
  font-size: 14px;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  color: ${({ theme, isAct }) => (isAct ? "#8371EC" : theme.text2)};
  cursor: pointer;
`;
export const BtnOne3 = styled.div`
  width: calc(30% - 12px);
  height: 40px;
  line-height: 40px;
  border-radius: 6px;
  text-align: center;
  border: 1px solid #dddddd;
  font-size: 14px;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  color: ${({ theme }) => theme.text2};
  cursor: pointer;
  overflow: hidden;
`;
export const BtnOne1 = styled.div<{ isAct: boolean }>`
  width: 100%;
  height: 40px;
  line-height: 40px;
  border-radius: 6px;
  text-align: center;
  font-size: 14px;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  background: #8371ec;
  color: ${({ theme }) => theme.bg0};
  cursor: pointer;
  margin-top: 10px;
  opacity: ${({ isAct }) => (isAct ? "1" : "0.3")};
`;

export const PropGroup = styled.div<{ isAct: boolean }>`
  width: 100%;
  height: ${({ isAct }) => (isAct ? "1" : "40px")};
  overflow: hidden;
  padding: 0 14px;
  box-sizing: border-box;
  border-radius: 4px;
  border: 1px solid #dddddd;
  margin-bottom: 10px;
`;
export const PropTop = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  > div {
    max-width: 180px;
    font-size: 16px;
    font-family: Inter-Regular, Inter;
    font-weight: 400;
    color: ${({ theme }) => theme.text1};
  }
`;
export const PropArrowImg = styled.img<{ isAct: boolean }>`
  width: 15px;
  height: 15px;
  transform: ${({ isAct }) => (isAct ? "rotateX(180deg)" : "rotateX(0deg)")};
`;
export const CloseImg = styled.img`
  width: 12px;
  height: 12px;
  margin-left: 5px;

`;
export const PropMain = styled.div``;
export const PropItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  margin: 6px 0 14px;
`;
export const PropItemName = styled.div`
  max-width: 140px;
  // background:#f00;
  flex: 1;
  font-size: 12px;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  color: ${({ theme }) => theme.text3};
  line-height: 14px;
`;
export const PropItemRight = styled.div`
  display: flex;
  align-items: center;
  > div {
    font-size: 12px;
    font-family: Inter-Regular, Inter;
    font-weight: 400;
    color: ${({ theme }) => theme.text3};
    line-height: 14px;
  }
`;
export const PropItemIcon = styled.img`
  width: 14px;
  height: 14px;
  margin-left: 7px;
`;
export const MainItem = styled.div`
  width: calc(25% - 10px);
  background: ${({ theme }) => theme.bg0};
  border: 1px solid ${({ theme }) => theme.border1};
  border-radius: 6px;
  margin-bottom: 16px;
  overflow: hidden;
  cursor: pointer;

  position: relative;
  box-shadow: 2px 2px 10px ${({ theme }) => theme.bg3};

  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    width: calc(50vw - 30px);
    background: ${({ theme }) => theme.bg0};
    border: 1px solid ${({ theme }) => theme.border1};
    border-radius: 6px;
    margin-bottom: 16px;
    overflow: hidden;
    cursor: pointer;
    box-shadow: none;
  }
`;
export const MainItemNone = styled.div`
  width: calc(25% - 10px);
  height: 0;
  opacity: 0;
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    width: calc(50vw - 30px);
  }
`;
export const MainItemLoad = styled.div`
  width: calc(25% - 10px);
  padding-top: 26%;
  background: ${({ theme }) => theme.bg2};
  border-radius: 6px;
  margin-bottom: 16px;
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    width: calc(50vw - 30px);
    height: 200px;
    background: ${({ theme }) => theme.bg2};
    overflow: hidden;
  }
`;

export const MainItemInfo = styled.div`
  padding: 5px;
  font-size: 13px;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  color: ${({ theme }) => theme.text3};
  line-height: 15px;
  position: absolute;
  left: 0;
  // bottom:-35px;
  bottom: 0;
  height: 70px;
  background: ${({ theme }) => theme.bg0};
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    padding: 5px;
    font-size: 13px;
    font-family: Inter-Regular, Inter;
    font-weight: 400;
    color: ${({ theme }) => theme.text3};
    line-height: 15px;
  }
`;
export const InfoTop = styled.div`
  display: flex;
  align-items: center;
  height: 20px;
  > div {
    max-width: calc(100% - 25px);
    overflow: hidden;
    text-overflow: ellipsis; //溢出用省略号显示
    white-space: nowrap; //溢出不换行
  }
`;

export const InfoBottomBuy = styled.div`
  background: ${({ theme }) => theme.text1};
  color: ${({ theme }) => theme.bg0};
  width: calc(100% - 10px);
  font-size: 12px;
  text-align: center;
  height: 30px;
  line-height: 30px;
  border-radius: 2px 2px 2px 2px;
  opacity: 0;
  margin: 0 5px 5px;
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    display:none;
   margin: 0 0 0 0;

  }
`;
export const InfoBottom = styled.div`
  display: flex;
  align-items: center;
  height: 15px;

  > div {
    max-width: 100%;

    // max-width: calc(10vw - 15px);
    overflow: hidden;
    text-overflow: ellipsis; //溢出用省略号显示
    white-space: nowrap; //溢出不换行
    font-size: 13px;
    color: ${({ theme }) => theme.text3};
    > span {
      font-size: 13px;
      color: ${({ theme }) => theme.text1};
    }
    @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
      max-width: 100%;

     }
  }
`;

export const ItemTopImg = styled.img`
  width: 10px;
  height: 10px;
  margin-left: 4px;
`;

export const ItemBottomImg = styled.img`
  width: 10px;
  height: 10px;
`;
export const InfoLine = styled.div`
  font-size: 15px;
  font-family: Arial-Regular, Arial;
  font-weight: 400;
  height: 20px;
  color: ${({ theme }) => theme.text1};
  max-wudth: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const MainItemImg = styled.img`
  width: 100%;
  transition: all 450ms ease;
  transform: scale(1);
  height: 100%;

  position: absolute;
  object-fit: contain;
  :hover {
    transform: scale(1.15);
  }
`;
export const MainItemBlock = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 0;
  padding-bottom: 100%;
`;

export const PageTitle = styled.div`
  font-size: 20px;
  font-family: Inter-Bold, Inter;
  font-weight: bold;
  color: ${({ theme }) => theme.text1};
  line-height: 50px;
`;
export const InfoImgTelLoad = styled.div`
  height: 11.5vw;
  width: 11.5vw;
  max-width: 220px;
  max-height: 220px;
  border-radius: 0.5vw;
  border: 4px solid ${({ theme }) => theme.bg0};
  object-fit: cover;
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    height: 73px;
    width: 73px;
    background: ${({ theme }) => theme.bg2};
  }
`;

export const ItemGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 8px;
`;
export const PopupView = styled.div<{ isShow: boolean }>`
  width: ${({ isShow }) => (isShow ? "100vw" : "0")};
  height: ${({ isShow }) => (isShow ? "100vh" : "0")};
  background: ${({ theme }) => theme.modalBG2};
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 10;
  display: flex;
  flex-direction: column-reverse;
  opacity: ${({ isShow }) => (isShow ? "1" : "0")};
  overflow: hidden;
`;
export const PopupMain = styled.div`
  width: 100vw;
  height: 70vh;
  background: ${({ theme }) => theme.bg1};
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 50px;
  box-sizing: border-box;

`;
export const TitleLine = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 19px;
  height: 56px;
  border-bottom: 1px solid ${({ theme }) => theme.border1};
`;
export const TitleLineL = styled.div`
  width: 13px;
`;
export const TitleLineC = styled.div`
  font-size: 18px;
  font-family: Inter-Medium, Inter;
  font-weight: 500;
  color: ${({ theme }) => theme.text1};
`;
export const TitleLineR = styled.img`
  width: 13px;
  height: 13px;
`;
export const FlexBlock = styled.div`
display: flex; flex-wrap: wrap;align-items:center;
`;
export const MainGroup = styled.div`
  padding: 0 19px;
  width: 100%;
  max-height:calc(70vh - 150px);
  box-sizing: border-box;
  overflow: auto;
  flex: 1;
`;
export const MainBtns = styled.div`
  width: 100%;
  padding: 0 19px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
`;
export const ReselectionBtn = styled.div`
  width: calc(45.8vw - 10px);
  height: 44px;
  line-height: 44px;
  background: #e0dfe9;
  border-radius: 4px;
  text-align: center;
  font-size: 18px;
  font-family: Inter-Medium, Inter;
  font-weight: 500;
  color: #383838;
`;
export const ApplyBtn = styled.div`
  width: calc(45.8vw - 10px);
  height: 44px;
  line-height: 44px;
  background: #6a5bda;
  border-radius: 4px;
  text-align: center;
  font-size: 18px;
  font-family: Inter-Medium, Inter;
  font-weight: 500;
  color: #fff;
`;
export const ItemNumText = styled.div`
  font-size: 15px;
  font-family: Inter-Medium, Inter;
  font-weight: 500;
  color: ${({ theme }) => theme.text1};
`;
export const FilterImg = styled.img`
  width: 18px;
  height: 18px;
`;
