import { Trans } from '@lingui/macro'


import { useWeb3React } from '@web3-react/core'
import PortfolioDrawer, { useAccountDrawer } from 'components/AccountDrawer'

import { getConnection } from 'connection'

import { useCallback, useEffect } from 'react'
import styled from 'styled-components/macro'

import { flexRowNoWrap } from 'theme/styles'


import {MAX_WIDTH_MEDIA_BREAKPOINT,MIN_WIDTH_MEDIA_BREAKPOINT,LARGE_MEDIA_BREAKPOINT} from "constants/width"

import { shortenAddress } from '../../utils'
import { ButtonSecondary } from '../Button'

import { RowBetween } from '../Row'
import StatusIcon from 'components/Identicon/StatusIcon'
import { Portal } from 'components/Portal'

// https://stackoverflow.com/a/31617326
const FULL_BORDER_RADIUS = 9999

const Web3StatusGeneric = styled(ButtonSecondary)`
  ${flexRowNoWrap};
  width: 100%;
  align-items: center;
  padding: 0.5rem;
  border-radius: ${FULL_BORDER_RADIUS}px;
  cursor: pointer;
  user-select: none;
  height: 36px;
  margin-right: 2px;
  margin-left: 2px;
  :focus {
    outline: none;
  }
 
`


const Web3StatusConnectWrapper = styled.div<{ faded?: boolean }>`
  ${flexRowNoWrap};
  align-items: center;
  background-color: ${({ theme }) => theme.accentActionSoft};
  border-radius: ${FULL_BORDER_RADIUS}px;
  border: none;
  padding: 0;
  height: 40px;

  color: ${({ theme }) => theme.accentAction};
  :hover {
    color: ${({ theme }) => theme.accentActionSoft};
    stroke: ${({ theme }) => theme.accentActionSoft};
  }

  transition: ${({
    theme: {
      transition: { duration, timing },
    },
  }) => `${duration.fast} color ${timing.in}`};
`

const Web3StatusConnected = styled(Web3StatusGeneric)<{
  pending?: boolean
  isClaimAvailable?: boolean
}>`
`

const AddressAndChevronContainer = styled.div`
  display: flex;

`

const Text = styled.p`
  flex: 1 1 auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0 0.5rem 0 0.25rem;
  font-size: 1rem;
  width: fit-content;
  font-weight: 500;
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}){
    font-size: 0.8rem;
    margin: 0 0.2rem 0 0.1rem;
  }
`

const StyledConnectButton = styled.button`
  background-color: transparent;
  border: none;
  border-top-left-radius: ${FULL_BORDER_RADIUS}px;
  border-bottom-left-radius: ${FULL_BORDER_RADIUS}px;
  cursor: pointer;
  font-weight: 600;
  font-size: 16px;
  padding: 10px 12px;
  color: inherit;
`

function Web3StatusInner() {
  const { account, connector, chainId, ENSName } = useWeb3React()
  const connection = getConnection(connector)
  const [, toggleAccountDrawer] = useAccountDrawer()
  const handleWalletDropdownClick = useCallback(() => {
  
    toggleAccountDrawer()
  }, [toggleAccountDrawer])

   useEffect(()=>{
    
   },[chainId])

  if (!chainId) {
    return null
  } else if (account) {
    return (

        <Web3StatusConnected
          data-testid="web3-status-connected"
          onClick={handleWalletDropdownClick}

        >
  <StatusIcon account={account} size={24} connection={connection} showMiniIcons={false} />
     <AddressAndChevronContainer>
              <Text>{ENSName || shortenAddress(account)}</Text>
            </AddressAndChevronContainer>
   
        </Web3StatusConnected>

    )
  } else {
    return (

        <Web3StatusConnectWrapper
        tabIndex={0}
        faded={!account}
        onKeyPress={(e) => e.key === 'Enter' && handleWalletDropdownClick()}
        onClick={handleWalletDropdownClick}
        >
          <StyledConnectButton tabIndex={-1} data-testid="navbar-connect-wallet">
            <Trans>Connect</Trans>
          </StyledConnectButton>
        </Web3StatusConnectWrapper>

    )
  }
}

export default function Web3Status() {
  return (
    <div>
      <Web3StatusInner />
      <Portal>
      <PortfolioDrawer />
     </Portal>
    </div>
  )
}
