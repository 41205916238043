import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { AppState } from "..";

const currentTimestamp = () => new Date().getTime()
interface UserState {
  account: Account | null;
  ref:null|string;
  info: null;
  expires_at:0;
  token: null | string;
  selectedWallet: undefined
  accounts:Record<string,AuthenticatedAccount>
  loading: "idle" | "pending" | "succeeded" | "failed";
  error: undefined | string;
  matchesDarkMode: false,
  userDarkMode: null,
  timestamp: number,
  chain:string,
  userLocale: null,

}

const initialState = {
  account: null,
  ref:null,
  info: null,
  expires_at:0,
  accounts:{},
  token: null,
  loading: "idle",
  error: undefined,
  timestamp:currentTimestamp()
} as UserState;

export const userSlice = createSlice({
  name: "account",
  initialState: initialState,
  reducers: {
    setCredentials: (
      state:UserState,
      { payload: { account, token ,expires_at} }: PayloadAction<{ account: Account; token: string,expires_at:number }>
    ) => {
      state.account = {...account,jwt:token};
      state.token = token;
      if (state.accounts===undefined){
        state.accounts={}
      }
      state.accounts[account.address]={account:account,token:token,expires_at}
    },
    setRef:(state:UserState, { payload: { ref} }: PayloadAction<{ ref: string }>)=>{
      state.ref=ref
    },
    logout: (
      state:UserState) => {
        if(state.account && state.account.address){
          delete state.accounts[state.account.address]
        }

      state.account = null;
      state.token = null;
      
    },
    updateAccount: (
      state:UserState,
      { payload: account }: PayloadAction<Account>
    ) => {
      console.log("reducer:",JSON.stringify(account))
      state.account = account;
    },
    updateInfo(state, { payload: { wallet } }) {
      console.log("selectd info",wallet)
      state.info = wallet
    },
    updateUser: (
      state:UserState,
      { payload: user }: PayloadAction<User>
    ) => {
      console.log("reducer:",JSON.stringify(state.account))
      if(state.account && state.token){
        state.account = {...state.account,user:user}
        if(!state.accounts){
          state.accounts={}
        }
        state.accounts[state.account.address]={account:state.account,token:state.token,expires_at:state.expires_at}
      }
    },

    updateSelectedWallet(state, { payload: { wallet } }) {
      console.log("selectd wallet",wallet)
      state.selectedWallet = wallet
    }
  },
});
export const selectCurrentAccount = (state: AppState) => state.user.account;
export const selectAccounts = (state: AppState) => state.user.accounts;
export const selectToken = (state: AppState) => state.user.token;
export const { setCredentials, updateAccount,updateUser,logout,updateSelectedWallet,updateInfo,setRef} = userSlice.actions;
export default userSlice.reducer;
