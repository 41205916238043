
import { useCallback } from 'react'

import { useAppDispatch, useAppSelector } from '../hooks'
import {updateHomePeriod,updateTokenTopPeriod,updateTokenWatchPeriod,updateTokenTrendingPeriod,updateCollectionTopPeriod,updateCollectionTrendingPeriod,updateCollectionWatchPeriod,
  updateHomeChain,updateTokenTopChain,updateTokenTrendingChain,updateTokenWatchChain,updateCollectionTopChain,updateCollectionTrendingChain,updateCollectionWatchChain,updateToast
} from './reducer'


export function useHomePeriod(): [string|null, (data:any) => void] {
  const dispatch = useAppDispatch()
  const homePeriod = useAppSelector((state) => state.stats.homePeriod)
  const setHomePeriod = useCallback((data:any) => {
    dispatch(updateHomePeriod(data))
  }, [dispatch])
  return [homePeriod, setHomePeriod]
}

export function useTokenTopPeriod(): [string|null,string|null, (data:any) => void] {
  const dispatch = useAppDispatch()
  const tokenTopPeriod = useAppSelector((state) => state.stats.tokenTopPeriod)
  const tokenTopPeriodLabel = useAppSelector((state) => state.stats.tokenTopPeriodLabel)
  const setTokenTopPeriod = useCallback((data:any) => {
    dispatch(updateTokenTopPeriod(data))
  }, [dispatch])
  return [tokenTopPeriod,tokenTopPeriodLabel, setTokenTopPeriod]
}

export function useTokenWatchPeriod(): [string|null, string|null, (data:any) => void] {
  const dispatch = useAppDispatch()
  const tokenWatchPeriod = useAppSelector((state) => state.stats.tokenWatchPeriod)
  const tokenWatchPeriodLabel = useAppSelector((state) => state.stats.tokenWatchPeriodLabel)

  const setTokenWatchPeriod = useCallback((data:any) => {
    dispatch(updateTokenWatchPeriod(data))
  }, [dispatch])
  return [tokenWatchPeriod,tokenWatchPeriodLabel, setTokenWatchPeriod]
}
export function useTokenTrendingPeriod(): [string|null, string|null, (data:any) => void] {
  const dispatch = useAppDispatch()
  const tokenTrendingPeriod = useAppSelector((state) => state.stats.tokenTrendingPeriod)
  const tokenTrendingPeriodLabel = useAppSelector((state) => state.stats.tokenTrendingPeriodLabel)

  const setTokenTrendingPeriod = useCallback((data:any) => {
    dispatch(updateTokenTrendingPeriod(data))
  }, [dispatch])
  return [tokenTrendingPeriod,tokenTrendingPeriodLabel, setTokenTrendingPeriod]
}

export function useCollectionTopPeriod(): [string|null, (data:any) => void] {
  const dispatch = useAppDispatch()
  const collectionTopPeriod = useAppSelector((state) => state.stats.collectionTopPeriod)
  const setCollectionTopPeriod = useCallback((data:any) => {
    dispatch(updateCollectionTopPeriod(data))
  }, [dispatch])
  return [collectionTopPeriod, setCollectionTopPeriod]
}

export function useCollectionTrendingPeriod(): [string|null, (data:any) => void] {
  const dispatch = useAppDispatch()
  const collectionTrendingPeriod = useAppSelector((state) => state.stats.collectionTrendingPeriod)
  const setCollectionTrendingPeriod = useCallback((data:any) => {
    dispatch(updateCollectionTrendingPeriod(data))
  }, [dispatch])
  return [collectionTrendingPeriod, setCollectionTrendingPeriod]
}

export function useCollectionWatchPeriod(): [string|null, (data:any) => void] {
  const dispatch = useAppDispatch()
  const collectionWatchPeriod = useAppSelector((state) => state.stats.collectionWatchPeriod)
  const setCollectionWatchPeriod = useCallback((data:any) => {
    dispatch(updateCollectionWatchPeriod(data))
  }, [dispatch])
  return [collectionWatchPeriod, setCollectionWatchPeriod]
}
export function useHomeChain(): [string|null, (data:any) => void] {
  const dispatch = useAppDispatch()
  const homeChain = useAppSelector((state) => state.stats.homeChain)
  const setHomeChain = useCallback((data:any) => {
    dispatch(updateHomeChain(data))
  }, [dispatch])
  return [homeChain, setHomeChain]
}

export function useTokenTopChain(): [string|null, (data:any) => void] {
  const dispatch = useAppDispatch()
  const tokenTopChain = useAppSelector((state) => state.stats.tokenTopChain)
  const setTokenTopChain = useCallback((data:any) => {
    dispatch(updateTokenTopChain(data))
  }, [dispatch])
  return [tokenTopChain, setTokenTopChain]
}
export function useTokenTrendingChain(): [string|null, (data:any) => void] {
  const dispatch = useAppDispatch()
  const tokenTrendingChain = useAppSelector((state) => state.stats.tokenTrendingChain)
  const setTokenTrendingChain = useCallback((data:any) => {
    dispatch(updateTokenTrendingChain(data))
  }, [dispatch])
  return [tokenTrendingChain, setTokenTrendingChain]
}
export function useTokenWatchChain(): [string|null, (data:any) => void] {
  const dispatch = useAppDispatch()
  const tokenWatchChain = useAppSelector((state) => state.stats.tokenWatchChain)
  const setTokenWatchChain = useCallback((data:any) => {
    dispatch(updateTokenWatchChain(data))
  }, [dispatch])
  return [tokenWatchChain, setTokenWatchChain]
}
export function useCollectionTopChain(): [string|null, (data:any) => void] {
  const dispatch = useAppDispatch()
  const collectionTopChain = useAppSelector((state) => state.stats.collectionTopChain)
  const setCollectionTopChain = useCallback((data:any) => {
    dispatch(updateCollectionTopChain(data))
  }, [dispatch])
  return [collectionTopChain, setCollectionTopChain]
}
export function useCollectionTrendingChain(): [string|null, (data:any) => void] {
  const dispatch = useAppDispatch()
  const collectionTrendingChain = useAppSelector((state) => state.stats.collectionTrendingChain)
  const setCollectionTrendingChain = useCallback((data:any) => {
    dispatch(updateCollectionTrendingChain(data))
  }, [dispatch])
  return [collectionTrendingChain, setCollectionTrendingChain]
}
export function useCollectionWatchChain(): [string|null, (data:any) => void] {
  const dispatch = useAppDispatch()
  const collectionWatchChain = useAppSelector((state) => state.stats.collectionWatchChain)
  const setCollectionWatchChain = useCallback((data:any) => {
    dispatch(updateCollectionWatchChain(data))
  }, [dispatch])
  return [collectionWatchChain, setCollectionWatchChain]
}
export function useToastOpen(): [boolean|null, string|null, string|null, (data:any) => void] {
  const dispatch = useAppDispatch()
  const toastOpen = useAppSelector((state) => state.stats.toastOpen)
  const toastType = useAppSelector((state) => state.stats.toastType)
  const toastLabel = useAppSelector((state) => state.stats.toastLabel)
  const setToastOpen = useCallback((data:any) => {
    dispatch(updateToast(data))
  }, [dispatch])
  return [toastOpen,toastType,toastLabel, setToastOpen]
}

// updateTokenWatchChain
