
import nftBg1 from "assets/nft/nft_popbg1.png";
import nftBg2 from "assets/nft/nft_popbg2.png";
import nftBg3 from "assets/nft/nft_popbg3.png";
import "../index.scss";
import {
  PageMain,PageRight,PageLeft
} from "./components_";
import {
  PageAll
} from "pages/Drop/components_";
// import DropBanner from "./components/DropBanner";
import CollectionBanner from "./components/DropBanner";
import Notable from "./components/Notable";
import Spotlight from "./components/Spotlight";
import Trending from "./components/Trending";
import PopularCasting from "./components/PopularCasting";
import PopularCollection from "./components/PopularCollection";

export default function Home() {
  const list3 = [
    { img: nftBg1, name: "What is nft martetplace?" },
    { img: nftBg2, name: "What is the nft floor price?" },
    { img: nftBg3, name: "How to evaluate an NFT?" },
  ];

  return (
    <>
    
      <CollectionBanner/>

      <PageAll>
        {/* <Trending /> */}
        {/* <Spotlight /> */}
        {/* <Notable /> */}
      <PageMain>
      <PageLeft><PopularCollection /></PageLeft>
      <PageRight><PopularCasting /></PageRight>
      </PageMain>
       
      </PageAll>
    </>
  );
}

