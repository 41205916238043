
import styled from "styled-components/macro";
import {
    LARGE_MEDIA_BREAKPOINT,
    MIN_WIDTH_MEDIA_BREAKPOINT,
  } from "constants/width";
const Page = styled.div`
  max-width: 1440px;
  min-width: 800px;
  height: auto;
  margin: 0 auto;
  text-align: left;
  overflow: hidden;
  padding-top: 40px;
  position: relative;
  z-index: 10;
  @media screen and (min-width: ${LARGE_MEDIA_BREAKPOINT}) {
    width: 95vw !important;
    max-width: 1440px !important;
    min-width: 760px !important;
  }
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    width: 95vw !important;
    padding-top: 20px;
    max-width: 800px !important;
    min-width: 0px !important;
  }
`;

export {Page}