
import { Input, Switch, Textarea } from "@rebass/forms";
import { useParams } from "react-router-dom";

import { useTokenList2Query, useTokenListQuery } from "apis/token";
import { ButtonPurple } from "components/Button";
import ChainSelect from "components/ChainSelect";
import TokensSelect from "components/TokenSelect";
import { useEffect, useState } from "react";
import { useDarkModeManager } from "theme/components/ThemeToggle";
import {
  CardBox,
  CardItem,
  CardMain,
  CardTitle,
  CloseImg,
  ItemLabel,
  ItemLabelFlex,
  ItemTag,
  ItemTip,
  ItemTipL,
  ItemTipR,
  ItemValue,
  ItemValueArea,
  OneTag,
  StyledModal,
  TagDel,
  areaStyle,
  inputStyle
} from "../../components_";

import closeIcon from "assets/tool/close_icon.png";
import closeIconN from "assets/tool/close_icon_n.png";
import { Token } from "types/token";




export interface CollectionDetail {
  name?: string;
  description?: string;
  category?: string
  payment_tokens?: Token[]
  collaborators?: string[]
  sensitive_content?: boolean;

}

interface DetailFormProps {
  visible: boolean;
  value?: CollectionDetail;
  onChange: (value: CollectionDetail) => void;
}

export default function DetailForm({
  visible,
  value,
  onChange,
}: DetailFormProps) {
  const [darkMode] = useDarkModeManager();
  const { chain, contract_address } = useParams();
  const [showPopup, setShowPopup] = useState(false);
  const [popuptype, setPopuptype] = useState("collaborator");
  const [filterkey, setFilterkey] = useState("");
  const [currencyList, setCurrencyList] = useState<Token[]>([]);
  const [collaboratorAddress, setCollaboratorAddress] = useState("");
  const { data } = useTokenList2Query({
    chain: chain as string,
    current: 1,
    pageSize: 10,
    native: true
  })
  const selectStyle = {
    height: "40px",
    width: "100%",
    outline: "none",
    borderRadius: "6px",
    background: darkMode ? "#212429" : "#f7f8fa",
    border: "none",
  };

  const saveCollaborator = () => {
    if (collaboratorAddress && collaboratorAddress !== '') {
      let list: string[] = []
      if (value?.collaborators) {
        list = [...value.collaborators]
      }
      list.push(collaboratorAddress)
      onChange({ ...value, ...{ collaborators: list } })
      setShowPopup(false)
    }

  }
  const delCollaborator = (index: number) => {
    let list: string[] = []
    if (value?.collaborators) {
      list = [...value.collaborators]
    }
    list.splice(index, 1)
    onChange({ ...value, ...{ collaborators: list } })

  }

  const addTokens = (e: Token) => {
    if (value && value.payment_tokens) {
      let list = [...value.payment_tokens]

      list.push(e)
      let arr = [...new Set(list)]
      onChange({ payment_tokens: arr })
    } else {
      let list = []
      list.push(e)
      let arr = [...new Set(list)]
      onChange({ payment_tokens: arr })
    }

  }
  const delOneToken = (e: number) => {
    // let list=[...currencyList]
    if (value && value.payment_tokens) {
      let list = [...value.payment_tokens]

      list.splice(e, 1)
      onChange({ payment_tokens: list })
      // setCurrencyList(list)
    }

  }
  const handleChainChange = (changeValue: Record<string, any>) => {
    onChange({ ...value, ...changeValue })
  }
  useEffect(() => {
    if (data && data.success) {
      if (value && data.data[0]) {
        if (value.payment_tokens) {
          let list = value.payment_tokens.map(el => {
            return el?.symbol?.toUpperCase()
          })
          let index = list.indexOf(data.data[0]?.symbol?.toUpperCase() as string)
          if (index !== -1) {
            if (value.payment_tokens[0] !== data.data[0]) {
              if (index > 0) {
                let arr = [...value.payment_tokens]
                arr.splice(index, 1)
                arr.unshift(data.data[0])
                onChange({ payment_tokens: arr })

              }

            }

          } else {
            let arr = [...value.payment_tokens]
            arr.unshift(data.data[0])
            onChange({ payment_tokens: arr })
          }

        } else {
          onChange({ payment_tokens: data.data })
        }

      }

    }
  }, [data, value]);


  return <>
    <CardBox isAct={visible}>
      <CardMain>
        <CardTitle>Collection Details</CardTitle>
        <CardItem>
          <ItemLabel>Name</ItemLabel>
          <ItemValue>
            <Input
              type="text"
              placeholder="Name your collection"
              value={value?.name}
              sx={inputStyle}
              onChange={(e) => {
                handleChainChange({ name: e.target.value })
              }}
            />
          </ItemValue>
        </CardItem>
        <CardItem>
          <ItemLabel>Description</ItemLabel>

          <ItemValueArea>
            <Textarea
              id="comment"
              name="comment"
              placeholder="Add a description"
              value={value?.description}
              sx={areaStyle}
              onChange={(e) => {
                handleChainChange({ description: e.target.value })
              }}
            />
          </ItemValueArea>
        </CardItem>

        {/* <CardItem>
          <ItemLabel>Category</ItemLabel>

          <ItemValue>
            <Select
              id="country"
              name="country"
              defaultValue=""
              sx={selectStyle}
            >
              {optionArr.map((e) => (
                <option
                  key={e}
                  style={{
                    color: darkMode ? "#C3C5CB" : "#565A69",
                  }}
                >
                  {e}
                </option>
              ))}
            </Select>
          </ItemValue>
        </CardItem> */}
        <CardItem>
          <ItemLabel>Blockchain</ItemLabel>

          <ItemTip>
            Select the blockchain where you'd like new items from this
            collection to be added by default.
          </ItemTip>
          <ItemValue>

            <ChainSelect value={chain || ''} onChange={() => { }} size={'filed'} />

          </ItemValue>
        </CardItem>
        <CardItem style={{ position: 'relative' }}>
          <ItemLabel>Payment tokens</ItemLabel>

          <ItemTip>
            These tokens can be used to buy and sell your items.
          </ItemTip>
          <ItemTag>
            {value?.payment_tokens?.map((i: Token, index1: number) => (
              <OneTag key={index1}>
                <div>{i.symbol}</div>
                {index1 < 1 ? null : (
                  <TagDel onClick={() => delOneToken(index1)} src={darkMode ? closeIconN : closeIcon} />
                )}

              </OneTag>
            ))}
          </ItemTag>

          <ItemValue style={{ overflow: 'visible' }}>
            <TokensSelect value={chain || ''} onChange={(e) => addTokens(e)} size={'filed'} />


          </ItemValue>
        </CardItem>
        <CardItem>
          <ItemLabel>Explicit & sensitive content</ItemLabel>

          <ItemTipL>
            Set this collection as explicit and sensitive content
          </ItemTipL>
          <ItemTipR>
            <Switch
              className="switch"
              sx={{ width: "40px", height: "20px" }}
              checked={value?.sensitive_content}
              onClick={() => handleChainChange({ sensitive_content: !value?.sensitive_content })}
            />
          </ItemTipR>
        </CardItem>
        {/* <CardItem>
          <ItemLabel>Collaborators</ItemLabel>

          <ItemTip>
            Can modify collection settings, receive payments for items
            they createdand create new items.
          </ItemTip>
          <ItemTip>
            Cannot change payout addresses or the percentage of creator
            earnings.
          </ItemTip>
        </CardItem>
        <CardItem>
          <ItemLabelFlex>
            <div>You</div>
            <div>Owner</div>
          </ItemLabelFlex>
          {value?.collaborators?.map((i: string, index: number) => (

            <ItemLabelFlex key={index}>
              <div>
                {i?.substring(0, 7) +
                  "..." +
                  i?.substr(i.length - 5)}
              </div>
              <div style={{ cursor: 'pointer' }}>
                <DelImg
                  src={darkMode ? closeIconN : closeIcon}
                  onClick={() => { delCollaborator(index) }}
                />
              </div>
            </ItemLabelFlex>

          ))}
          <ItemLabel onClick={() => { setShowPopup(true) }} style={{ cursor: 'pointer' }}>+ Add Add Collaborator</ItemLabel>
        </CardItem> */}
      </CardMain>
      <StyledModal
        isOpen={showPopup && popuptype == "collaborator"}
        onBackgroundClick={() => setShowPopup(!showPopup)}
        onEscapeKeydown={() => setShowPopup(!showPopup)}
      ><ItemLabelFlex>
          <CardTitle>Add a collaborator</CardTitle>
          <CloseImg
            src={darkMode ? closeIconN : closeIcon}
            onClick={() => setShowPopup(!showPopup)}
          />
        </ItemLabelFlex>

        <CardItem>
          <ItemLabelFlex>
            <ItemLabel>Address</ItemLabel>
          </ItemLabelFlex>
          <ItemValue>
            <Input
              type="text"
              placeholder="e.g. 0x1ed3...or destination.eth,destination.lens"
              value={collaboratorAddress}
              sx={inputStyle}
              onChange={(e) => {
                setCollaboratorAddress(e.target.value);
              }}
            />
          </ItemValue>
        </CardItem>

        <ButtonPurple padding={'10px'} $borderRadius={'4px'} width={'100%'} onClick={() => saveCollaborator()}>Apply</ButtonPurple></StyledModal>

    </CardBox>
  </>;
}
